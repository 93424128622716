import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SwipeAction, Dialog, Button, Ellipsis, SearchBar } from 'antd-mobile';
import cls from 'classnames';
import type { HotTagsItem } from '@/apis/canteen';
import './index.less';

interface IProps {
  tagList: HotTagsItem[];
  activeTagCode?: string;
  onSelect?: (code: string) => void;
}

const Tags: React.FC<IProps> = (props) => {
  const { tagList, activeTagCode, onSelect } = props;
  return (
    <div className="tags-container">
      <div className="tags-content">
        {(tagList || []).map((i) => {
          const { code, label } = i || {};
          return (
            <div
              key={code}
              className={cls('item', { selected: activeTagCode === code })}
              onClick={() => {
                onSelect && onSelect(activeTagCode === code ? '' : code!);
              }}>
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tags;
