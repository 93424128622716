import type { CSSProperties, FC } from 'react';
import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { Image } from 'antd-mobile';
import type { ProductInfoCustomizationItem, ProductInfoCustomizationItemValueItem } from '@/apis/product/types';
import { getCDNAssets } from '@/utils';

import type { CustomizationSingleTitleProps } from './Head';
import { CustomizationSingleTitle } from './Head';

import styles from './styles/singlespecial.module.less';

export type SingleSpecialViewProps = {
  className?: string;
  style?: CSSProperties;
  items?: ProductInfoCustomizationItem[];
  showTitleBar?: boolean;
  titleBarProps?: Partial<CustomizationSingleTitleProps>;
  onSelect?: (item: ProductInfoCustomizationItem, value: ProductInfoCustomizationItemValueItem) => void;
};

const SingleSpecialView: FC<SingleSpecialViewProps> = (props) => {
  const { className, style, items, showTitleBar, titleBarProps, onSelect } = props;

  const showBar = useMemo(() => items && !isEmpty(items) && showTitleBar, [items, showTitleBar]);

  return (
    <div className={className} style={style}>
      {showBar && <CustomizationSingleTitle {...titleBarProps} />}
      {items?.map((i, index) => (
        <div key={`${String(i.code)}${index}`}>
          <div className={styles.specialTitle}>{i.name}(单选)</div>
          <div className={styles.specialWrapper}>
            {i.values?.map((v, idx) => {
              const checked = v.checked === 1;
              const icon = checked
                ? getCDNAssets('detail_special_white_selected.png')
                : i.soldOut
                ? getCDNAssets('detail_special_white_soldout.png')
                : undefined;

              return (
                <div
                  key={`${String(v.code)}${String(v.code)}${idx}`}
                  className={styles.specialItem}
                  onClick={() => (i.soldOut ? undefined : onSelect?.(i, v))}>
                  <div className={styles.specialItemImgView} data-checked={checked}>
                    <Image
                      placeholder={null}
                      fallback={
                        <img className={styles.specialItemImgViewImg} src={getCDNAssets('menu_list_default_img.png')} />
                      }
                      className={styles.specialItemImgViewImg}
                      fit="scale-down"
                      src={v.image}
                    />
                    {icon && <Image className={styles.specialItemImgViewIcon} fit="scale-down" src={icon} />}
                  </div>
                  <div className={styles.specialItemName} data-checked={checked}>
                    {v.name}
                  </div>
                  {i.soldOut && <div className={styles.specialItemSoldOutMask} />}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SingleSpecialView;
