import type { FC, CSSProperties, MouseEventHandler } from 'react';
import { useMemo, useState, useCallback, memo } from 'react';
import classNames from 'classnames';

import { Image } from 'antd-mobile';

import type { ProductInfoComboItem, ProductInfoComboProductType, ProductInfoCustomization } from '@/apis/product/types';
import { getCDNAssets } from '@/utils';
import { getExtraCustomizationCacheKey } from '../useEditor';
import { getCustomizationItemsTotalPrice } from '../func';

import styles from './styles/combodetail.module.less';

const MAX_DISPLAY_COMBO_LENGTH = 6;

const PriceNode: FC<{ diffPrice?: number; extraPrice?: number }> = (props) => {
  const { diffPrice, extraPrice = 0 } = props;

  const [unit, priceTxt] = useMemo(() => {
    if (diffPrice && diffPrice > 0) {
      const _txt = `¥${(diffPrice + extraPrice) / 100}`;
      return ['+', _txt];
    }

    if (diffPrice && diffPrice < 0) {
      const _txt = `¥${((diffPrice + extraPrice) * -1) / 100}`;
      return ['-', _txt];
    }

    return ['', ''];
  }, [diffPrice, extraPrice]);

  return (
    <div className={styles.comboPrice}>
      {diffPrice === 0 ? (
        '¥0'
      ) : (
        <div>
          {unit && <span className={styles.comboPriceUnit}>{unit}</span>}
          {priceTxt && <span>{priceTxt}</span>}
        </div>
      )}
    </div>
  );
};

export type ComboItemProps = {
  className?: string;
  style?: CSSProperties;
  choicesType?: number;
  item?: ProductInfoComboProductType;
  itemExtraCustomization?: ProductInfoComboProductType['customization'];
  /** for choicesType=1 */
  selected?: boolean;
  onCustom?: MouseEventHandler<HTMLDivElement>;
  onSelect?: MouseEventHandler<HTMLDivElement>;
};

export const ComboItem = memo(function ComboItem(props: ComboItemProps) {
  const { className, style, choicesType, item, itemExtraCustomization, selected, onCustom, onSelect } = props;

  const tagImg = useMemo(
    () => (item?.tags && Array.isArray(item.tags) ? item.tags[0]?.image : undefined),
    [item?.tags],
  );

  const isDefault = useMemo(
    () => (choicesType === 0 && item?.isDefault === 1) || selected,
    [choicesType, item?.isDefault, selected],
  );

  const exterPrice = useMemo(
    () => getCustomizationItemsTotalPrice(itemExtraCustomization?.items),
    [itemExtraCustomization],
  );

  const onCustomClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.stopPropagation();

      onCustom?.(e);
    },
    [onCustom],
  );

  return (
    <div
      className={classNames(styles.comboItemWrapper, className)}
      style={style}
      data-selected={selected}
      data-choicestype={choicesType}
      onClick={onSelect}>
      {tagImg && <Image className={styles.comboTagImg} placeholder={null} src={tagImg} />}
      <div className={styles.comboInfo}>
        <Image
          className={styles.comboImg}
          placeholder={null}
          fit="scale-down"
          fallback={<img className={styles.comboImgFallback} src={getCDNAssets('menu_list_default_img.png')} />}
          src={item?.image}
        />
        <div className={styles.comboName} data-default={isDefault}>
          {item?.name}
        </div>

        {choicesType === 1 && <PriceNode diffPrice={item?.diffPrice} extraPrice={exterPrice} />}
      </div>
      {item?.hasCustomization === 1 && (choicesType === 0 || (choicesType !== 0 && selected)) && (
        <div className={styles.comboCustomBtn} onClick={onCustomClick}>
          定制
        </div>
      )}
      {/* custom button placeholder */}
      {item?.hasCustomization === 1 && choicesType !== 0 && !selected && (
        <div className={styles.comboCustomBtnPlaceholder} />
      )}
      {choicesType === 1 && (
        <Image
          className={styles.comboSelectImg}
          placeholder={null}
          fallback={<img src={getCDNAssets('menu_list_default_img.png')} />}
          src={selected ? getCDNAssets('detail-item-sel.png') : getCDNAssets('detail-item-nor.png')}
        />
      )}
    </div>
  );
});

export type ComboDetailProps = {
  className?: string;
  style?: CSSProperties;
  items?: ProductInfoComboItem[];
  itemsExtraCustomizationObj?: Record<string, ProductInfoCustomization>;
  onSelect?: (combo: ProductInfoComboItem, comboProduct: ProductInfoComboProductType) => void;
  onCustom?: (choicesType: number, combo: ProductInfoComboItem, comboProduct: ProductInfoComboProductType) => void;
};

const ComboDetail: FC<ComboDetailProps> = (props) => {
  const { className, style, items, itemsExtraCustomizationObj, onSelect, onCustom } = props;
  const [unfoldIds, setUnfoldIds] = useState<number[]>([]);

  const [comboNoChoices, comboCanChoices] = useMemo(() => {
    const _noChoices: ProductInfoComboItem[] = [];
    const _canChoices: ProductInfoComboItem[] = [];
    items?.forEach((i) => {
      if (i.isChoices === 0) {
        _noChoices.push(i);
      } else {
        _canChoices.push(i);
      }
    });

    return [_noChoices, _canChoices];
  }, [items]);

  const onFlodClick = useCallback((isFold: boolean, index: number) => {
    if (isFold) {
      setUnfoldIds((d) => (d.includes(index) ? d : [...d, index]));
    } else {
      setUnfoldIds((d) => d.filter((i) => i !== index));
    }
  }, []);

  return (
    <div className={className} style={style}>
      {comboNoChoices && comboNoChoices.length > 0 && (
        <div className={styles.choicesWrapper}>
          <div className={styles.itemTitle}>已包含</div>
          <div className={styles.itemChoices}>
            {comboNoChoices.map((c, index) =>
              c.comboProducts?.map((p) => (
                <ComboItem
                  key={`combonoitem${String(p.code)}${index}`}
                  className={styles.itemChoice}
                  choicesType={0}
                  item={p}
                  onCustom={() => onCustom?.(0, c, p)}
                />
              )),
            )}
          </div>
        </div>
      )}

      {comboCanChoices &&
        comboCanChoices.length > 0 &&
        comboCanChoices.map((c, index) => {
          const isFold = !unfoldIds.includes(index);
          const displayProducts = isFold ? c.comboProducts?.slice(0, MAX_DISPLAY_COMBO_LENGTH) : c.comboProducts;

          return (
            <div key={`combocanitem${String(c.name)}${index}`}>
              <div className={styles.itemTitle}>{c.name}(单选)</div>
              <div className={styles.itemChoices}>
                {displayProducts?.map((p) => {
                  const extraCustomizationCacheKey = p.code && getExtraCustomizationCacheKey(c, p.code);
                  const _customization =
                    (extraCustomizationCacheKey &&
                      itemsExtraCustomizationObj &&
                      itemsExtraCustomizationObj[extraCustomizationCacheKey]) ||
                    undefined;

                  return (
                    <ComboItem
                      key={`combocanitempro${String(p.code)}${index}`}
                      className={styles.itemChoice}
                      choicesType={1}
                      item={p}
                      itemExtraCustomization={_customization}
                      selected={p.isDefault === 1}
                      onSelect={() => onSelect?.(c, p)}
                      onCustom={() => onCustom?.(1, c, p)}
                    />
                  );
                })}
              </div>

              {c.comboProducts && c.comboProducts.length > MAX_DISPLAY_COMBO_LENGTH && (
                <div className={styles.moreWrapper}>
                  <div className={styles.moreButton} onClick={() => onFlodClick(isFold, index)}>
                    {isFold ? '更多' : '收起'}
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default ComboDetail;
