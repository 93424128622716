import { List, Switch } from 'antd-mobile';
import { useAppConfig } from '@/hooks/uesAppConfig';

export const EnvChangePage = () => {
  const {
    apiDomain,
    setApiDomain,
    disabledAPIPerf,
    setDisabledAPIPerf,
    disabledEventTracker,
    setDisabledEventTracker,
  } = useAppConfig();

  upsdk.setNavigationBarTitle({ title: 'M站title' });

  return (
    <>
      <List header="API Endpoint">
        <List.Item>当前环境 - {apiDomain}</List.Item>
        <List.Item
          onClick={() => {
            setApiDomain('https://api.mcdchina.net');
          }}>
          设置为生产环境
        </List.Item>
        <List.Item
          onClick={() => {
            setApiDomain('https://api-uat.mcdchina.net');
          }}>
          设置为UAT环境
        </List.Item>
        <List.Item
          onClick={() => {
            setApiDomain('https://api-sit.mcdchina.net');
          }}>
          设置为SIT环境
        </List.Item>
      </List>
      <List header="Tracker">
        <List.Item
          extra={
            <Switch
              checked={disabledAPIPerf}
              onChange={(val) => {
                setDisabledAPIPerf(val);
              }}
            />
          }>
          关闭API性能埋点
        </List.Item>
        <List.Item
          extra={
            <Switch
              checked={disabledEventTracker}
              onChange={(val) => {
                setDisabledEventTracker(val);
              }}
            />
          }>
          关闭神策埋点
        </List.Item>
      </List>
    </>
  );
};
