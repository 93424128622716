import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IndexBar, List } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@/store/';
import { SelectCityTag, updateStoreInfo, updatePlace } from '@/store/addressSlice';
import type { CityGroup, CategoryInfo, CityInfo } from '@/apis/address';
import { fetchAllCities } from '@/apis/address';
import useLocationInfo from '@/hooks/useLocationInfo';
import Header from '@/components/Header';
import useTitle from '@/hooks/useTitle';
import CityBtn from './CityBtn';
import './index.less';
import { trackEvent } from '@/utils/tracker';

type CustomCategoryInfo = CategoryInfo & {
  isLocation?: boolean;
  isHotCity?: boolean;
};

const SelectPlace = () => {
  useTitle('选择城市');
  const selectCityTag = useSelector((state: RootState) => state.address.selectCityTag);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { locationAndCityInfo, refetch } = useLocationInfo();

  const [cityGroup, setCityGroup] = useState<CityGroup>();

  const getAllCities = useCallback(() => {
    fetchAllCities()
      .then((res) => {
        setCityGroup(res!);
      })
      .catch(() => {});
  }, []);
  const init = useCallback(() => {
    getAllCities();
  }, [getAllCities]);

  useEffect(() => {
    init();
  }, []);

  const renderData = useMemo<CustomCategoryInfo[]>(() => {
    if (!cityGroup) return [];
    const { groups, hotCities } = cityGroup;
    const { loading, city, hadFetch } = locationAndCityInfo || {};
    const { code, name, latitude, longitude } = city || {};
    return [
      {
        isLocation: true,
        initial: '定位',
        cities: [
          {
            code,
            // hasStores: 1,
            // isHotCity: 1,
            latitude,
            longitude,
            name,
            // 处理定位case
            loading,
            isErr: hadFetch && (!city || !code),
          } as CityInfo,
        ],
      },
      { isHotCity: true, initial: '热门城市', cities: hotCities },
      ...groups,
    ];
  }, [cityGroup, locationAndCityInfo]);

  const selectCity = useCallback(
    (cityItem: CityInfo) => {
      const { code, name, latitude, longitude } = cityItem || {};
      if (!code) return;
      const newCity = {
        cityCode: code,
        cityName: name,
        latitude,
        longitude,
      };
      if (selectCityTag === SelectCityTag.canteen) {
        dispatch(updateStoreInfo(newCity));
      } else if (selectCityTag === SelectCityTag.address) {
        dispatch(updatePlace(newCity));
      }
      trackEvent('switchCity', {
        page_source: selectCityTag === SelectCityTag.canteen ? '选择餐厅页' : '选择地址页',
        city_name: name,
      });
      navigate(-1);
    },
    [dispatch, navigate, selectCityTag],
  );

  const renderBlock = useCallback((item: CustomCategoryInfo) => {
    const { isLocation, isHotCity, cities } = item || {};
    return (
      <div className="city-list">
        {(cities || []).map((i) => {
          if (isLocation) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { loading, isErr, code, name } = i;
            return (
              <CityBtn
                isLocation={isLocation}
                key={code}
                handleClick={() => {
                  if (loading) return;
                  if (isErr) {
                    refetch();
                    return;
                  }
                  selectCity(i);
                }}>
                {loading ? '定位中' : isErr ? '定位失败，点击重试' : name}
              </CityBtn>
            );
          } else if (isHotCity) {
            return (
              <CityBtn
                isLocation={isLocation}
                key={i.code}
                handleClick={() => {
                  selectCity(i);
                }}>
                {i.name}
              </CityBtn>
            );
          } else {
            return (
              <div
                key={i.code}
                className="city-item"
                onClick={() => {
                  selectCity(i);
                }}>
                {i.name}
              </div>
            );
          }
        })}
      </div>
    );
  }, []);

  return (
    <div className="select-city">
      {/* <Header title="选择城市" /> */}
      <div className="city-container">
        <IndexBar>
          {renderData.map((groupItem) => {
            const { initial } = groupItem;
            return (
              <IndexBar.Panel index={initial} title={initial} key={`${initial}`}>
                {renderBlock(groupItem)}
              </IndexBar.Panel>
            );
          })}
        </IndexBar>
      </div>
    </div>
  );
};

export default SelectPlace;
