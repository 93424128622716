import type { CSSProperties, FC } from 'react';

import { SafeArea } from 'antd-mobile';

export const CART_SAFE_GAP = 75;

export type CartSafeGapProps = {
  className?: string;
  style?: CSSProperties;
  /** 75px */
  gap?: CSSProperties['height'];
};

const defaultProps: Partial<CartSafeGapProps> = {
  gap: CART_SAFE_GAP,
};

const CartSafeGap: FC<CartSafeGapProps> = (props) => {
  const { className, style, gap } = props;

  return (
    <div className={className} style={style}>
      <div style={{ height: gap }} />
      <SafeArea position="bottom" />
    </div>
  );
};

CartSafeGap.defaultProps = defaultProps;

export default CartSafeGap;
