import { useEffect } from 'react';
import { HOME, ORDER, MY } from '@/constants/path';

const useTitle = (title: string, bgColor?: string) => {
  useEffect(() => {
    upsdk.setNavigationBarTitle({ title });
  }, [title]);

  useEffect(() => {
    upsdk.registerLifecycle({
      onAppShow: function () {
        upsdk.setNavigationBarTitle({ title });
      },
      onPageShow: function () {
        upsdk.setNavigationBarTitle({ title });
      },
    });
  }, []);

  useEffect(() => {
    const isHideBackBtn = [HOME, ORDER, MY].includes(location.pathname);
    upsdk.setTitleStyle({
      backBtnVisible: isHideBackBtn ? '0' : '1', // 可选，左侧返回按钮是否显示。'0'不显示，'1'显示，不传或空则默认显示
      navBackgroundColor: bgColor || '0x8FFFFFFF',
      appletStyle: 'black',
    });
  }, []);
};

export default useTitle;
