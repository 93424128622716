import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from '@/store';
import type { DeliveryDateInfoType } from '@/store/productSlice';
import {
  updateStore as storeUpdateStore,
  switchStoreAsync,
  updateAddress,
  updateCurrentLocation,
  updateDateInfo,
  updataNewAddressId,
  updataNewStoreCode,
} from '@/store/productSlice';
import type { StoreItem, MemberAddressItem, PoisGeocodeItem } from '@/apis/product/types';
import type { GetStoreByStoreCodeQuery } from '@/apis/product';
import { parseObj } from '@/utils';

export const DELIVERY_ADDRESS_KEY = 'DELIVERY_ADDRESS_KEY';
export const PICKUP_STORE_CODE_KEY = 'PICKUP_STORE_CODE_KEY';

const canUseLocalStorage = typeof localStorage !== undefined;

/**
 * 获取当前店铺信息
 * @param orderType
 */
export function useStore(orderType = 1) {
  const productstate = useSelector((s: RootState) => s.product);
  const dispatch = useDispatch<AppDispatch>();

  const { storeInfo, localCacheStoreCode, deliveryAddressInfo, curLocatioon, curDateInfo, newStoreCode, newAddressId } =
    useMemo(() => {
      if (Number(orderType) === 1) {
        const { curStore, newStoreCode: _newStoreCode } = productstate.pickup;
        const _localCacheStoreCode = (canUseLocalStorage && localStorage.getItem(PICKUP_STORE_CODE_KEY)) || undefined;
        return { storeInfo: curStore, localCacheStoreCode: _localCacheStoreCode, newStoreCode: _newStoreCode };
      }

      if (Number(orderType) === 2) {
        const { curStore, address, currentLocation, dateInfo, newAddressId: _newAddressId } = productstate.delivery;
        const _address =
          address ||
          (canUseLocalStorage && (parseObj(localStorage.getItem(DELIVERY_ADDRESS_KEY)) as MemberAddressItem)) ||
          undefined;

        return {
          storeInfo: curStore,
          deliveryAddressInfo: _address,
          curLocatioon: currentLocation,
          curDateInfo: dateInfo,
          newAddressId: _newAddressId,
        };
      }

      return {};
    }, [productstate, orderType]);

  const updateStore = useCallback(
    (item: StoreItem | undefined) => {
      // sync to local
      if (canUseLocalStorage) {
        localStorage.setItem(PICKUP_STORE_CODE_KEY, item?.code || '');
      }
      return dispatch(storeUpdateStore({ orderType, store: item }));
    },
    [dispatch, orderType],
  );
  const updateDeliveryAddress = useCallback(
    (info?: MemberAddressItem) => {
      // sync to local
      if (canUseLocalStorage) {
        localStorage.setItem(DELIVERY_ADDRESS_KEY, info ? JSON.stringify(info) : '');
      }

      return dispatch(updateAddress(info));
    },
    [dispatch],
  );

  const updateDeliveryCurLocation = useCallback(
    (item?: PoisGeocodeItem) => {
      return dispatch(updateCurrentLocation(item));
    },
    [dispatch],
  );

  const updateDeliveryDateInfo = useCallback(
    (info?: DeliveryDateInfoType) => {
      return dispatch(updateDateInfo(info));
    },
    [dispatch],
  );

  const switchStore = useCallback(
    (query: GetStoreByStoreCodeQuery) => dispatch(switchStoreAsync({ orderType, ...query })),
    [dispatch, orderType],
  );

  const clearExternalStoreData = useCallback(() => {
    dispatch(updataNewStoreCode(undefined));
    dispatch(updataNewAddressId(undefined));
  }, [dispatch]);

  return {
    /** for ordertype 2. 临时定位地址（无 addressId） */
    curLocatioon,
    /** for ordertype 2. 当前外卖地址信息 */
    deliveryAddressInfo,
    /** for ordertype 2. 外卖预约时间信息 */
    /** for ordertype 1. 外部更新的新 store code */
    newStoreCode,
    /** for ordertype 2. 外部更新的新 address id */
    newAddressId,
    curDateInfo,
    storeInfo,
    localCacheStoreCode,
    status: productstate.status,
    updateStore,
    switchStore,
    /** for ordertype 2. 更新地址 */
    updateDeliveryAddress,
    /** for ordertype 2. 更新临时地址 */
    updateDeliveryCurLocation,
    /** for ordertype 2. 更新外卖预约时间信息 */
    updateDeliveryDateInfo,
    /** for external. reset all */
    clearExternalStoreData,
  };
}

export function useStoreActionsForExternal() {
  const dispatch = useDispatch<AppDispatch>();

  const updateProductNewCodeId = useCallback(
    (storeCode?: string, addressId?: string) => {
      dispatch(updataNewStoreCode(storeCode));
      dispatch(updataNewAddressId(addressId));
    },
    [dispatch],
  );

  return {
    updateProductNewCodeId,
  };
}
