import { useState, useRef } from 'react';
import { Button, Form, Input } from 'antd-mobile';
import './index.less';
import { useNavigate } from 'react-router-dom';
import { fetchLogoutVerificationl } from '@/apis/my';

interface iProps {
  tel?: string;
  scene?: number;
  showCouponCardBottom?: boolean;
  vercodeSuccess?: (code: string) => void;
}
/**
 * showInstructions 是否展示使用说明
 */
const CommonCouponCard: React.FC<iProps> = (props) => {
  const { tel, vercodeSuccess } = props || {};
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState('');
  const [leftTime, setLeftTime] = useState(0);
  const [focus, setFocus] = useState(true);
  const isSubmit = useRef(true);
  const [verCode, setVerCode] = useState('');
  const request = async () => {
    if (!isSubmit.current) {
      return;
    }
    const phone = '18162437989'; //aesEncrypt(tel);
    isSubmit.current = false;
    await fetchLogoutVerificationl({ tel: tel!, type: 4 });
    submit();
    isSubmit.current = true;
  };
  const verication = (value: string) => {
    if (value.length == 0) {
      setErrMsg('');
    } else if (value.length != 6) {
      setErrMsg('验证码格式错误');
    }
  };

  const vericationOk = (value: string) => {
    if (value.length == 6) {
      setErrMsg('');
      vercodeSuccess!(value);
    } else {
      vercodeSuccess!('');
    }
  };
  const submit = () => {
    let leftT = 29;
    setLeftTime(leftT);
    const interTimer = setInterval(() => {
      if (leftT == 0) {
        clearInterval(interTimer);
        return;
      }
      leftT--;
      setLeftTime(leftT);
    }, 1000);
  };
  return (
    <div className="verify-code">
      <span className="verify-phone-label">验证码 </span>
      <div className="verify-code-input">
        <div className="verify-code-input-row">
          <Input
            className="verify-phone-input"
            maxLength={6}
            // type="number"
            clearable
            placeholder="请输入验证码"
            value={verCode}
            onChange={(value: string) => {
              if (value?.length > 5) {
                setFocus(false);
              }
              vericationOk(value);
              if (value.length == 0) {
                setErrMsg('');
              }
              setVerCode(value);
            }}
            onBlur={() => {
              verication(verCode);
            }}
          />
          <Button
            size="mini"
            shape="rounded"
            color="primary"
            disabled={leftTime != 0}
            onClick={() => {
              request()
                .then(() => {})
                .catch(() => {});
            }}>
            {leftTime == 0 ? '获取验证码' : '重新获取(' + leftTime.toString() + ')'}
          </Button>
        </div>

        <span className="verify-phone-text">{errMsg}</span>
        <div className={errMsg.length > 0 ? 'verify-code-line-error' : 'verify-code-line'} />
      </div>
    </div>
  );
};

export default CommonCouponCard;
