import type { CSSProperties, FC, MouseEventHandler, MouseEvent } from 'react';
import { useMemo, useCallback } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Image } from 'antd-mobile';
import type { PromotionCouponsCardMenuDiscount, PromotionCouponsCardDiscountItem } from '@/apis/product/types';
import { getCDNAssets } from '@/utils';

import styles from './index.module.less';

const DEFAULT_MODULE_IMG = 'https://img.mcd.cn/gallery/244520201201034409310.png';
const DEFAULT_BG_IMG = 'https://img.mcd.cn/gallery/89820201201034409431.png';

export type ProductMenuCouponDiscountProps = {
  className?: string;
  style?: CSSProperties;
  selectedCodes?: string[];
  data?: PromotionCouponsCardMenuDiscount;
  onMore?: MouseEventHandler<HTMLDivElement>;
  onAdd?: (item: PromotionCouponsCardDiscountItem) => unknown;
  onCardClick?: (item: PromotionCouponsCardDiscountItem) => unknown;
};

const ProductMenuCouponDiscount: FC<ProductMenuCouponDiscountProps> = (props) => {
  const { className, style, selectedCodes, data, onAdd, onCardClick, onMore } = props;

  // TODO 无可领取券展示
  const discountItems = useMemo(
    () => data?.discountItems?.filter((i) => i.code !== 'CPN_KINGS_LIFE'),
    [data?.discountItems],
  );
  const itemsLength = useMemo(() => discountItems?.length || 0, [discountItems?.length]);

  const isItemDisabled = useCallback(
    (code?: string) => {
      if (code && selectedCodes && !isEmpty(selectedCodes)) {
        return selectedCodes.includes(code);
      }
      return false;
    },
    [selectedCodes],
  );

  const onAddClick = useCallback(
    (e: MouseEvent<HTMLDivElement>, item: PromotionCouponsCardDiscountItem) => {
      e.stopPropagation();

      if (!isItemDisabled(item?.code)) {
        onAdd?.(item);
      }
    },
    [isItemDisabled, onAdd],
  );

  const onItemClick = useCallback(
    (e: MouseEvent<HTMLDivElement>, item: PromotionCouponsCardDiscountItem) => {
      e.stopPropagation();
      onCardClick?.(item);
    },
    [onCardClick],
  );

  if (!data || isEmpty(data) || !data?.totalCouponCount) {
    return null;
  }
  const moduleImage = data.moduleImage || DEFAULT_MODULE_IMG;
  const bgImage = data.bgImage || DEFAULT_BG_IMG;
  const isSingleItem = itemsLength === 1;

  return (
    <div
      className={classNames(styles.wrapper, className)}
      style={{ backgroundImage: `url(${bgImage})`, height: itemsLength > 2 ? '172px' : '138px', ...style }}>
      <div className={styles.titleWrapper}>
        <Image className={styles.titleImg} placeholder={null} src={moduleImage} />
        <div className={styles.titleNum} onClick={onMore}>
          我的优惠券共<span className={styles.titleNumCount}>{data.totalCouponCount}</span>张
          <Image className={styles.titleNumIcon} placeholder={null} src={getCDNAssets('menu_coupon_count_arrow.png')} />
        </div>
      </div>
      <div className={styles.content} data-total={itemsLength}>
        {discountItems?.map((i) => {
          if (!i) {
            return null;
          }

          const disabled = isItemDisabled(i.code);

          return (
            <div key={i.id} className={styles.item} data-more={itemsLength > 3} onClick={(e) => onItemClick(e, i)}>
              {isSingleItem && (
                <Image
                  className={styles.itemLeft}
                  placeholder={null}
                  src={getCDNAssets('menu_coupon_item_white.png')}
                />
              )}
              <div className={styles.itemRight} data-total={itemsLength}>
                <Image
                  className={styles.itemImg}
                  data-total={itemsLength}
                  placeholder={<Image placeholder={null} src={getCDNAssets('default.png')} />}
                  fallback={<Image placeholder={null} src={getCDNAssets('default.png')} />}
                  fit="scale-down"
                  src={i.image}
                />
                <div className={styles.itemTitleView} data-total={itemsLength}>
                  <div className={styles.itemTitleText} data-ellipsis={itemsLength >= 3}>
                    {i.title}
                  </div>
                  {isSingleItem && <div className={styles.itemTimeRange}>{i.timeRange}</div>}
                </div>
                {isSingleItem ? (
                  <div className={styles.itemSignleBtn} data-disabled={disabled} onClick={(e) => onAddClick(e, i)}>
                    去使用
                  </div>
                ) : (
                  <div className={styles.itemAddBtn} data-absolute={itemsLength >= 3} onClick={(e) => onAddClick(e, i)}>
                    <Image
                      placeholder={null}
                      src={
                        disabled ? getCDNAssets('home_add_invalid.png') : getCDNAssets('home_coupon_item_add_icon.png')
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {itemsLength > 3 && (
          <div className={styles.showMoreBtn} onClick={onMore}>
            <div className={styles.showMoreBtnText}>更多优惠</div>
            <Image
              className={styles.showMoreBtnIcon}
              placeholder={null}
              src={getCDNAssets('menu_coupon_more_icon.png')}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductMenuCouponDiscount;
