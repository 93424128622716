import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { fetchCashierChannels, preOrder } from '@/apis/orderPay';
import type { ChannelInfos } from '@/apis/orderPay.type';
import { formatPrice } from '@/utils';
import './index.less';
import { Toast, Radio, Button } from 'antd-mobile';
import useTitle from '@/hooks/useTitle';
const defaultChannel = 'UNION';
import { ORDER_DETAIL, ORDER } from '@/constants/path';
import unionIcon from '@/assets/union-icon.png';
import unionPayIcon from '@/assets/union-pay.png';
import { logPayOrder } from './log';
import useLocationInfo from '@/hooks/useLocationInfo';

const Pay = () => {
  useTitle('收银台');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { orderId, payId, source } = Object.fromEntries(searchParams.entries());

  const { locationAndCityInfo } = useLocationInfo();

  const [channelList, setChannelList] = useState<ChannelInfos[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<string>(defaultChannel);
  const [realPay, setRealPay] = useState<number>();

  const getCashierChannels = useCallback(() => {
    fetchCashierChannels({
      orderId,
      payId,
      source,
    })
      .then((res) => {
        const { channelInfos, realTotalAmount } = res || {};
        channelInfos && setChannelList(channelInfos);
        realTotalAmount && setRealPay(realTotalAmount);
      })
      .catch(() => {
        Toast.show('请求失败！');
      });
  }, [orderId, payId, source]);

  const init = useCallback(() => {
    getCashierChannels();
  }, [getCashierChannels]);

  const backToOrderList = useCallback(() => {
    navigate(ORDER, { replace: true });
  }, []);

  useEffect(() => {
    init();
    window.addEventListener('popstate', backToOrderList, { once: true });
  }, []);

  const payChannelList = useMemo(() => {
    // TODO: UNION
    const allowCodeList = [defaultChannel];
    return channelList.filter(({ code }) => allowCodeList.includes(code!));
  }, [channelList]);

  const selectedChannelInfo = useMemo(() => {
    return payChannelList.find(({ code }) => code === selectedChannel);
  }, [selectedChannel, payChannelList]);

  const { code: payChannel, name: showText } = selectedChannelInfo || {};

  const arousePay = useCallback(
    (tn: string) => {
      upsdk.pay({
        tn,
        success: function () {
          window.removeEventListener('popstate', backToOrderList);
          navigate(ORDER_DETAIL + '?orderId=' + orderId, { replace: true });
          // 支付成功, 开发者执行后续操作。
          logPayOrder(orderId, locationAndCityInfo?.nearestStoreInfo, { isSuccess: true });
        },
        fail: function (err) {
          const errMsg = err?.msg || '支付失败';
          Toast.show(errMsg);
          // 支付失败, err.msg 是失败原因描述, 比如TN号不合法, 或者用户取消了交易 等等。
          // navigate(ORDER_DETAIL + '?orderId=' + orderId);
          logPayOrder(orderId, locationAndCityInfo?.nearestStoreInfo, { isError: true, errMsg: errMsg });
        },
      });
    },
    [orderId],
  );

  const pay = useCallback(() => {
    payChannel &&
      preOrder({
        payId,
        source: Number(source),
        payChannel,
      })
        .then((res) => {
          const { channelPayData } = res || {};
          if (channelPayData) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const { tn } = JSON.parse(channelPayData);
            arousePay(tn as string);
          }
        })
        .catch((err: Error) => {
          Toast.show(err.message || '请求失败！');
        });
  }, [payChannel, payId, source, arousePay]);

  const renderItemName = useCallback((code: string, name: string) => {
    if (code === defaultChannel) {
      return (
        <div className="name-wrapper">
          <img className="prefix-icon" src={unionIcon} />
          <span className="name">{name}</span>
          <img className="post-icon" src={unionPayIcon} />
        </div>
      );
    }
    return name;
  }, []);

  const payDom = useMemo(() => {
    const payStr = String(formatPrice(realPay));
    const [intPay, pointPay] = payStr.split('.');
    return (
      <span className="val">
        <span className="int">{intPay}</span>
        {pointPay ? (
          <>
            .<span className="point">{pointPay}</span>
          </>
        ) : null}
      </span>
    );
  }, [realPay]);

  return (
    <div className="pay-container">
      <div className="pay-info">
        <div className="title">需支付</div>
        <div className="amount">
          <span className="symbol">¥</span>
          {/* {formatPrice(realPay)} */}
          {payDom}
        </div>
      </div>
      <div className="divider" />
      <div className="channel">选择支付方式</div>
      <Radio.Group
        value={selectedChannel}
        onChange={(val) => {
          setSelectedChannel(val as string);
        }}>
        {payChannelList.map((i) => {
          const { code, name } = i || {};
          return (
            <Radio key={code} value={code}>
              {renderItemName(code!, name!)}
            </Radio>
          );
        })}
      </Radio.Group>

      <div className="btn-wrapper">
        <Button color="primary" shape="rounded" disabled={!payChannel} onClick={pay}>
          {payChannel ? `使用${showText || ''}支付` : '请选择支付方式'}
        </Button>
      </div>
    </div>
  );
};

export default Pay;
