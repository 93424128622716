/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDeepCompareEffect } from 'ahooks';
import { useImmer } from 'use-immer';

import type { CartCustomization_, CartDetailResponse, CartInfoParams, MenuCartResponseProduct } from '@/apis/cart';
import type { PutUpdateCartBody } from '@/apis/product/index';
import type {
  ProductData,
  ProductInfo,
  ProductInfoComboItem,
  ProductInfoComboProductType,
  ProductInfoCustomization,
  ProductInfoCustomizationItem,
  ProductInfoCustomizationItemValueItem,
  ProductInfoCustomizationOption,
} from '@/apis/product/types';
import { useCartAction, useCartState } from '@/hooks/useCart';

import { getCustomizationItemsTotalPrice } from './func';

/**
 * get extraCustomizationCache key
 */
export const getExtraCustomizationCacheKey = (combo: ProductInfoComboItem, producCode: string): string => {
  return `${combo.choicesCode || ''}#${combo.idx || ''}-${producCode}`;
};

/**
 *
 * @param product
 * @param extraCache
 */
export const getProductComboAndCustomizationDiffPrice = (
  product?: ProductInfo,
  extraCache?: Record<string, ProductInfoCustomization>,
) => {
  let _price = 0;

  if (product?.comboItems) {
    product.comboItems.forEach((c) => {
      if (c.comboProducts) {
        const _p = c.comboProducts.find((p) => p.isDefault);
        if (_p?.diffPrice) {
          _price += _p.diffPrice;
        }

        if (_p?.code && extraCache && extraCache[getExtraCustomizationCacheKey(c, _p.code)]) {
          const _extraCustomization = extraCache[getExtraCustomizationCacheKey(c, _p.code)];
          if (_extraCustomization?.items) {
            _price += getCustomizationItemsTotalPrice(_extraCustomization.items);
          }
        }
      }
    });
  }

  if (product?.customization?.items) {
    _price += getCustomizationItemsTotalPrice(product.customization.items);
  }

  return _price;
};

export function fmtProductCacheToPutBody(
  quantity?: number,
  matchedSpecsCode?: string,
  body?: Omit<PutUpdateCartBody, 'products'>,
  productCache?: ProductData['product'],
  extraCustomizationCache?: Record<string, ProductInfoCustomization>,
  /** 编辑 FROM CART */
  fromCartPrevData?: MenuCartResponseProduct,
  /** for addToCart. coupon info */
  otherCouponInfo?: { couponId?: string; couponCode?: string; promotionId?: string },
): PutUpdateCartBody | undefined {
  if (quantity && body && productCache) {
    const cutProduct =
      productCache.type === 3
        ? productCache?.products?.find((p) => p.matchedSpecsCode === matchedSpecsCode)
        : productCache;

    const type = Number(cutProduct?.type);

    // TODO delete
    console.info(type, productCache, extraCustomizationCache);

    if (!cutProduct) {
      return undefined;
    }

    const fmtCustomization = (item?: ProductInfoCustomization): ProductInfoCustomization | undefined => {
      if (item) {
        const { items, options } = item;
        if (items && Array.isArray(items)) {
          const _items = items.map((i) => {
            const _vs = i.values?.filter((v) => v.checked === 1);
            return { ...i, values: _vs };
          });
          return { items: _items, options };
        }

        return { items: [], options };
      }
      return undefined;
    };

    const fmtComboItems = (items?: ProductInfoComboItem[]): ProductInfoComboItem[] | undefined => {
      if (items && Array.isArray(items)) {
        return items.map((i) => {
          const _comboProducts = i.comboProducts
            ?.filter((p) => p.isDefault === 1)
            ?.map((p) => {
              const _code = p.code;
              let customization = null;
              if (
                _code &&
                extraCustomizationCache &&
                extraCustomizationCache[getExtraCustomizationCacheKey(i, _code)]
              ) {
                customization = fmtCustomization(extraCustomizationCache[getExtraCustomizationCacheKey(i, _code)]);
              }

              return { code: p.code, customization };
            });

          return { ...i, comboProducts: _comboProducts };
        });
      }

      return undefined;
    };

    const _replacedDataFromCart =
      fromCartPrevData && fromCartPrevData.quantity
        ? { ...fromCartPrevData, quantity: -fromCartPrevData.quantity }
        : undefined;
    const _couponInfoFromCart = _replacedDataFromCart?.coupon
      ? {
          couponId: _replacedDataFromCart.coupon?.id,
          couponCode: _replacedDataFromCart.coupon?.code,
          promotionId: _replacedDataFromCart.coupon?.promotionId,
        }
      : undefined;

    if (type === 1) {
      const { code, customization, name } = cutProduct;

      const _product = {
        code,
        customization: fmtCustomization(customization),
        name,
        quantity,
        type: 1,
      };

      return {
        ...body,
        products: _replacedDataFromCart
          ? [_replacedDataFromCart, { ..._product, ..._couponInfoFromCart }]
          : [{ ..._product, ...otherCouponInfo }],
      } as PutUpdateCartBody;
    }

    if (type === 2) {
      const { code, customization, name, comboItems } = cutProduct;
      const _product = {
        code,
        customization: fmtCustomization(customization),
        comboItems: fmtComboItems(comboItems),
        name,
        quantity,
        type: 1,
      };

      return {
        ...body,
        products: _replacedDataFromCart
          ? [_replacedDataFromCart, { ..._product, ..._couponInfoFromCart }]
          : [{ ..._product, ...otherCouponInfo }],
      } as PutUpdateCartBody;
    }
  }
  return undefined;
}

function parseProductCustomizationCartToCache(customization: CartCustomization_): ProductInfoCustomization {
  const { items, options } = customization;

  const _items = items?.map((i) => {
    return { ...i, values: i.values?.map((v) => ({ ...v, checked: 1 })) };
  });

  return { items: _items, options } as ProductInfoCustomization;
}

export function parseProductCartToCache(
  pCache?: ProductData['product'],
  cartProduct?: MenuCartResponseProduct,
): [ProductData['product'] | undefined, Record<string, ProductInfoCustomization>] {
  if (pCache && cartProduct) {
    const { customization, comboItems } = cartProduct;
    let _rtnPData = { ...pCache };
    const _extraCustomizationCache: Record<string, ProductInfoCustomization> = {};

    if (pCache.customization && customization) {
      const { items, options } = pCache.customization;

      const _items = items?.map((i) => {
        const _i = customization.items?.find((ii) => ii.code === i.code);
        const _vs = i.values?.map((v) => {
          const _checkedValue = _i?.values?.[0];
          return { ...v, checked: _checkedValue?.code === v.code ? 1 : 0 };
        });
        return { ...i, values: _vs };
      });

      const _options = options?.map((o) => {
        const _o = customization.options?.find((oo) => oo.code === o.code);
        if (_o) {
          return { ...o, checked: _o.checked === 1 ? 1 : 0 };
        }
        return o;
      });

      if (items || options) {
        _rtnPData = { ..._rtnPData, customization: { items: _items, options: _options } };
      }
    }

    if (pCache.comboItems && comboItems) {
      const _comboItems = pCache.comboItems.map((c, index) => {
        const _cartProduct = comboItems[index]?.comboProducts?.[0];
        const _defaultPCode = _cartProduct?.code;

        if (_defaultPCode) {
          if (_cartProduct?.customization) {
            _extraCustomizationCache[getExtraCustomizationCacheKey(c, _defaultPCode)] =
              parseProductCustomizationCartToCache(_cartProduct.customization);
          }
          const _comboProducts = c.comboProducts?.map((p) => {
            return { ...p, isDefault: p.code === _defaultPCode ? 1 : 0 };
          });
          return { ...c, comboProducts: _comboProducts };
        }

        return c;
      });

      if (_comboItems && _comboItems.length > 0) {
        _rtnPData = { ..._rtnPData, comboItems: _comboItems };
      }
    }

    return [_rtnPData, _extraCustomizationCache];
  }

  return [pCache, {}];
}

function useGetProductForCart(productCode?: string, options?: { sequence?: number } & Partial<CartInfoParams>) {
  const [curCartProduct, setCurCartProduct] = useState<MenuCartResponseProduct>();
  const [cartstate, cartstateStatus] = useCartState(options?.orderType);
  const { syncTheLatest } = useCartAction();

  const findCartProduct = useCallback(
    (products?: MenuCartResponseProduct[]) =>
      (products || cartstate?.value?.products)?.find((p) => p.code === productCode && p.sequence === options?.sequence),
    [cartstate?.value?.products, options?.sequence, productCode],
  );
  const findCartProductRef = useRef(findCartProduct);
  findCartProductRef.current = findCartProduct;

  useDeepCompareEffect(() => {
    // 购物车编辑模式，无购物车数据时需要重新请求
    if (!cartstate?.value && cartstateStatus === 'idle' && options && productCode) {
      const { cartType, daypartCode, orderType, storeCode, sequence, ...restParams } = options;

      if (cartType && daypartCode && orderType && storeCode) {
        void syncTheLatest({
          cartType,
          daypartCode,
          orderType,
          storeCode,
          ...restParams,
        }).then((res) => {
          const _p = findCartProduct((res.payload as CartDetailResponse)?.products);
          setCurCartProduct(_p);
        });
      }
    }
  }, [options, cartstate, cartstateStatus, productCode, syncTheLatest]);

  useEffect(() => {
    const _p = findCartProductRef.current();
    setCurCartProduct(_p);
  }, [options?.sequence, productCode]);

  return curCartProduct;
}

export default function useProductDetailEditorCache(
  data?: ProductData['product'],
  fromCart?: { sequence?: number } & Partial<CartInfoParams>,
  otherCouponInfo?: { couponId?: string; couponCode?: string; promotionId?: string },
) {
  const [productCache, updateProductCache] = useImmer(data);

  // TODO 未与 matchedSpecsCode 进行绑定, 切换套餐会继承相同产品定制
  const [extraCustomizationCache, updateExtraCustomizationCache] = useImmer<Record<string, ProductInfoCustomization>>(
    {},
  );

  const { updateCart, loginBtnNode } = useCartAction();

  const isFromCart = useMemo(() => Boolean(fromCart), [fromCart]);

  const restProductCache = useCallback(() => {
    updateProductCache(data);
    updateExtraCustomizationCache({});
  }, [data, updateExtraCustomizationCache, updateProductCache]);

  useDeepCompareEffect(() => {
    if (!isFromCart) {
      updateProductCache(data);
    }
  }, [data, isFromCart]);

  // for fromCart: start-------------
  const curCartProduct = useGetProductForCart(data?.code, fromCart);
  const disabledCountStepper = useMemo(
    () =>
      Boolean(
        curCartProduct?.coupon &&
          Number(curCartProduct.coupon.showPmtType) === 1 &&
          Number(curCartProduct.coupon.rightCardType !== 3),
      ),
    [curCartProduct?.coupon],
  );
  const [initProductForCart, setInitProductForCart] = useState<{
    value?: ProductData['product'];
    extra?: Record<string, ProductInfoCustomization>;
  }>();
  useDeepCompareEffect(() => {
    if (isFromCart && fromCart?.sequence) {
      const [_c, _e] = parseProductCartToCache(data, curCartProduct);
      updateProductCache(_c);
      updateExtraCustomizationCache(_e);
      setInitProductForCart({ value: _c, extra: _e });
    }
  }, [fromCart?.sequence, isFromCart, data, curCartProduct]);
  // ---------------end fromCart

  // 多选
  const changeCustomizationOptionCheck = useCallback(
    (check: number, productCode?: string, option?: ProductInfoCustomizationOption) => {
      updateProductCache((d) => {
        if (d?.code === productCode) {
          const _index = d?.customization?.options?.findIndex((o) => o.code === option?.code);
          if (_index != null && d?.customization?.options) {
            d.customization.options[_index].checked = check;
          }
        } else if (d?.products) {
          const _pIdx = d.products.findIndex((p) => p.code === productCode);
          if (_pIdx != null) {
            const _oIdx = d.products[_pIdx].customization?.options?.findIndex((o) => o.code === option?.code);
            if (_oIdx && d.products[_pIdx].customization?.options) {
              d.products[_pIdx].customization!.options[_oIdx].checked = check;
            }
          }
        }
      });
    },
    [updateProductCache],
  );

  // 单选
  const changeCustomizationItemCheck = useCallback(
    (productCode?: string, item?: ProductInfoCustomizationItem, itemValue?: ProductInfoCustomizationItemValueItem) => {
      updateProductCache((d) => {
        if (d?.code === productCode) {
          const _iIdx = d?.customization?.items.findIndex((i) => i.code === item?.code);
          if (
            _iIdx != null &&
            d?.customization?.items[_iIdx].values?.find((v) => v?.code && itemValue?.code && v.code === itemValue.code)
          ) {
            const _values = d.customization.items[_iIdx].values?.map((v) => {
              if (v.code === itemValue?.code) {
                return { ...v, checked: 1 };
              }
              return { ...v, checked: 0 };
            });
            d.customization.items[_iIdx].values = _values;
          }
        } else if (d?.products) {
          const _pIdx = d.products.findIndex((p) => p.code === productCode);
          if (_pIdx != null) {
            const _iIdx = d.products[_pIdx].customization?.items.findIndex((i) => i.code === item?.code);
            if (
              _iIdx != null &&
              d.products[_pIdx].customization!.items[_iIdx].values?.find((v) => v?.code === itemValue?.code)
            ) {
              const _values = d.products[_pIdx].customization!.items[_iIdx].values?.map((v) => {
                if (v.code === itemValue?.code) {
                  return { ...v, checked: 1 };
                }
                return { ...v, checked: 0 };
              });

              d.products[_pIdx].customization!.items[_iIdx].values = _values;
            }
          }
        }
      });
    },
    [updateProductCache],
  );

  const changeComboItemsProductDefault = useCallback(
    (productCode?: string, combo?: ProductInfoComboItem, comboProduct?: ProductInfoComboProductType) => {
      if (productCode && combo && comboProduct) {
        updateProductCache((d) => {
          if (d?.code === productCode) {
            const _cIndex = d.comboItems?.findIndex((c) => c.idx === combo.idx);

            if (_cIndex != null) {
              const _comboProducts = d.comboItems![_cIndex].comboProducts?.map((p) => {
                if (p.code === comboProduct.code) {
                  return { ...p, isDefault: 1 };
                }
                return { ...p, isDefault: 0 };
              });

              d.comboItems![_cIndex].comboProducts = _comboProducts;
            }
          } else if (d?.products) {
            const _pIdx = d.products.findIndex((p) => p.code === productCode);
            if (_pIdx != null) {
              const _cIndex = d.products[_pIdx].comboItems?.findIndex((c) => c.idx === combo.idx);
              if (_cIndex != null) {
                const _comboProducts = d.products[_pIdx].comboItems![_cIndex].comboProducts?.map((p) => {
                  if (p.code === comboProduct.code) {
                    return { ...p, isDefault: 1 };
                  }
                  return { ...p, isDefault: 0 };
                });

                d.products[_pIdx].comboItems![_cIndex].comboProducts = _comboProducts;
              }
            }
          }
        });
      }
    },
    [updateProductCache],
  );

  const addToCart = useCallback(
    async (quantity?: number, matchedSpecsCode?: string, otherBody?: Omit<PutUpdateCartBody, 'products'>) => {
      const body = fmtProductCacheToPutBody(
        quantity,
        matchedSpecsCode,
        otherBody,
        productCache,
        extraCustomizationCache,
        undefined,
        otherCouponInfo,
      );

      if (!body) {
        throw new Error('加入购物车失败');
      }

      return await updateCart(body);
    },
    [extraCustomizationCache, otherCouponInfo, productCache, updateCart],
  );

  const saveToCart = useCallback(
    async (quantity?: number, matchedSpecsCode?: string, otherBody?: Omit<PutUpdateCartBody, 'products'>) => {
      const body = fmtProductCacheToPutBody(
        quantity,
        matchedSpecsCode,
        otherBody,
        productCache,
        extraCustomizationCache,
        curCartProduct,
      );

      if (!body) {
        throw new Error('保存失败');
      }

      return await updateCart(body);
    },
    [curCartProduct, extraCustomizationCache, productCache, updateCart],
  );

  return {
    productCache,
    updateProductCache,
    extraCustomizationCache,
    updateExtraCustomizationCache,
    /** fromCart */
    intCountFromCart: curCartProduct?.quantity,
    /** fromCart */
    disabledCountStepper,
    /** fromCart */
    curCartProduct,
    /** fromCart */
    initProductForCart,
    changeCustomizationOptionCheck,
    changeCustomizationItemCheck,
    changeComboItemsProductDefault,
    restProductCache,
    addToCart,
    saveToCart,

    // TODO
    loginBtnNode,
  };
}
