import { useResource } from '@axios-use/react';

import type { GetSearchProductQuery } from '@/apis/product';
import { getSearchProductReqConfig } from '@/apis/product';

export default function useProductSearch(params?: Partial<GetSearchProductQuery>) {
  const [{ data, isLoading }, requestSearch] = useResource(
    getSearchProductReqConfig,
    [params as GetSearchProductQuery],
    {
      filter: (p) => Boolean(p?.keyword) && Boolean(p?.storeCode) && Boolean(p.daypartCode),
      cache: false,
    },
  );

  return {
    searchData: data,
    isLoading,
    requestSearch,
  };
}
