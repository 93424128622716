import { useState, useRef, useEffect, useCallback } from 'react';
import { useThrottleFn } from 'ahooks';
import type { MenuListItem } from '@/apis/product/types';

export const CATEGORY_NAME_HEIGHT = 28;
export const CATEGORY_TAGS_HEIGHT = 42;

export const getProductCardIdKey = (code: string) => `product-code-${code}`;
export const getMenuCategoryIdKey = (code: string) => `category-${code}`;

export type MenuScrollLayoutOptions = {
  initActiveKey?: string;
  initCategoryKey?: string;
  items?: MenuListItem[];
  offsetTop?: number;
  wrapperScrollEl?: HTMLDivElement | null;
};

export function useMenuScrollLayout(options?: MenuScrollLayoutOptions) {
  const { initActiveKey, initCategoryKey, items, offsetTop = 0, wrapperScrollEl } = options || {};
  const initItem = items?.[0];

  const [activeKey, setActiveKey] = useState<string>(initActiveKey || initItem?.categoryCode || '');
  const [categoryKey, setCategoryKey] = useState<string | undefined>(
    initCategoryKey || initItem?.categories?.[0].categoryCode || '',
  );

  const scrollElementRef = useRef(wrapperScrollEl);
  scrollElementRef.current = wrapperScrollEl;

  const categoryScrollBlockRef = useRef<boolean>(false);

  const scrollToCategory = useCallback(
    (code?: string) => {
      if (!code) {
        return;
      }
      const _categoryEl = document.getElementById(getMenuCategoryIdKey(code));

      if (_categoryEl) {
        setCategoryKey(code);

        const _top =
          (Number(_categoryEl?.getBoundingClientRect().top) || 0) +
          (Number(wrapperScrollEl?.scrollTop) || 0) -
          (offsetTop || 0) +
          Number(wrapperScrollEl?.offsetTop || 0);
        categoryScrollBlockRef.current = true;
        wrapperScrollEl?.scrollTo({ top: _top });

        setTimeout(() => (categoryScrollBlockRef.current = false), 300);
      }
    },
    [offsetTop, wrapperScrollEl],
  );

  const scrollToProduct = useCallback(
    (code?: string) => {
      if (!code) {
        return;
      }
      const _productEl = document.getElementById(getProductCardIdKey(code));

      if (_productEl) {
        const _containCategory =
          items?.find((i) => {
            if (!i.categories && i.productList && i.productList.find((p) => p.productCode === code)) {
              return true;
            }
            if (i.categories && i.categories.some((c) => c.productList.find((p) => p.productCode === code))) {
              return true;
            }
            return false;
          })?.categories != null;

        const _top =
          (Number(_productEl?.getBoundingClientRect().top) || 0) +
          (Number(wrapperScrollEl?.scrollTop) || 0) -
          // Selector height
          (_containCategory ? CATEGORY_TAGS_HEIGHT : 0) -
          (offsetTop || 0) +
          Number(wrapperScrollEl?.offsetTop || 0);
        wrapperScrollEl?.scrollTo({ top: _top });
      }
    },
    [items, offsetTop, wrapperScrollEl],
  );

  const { run: handleScroll } = useThrottleFn<() => void>(
    () => {
      if (items) {
        let currentKey = items[0].categoryCode;
        for (const item of items) {
          const _element = document.getElementById(getMenuCategoryIdKey(item.categoryCode));
          if (!_element) continue;
          const _rect = _element.getBoundingClientRect();

          const _scrollElOffsetTop = scrollElementRef.current?.offsetTop || 0;

          if (_rect.top <= _scrollElOffsetTop + offsetTop + 2) {
            currentKey = item.categoryCode;

            if (!categoryScrollBlockRef.current && item.categories && Array.isArray(item.categories)) {
              item.categories.forEach((child, childIndex) => {
                const _childCategoryEl = document.getElementById(getMenuCategoryIdKey(child.categoryCode));
                if (_childCategoryEl) {
                  const _childCategoryRect = _childCategoryEl.getBoundingClientRect();
                  const _baseTop =
                    childIndex === 0
                      ? CATEGORY_NAME_HEIGHT + CATEGORY_TAGS_HEIGHT + _scrollElOffsetTop
                      : CATEGORY_TAGS_HEIGHT + _scrollElOffsetTop;

                  if (_childCategoryRect.top <= _baseTop + offsetTop + 2) {
                    setCategoryKey(child.categoryCode);
                  }
                }
              });
            }
          } else {
            break;
          }
        }

        setActiveKey(currentKey);
      }
    },
    {
      leading: true,
      trailing: true,
      wait: 100,
    },
  );

  const handleScrollRef = useRef(handleScroll);
  handleScrollRef.current = handleScroll;

  const scrollMounted = useRef(false);
  useEffect(() => {
    if (wrapperScrollEl && !scrollMounted.current) {
      wrapperScrollEl.addEventListener('scroll', handleScrollRef.current);
      scrollMounted.current = true;
    }
    return () => {
      if (wrapperScrollEl) {
        wrapperScrollEl.removeEventListener('scroll', handleScrollRef.current);
      }
    };
  }, [wrapperScrollEl]);

  return {
    activeKey,
    setActiveKey,
    categoryKey,
    setCategoryKey,

    scrollToProduct,
    scrollToCategory,

    categoryScrollBlockRef,
  };
}
