import React, { useCallback, useMemo, useRef } from 'react';
import { Button, Form, Input, Radio, Dialog, Toast } from 'antd-mobile';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '@/store/';
import { updatePlace } from '@/store/addressSlice';
import { SELECT_PLACE, MY_ADDRESS } from '@/constants/path';
import AddressFooter from '@/components/AddressFooter';
import { createNewAddress, updateAddress, deleteAddress, fetchNearByStore } from '@/apis/address';
import type { NearByStoreParams, Address } from '@/apis/address';
import useTitle from '@/hooks/useTitle';

import PlaceCard from './components/PlaceCard';

import './index.less';

const genderStyle = {
  '--icon-size': '18px',
  '--font-size': '14px',
  '--gap': '6px',
};

interface FormValues {
  detail: string;
  fullName: string;
  gender: number;
  phone: string;
}

const EditAddress = () => {
  const { detailInfo, placeInfo, addressExtraInfo } = useSelector((state: RootState) => {
    const { detail, place, addressExtraInfo: addressExtra } = state.address;
    return {
      detailInfo: detail,
      placeInfo: place,
      addressExtraInfo: addressExtra,
    };
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadingRef = useRef<boolean>(false);

  const [form] = Form.useForm();

  const isEdit = useMemo(() => {
    const { id } = detailInfo || {};
    return Boolean(id);
  }, [detailInfo]);

  useTitle(`${isEdit ? ' 修改' : '新增'}配送地址`);

  const realDetail = useMemo(() => {
    const { cityCode, cityName, address, name, latitude, longitude } = placeInfo || {};
    return {
      ...detailInfo,
      ...(name ? { cityCode, cityName, address: name, streetAddress: address, latitude, longitude } : null),
    };
  }, [detailInfo, placeInfo]);

  const changePlace = useCallback(() => {
    if (isEdit) {
      const { cityCode, cityName, streetAddress, address, latitude, longitude } = realDetail || {};
      dispatch(
        updatePlace({
          cityCode,
          cityName,
          name: address,
          address: streetAddress,
          latitude,
          longitude,
        }),
      );
      navigate(SELECT_PLACE + '?isChange=1');
      return;
    }
    navigate(SELECT_PLACE + '?isChange=1');
    // navigate(-1);
  }, [isEdit, realDetail, navigate, dispatch]);

  const { address, streetAddress, detail, fullName, phone, gender, longitude, latitude, cityCode, cityName, id } =
    realDetail || {};

  const checkMobile = useCallback(
    (_: any, val: string) => {
      const reg = /^1[3456789]\d{9}$/;
      if (!(val || '').trim()) {
        return Promise.reject(new Error('手机号码不能为空'));
      }
      if (reg.test(val) || val === phone) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('手机号格式错误'));
    },
    [phone],
  );

  const submit = useCallback(
    async (p: Partial<Address>) => {
      if (loadingRef.current) {
        return;
      }
      loadingRef.current = true;
      let server = createNewAddress;

      if (isEdit) {
        server = updateAddress;
      }
      await server(p)
        .then(() => {
          // navigate(MY_ADDRESS);
          navigate(isEdit ? -1 : -2);
        })
        .catch((err: Error) => {
          Toast.show(err?.message || '操作失败！');
        })
        .finally(() => {
          loadingRef.current = false;
        });
    },
    [isEdit, navigate],
  );

  const modifyAddress = useCallback(
    ({ phone: newPhone, ...other }: FormValues) => {
      const p = {
        cityCode,
        cityName,
        address,
        streetAddress,
        longitude,
        latitude,
        phone: newPhone === phone ? '' : newPhone,
        ...other,
        tags: '',
        ...(isEdit
          ? {
              id,
            }
          : null),
      };

      if (addressExtraInfo?.enterFrom) {
        const params = {
          orderType: addressExtraInfo?.orderType,
          latitude: p.latitude,
          longitude: p.longitude,
        } as NearByStoreParams;
        fetchNearByStore(params)
          .then(() => {
            void submit(p);
          })
          .catch((err: Error) => {
            void Dialog.confirm({
              destroyOnClose: true,
              title: '很抱歉',
              content: `${err.message || '附近的餐厅繁忙,暂时无法配送到当前地址，是否继续保存'}`,
              cancelText: '修改',
              confirmText: '保存',
              onConfirm: async () => {
                await submit(p);
              },
            });
          });
      } else {
        void submit(p);
      }
    },
    [cityCode, cityName, address, streetAddress, longitude, latitude, isEdit, id, addressExtraInfo, submit, phone],
  );

  const handleSave = useCallback(() => {
    form
      .validateFields()
      .then((values: FormValues) => {
        modifyAddress({ ...values });
      })
      .catch(() => {});
  }, [form, modifyAddress]);

  const handleDelete = useCallback(() => {
    deleteAddress({ addressId: id! })
      .then(() => {
        // navigate(MY_ADDRESS);
        navigate(-1);
      })
      .catch((err) => {});
  }, [id, navigate]);

  return (
    <div className="edit-address-container">
      <div className="content">
        <PlaceCard streetAddress={streetAddress!} address={address!} handleClick={changePlace} />
        <div>
          <Form
            form={form}
            layout="horizontal"
            mode="card"
            className="form-wrapper"
            initialValues={{ detail, fullName, phone, gender: gender || 2 }}>
            <Form.Item
              label="门牌号"
              name={'detail'}
              // required={true}
              rules={[
                {
                  validator: (_: any, val: string) => {
                    if (!val) {
                      return Promise.reject(new Error('门牌号不能为空'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}>
              <Input placeholder="楼号/门牌号/楼层/房间号等" maxLength={20} />
            </Form.Item>
            <Form.Item
              className="full-name"
              label="联系人"
              name={'fullName'}
              rules={[
                {
                  validator: (_: any, val: string) => {
                    if (!val) {
                      return Promise.reject(new Error('联系人不能为空'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              extra={
                <Form.Item name="gender" required noStyle={true}>
                  <Radio.Group>
                    <Radio
                      value={2}
                      icon={(checked) =>
                        checked ? (
                          <span className="radio-icon selected">
                            <span />
                          </span>
                        ) : (
                          <span className="radio-icon"></span>
                        )
                      }
                      style={{ ...genderStyle, marginRight: '10px' }}>
                      女士
                    </Radio>
                    <Radio
                      value={1}
                      icon={(checked) =>
                        checked ? (
                          <span className="radio-icon selected">
                            <span />
                          </span>
                        ) : (
                          <span className="radio-icon"></span>
                        )
                      }
                      style={genderStyle}>
                      先生
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              }>
              <Input placeholder="请填写姓名" maxLength={12} />
            </Form.Item>

            <Form.Item label="手机号" name={'phone'} rules={[{ validator: checkMobile }]}>
              <Input placeholder="请填写手机号码" maxLength={11} />
            </Form.Item>
          </Form>
        </div>
      </div>
      <AddressFooter
        text="保存地址"
        handleClick={handleSave}
        customOperate={
          isEdit ? (
            <div className="btn-wrapper">
              <Button shape="rounded" onClick={handleDelete}>
                删除地址
              </Button>
              <Button color="primary" shape="rounded" onClick={handleSave}>
                保存地址
              </Button>
            </div>
          ) : undefined
        }
      />
    </div>
  );
};

export default EditAddress;
