import type { AxiosRequestConfig } from 'axios';
import { request } from '@axios-use/react';
import { BIZ_CODE_102, BIZ_CODE_101, BIZ_CODE_600 } from '@/constants/code';
import type { CommonResponse } from '../request';
import omcHttp, { REQUEST_CHANNEL_CODE } from '../request';

import type {
  MenuBannerItem,
  MenuListData,
  PromotionCouponsCardType,
  PromotionCouponProductsType,
  ProductSearchData,
  StoresVicinityData,
  StoreItem,
  ProductData,
  ProductPromotionInfo,
  ProductInfoCustomization,
  ProductInfoComboItem,
  PoisGeocodeItem,
  MemberAddressItem,
  DaypartsTimeOption,
} from './types';

import type { CartInfoParams, CartDetailResponse } from '../cart';
import type { ConfirmValidationInfoResponse } from '../orderConfirm.type';

const URL_CONST = {
  CART: '/bff/cart/carts',
  EMPTY_CART: '/bff/cart/carts/empty',
  NEARBYSTORE: '/bff/store/stores/vicinity',
  MENULIST: '/bff/spc/menu',
  SEARCHMENU: '/bff/spc/products/search',
  // VALIDATION: '/bff/spc/carts/validation',
  VALIDATION_NEW: '/bff/order/confirmation/validationinfo',
  MENULISTBANNER: '/bff/store/menu/banners',
  PRODUCTDETAIL: '/bff/spc/products/',
  MCCAFEPRODUCTDETAIL: '/bff/spc/products/detail/',
  PRODUCTDESC: '/bff/spc/kvs/',
  ALLCITIES: '/bff/store/cities/group',
  CITYNAME: '/bff/store/cities',
  // NEARBYSTORES: HOST + ' /bff/store/stores/nearby',
  ALLSTORES: '/bff/store/stores',
  STORESFILTERTAG: '/bff/store/stores/hottags',
  FREQUENTSTORES: '/bff/store/stores/frequent',
  COUPONPRODUCTS: '/bff/promotion/coupons/products',
  // MENUCOUPONS: '/bff/promotion/menu/discount',
  ADDRESSMANAGER: '/bff/member/addresses',
  ADDRESSPOISEARCH: '/bff/member/addresses/poi',
  CUSTOMLOCATIONPOI: '/bff/store/pois/search',
  QUERYSTOREBYBECODE: '/bff/store/stores/be/',
  QUERYSTOREBYSTORECODE: '/bff/store/stores/',
  NEWSTOREDETAILINFO: '/bff/store/stores/portal/base',
  FETCHMDSSWITCHDELIVERYTIME: '/bff/store/stores/{storeCode}/menu/daypartsTimeoption',
  OHMAICARDMENU: '/bff/spc/menu/card',
  STORERECOMMENDINFO: '/bff/portal/recommend/customer',
  COUPONDATALISTWITHSCENE: '/bff/promotion/coupons/v2',
  MENUANOTHERORDER: '/bff/order/orders/last',
  MENUBUYANOTHER: '/bff/order/orders',
  MENUMCCAFEPOINT: '/bff/promotion/menu/mcCafe/discount',
  DRIVINGDISTANCE: '/bff/store/pois/distance',
  MENURIGHTCARDINFO: '/bff/promotion/coupons/card',
  DCCONFIGUREPRICE: '/bff/promotion/price',
  STOREFAVLIST: '/bff/store/stores/collection/list',
  STOREUPDATEFAV: '/bff/member/store/collection',
  POIS_SEARCH_WALK: '/bff/store/pois/direction/walk',
  POIS_GEOCODE: '/bff/store/pois/geocode',
  GETEXCLUSIVECONFIG: '/bff/member/exclusive/config',
  SAVEEXCLUSIVECONFIG: '/bff/member/exclusive/save',
  ARRIVEDSTORE: '/bff/order/orders/{orderId}/confirmArrive',
  STOREDETAILBOOKING: '/bff/store/stores/portal/booking',
  STOREDETAILTAB: '/bff/promotion/stores/portal/tabs',
};

export type MenuBizCodeObj = {
  beType?: string;
  isGroupMeal?: string;
};

/**
 * copied from miniapp
 */
const menuBizCode = (isGroupMeal = '', beType = ''): string => {
  let bizCode = '';
  if (beType === '3') {
    bizCode = BIZ_CODE_102;
  } else if (beType === '' || !beType) {
    bizCode = BIZ_CODE_101;
  }
  if (!isGroupMeal || isGroupMeal !== '') {
    bizCode = BIZ_CODE_600;
  }
  return bizCode;
};

export type GetMenuBannerQuery = {
  daypartCode: number;
  orderType: number;
  storeCode: string;
  beCode?: string;
  themeId?: string;
  uniCode?: string;
} & MenuBizCodeObj;

/**
 * [GET] menu banner
 * @param params
 * @param ops
 */
export const getMenuBannerReqConfig = (params: GetMenuBannerQuery, ops?: AxiosRequestConfig, deps?: any) => {
  const { isGroupMeal, beType, ...rest } = params || {};

  return request<MenuBannerItem[]>({
    method: 'get',
    url: URL_CONST.MENULISTBANNER,
    params: rest,
    headers: { biz_from: '1005', biz_scenario: menuBizCode(isGroupMeal, beType) },
    ...ops,
  });
};

export type GetStoreMenuQuery = {
  storeCode: string;
  orderType: number;
  dayPartCode: number;
  channelCode?: string;
  beCode?: string;
  date?: string;
  time?: string;
} & MenuBizCodeObj;

/**
 * [GET] Store menu list
 * @param params
 * @param ops
 */
export const getStoreMenuReqConfig = (params: GetStoreMenuQuery, ops?: AxiosRequestConfig, deps?: any) => {
  const { channelCode = REQUEST_CHANNEL_CODE, ...rest } = params;
  return request<MenuListData>({
    method: 'get',
    url: URL_CONST.MENULIST,
    params: {
      channelCode,
      ...rest,
    },
    ...ops,
  });
};

export type GetMenuRightCardInfoQuery = {
  storeCode: string;
  orderType: number;
  daypartCode: number;
  beCode?: string;
  cityCode?: string;
  beType?: string;
  /** 场景: 1-App首页;2-菜单页 默认1 */
  scene?: string;
  /** 点餐日期 */
  date?: string;
  /** 点餐时间，格式 HH:mm，默认当前 */
  time?: string;
  /** 心意卡主题id */
  themeId?: string;
  /** 心意卡流水号 */
  uniCode?: string;
};

/**
 * [GET]
 * @param params
 * @param ops
 */
export const getMenuRightCardInfoReqConfig = (
  params: GetMenuRightCardInfoQuery,
  ops?: AxiosRequestConfig,
  deps?: any,
) => {
  return request<PromotionCouponsCardType>({
    method: 'get',
    url: URL_CONST.MENURIGHTCARDINFO,
    params,
    ...ops,
  });
};

export type GetCouponProductListQuery = {
  daypartCode: string;
  storeCode: string;
  orderType: string;
  couponCode: string;
  couponId: string;
  promotionId?: string;
  channelCode?: string;
  beCode?: string;
  date?: string;
  time?: string;
} & MenuBizCodeObj;

export const getCouponProductList = (params: GetCouponProductListQuery, ops?: AxiosRequestConfig) => {
  const { channelCode = REQUEST_CHANNEL_CODE, ...restParams } = params || {};

  return request<PromotionCouponProductsType>({
    method: 'get',
    url: URL_CONST.COUPONPRODUCTS,
    headers: {
      biz_from: '1012',
      biz_scenario: menuBizCode(params.isGroupMeal, params.beType),
    },
    params: {
      channelCode,
      ...restParams,
    },
    ...ops,
  });
};

export type GetNearByStoreQuery = {
  /** 用户配送地址ID，传入此参数时，需要用户已登陆。若地址ID不为空，则不会使用经纬度获取门店。 */
  addressId?: string;
  /** BE类型:1-母店;2-麦乐送;3-麦咖啡; */
  beType?: string;
  /** MDS 配送日期，格式如 20200915，不传默认当天。 */
  date?: string;
  /** 距离, 单位: 米，默认 10千米。 */
  distance?: string;
  /** 热门标签编码筛选，仅支持单个 */
  hotTagCode?: string;
  /** 是否城市中心默认经纬度。枚举值：0-否;1-是。默认 0。 */
  isCityCenter?: string;
  /** 门店名称关键字筛选 */
  keyword?: string;
  /** 经度 */
  latitude?: number;
  /** 纬度 */
  longitude?: number;
  /** 订单类型:1-到店自取;2-外送(即MDS); 默认为 1。 */
  orderType?: number;
  /** 展示场景，枚举值：1-推荐门店;2-所有门店。默认 1。 */
  showType?: string;
  /** MDS 配送时间，格式如 09:05，不传默认当前时间。 */
  time?: string;
} & MenuBizCodeObj;

/**
 * [GET]
 * @param params
 * @param ops
 */
export const getNearByStoreReqConfig = (params: GetNearByStoreQuery = {}, ops?: AxiosRequestConfig) => {
  return request<StoresVicinityData>({
    method: 'get',
    url: URL_CONST.NEARBYSTORE,
    headers: { biz_from: '1003', biz_scenario: menuBizCode(params.isGroupMeal, params.beType) },
    params,
    ...ops,
  });
};

export type GetStoreByStoreCodeQuery = {
  storeCode: string;
} & MenuBizCodeObj;

/**
 * [GET]
 * @param params
 * @param ops
 */
export const getStoreByStoreCodeReqConfig = (params: GetStoreByStoreCodeQuery, ops?: AxiosRequestConfig) => {
  const { storeCode, beType, isGroupMeal } = params || {};

  return request<StoreItem>({
    method: 'get',
    url: `${URL_CONST.QUERYSTOREBYSTORECODE}${storeCode}`,
    headers: {
      biz_from: '1004',
      biz_scenario: menuBizCode(isGroupMeal, beType),
    },
    pathVars: [storeCode],
    ...ops,
  } as AxiosRequestConfig);
};

export type GetSearchProductQuery = {
  /** 门店编码 */
  storeCode: string;
  /** 售卖餐段 */
  daypartCode: number;
  /** 关键词 */
  keyword: string;
  /** 订单类型 */
  orderType: number;
  /** 门店的becode */
  beCode?: string;
  /** 日期，默认当天，格式形如 20200808 */
  date?: string;
  /** 团餐标志位 1:是团餐 */
  isGroupMeal?: string;
  /** 关键词 */
  needWithOrder?: string;
  /** 时间，默认当前时间, 格式为 HH:mm, 比如 09:08 */
  time?: string;
  channelCode?: string;
} & MenuBizCodeObj;

/**
 * [GET]
 * @param params
 * @param ops
 */
export const getSearchProductReqConfig = (params: GetSearchProductQuery, ops?: AxiosRequestConfig) => {
  const { isGroupMeal, beType, channelCode = REQUEST_CHANNEL_CODE, ...rest } = params || {};
  return request<ProductSearchData>({
    method: 'get',
    url: URL_CONST.SEARCHMENU,
    params: {
      channelCode,
      isGroupMeal,
      ...rest,
    },
    headers: { biz_from: '1014', biz_scenario: menuBizCode(isGroupMeal, beType) },
    ...ops,
  });
};

export type PostMcProductDetailQuery = {
  productCode: string;
  storeCode: string;
  orderType: number;
  daypartCode: number;
  channelCode?: string;
  beCode?: string;
  cardId?: string;
  time?: string;
  date?: string;
  fromCombo?: boolean;
  ignoreSmartPrice?: boolean;
  hasCard?: boolean;
  couponId?: string;
  couponCode?: string;
  membershipCode?: string;
  pageSource?: number;
  pmtType?: string;
  promotionId?: string;
  hasRights?: boolean;
  abtestIds?: string[];
  productPromotions?: {
    productCode?: string;
    promotionInfo?: ProductPromotionInfo;
    userPromotionIds?: string[];
  }[];
} & MenuBizCodeObj;

/**
 * [POST]
 * @param params
 * @param ops
 */
export const getPOSTMcProductDetailReqConfig = (params: PostMcProductDetailQuery, ops?: AxiosRequestConfig) => {
  const {
    productCode,
    isGroupMeal,
    beType,
    channelCode = REQUEST_CHANNEL_CODE,
    fromCombo = false,
    ignoreSmartPrice = false,
    pageSource = 0,
    hasCard = false,
    hasRights = false,
    ...rest
  } = params || {};

  return request<ProductData>({
    method: 'post',
    url: `${URL_CONST.MCCAFEPRODUCTDETAIL}${productCode}`,
    headers: {
      biz_from: '1001',
      biz_scenario: menuBizCode(isGroupMeal, beType),
    },
    params: {
      channelCode,
      productCode,
      isGroupMeal,
      fromCombo,
      ignoreSmartPrice,
      pageSource,
      hasCard,
      hasRights,
      ...rest,
    },
    pathVars: [productCode],
    ...ops,
  } as AxiosRequestConfig);
};

export type GetProductDescReqConfig = MenuBizCodeObj;

/**
 * [GET]
 * @param params
 * @param ops
 */
export const getProductDescReqConfig = (params?: GetProductDescReqConfig, ops?: AxiosRequestConfig) => {
  const { isGroupMeal, beType } = params || {};

  return request<string>({
    method: 'get',
    url: `${URL_CONST.PRODUCTDESC}productManual`,
    headers: {
      biz_from: '1002',
      biz_scenario: menuBizCode(isGroupMeal, beType),
    },
    pathVars: ['productManual'],
    ...ops,
  } as AxiosRequestConfig);
};

/**
 * [GET] 获取购物车详情
 * @param params
 * @param ops
 */
export const getCartsInfoReqConfig = (params?: CartInfoParams, ops?: AxiosRequestConfig) => {
  const {
    cartType = 1,
    channelCode = REQUEST_CHANNEL_CODE,
    daypartCode,
    orderType,
    storeCode,
    beCode,
    time,
    date,
    isGroupMeal,
    beType,
  } = params || {};
  return request<CartDetailResponse>({
    method: 'get',
    url: URL_CONST.CART,
    headers: {
      biz_from: '1007',
      biz_scenario: menuBizCode(isGroupMeal, beType),
    },
    params: {
      cartType,
      channelCode,
      daypartCode,
      orderType,
      storeCode,
      beCode,
      time,
      date,
      isGroupMeal,
    },
    ...ops,
  });
};

export type PutUpdateCartBody = {
  /** 操作:1上限弹框移除加购,2冲突弹框移除加购 */
  operation?: number;
  cartType: string;
  channelCode?: string;
  daypartCode: string;
  orderType: number;
  storeCode: string;
  beCode?: string;
  products: {
    cardId?: string;
    code?: string;
    name?: string;
    quantity: number;
    sequence?: number;
    type?: number;
    couponCode?: string;
    couponId?: string;
    promotionId?: string;
    comboItems?: ProductInfoComboItem[];
    customization?: ProductInfoCustomization;
    operationType?: number;
    pageSource?: string;
  }[];
  date?: string;
  time?: string;
  /** 加购的数据来源: 1.菜单 2.订单 3.优惠券 */
  dataSource?: number;
  isGroupMeal?: string;
  beType?: string;
  abTest?: string;
};

export type PutCartResponseDataType = {
  cartDetail: CartDetailResponse;
  pmtLimit?: {
    text?: string;
    // TODO unknown
    coupons?: any[];
  };
  // TODO unknown
  conflictCoupons?: {
    text?: string;
  };
};

/**
 * [PUT] 购物差增删改
 * @param body
 * @param ops
 */
export const getPutUpdateCartReqConfig = (body: PutUpdateCartBody, ops?: AxiosRequestConfig) => {
  return request<PutCartResponseDataType>({
    method: 'put',
    url: URL_CONST.CART,
    params: {
      channelCode: REQUEST_CHANNEL_CODE,
      ...body,
    },
    ...ops,
  });
};

export type PutClearCartsBody = {
  cartType?: string;
  channelCode?: string;
  daypartCode?: string;
  orderType?: number;
  storeCode?: string;
  beCode?: string;
  date?: string;
  time?: string;
  isGroupMeal?: string;
  abTest?: string;
};

/**
 * [PUT] 清空购物车
 * @param body
 * @param ops
 */
export const getPutClearCartsReqConfig = (body?: PutClearCartsBody, ops?: AxiosRequestConfig) => {
  const {
    cartType,
    channelCode = REQUEST_CHANNEL_CODE,
    daypartCode,
    orderType = 1,
    storeCode,
    beCode,
    date,
    time,
    isGroupMeal,
    abTest,
  } = body || {};
  return request<CartDetailResponse>({
    method: 'put',
    url: URL_CONST.EMPTY_CART,
    params: {
      cartType,
      channelCode,
      daypartCode,
      orderType,
      storeCode,
      beCode,
      date,
      time,
      isGroupMeal,
      abTest,
    },
  });
};

export type GetOrderValidationQuery = {
  /** 订单类型 */
  orderType: number;
  /** 餐时段 */
  dayPartCode: number | string;
  /** 门店code-当前 */
  storeCode: string;
  /** 门店beCode */
  beCode?: string;
  /** 进入订单确认来源，为DT2.0增的字段，其他场景都不变，（dt2-》2 ;不传就代表正常的取餐方式,不做逻辑处理） */
  enterScene?: string;
  /** 期望配送时间-当前,格式2020-02-02 02:30 */
  expectDeliveryTime?: string;
  /** 麦乐送指定保留选项日期，其它日期去掉 */
  fixedOptDate?: string;
  /** 配送地址id */
  addressId?: string;
  /** 团餐标志位 1:是团餐 */
  isGroupMeal?: string;
  /** 门店beType */
  beType?: string;
};

/**
 * [GET] 提交订单验证
 * @param params
 * @param ops
 */
export const getOrderValidationReqConfig = (params?: GetOrderValidationQuery, ops?: AxiosRequestConfig) => {
  return request<ConfirmValidationInfoResponse>({
    method: 'get',
    url: URL_CONST.VALIDATION_NEW,
    params,
  });
};

/**
 * [GET] 提交订单验证 fetch
 * @param params
 * @param ops
 */
export const getOrderValidation = (params?: GetOrderValidationQuery, ops?: AxiosRequestConfig) => {
  return omcHttp(getOrderValidationReqConfig(params, ops)) as Promise<CommonResponse<ConfirmValidationInfoResponse>>;
};

export type GetPoisGeocodeListQuery = {
  latitude: number;
  longitude: number;
  abId?: string;
};

/**
 * [GET]
 * @param params
 * @param ops
 */
export const getPoisGeocodeListReqConfig = (params?: GetPoisGeocodeListQuery, ops?: AxiosRequestConfig) => {
  return request<PoisGeocodeItem[]>({
    method: 'get',
    url: URL_CONST.POIS_GEOCODE,
    params,
    ...ops,
  });
};

/**
 * [GET] 地址详细
 */
export const getMemberAddressDetail = (id: string, ops?: AxiosRequestConfig) => {
  const url = `${URL_CONST.ADDRESSMANAGER}/${id}`;

  return request<MemberAddressItem>({
    method: 'get',
    url,
    pathVars: [id],
    ...ops,
  } as AxiosRequestConfig);
};

export type GetMDSDeliveryTimesQuery = {
  storeCode: string;
  beCode: string;
  dayPartCode?: number;
  fixedOptDate?: string;
  /** pickup 或 mds，默认 mds */
  type?: string;
} & MenuBizCodeObj;

/**
 * [GET]
 * @param params
 * @param ops
 */
export const getMDSDeliveryTimesReqConfig = (params?: GetMDSDeliveryTimesQuery, ops?: AxiosRequestConfig) => {
  const { storeCode, ...restParams } = params || ({} as GetMDSDeliveryTimesQuery);
  const url = URL_CONST.FETCHMDSSWITCHDELIVERYTIME.replace('{storeCode}', storeCode);

  return request<{ dateDaypartTimeOptions: DaypartsTimeOption[] }>({
    method: 'get',
    url,
    params: restParams,
    headers: {
      biz_from: '1016',
      biz_scenario: menuBizCode(restParams.isGroupMeal, restParams.beType),
    },
    pathVars: [storeCode],
    ...ops,
  } as AxiosRequestConfig);
};
