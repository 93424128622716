import type { CSSProperties, FC, ForwardRefRenderFunction, RefObject } from 'react';
import { useRef, useEffect, useCallback, useMemo, forwardRef, Fragment } from 'react';
import { SideBar, Image, Selector } from 'antd-mobile';
import flatMap from 'lodash/flatMap';

import type { MenuListItem, MenuListProductItem, StoreDaypartItem, StoreItem } from '@/apis/product/types';
import { getCDNAssets } from '@/utils';

import type { ProductCardProps } from './Card';
import ProductCard from './Card';
import CartSafeGap from '../../Cart/SafeGap';
import { useMenuScrollLayout, getProductCardIdKey, getMenuCategoryIdKey } from './useScrollLayout';

import styles from './list.module.less';
import { trackEvent } from '@/utils/tracker';

export const getProductCount = (map?: Map<string, number>, item?: MenuListProductItem) => {
  if (map && item) {
    const { productType, productCode, groupList } = item;
    if (productType === 3 && groupList && Array.isArray(groupList)) {
      const _total = (groupList.map((i) => i.subProductCode).filter(Boolean) as string[])
        .map((c) => map.get(c) || 0)
        .reduce((a, b) => a + b, 0);

      return _total;
    }

    return map.get(productCode);
  }
  return undefined;
};

const SideBarItem: FC<{ img: string; name: string }> = (props) => {
  const { img, name } = props;
  return (
    <div className={styles.menubarItem}>
      <div className={styles.menubarItemImg}>
        <Image
          placeholder={<Image placeholder={null} src={getCDNAssets('default.png')} />}
          fallback={<Image placeholder={null} src={getCDNAssets('default.png')} />}
          src={img}
        />
      </div>
      <div className={`${styles.menubarItemTitle} menu-list-sidebar-item-name`}>{name}</div>
    </div>
  );
};

export type ProductMenuListProps = {
  className?: string;
  style?: CSSProperties;
  orderType?: number;
  storeInfo?: StoreItem | null;
  daypart?: StoreDaypartItem;
  items?: MenuListItem[];
  cartQuantityGroup?: [string, number][];
  sideCanAutoScroll?: boolean;
  wrapperScrollRef?: RefObject<HTMLDivElement>;
  offsetTop?: number;
  onCardClick?: (p: MenuListProductItem) => void;
  onCardSelect?: ProductCardProps['onSelect'];
  onCardAdd?: ProductCardProps['onAdd'];
  onCardSub?: ProductCardProps['onSub'];

  /** 自动滚动到产品/分类位置, 产品优先级>分类优先级 */
  autoScroll?: {
    productCode?: string;
    categoryCode?: string;
    onComplete?: () => void;
  };
};

const ProductMenuList: ForwardRefRenderFunction<HTMLDivElement, ProductMenuListProps> = (props, ref) => {
  const {
    className,
    style,
    orderType,
    storeInfo,
    daypart,
    items,
    cartQuantityGroup,
    sideCanAutoScroll,
    wrapperScrollRef,
    offsetTop,
    onCardClick,
    onCardSelect,
    onCardAdd,
    onCardSub,
    autoScroll,
  } = props;
  const manualChangeSidebarRef = useRef<boolean>(false);
  const sideScrollElRef = useRef<HTMLDivElement>(null);

  const cartQuantityMap = useMemo(() => new Map(cartQuantityGroup), [cartQuantityGroup]);

  const { activeKey, categoryKey, scrollToProduct, scrollToCategory } = useMenuScrollLayout({
    items,
    offsetTop,
    wrapperScrollEl: wrapperScrollRef?.current,
  });

  const onCategoryChange = useCallback(
    (v: string[]) => {
      if (Array.isArray(v) && v[0]) {
        scrollToCategory(v[0]);
      }
    },
    [scrollToCategory],
  );

  const onSiderChange = useCallback(
    (key: string) => {
      manualChangeSidebarRef.current = true;
      const _categoryEl = document.getElementById(getMenuCategoryIdKey(key));

      const _top =
        (Number(_categoryEl?.getBoundingClientRect().top) || 0) +
        (Number(wrapperScrollRef?.current?.scrollTop) || 0) -
        (offsetTop || 0) +
        Number(wrapperScrollRef?.current?.offsetTop || 0);

      wrapperScrollRef?.current?.scrollTo({ top: _top });
    },
    [offsetTop, wrapperScrollRef],
  );

  const sideCanAutoScrollRef = useRef(sideCanAutoScroll);
  sideCanAutoScrollRef.current = sideCanAutoScroll;
  useEffect(() => {
    if (activeKey && sideCanAutoScrollRef.current) {
      const _el = document.querySelector(`[data-id="sidebar-item-${activeKey}"]`);
      if (_el && !manualChangeSidebarRef.current) {
        const _top =
          (Number(_el.getBoundingClientRect().top) || 0) +
          (Number(sideScrollElRef.current?.scrollTop) || 0) -
          (offsetTop || 0) +
          Number(wrapperScrollRef?.current?.offsetTop || 0);

        sideScrollElRef.current?.scroll({ top: _top });
      }
      manualChangeSidebarRef.current = false;
    }
  }, [activeKey, offsetTop, wrapperScrollRef]);

  // atuo-scroll effect start ------
  const scrollToCategoryRef = useRef(scrollToCategory);
  scrollToCategoryRef.current = scrollToCategory;
  const scrollToProductRef = useRef(scrollToProduct);
  scrollToProductRef.current = scrollToProduct;
  const autoScrollOnCompleteRef = useRef(autoScroll?.onComplete);
  autoScrollOnCompleteRef.current = autoScroll?.onComplete;
  useEffect(() => {
    // funtion add to queue (wait for el render)
    let _timer: ReturnType<typeof setTimeout> | null = null;
    if (autoScroll?.productCode) {
      const _code = autoScroll.productCode;
      _timer = setTimeout(() => {
        scrollToProductRef.current(_code);
        autoScrollOnCompleteRef.current?.();
      }, 0);
    } else if (autoScroll?.categoryCode) {
      const _code = autoScroll.categoryCode;
      _timer = setTimeout(() => {
        scrollToCategoryRef.current(_code);
        autoScrollOnCompleteRef.current?.();
      }, 0);
    }
    return () => {
      if (_timer) {
        clearTimeout(_timer);
      }
    };
  }, [autoScroll?.categoryCode, autoScroll?.productCode]);
  // ------ atuo-scroll effect end

  const sidebarNode = useMemo(() => {
    return (
      <SideBar
        activeKey={activeKey}
        style={{
          '--width': '65px',
          '--height': 'max-content',
          minHeight: '100%',
          overflowY: 'hidden',
        }}
        onChange={(code) => {
          const _itemIndex = items?.findIndex((i) => i?.categoryCode === code);
          const _item = items && _itemIndex != null && _itemIndex >= 0 ? items[_itemIndex] : undefined;

          trackEvent('clickList', {
            belong_page: orderType === 1 ? '到店取餐菜单页' : '麦乐送菜单页',
            is_default: '否',
            list_name: _item?.categoryName,
            primary_list_rank: _itemIndex ?? 0 + 1,
            second_list_rank: '',
            second_list_name: '',
            order_time: daypart?.daypartName,
            operation_type: '左侧切换',
            us_name: storeInfo?.shortName,
            us_code: orderType === 1 ? storeInfo?.code : storeInfo?.beCode,
          });
          onSiderChange(code);
        }}>
        {items?.map((item) => (
          <SideBar.Item
            data-id={`sidebar-item-${item.categoryCode}`}
            key={item.categoryCode}
            title={<SideBarItem img={item.image} name={item.categoryName} />}
            badge={(item.categoryTags && Array.isArray(item.categoryTags) && item.categoryTags[0]) || undefined}
          />
        ))}
      </SideBar>
    );
  }, [
    activeKey,
    daypart?.daypartName,
    items,
    onSiderChange,
    orderType,
    storeInfo?.beCode,
    storeInfo?.code,
    storeInfo?.shortName,
  ]);

  const productListNode = useMemo(() => {
    return items?.map((i, itemIndex) => (
      <div key={i.categoryCode} style={{ position: 'relative' }}>
        <div id={getMenuCategoryIdKey(i.categoryCode)} className={styles.categoeyName}>
          {i.categoryName}
        </div>
        {i.categories?.length > 0 ? (
          <div
            className={`${styles.categoeySelector} menu-list-categoey-wrapper`}
            style={{ '--top': offsetTop } as CSSProperties}>
            <Selector
              style={{
                fontSize: 'var(--adm-font-size-4)',
                lineHeight: 1,
                '--color': '#ffffff',
                '--border': 'solid #E0E0E0 1px',
                '--border-radius': '13px',
                '--checked-border': 'solid var(--adm-color-primary) 1px',
                '--checked-color': 'rgb(255 188 13 / 6%)',
                '--checked-text-color': 'var(--adm-color-text)',
                '--padding': '6px 12px',
              }}
              value={categoryKey ? [categoryKey] : undefined}
              options={i.categories.map((c) => ({ label: c.categoryName, value: c.categoryCode })) || []}
              onChange={(v) => {
                const _cur = Array.isArray(v) ? v[0] : undefined;
                const _categoryIndex = i.categories?.findIndex((c) => c?.categoryCode === _cur);
                const _category =
                  i.categories && _categoryIndex != null && _categoryIndex >= 0
                    ? i.categories[_categoryIndex]
                    : undefined;

                trackEvent('clickList', {
                  belong_page: orderType === 1 ? '到店取餐菜单页' : '麦乐送菜单页',
                  is_default: '否',
                  list_name: i.categoryName,
                  primary_list_rank: itemIndex + 1,
                  second_list_rank: _categoryIndex + 1,
                  second_list_name: _category?.categoryName,
                  order_time: daypart?.daypartName,
                  operation_type: '二级切换',
                  us_name: storeInfo?.shortName,
                  us_code: orderType === 1 ? storeInfo?.code : storeInfo?.beCode,
                });
                onCategoryChange(v);
              }}
            />
          </div>
        ) : (
          <div style={{ height: 10 }} />
        )}
        {i.productList?.map((p) => (
          <ProductCard
            id={getProductCardIdKey(p.productCode)}
            key={p.productCode}
            orderType={orderType}
            item={p}
            onClick={onCardClick}
            count={getProductCount(cartQuantityMap, p)}
            onAdd={onCardAdd}
            onSub={onCardSub}
            onSelect={onCardSelect}
          />
        ))}
        {flatMap(i.categories, (c) => (
          <Fragment key={c.categoryCode}>
            <div id={getMenuCategoryIdKey(c.categoryCode)} />
            {c.productList?.map((p) => (
              <ProductCard
                id={getProductCardIdKey(p.productCode)}
                key={p.productCode}
                orderType={orderType}
                item={p}
                count={getProductCount(cartQuantityMap, p)}
                onClick={onCardClick}
                onAdd={onCardAdd}
                onSub={onCardSub}
                onSelect={onCardSelect}
              />
            ))}
          </Fragment>
        ))}
      </div>
    ));
  }, [
    cartQuantityMap,
    categoryKey,
    daypart?.daypartName,
    items,
    offsetTop,
    onCardAdd,
    onCardClick,
    onCardSelect,
    onCardSub,
    onCategoryChange,
    orderType,
    storeInfo?.beCode,
    storeInfo?.code,
    storeInfo?.shortName,
  ]);

  return (
    <div ref={ref} className={`${styles.container} ${className || ''}`} style={style}>
      <div ref={sideScrollElRef} className={styles.side} style={{ '--top': offsetTop } as CSSProperties}>
        {sidebarNode}
      </div>
      <div className={styles.main}>
        {productListNode}
        {/* safe gap */}
        <CartSafeGap />
      </div>
    </div>
  );
};

export default forwardRef(ProductMenuList);
