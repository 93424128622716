import httpInstance from './request';
import { BIZ_CODE_101, BIZ_CODE_102, BIZ_CODE_103, BIZ_CODE_600 } from '@/constants/code';
import type { CommonResponse } from './request';

import type {
  ConfirmationChangeResponse,
  SwitchParams,
  OrderParams,
  CreateOrderResponse,
  ConfirmValidationInfoResponse,
  Carts,
  CartUpdateResponseVo_,
  SwitchAddressParams,
} from './orderConfirm.type';

const URLS = {
  ORDER_CONFIRM_INFO: '/bff/order/confirmation/info', // 获取订单确认信息
  CHANGE_COUNT: '/bff/order/confirmation/product/quantity', // 修改数量 目前废弃！！
  CHANGE_PICK_TIME: '/bff/order/confirmation/pickuptime/switch', // 校验取餐时间修改结果
  CHANGE_DELIVERY_TIME: '/bff/order/confirmation/deliverytime/switch', // 校验配送时间修改结果
  CHANGE_STORE: '/bff/order/confirmation/store/switch', // 校验门店切换结果
  CHANGE_ADDRESS: '/bff/order/confirmation/address/switch', // 校验地址切换结果
  SUBMIT_ORDER: '/bff/order/orders', // 下单
  COUPONS_DELIVERY: '/bff/promotion/coupons/delivery/available', // 运费券
  COUPONS_ORDER: '/bff/promotion/coupons/order/available', // 订单优惠券
  ADD_RECOMMEND: '/bff/order/confirmation/carts', // 同步购物车 加购推荐商品 用券
  PAGE_CONFIG: '/bff/order/confirmation/pageconfig', // 获取页面配置 权益卡使用
  RIGHT_CARD_INFO: '/bff/order/confirmation/rightcard', // 获取权益卡信息
  RIGHT_CARD_DETAIL: '/bff/spc/coupons/rightCard/',
  FETCH_ADD_ON: '/bff/portal/recommend/addon',
  REPORD_AD_INFO: '/bff/common/ad/report/add',
  QUERY_ORDER_PROMOTION: '/bff/order/confirmation/promotion',
  FETCH_SMALL_ADDON_URL: '/bff/portal/recommend/addon/smart',
  QUERY_EATTYPE_SWITCH: '/bff/order/confirmation/eattype/switch',
  QUERY_LICENSE_PLATE: '/bff/member/licenseplate',
  SAVELICENSEPLATE: '/bff/member/licenseplate',
  ISNEARESTSTORE: '/bff/store/stores/getNearest/',
  INTEGRAL_ORDER_CREATE: '/bff/ectrade/order/create', // 订单确认页积分购券下单
  GET_HEARD_CARD: '/bff/order/confirmation/heartcard', // 心意卡

  VALIDATION_NEW: '/bff/order/confirmation/validationinfo',
};

export interface ChangePickTimeParams {
  // 必须-餐时段
  dayPartCode: string;
  // 必须-订单类型
  orderType: string;
  // 实付配送费,单位元
  realDeliveryPrice: string;
  // 实付金额,单位元,不含餐具费
  realTotalAmount: string;
  // 门店code-当前
  storeCode: string;
}
export interface ChangeDeliveryTimeParams {
  // 必须-餐时段
  dayPartCode: string;
  // 必须-订单类型
  orderType: string;
  // 实付配送费,单位元
  realDeliveryPrice: string;
  // 实付金额,单位元,不含餐具费
  realTotalAmount: string;
  // 门店code-当前
  storeCode: string;

  beType?: string;
}

export const changePickTime = (p: ChangePickTimeParams) => {
  return httpInstance
    .put<CommonResponse<ConfirmationChangeResponse>>(URLS.CHANGE_PICK_TIME, {
      params: { ...p },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });
};
export const changeDeliveryTime = (p: ChangeDeliveryTimeParams) => {
  return httpInstance
    .put<CommonResponse<ConfirmationChangeResponse>>(URLS.CHANGE_DELIVERY_TIME, {
      params: { ...p },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });
};

export const changeStore = (p: SwitchParams) => {
  return httpInstance
    .put<CommonResponse<ConfirmationChangeResponse>>(URLS.CHANGE_STORE, {
      params: { ...p },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });
};

export const changeAddress = (p: SwitchAddressParams) => {
  return httpInstance
    .put<CommonResponse<ConfirmationChangeResponse>>(URLS.CHANGE_ADDRESS, {
      params: { ...p },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });
};

export const submitOrder = (p: OrderParams) => {
  return httpInstance
    .post<CommonResponse<CreateOrderResponse>>(URLS.SUBMIT_ORDER, {
      params: { ...p },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });
};

export interface OrderConfirmationParams {
  //   购物车类型: 1-普通购物车
  cartType: number;
  channelCode?: number;
  //   餐段
  daypartCode?: string;
  dayPartCode?: string;
  //   外送类型:1-pickup;2-deliver;
  orderType: number;
  //   餐厅编码
  storeCode: string;
  beCode?: string;
  isGroupMeal?: string;
  beType?: string;
  addressId?: string;
  expectDeliveryTime?: string;
}

export const fetchOrderConfirmation = (p: OrderConfirmationParams) => {
  return httpInstance
    .get<CommonResponse<ConfirmValidationInfoResponse>>(URLS.VALIDATION_NEW, {
      params: { ...p },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });
};

export const updateOrderConfirm = (p: Carts) => {
  return httpInstance
    .put<CommonResponse<CartUpdateResponseVo_>>(URLS.ADD_RECOMMEND, {
      params: { ...p },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });
};
