import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Tabs } from 'antd-mobile';
import Header from '@/components/Header';
import { fetchOrderCategory } from '@/apis/order';
import type { OrderCategory } from '@/apis/order';
import CategoryItem from './components/CategoryItem';
import useTitle from '@/hooks/useTitle';
import './index.less';

const OrderPage = () => {
  useTitle('我的订单');
  const [activeKey, setActiveKey] = useState<string>('');
  const [orderCategory, setOrderCategory] = useState<OrderCategory>();
  const init = useCallback(() => {
    fetchOrderCategory()
      .then((res) => {
        setOrderCategory(res!);
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    init();
  }, []);

  const { list: oldList, queryOrderTimeTips } = orderCategory || {};

  const list = useMemo(() => (oldList || []).filter(({ id }) => id !== 0), [oldList]);

  useEffect(() => {
    if (list && list.length > 0) {
      setActiveKey(String(list[0]?.id));
    }
  }, [list]);

  const invoiceDom = useMemo(
    () => (
      <Button shape="rounded" fill="none" size="mini" style={{ marginRight: '15px', backgroundColor: '#F5F5F5' }}>
        开发票
      </Button>
    ),
    [],
  );

  return (
    <div className="order-container">
      {/* <Header title="我的订单" hideBack={true} rightContent={invoiceDom} className="order-header" /> */}
      <div className="content">
        <Tabs
          activeKey={activeKey}
          stretch={false}
          onChange={(key) => {
            setActiveKey(key);
          }}>
          {(list || []).map((item) => {
            const { name, id } = item || {};
            return (
              <Tabs.Tab
                title={
                  <span className="tab-item">
                    {name}
                    <span className="bg"></span>
                  </span>
                }
                key={id}>
                <CategoryItem id={id} queryOrderTimeTips={queryOrderTimeTips} />
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};
export default OrderPage;
