import type { CSSProperties, FC, MouseEventHandler, PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { getCDNAssets } from '@/utils';

import styles from './styles/numbtn.module.less';

export const getButtonLightImg = (num: number, isplus?: boolean, max?: number, min?: number) => {
  if (isplus) {
    if (typeof max === 'number' && num >= max) {
      return getCDNAssets('home_add_invalid.png');
    } else {
      return getCDNAssets('home_plus.png');
    }
  } else {
    if (typeof min === 'number' && num <= min) {
      return getCDNAssets('home_minus_invalid.png');
    }
    return getCDNAssets('home_minus.png');
  }
};

export type ProductCardCountStepperProps = {
  className?: string;
  btnClassName?: string;
  numClassName?: string;
  style?: CSSProperties;
  count?: number;
  minCount?: number;
  maxCount?: number;
  addDisabled?: boolean;
  subDisabled?: boolean;
  isGaryStyle?: boolean;
  onAdd?: MouseEventHandler<HTMLDivElement>;
  onSub?: MouseEventHandler<HTMLDivElement>;
  showSub?: (count?: number) => boolean;
};

export const ProductCardCountStepper: FC<ProductCardCountStepperProps> = (props) => {
  const {
    className,
    btnClassName,
    numClassName,
    style,
    count = 0,
    minCount,
    maxCount,
    addDisabled,
    subDisabled,
    isGaryStyle,
    onAdd,
    onSub,
    showSub,
  } = props;

  const { curSubDisabled, subImg } = useMemo(() => {
    const disabled = subDisabled || (typeof minCount === 'number' && count <= minCount);
    const img = disabled ? getCDNAssets('home_minus_invalid.png') : getButtonLightImg(count, false, maxCount, minCount);

    return {
      curSubDisabled: disabled,
      subImg: img,
    };
  }, [count, maxCount, minCount, subDisabled]);
  const { curAddDisabled, addImg } = useMemo(() => {
    const disabled = addDisabled || (typeof maxCount === 'number' && count >= maxCount);
    const img = disabled
      ? isGaryStyle
        ? getCDNAssets('home_add_gray_invalid.png')
        : getCDNAssets('home_add_invalid.png')
      : getButtonLightImg(count, true, maxCount, minCount);

    return {
      curAddDisabled: disabled,
      addImg: img,
    };
  }, [addDisabled, count, isGaryStyle, maxCount, minCount]);

  const displaySubBtn = useMemo(() => (typeof showSub === 'function' ? showSub(count) : true), [count, showSub]);

  const onSubClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (!curSubDisabled) {
        onSub?.(e);
      }
    },
    [curSubDisabled, onSub],
  );
  const onAddClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (!curAddDisabled) {
        onAdd?.(e);
      }
    },
    [curAddDisabled, onAdd],
  );

  return (
    <div className={classNames(styles.stepperWrapper, className)} style={style}>
      {displaySubBtn && (
        <>
          <div
            className={classNames(styles.stepperButtonItem, btnClassName)}
            data-disabled={curSubDisabled}
            onClick={onSubClick}>
            <img className={styles.stepperImg} src={subImg} />
          </div>
          <div className={classNames(styles.stepperCount, numClassName)}>{count}</div>
        </>
      )}
      <div
        className={classNames(styles.stepperButtonItem, btnClassName)}
        data-disabled={curAddDisabled}
        onClick={onAddClick}>
        <img className={styles.stepperImg} src={addImg} />
      </div>
    </div>
  );
};

export type ProductCardSelectButtonProps = {
  className?: string;
  style?: CSSProperties;
  count?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const ProductCardSelectButton: FC<PropsWithChildren<ProductCardSelectButtonProps>> = (props) => {
  const { className, style, count = 0, children = '选规格', onClick } = props;
  return (
    <div className={classNames(styles.selectWrapper, className)} style={style} onClick={onClick}>
      {count > 0 && <div className={styles.selectCount}>{count}</div>}
      <span>{children}</span>
    </div>
  );
};
