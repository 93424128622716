import { trackEvent } from '@/utils/tracker';
import { fetchOrderDetail } from '@/apis/order';
import type { StoreItem } from '@/apis/canteen';
import { daypartMap } from '@/constants';

export const logPayOrder = (
  orderId: string,
  nearestStoreInfo?: StoreItem,
  statusInfo?: { isSuccess?: boolean; isError?: boolean; errMsg?: string },
) => {
  const { isSuccess, isError, errMsg } = statusInfo || {};
  const { code, address, name } = nearestStoreInfo || {};
  let logCardInfo: {
    useCardStock: string;
    cardstockId: string;
    cardstockName: string;
  } = {
    useCardStock: '',
    cardstockId: '',
    cardstockName: '',
  };
  try {
    const logCardInfoStr = window.localStorage.getItem('logCardInfo');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    logCardInfo = JSON.parse(logCardInfoStr!);
  } catch (error) {
    console.log('not get logCardInfo');
  }

  fetchOrderDetail(orderId)
    .then((res) => {
      if (res) {
        const {
          daypartCode,
          realTotalAmount,
          orderType,
          totalAmount,
          totalDiscountAmount,
          couponList,
          tablewareInfo,
          remark,
          storeCode,
          storeName,
          storeAddress,
          storeCityName,
          deliveryInfo,
          deliveryPrice,
          saleTime,
          eatTypeName,
          orderProductList,
        } = res;
        trackEvent('payOrder', {
          order_time: daypartMap[String(daypartCode) as keyof typeof daypartMap] || '',
          page_source: '云闪付点餐',
          order_id: orderId,
          actual_order_amount: realTotalAmount,
          order_type: orderType,
          original_order_price: totalAmount,
          discount_amount: totalDiscountAmount,
          is_use_card_stock: logCardInfo?.useCardStock,
          cardstock_name: logCardInfo?.cardstockName,
          cardstock_id: logCardInfo?.useCardStock,
          us_code: storeCode,
          us_name: storeName,
          us_address: storeAddress,
          loc_us_code: name,
          loc_us_name: name,
          loc_us_address: address,
          is_loc_namecode: code === storeCode,
          dining_way: eatTypeName,
          takefood_time: saleTime,
          transportation_costs: deliveryPrice,
          deliver_time: deliveryInfo?.expectDeliveryTime,
          receiver_sex: deliveryInfo?.gender,
          receiver_city: storeCityName,
          note_content: remark,
          need_ware: tablewareInfo,
          payment_method: 'UNION',
          is_success: isSuccess,
          fail_reason: errMsg,
          is_use_coupon: couponList && couponList.length > 0,
        });

        (orderProductList || []).map((i) => {
          const { productCode, productName, quantity, productType, price, subtotal, realSubtotal } = i || {};
          trackEvent('payOrderDetail', {
            order_time: daypartMap[String(daypartCode) as keyof typeof daypartMap] || '',
            order_id: orderId,
            order_type: orderType,
            commodity_id: productCode,
            commodity_name: productName,
            commodity_quantity: quantity,
            product_type: productType,
            original_price: price,
            present_price: subtotal,
            total_price: realSubtotal,
            is_use_card_stock: logCardInfo?.useCardStock,
            cardstock_name: logCardInfo?.cardstockName,
            cardstock_id: logCardInfo?.useCardStock,
            combo_id: productCode,
            combo_name: productName,
            combo_quantity: quantity,
          });
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
