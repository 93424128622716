const BIZ_CODE_1001 = '1001';
const BIZ_CODE_100 = '100';
const BIZ_CODE_101 = '101';
const BIZ_CODE_102 = '102';
const BIZ_CODE_103 = '103';
const BIZ_CODE_202 = '202';
const BIZ_CODE_201 = '201';
const BIZ_CODE_300 = '300';
const BIZ_CODE_600 = '600';
const BIZ_CODE_700 = '700';
export {
  BIZ_CODE_1001,
  BIZ_CODE_100,
  BIZ_CODE_101,
  BIZ_CODE_102,
  BIZ_CODE_103,
  BIZ_CODE_202,
  BIZ_CODE_300,
  BIZ_CODE_600,
  BIZ_CODE_700,
  BIZ_CODE_201,
};
