import { useEffect, useState, useRef } from 'react';
import { useDeepMemo } from '@axios-use/react';
import { useThrottleFn } from 'ahooks';

export const SEARCH_NODE_HEIGHT = 42;
export const BANNER_HEIGHT = 240;

export type ScrollLayoutOptions = {
  storeEffectDeps?: boolean[];
};

export function useScrollLayout(options?: ScrollLayoutOptions) {
  const { storeEffectDeps } = options || {};

  // StoreNode
  const [storeNodeHeight, setStoreNodeHeight] = useState<number>();
  const storeNodeRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (storeEffectDeps == null || (Array.isArray(storeEffectDeps) && storeEffectDeps.every((i) => Boolean(i)))) {
      if (storeNodeRef.current?.offsetHeight) {
        setStoreNodeHeight(storeNodeRef.current.offsetHeight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepMemo([storeEffectDeps]));

  const menuListOffsetTop = storeNodeHeight ? storeNodeHeight + SEARCH_NODE_HEIGHT : undefined;
  // ----------- end

  const containerRef = useRef<HTMLDivElement>(null);
  const listPanelRef = useRef<HTMLDivElement>(null);
  const [fullScreen, setFullScreen] = useState(false);

  const menuListRef = useRef<HTMLDivElement>(null);

  const { run: containerScroll } = useThrottleFn(
    () => {
      const _scrollTop = containerRef.current?.scrollTop;
      const _offsetTop = listPanelRef.current?.offsetTop;

      if (_offsetTop && _scrollTop && _offsetTop - _scrollTop <= 22) {
        setFullScreen(true);
      } else {
        setFullScreen(false);
      }
    },
    {
      leading: true,
      trailing: true,
      wait: 100,
    },
  );
  const containerScrollRef = useRef(containerScroll);
  containerScrollRef.current = containerScroll;

  useEffect(() => {
    const containerEl = containerRef.current;
    if (containerEl) {
      containerEl.addEventListener('scroll', containerScrollRef.current);
    }
    return () => {
      if (containerEl) {
        containerEl.removeEventListener('scroll', containerScrollRef.current);
      }
    };
  }, []);

  return {
    storeNodeRef,
    storeNodeHeight,
    menuListOffsetTop,

    containerRef,
    listPanelRef,
    fullScreen,

    menuListRef,
  };
}
