import type { CSSProperties, FC } from 'react';
import classNames from 'classnames';
import { SideBar, Skeleton } from 'antd-mobile';

import styles from './list.module.less';

const ProductCardSkeleton = () => {
  return (
    <div style={{ padding: '12px 15px 12px 8px', display: 'flex', flexDirection: 'row', gap: '8px' }}>
      <Skeleton style={{ '--width': '130px', '--height': '97px' }} />
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '8px 0' }}>
        <Skeleton.Title style={{ height: '17px', width: '80%', margin: '0 0 10px 0' }} />
        <Skeleton style={{ '--width': '100%', '--height': '15px' }} />
        <Skeleton style={{ '--width': '22px', '--height': '18px', marginTop: '20px' }} />
      </div>
    </div>
  );
};

const SIDE_INDEXS = [...new Array(10).fill(null).map((_, idx) => idx)];
const CARD_INDEXS = [...new Array(20).fill(null).map((_, idx) => idx)];

export type ProductMenuListSkeletonProps = {
  className?: string;
  style?: CSSProperties;
};

const ProductMenuListSkeleton: FC<ProductMenuListSkeletonProps> = (props) => {
  const { className, style } = props;
  return (
    <div className={classNames(styles.container, className)} style={style}>
      <div className={styles.side}>
        <SideBar
          activeKey={'side-item-0'}
          style={{
            '--width': '65px',
            '--height': 'max-content',
            minHeight: '100%',
            overflowY: 'hidden',
          }}>
          {SIDE_INDEXS.map((i) => (
            <SideBar.Item
              data-id={`side-item-${i}`}
              key={`side-item-${i}`}
              title={<Skeleton.Title style={{ height: '42px', width: '40px', margin: '0 auto' }} />}
            />
          ))}
        </SideBar>
      </div>
      <div className={styles.main}>
        {CARD_INDEXS.map((i) => (
          <ProductCardSkeleton key={`card-skeleton-${i}`} />
        ))}
      </div>
    </div>
  );
};

export default ProductMenuListSkeleton;
