import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SwipeAction, Dialog, Button, Ellipsis, SearchBar } from 'antd-mobile';
import cls from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '@/store/';
import type { LocationAndCityInfo } from '@/store/addressSlice';
import { updatePlace, setSelectCityTag, SelectCityTag } from '@/store/addressSlice';
import useEditing from '@/hooks/useEditing';
import Header from '@/components/Header';
import AddressFooter from '@/components/AddressFooter';

import AddressSearchBar from '@/components/AddressSearchBar';
import AddressPlaceList from '@/components/AddressPlaceList';
import { fetchCityInfo, customAMapAroundPOI } from '@/apis/address';
import type { Place, CityInfo } from '@/apis/address';
import { SEARCH_PLACE, EDIT_ADDRESS, SELECT_CITY } from '@/constants/path';
import type { LocationInfo } from '@/utils';
import { getLocationInfo } from '@/utils';
import useLocationInfo from '@/hooks/useLocationInfo';
import useTitle from '@/hooks/useTitle';
import useQuery from '@/hooks/useQuery';
import './index.less';

const SelectPlace = () => {
  useTitle('选择地址');
  const query = useQuery<{ isChange: string }>();
  const placeInfo = useSelector((state: RootState) => state.address.place);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { locationAndCityInfo } = useLocationInfo();

  const [placeList, setPlaceList] = useState<Place[]>([]);
  const [selectIdx, setSelectIdx] = useState<number>();

  const contentRef = useRef<HTMLDivElement>();
  const searchRef = useRef<HTMLDivElement>();
  const titleRef = useRef<HTMLDivElement>();

  const { isEditing } = useEditing();

  const timer = useRef<number>(null);

  useEffect(() => {
    if (!placeInfo) {
      const { code, name, latitude, longitude } = locationAndCityInfo?.city || {};
      code &&
        dispatch(
          updatePlace({
            cityCode: code,
            cityName: name,
            latitude,
            longitude,
          }),
        );
    }
  }, [locationAndCityInfo, placeInfo, dispatch]);

  const getAroundPlace = useCallback((p: LocationInfo) => {
    const { latitude, longitude } = p;
    // @ts-ignore
    timer.current = Date.now();
    const triggerTime = timer.current;
    customAMapAroundPOI({ latitude, longitude })
      .then((res) => {
        // 存在前一个请求覆盖后一个的情况
        if (timer.current === triggerTime) {
          setPlaceList(res || []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // 获取附近的地点
    const { latitude, longitude, cityCode } = placeInfo || {};
    if (cityCode === locationAndCityInfo?.city?.code) {
      // 说明是当前定位城市 用定位请求附近的地点
      const { latitude: realLatitude, longitude: realLongitude } = locationAndCityInfo || {};
      if (realLatitude && realLongitude) {
        getAroundPlace({ latitude: realLatitude, longitude: realLongitude });
        return;
      }
    }
    if (latitude && longitude) {
      getAroundPlace({ latitude, longitude });
    }
  }, [placeInfo]);

  const canComplete = useMemo(() => selectIdx || selectIdx === 0, [selectIdx]);

  const handleClick = useCallback(() => {
    const selectPlace = placeList[selectIdx as number];
    //  selectPlace中缺少cityCode cityName
    const updatedPlace = {
      ...selectPlace,
      cityCode: selectPlace.cityCode || placeInfo?.cityCode,
      cityName: selectPlace.cityName || placeInfo?.cityName,
    };
    dispatch(updatePlace({ ...updatedPlace }));
    // navigate(EDIT_ADDRESS);
    query?.isChange ? navigate(-1) : navigate(EDIT_ADDRESS);
  }, [placeInfo, selectIdx, placeList, dispatch, navigate, query]);

  const { cityCode, cityName } = placeInfo || {};

  return (
    <div className="select-place-container">
      {/* <Header title="选择地址" /> */}
      <div className="content" ref={contentRef as React.LegacyRef<HTMLDivElement>}>
        <AddressSearchBar
          cityInfo={{ code: cityCode, name: cityName }}
          // @ts-ignore
          ref={searchRef}
          onChangeCity={() => {
            dispatch(setSelectCityTag(SelectCityTag.address));
            navigate(SELECT_CITY);
          }}
          onFocus={() => {
            navigate(SEARCH_PLACE, { replace: true });
          }}
        />
        <div className="title-wrapper" ref={titleRef as React.LegacyRef<HTMLDivElement>}>
          <div className="title">
            附近地点
            <div className="bg" />
          </div>
        </div>
        <div className="place-container">
          <AddressPlaceList
            placeList={placeList}
            // height={calcPlaceListHeight()}
            selectIdx={selectIdx}
            onSelect={(idx: number) => {
              setSelectIdx(idx);
            }}
          />
        </div>
      </div>
      <AddressFooter text={isEditing ? '保存地址' : '添加配送地址'} disabled={!canComplete} handleClick={handleClick} />
    </div>
  );
};

export default SelectPlace;
