import type { CSSProperties, FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { SpinLoading } from 'antd-mobile';

import styles from './spin.module.less';

export type SpinProps = {
  className?: string;
  style?: CSSProperties;
  loading?: boolean;
};

const Spin: FC<PropsWithChildren<SpinProps>> = (props) => {
  const { className, style, loading, children } = props;
  return (
    <div className={classNames(styles.wrapper, className)} style={style} data-loading={loading}>
      {children}
      {loading && (
        <div className={styles.loadingWrapper}>
          <SpinLoading />
        </div>
      )}
    </div>
  );
};

export default Spin;
