import type { FC, MouseEventHandler, MouseEvent } from 'react';
import { useCallback, useMemo, memo } from 'react';

import type { ImageProps } from 'antd-mobile/es/components/image';
import { Ellipsis, Image } from 'antd-mobile';

import type { MenuListProductItem } from '@/apis/product/types';
import { getCDNAssets } from '@/utils';

import ProductCardTags from './Tags';
import ProductCardPrice from './Price';
import { ProductCardSelectButton, ProductCardCountStepper } from './NumButton';

import styles from './styles/card.module.less';

const MyImage = memo(
  (props: ImageProps) => <Image {...props} />,
  (prevProps, nextProps) => prevProps.src === nextProps.src,
);
MyImage.displayName = 'MyImage';

export type ProductCardProps = {
  id?: string;
  orderType?: number;
  item: MenuListProductItem;
  count?: number;
  onClick?: (p: MenuListProductItem, e: MouseEvent<HTMLDivElement>) => void;
  /** 选规格 */
  onSelect?: (p: MenuListProductItem) => void;
  /** add to cart */
  onAdd?: (p: MenuListProductItem) => unknown;
  /** remove */
  onSub?: (p: MenuListProductItem) => unknown;
};

const ProductCard: FC<ProductCardProps> = memo((props) => {
  const { id, orderType = 1, count, item, onClick, onSelect, onAdd, onSub } = props;

  const onCardClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      onClick?.(item, e);
    },
    [item, onClick],
  );

  const onCardSelect = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.stopPropagation();
      onSelect?.(item);
    },
    [item, onSelect],
  );
  const onCardAdd = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.stopPropagation();
      onAdd?.(item);
    },
    [item, onAdd],
  );
  const onCardSub = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.stopPropagation();
      onSub?.(item);
    },
    [item, onSub],
  );

  const isMidImgMode = useMemo(() => !!item.productMidImage, [item.productMidImage]);
  const showMemoText = !isMidImgMode && !!item.memo;

  return (
    <div
      id={id}
      className={styles.productItem}
      style={{ backgroundImage: isMidImgMode && item.productMidImage ? `url(${item.productMidImage})` : undefined }}
      data-midmode={isMidImgMode}
      data-displaymode={item.style?.displayMode}
      onClick={onCardClick}>
      <div className={styles.productItemImg}>
        {!isMidImgMode && (
          <MyImage
            src={item.productImage}
            fit="scale-down"
            fallback={<Image placeholder={null} src={getCDNAssets('menu_list_default_img.png')} />}
            placeholder={<Image placeholder={null} src={getCDNAssets('menu_list_default_img.png')} />}
            lazy
          />
        )}
      </div>
      <div className={styles.productItemContent}>
        <div>
          <div className={styles.productItemContentTitle} style={{ color: item.style?.titleColor || undefined }}>
            {item.productName}
          </div>
          {showMemoText && <Ellipsis className={styles.productItemContentDesc} rows={2} content={item.memo} />}
          {(item.cardTagImage || (item.tags && item.tags.length > 0)) && (
            <div className={styles.cardTags} style={{ paddingTop: !showMemoText ? 10 : undefined }}>
              {item.cardTagImage ? (
                <div className={styles.cardTagBg}>
                  <Image src={item.cardTagImage} className={styles.cardTagIcon} />
                  <div className={styles.cardTagText}>{item.cardTagText}</div>
                </div>
              ) : (
                item.tags && item.tags.length > 0 && <ProductCardTags tags={item.tags} isSkinTag={isMidImgMode} />
              )}
            </div>
          )}
        </div>
        <div className={styles.productItemContentMore}>
          <ProductCardPrice
            style={{ color: item.style?.priceColor || undefined }}
            separateStyle={{ color: item.style?.separatePriceColor || undefined }}
            price={orderType === 2 ? item.priceInfo?.deliveryPriceText : item.priceInfo?.eatInPriceText}
            separatePrice={item.priceInfo?.separatePriceText}
            priceShowStyle={item.priceInfo?.priceShowStyle}
          />
          {item.style?.button || [2, 3].includes(item.productType) ? (
            <ProductCardSelectButton
              style={{
                backgroundImage: item.style?.button?.btnImage ? `url(${item.style.button.btnImage})` : undefined,
                color: item.style?.button?.btnTextColor ? item.style.button.btnTextColor : undefined,
              }}
              count={count}
              onClick={onCardSelect}>
              {item.style?.button?.btnText || '选规格'}
            </ProductCardSelectButton>
          ) : [1].includes(item.productType) ? (
            <ProductCardCountStepper
              showSub={(c) => Boolean(c && c > 0)}
              minCount={0}
              maxCount={item.maxPurchaseQuantity}
              count={count}
              onAdd={onCardAdd}
              onSub={onCardSub}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
});
ProductCard.displayName = 'ProductCard';

export default ProductCard;
