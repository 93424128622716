import { useMemo } from 'react';
import { useResource } from '@axios-use/react';
import pick from 'lodash/pick';
import type { PostMcProductDetailQuery, GetCouponProductListQuery } from '@/apis/product';
import { getProductDescReqConfig, getPOSTMcProductDetailReqConfig, getCouponProductList } from '@/apis/product';

export default function useProductDetail(params?: Partial<PostMcProductDetailQuery>, needCouponPrice?: boolean) {
  const { isGroupMeal, beType } = params || {};
  const [proRes, refreshProduct] = useResource(getPOSTMcProductDetailReqConfig, [params as PostMcProductDetailQuery], {
    filter: (p) => Boolean(p && p.productCode && p.storeCode && p.daypartCode && p.orderType),
  });

  const [descRes, refreshProductDesc] = useResource(getProductDescReqConfig, [{ isGroupMeal, beType }]);
  const [couponsProductsRes] = useResource(
    getCouponProductList,
    [
      {
        ...pick(params, [
          'daypartCode',
          'storeCode',
          'orderType',
          'couponCode',
          'couponId',
          'promotionId',
          'beCode',
          'date',
          'time',
          'isGroupMeal',
          'beType',
        ]),
      } as unknown as GetCouponProductListQuery,
    ],
    {
      filter: (p) =>
        Boolean(needCouponPrice && p.daypartCode && p.storeCode && p.orderType && p.couponCode && p.couponId),
      cache: false,
    },
  );
  const couponPrice = useMemo(() => {
    const _productCode = proRes.data?.product.code;
    if (couponsProductsRes.data?.productList && _productCode) {
      const _p = couponsProductsRes.data.productList.find((p) => p.productCode === _productCode);
      return _p?.priceInfo;
    }
    return undefined;
  }, [couponsProductsRes.data?.productList, proRes.data?.product.code]);

  return {
    productData: proRes.data,
    productLoading: proRes.isLoading,
    refreshProduct,
    productDesc: descRes.data,
    productDescLoading: descRes.isLoading,
    refreshProductDesc,
    couponPrice,
  };
}
