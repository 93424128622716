import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Config, pointsText } from '@/apis/home';
export interface ConfigInfo {
  config?: Config;
  pointsText?: pointsText;
  // TODO:
  hasInit: boolean;
}
const initialState: ConfigInfo = {
  hasInit: false,
};

export const commonConfig = createSlice({
  name: 'commonConfig',
  initialState,
  reducers: {
    updateCommonConfig: (state, action: PayloadAction<Config | undefined>) => {
      state.config = action.payload;
      state.pointsText = action.payload?.pointsText;
    },
  },
});
export const { updateCommonConfig } = commonConfig.actions;
export default commonConfig.reducer;
