import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Navigate } from 'react-router';
import Home from '@/pages/Home';
import Order from '@/pages/Order';
import My from '@/pages/My';
import MyAddress from '@/pages/Address/AddressList';
import SelectPlace from '@/pages/Address/SelectPlace';
import SearchPlace from '@/pages/Address/SearchPlace';
import SelectCity from '@/pages/Address/SelectCity';
import EditAddress from '@/pages/Address/EditAddress';
import CanteenSelect from '@/pages/Canteen/Select';
import CanteenSearch from '@/pages/Canteen/Search';
import CanteenDetail from '@/pages/Canteen/Detail';
import OrderConfirm from '@/pages/OrderConfirm';
import OrderPay from '@/pages/OrderPay';
import OrderDetail from '@/pages/Order/OrderDetail';
import OrderDetail2 from '@/pages/Order/OrderDetail/index2';
import UserInfo from '@/pages/My/UserInfo';
import Protocol from '@/pages/My/Protocol';
import Options from '@/pages/My/Options';
import Invoice from '@/pages/My/Invoice';
import Logout from '@/pages/My/Logout';
import CardList from '@/pages/CardList';
import CurCouponList from '@/pages/CardList/CurCouponList';
import CouponsDetail from '@/pages/CardList/CouponsDetail';
import CardDetail from '@/pages/CardList/CardDetail';
import EquityCardList from '@/pages/CardList/EquityCardList';
import { MobileLogin } from '@/pages/Login';
import { UserProfile } from '@/pages/UserProfile';
import { EnvChangePage } from '@/pages/ChangeEnv';

import Main from '@/components/Main';

import {
  MY_ADDRESS,
  SELECT_PLACE,
  SEARCH_PLACE,
  SELECT_CITY,
  EDIT_ADDRESS,
  HOME,
  ORDER,
  MY,
  USER_INFO,
  CARD_LIST,
  PROTOCOL,
  OPTIONS,
  INVOICE,
  SELECT_CANTEEN,
  SEARCH_CANTEEN,
  CANTEEN_DETAIL,
  ORDER_CONFIRM,
  ORDER_PAY,
  LOGOUT,
  COUPONS_DETAIL,
  CARD_DETAIL,
  ORDER_DETAIL,
  AVAILABLE_CARD_LIST,
} from '@/constants/path';

import ProductPage from '@/pages/Product';
import ProductSearchPage from '@/pages/Product/Search/Page';
import ProductDetailPage from '@/pages/Product/Detail/Page';
import ProductCouponSelect from '@/pages/Product/Coupon/Select/Page';
const AntDPreview = lazy(() => import('@/pages/AntD'));

const routes: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        index: true,
        element: <Navigate to={HOME} replace />,
      },
      {
        path: 'product',
        children: [
          {
            index: true,
            element: <ProductPage />,
          },
          {
            path: 'search',
            element: <ProductSearchPage />,
          },
          {
            path: ':productCode',
            element: <ProductDetailPage />,
          },
          {
            path: 'coupon/select',
            element: <ProductCouponSelect />,
          },
        ],
      },
    ],
  },
  {
    path: '/main',
    element: <Main />,
    children: [
      {
        path: HOME,
        element: <Home />,
      },
      {
        path: ORDER,
        element: <Order />,
      },
      {
        path: MY,
        element: <My />,
      },
    ],
  },

  {
    path: '/antd',
    element: <AntDPreview />,
  },
  {
    path: '/login/mobile',
    element: <MobileLogin />,
  },
  {
    path: '/user/profile',
    element: <UserProfile />,
  },
  {
    path: MY_ADDRESS,
    element: <MyAddress />,
  },
  {
    path: SELECT_PLACE,
    element: <SelectPlace />,
  },
  {
    path: SEARCH_PLACE,
    element: <SearchPlace />,
  },
  {
    path: SELECT_CITY,
    element: <SelectCity />,
  },
  {
    path: EDIT_ADDRESS,
    element: <EditAddress />,
  },
  {
    path: USER_INFO,
    element: <UserInfo />,
  },
  {
    path: PROTOCOL,
    element: <Protocol />,
  },
  {
    path: OPTIONS,
    element: <Options />,
  },
  {
    path: INVOICE,
    element: <Invoice />,
  },
  {
    path: LOGOUT,
    element: <Logout />,
  },
  {
    path: CARD_LIST,
    element: <CardList />,
  },
  {
    path: AVAILABLE_CARD_LIST,
    element: <CurCouponList />,
  },
  {
    path: COUPONS_DETAIL,
    element: <CouponsDetail />,
  },
  {
    path: CARD_DETAIL,
    element: <CardDetail />,
  },
  {
    path: '/cardList/equityCardList',
    element: <EquityCardList />,
  },
  {
    path: SELECT_CANTEEN,
    element: <CanteenSelect />,
  },
  {
    path: SEARCH_CANTEEN,
    element: <CanteenSearch />,
  },
  {
    path: CANTEEN_DETAIL,
    element: <CanteenDetail />,
  },
  {
    path: ORDER_CONFIRM,
    element: <OrderConfirm />,
  },
  {
    path: ORDER_PAY,
    element: <OrderPay />,
  },
  {
    path: ORDER_DETAIL,
    element: <OrderDetail />,
  },
  {
    path: ORDER_DETAIL + '2',
    element: <OrderDetail2 />,
  },
  {
    path: 'change_env',
    element: <EnvChangePage />,
  },
];

export default routes;
