import type { FC } from 'react';
import React, { useMemo } from 'react';
import { getCDNAssets } from '@/utils';
import type { OrderProcessNodeHistoryItem } from '@/apis/order.type';
import './index.less';

interface IProps {
  orderProcessNodeHistory?: OrderProcessNodeHistoryItem[];
  orderStatusDetail?: string;
  expectDeliveryTimeShort?: string;
  orderType: number;
  isShowDivider?: boolean;
}

const StatusModel: FC<IProps> = (props) => {
  const {
    orderProcessNodeHistory,
    orderStatusDetail,
    expectDeliveryTimeShort,
    orderType,
    isShowDivider = true,
  } = props || {};

  //   mock
  //   const orderProcessNodeHistory = [
  //     {
  //       isCurrent: 0,
  //     },
  //     {
  //       isCurrent: 1,
  //     },
  //     {
  //       isCurrent: 0,
  //     },
  //   ];
  // eslint-disable-next-line no-template-curly-in-string
  //   const orderStatusDetail = '配送中，预计 ${expectDeliveryTimeShort}送达';
  //   const expectDeliveryTimeShort = '12:12';

  const selectedIndex = useMemo(() => {
    let selectedIdx = 0;
    (orderProcessNodeHistory || []).forEach((item, index) => {
      if (item.isCurrent == 1) {
        selectedIdx = index;
      }
    });
    return selectedIdx;
  }, [orderProcessNodeHistory]);

  const timeTitle = useMemo(() => {
    let innerTimeTitle: string[] = [];
    if (orderStatusDetail) {
      // eslint-disable-next-line no-template-curly-in-string
      innerTimeTitle = orderStatusDetail.split('${expectDeliveryTimeShort}');
    }
    return innerTimeTitle;
  }, [orderStatusDetail]);

  const statusItem = (text: string, img: string, isSelected: boolean) => {
    return (
      <div className="status-item">
        <img className="status-img" src={img} />
        <span className="status-text" style={{ color: isSelected ? '#222222' : '#CCCCCC' }}>
          {text}
        </span>
      </div>
    );
  };

  if (!orderProcessNodeHistory) {
    return null;
  }

  return (
    <div className="status-outside">
      {!!timeTitle && timeTitle.length > 0 && (
        <div className={'status-title'}>
          <span className={'status-title-normal'}>{timeTitle[0]}</span>
          {!!expectDeliveryTimeShort && <span className={'status-title-color'}>{expectDeliveryTimeShort}</span>}
          {timeTitle.length > 1 && <span className={'status-title-normal'}>{timeTitle[1]}</span>}
        </div>
      )}
      <div className="status-view">
        {statusItem('已下单', getCDNAssets('order_icon_status_0.png'), false)}
        <div
          className="dash-line"
          style={{ width: '30Px', marginBottom: '15Px', marginLeft: '10Px', marginRight: '10Px' }}
        />
        {statusItem(
          '配餐中',
          getCDNAssets(selectedIndex == 1 ? 'order_icon_status_1_selected.png' : 'order_icon_status_1.png'),
          selectedIndex == 1,
        )}
        <div
          className="dash-line"
          style={{ width: '30Px', marginBottom: '15Px', marginLeft: '10Px', marginRight: '10Px' }}
        />
        {statusItem(
          '待取餐',
          getCDNAssets(
            selectedIndex == 2
              ? orderType == 1
                ? 'order_icon_status_2_shop_selected.png'
                : 'order_icon_status_2_mds_selected.png'
              : orderType == 1
              ? 'order_icon_status_2_shop.png'
              : 'order_icon_status_2_mds.png',
          ),
          selectedIndex == 2,
        )}
        <div
          className="dash-line"
          style={{ width: '30Px', marginBottom: '15Px', marginLeft: '10Px', marginRight: '10Px' }}
        />
        {statusItem(orderType == 1 ? '已完成' : '已送达', getCDNAssets('order_icon_status_3.png'), false)}
      </div>
      {isShowDivider && (
        <div className="divider">
          <div className="semi-circle-left" />
          <div
            className="dash-line"
            style={{
              width: '100%',
              marginLeft: '20Px',
              marginRight: '20Px',
            }}
          />
          <div className="semi-circle-right" />
        </div>
      )}
    </div>
  );
};

export default StatusModel;
