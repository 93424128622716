import React from 'react';
import { Button } from 'antd-mobile';
import type { ButtonProps } from 'antd-mobile/es/components/button';

import './index.less';

interface Iprops extends ButtonProps {
  text?: string;
  handleClick?: () => void;
  customOperate?: React.ReactElement;
}

const AddressFooter: React.FC<Iprops> = (props) => {
  const { text = '', handleClick, customOperate, ...resProps } = props;

  return (
    <div className="footer-container">
      {customOperate ? (
        customOperate
      ) : (
        <Button
          className="btn"
          color="primary"
          shape="rounded"
          onClick={() => {
            handleClick && handleClick();
          }}
          {...resProps}>
          {text}
        </Button>
      )}
    </div>
  );
};

export default AddressFooter;
