import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';

export const CONFIG_API_DOMAIN = 'CONFIG_API_DOMAIN';
export const CONFIG_DISABLED_API_PERF = 'CONFIG_DISABLED_API_PERF';
export const CONFIG_DISABLED_EVENT_TRACKER = 'CONFIG_DISABLED_EVENT_TRACKER';

const cookies = new Cookies();

export const getStringConfig = (name: string, defaultValue: string): string => {
  const allValues = cookies.getAll<{ [key: string]: string }>();
  if (name in allValues) {
    return allValues[name];
  }
  return defaultValue;
};

export const getBooleanConfig = (name: string, defaultValue: boolean): boolean => {
  const allValues = cookies.getAll<{ [key: string]: string }>();
  if (name in allValues) {
    return allValues[name] === 'true';
  }
  return defaultValue;
};

export const useAppConfig = () => {
  const setConfig = <T extends string | boolean>(name: string, value: T) => {
    cookies.set(name, value);
  };

  const [apiDomain, setApiDomain] = useState(getStringConfig(CONFIG_API_DOMAIN, 'https://api-uat.mcdchina.net'));
  const [disabledAPIPerf, setDisabledAPIPerf] = useState(getBooleanConfig(CONFIG_DISABLED_API_PERF, false));
  const [disabledEventTracker, setDisabledEventTracker] = useState(
    getBooleanConfig(CONFIG_DISABLED_EVENT_TRACKER, false),
  );

  useEffect(() => {
    setConfig(CONFIG_API_DOMAIN, apiDomain);
  }, [apiDomain]);

  useEffect(() => {
    setConfig(CONFIG_DISABLED_API_PERF, disabledAPIPerf);
  }, [disabledAPIPerf]);

  useEffect(() => {
    setConfig(CONFIG_DISABLED_EVENT_TRACKER, disabledEventTracker);
  }, [disabledEventTracker]);

  return {
    CONFIG_API_DOMAIN,
    getStringConfig,

    apiDomain,
    setApiDomain,
    disabledAPIPerf,
    setDisabledAPIPerf,
    disabledEventTracker,
    setDisabledEventTracker,
  };
};
