import React, { useMemo } from 'react';
import { SwipeAction, Dialog, Button, Ellipsis } from 'antd-mobile';
import './index.less';

interface IProps {
  streetAddress: string;
  address: string;
  handleClick?: () => void;
}

const PlaceCard: React.FC<IProps> = (props) => {
  const { streetAddress, address, handleClick } = props;
  return (
    <div className="place-card-container">
      <div className="info">
        <div className="address">
          <Ellipsis content={address} />
        </div>
        <div className="streetAddress">
          <Ellipsis content={streetAddress} />
        </div>
      </div>
      <div className="btn" onClick={handleClick}>
        <span>更换地址</span>
      </div>
    </div>
  );
};

export default PlaceCard;
