import type { OrderDetail } from '@/apis/order.type';
import type { Order } from '@/apis/order';
import { trackEvent } from '@/utils/tracker';

export const trackOrderCancelEvent = (pageName: string, order: OrderDetail | Order) => {
  trackEvent('cancelOrder', {
    page_source: pageName,
  });

  (order.orderProductList || []).forEach((product) => {
    const eventData = {
      order_id: order.orderId,
      actual_order_amount: order.realTotalAmount,
      us_code: order.storeCode,
      order_type: order.orderType === '1' ? '到店取餐' : '麦乐送',
    };

    if (product.productType === '1') {
      trackEvent('cancelOrderDetail', {
        ...eventData,
        commodity_id: product.productCode,
        commodity_name: product.productName,
        commodity_quantity: product.quantity,
      });
    } else if (product.productType === '2') {
      trackEvent('cancelOrderDetail', {
        ...eventData,
        combo_id: product.productCode,
        combo_name: product.productName,
        combo_quantity: product.quantity,
      });
    }
  });
};
