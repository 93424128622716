import type { FC, CSSProperties } from 'react';
import { Fragment, useMemo } from 'react';
import { Image } from 'antd-mobile';

import type { MenuListProductItem } from '@/apis/product/types';

import styles from './styles/tags.module.less';

const MAX_TAGS_LENGTH = 3;
const MAX_TAGS_CHAR_LENGTH = 9;

export type ProductCardTagsProps = {
  tags?: MenuListProductItem['tags'];
  isSkinTag?: boolean;
};

const ProductCardTags: FC<ProductCardTagsProps> = (props) => {
  const { tags, isSkinTag } = props;

  const displayTags = useMemo(() => {
    if (!tags) {
      return null;
    }
    /** 第一个标签字数超过了9 -> 不展示标签 */
    if (tags && tags[0].style !== 3 && tags[0].name.length >= MAX_TAGS_CHAR_LENGTH) {
      return null;
    }

    /**
     * 配置两个标签or三个标签	第一个标签字数为8，第二个标签为3	只展示1个标签
     * 配置了三个标签	前两个标签总字数为8，第三个标签为3	只展示2个标签
     * 配置了三个标签	三个标签&总字数<9	展示三个标签
     * 配置了三个标签	三个标签&总字数=9 展示三个标签
     * 且最多展示3个标签
     */
    if (tags.length >= 2) {
      const _arr: MenuListProductItem['tags'] = [];
      let _tot = 0;
      tags.slice(0, MAX_TAGS_LENGTH).forEach((t) => {
        _tot += t.name?.length || 0;

        if (_tot <= MAX_TAGS_CHAR_LENGTH) {
          _arr.push(t);
        }
      });

      return _arr;
    }

    return tags;
  }, [tags]);

  const displayTagsLength = displayTags?.length || 0;

  return (
    <div className={styles.tagsBg}>
      {displayTags?.map((t) => (
        <Fragment key={t.name}>
          {t.style === 3 ? (
            <div>
              <Image placeholder={null} className={styles.tagImg} fit="scale-down" src={t.image} />
            </div>
          ) : (
            <div
              className={styles.tagItem}
              data-style={t.style}
              data-single={displayTagsLength === 1}
              data-skin={isSkinTag}
              style={{ '--tag-color': t.color } as CSSProperties}>
              {t.name}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default ProductCardTags;
