import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, Ellipsis, Toast } from 'antd-mobile';
import Header from '@/components/Header';
import { fetchOrderCancle } from '@/apis/order';
import type { OrderCategory, Order } from '@/apis/order';
import { ORDER_DETAIL, ORDER_PAY } from '@/constants/path';
import './index.less';
import cls from 'classnames';
import useCountDown from '@/hooks/useCountDown';
import { trackOrderCancelEvent } from '../../orderEventTracker';

interface IProps {
  data: Order;
  getOrderList?: (pageNo: number, pageSize: number) => void;
}
const OrderItem: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const { data, getOrderList } = props;
  const {
    storeName,
    orderStatus,
    orderProductList,
    orderActionList,
    realTotalAmount,
    orderId,
    payId,
    orderStatusCode,
    orderType,
    expirePayTime,
    orderStatusTips,
    estimateDeliveryTime,
    pickupCode,
    pickupWaitOrder,
    pickupWaitMinute,
    riderLocation,
    lockerCode,
    tag,
    eatType,
  } = data || {};

  const handleEnd = useCallback(() => {
    window.location.reload();
  }, []);

  const { restTime, isShow } = useCountDown({ expirePayTime: expirePayTime!, onEnd: handleEnd });
  const { m, s } = restTime || {};

  const isJumping = useRef(false);
  const numName = useMemo(() => {
    const len = (orderProductList || []).length;
    return len === 1 ? 'one' : 'more';
  }, [orderProductList]);
  const splitStr = '{leftTime}'; //tips 其他平台订单的分割字符串
  const splitWaitStr = '{pickupCode}'; //分割pickupCode
  // 倒计时
  const renderTips = () => {
    let tipText = <span />;
    let showTips = false;
    if (isShow) {
      showTips = true;
      const timeStr = String(m).padStart(2, '0') + ':' + String(s).padStart(2, '0');
      if (orderStatusTips && orderStatusTips.length > splitStr.length && orderStatusTips.indexOf(splitStr) > -1) {
        const index = orderStatusTips.indexOf(splitStr);
        const leftStr = orderStatusTips.slice(0, index);
        const rightStr = orderStatusTips.slice(index + splitStr.length);

        tipText = (
          <span className="tip_text">
            {leftStr}
            <span className="tip_red">{timeStr}</span>
            {rightStr}
          </span>
        );
      } else {
        if (tag?.name) {
          tipText = (
            <span className="tip_text">
              请于
              <span className="tip_red">{timeStr}</span>
              前，去{tag?.name}支付
            </span>
          );
        } else {
          tipText = (
            <span className="tip_text">
              等待支付，还有
              <span className="tip_red">{timeStr}</span>
              分钟
            </span>
          );
        }
      }
    } else if (pickupCode && pickupCode.length > 0) {
      showTips = true;
      let tipLeftTitle = '取餐码';
      let tipBackText = eatType === 5 ? '，签到后请等待员工送餐至车' : '，请凭取餐码前往取餐'; // 5为新DT
      if (orderStatusCode == '3' && orderStatusTips && orderStatusTips.indexOf(splitWaitStr) > -1) {
        //待取餐
        const waitIndex = orderStatusTips.indexOf(splitWaitStr);
        tipLeftTitle = orderStatusTips.slice(0, waitIndex);
        tipBackText = orderStatusTips.slice(waitIndex + splitWaitStr.length);
      }
      tipText = (
        <span className="tip_text">
          {tipLeftTitle}
          <span className="tip_red">{' ' + pickupCode + ' '}</span>
          {tipBackText}
        </span>
      );
    } else if (pickupWaitMinute && pickupWaitMinute > 0 && (orderStatusCode == '2' || orderStatusCode == '10')) {
      // FoodProduction配餐中  2 || FoodProduction_Store 配餐中-餐厅确认 10
      showTips = true;
      tipText = (
        <span className="tip_text">
          前面排队
          <span className="tip_red">{' ' + (pickupWaitOrder || 0).toString() + ' '}</span>
          单，预计等待
          <span className="tip_red">{' ' + (pickupWaitMinute || 0).toString() + ' '}</span>
          分钟
        </span>
      );
    } else if (lockerCode && lockerCode.length > 0) {
      showTips = true;
      tipText = (
        <span className="tip_text">
          取餐柜密码
          <span className="tip_red">{' ' + lockerCode + ' '}</span>
          ，请至取餐柜取餐
        </span>
      );
    } else if (orderType == '2' && orderStatusCode == '4' && !riderLocation) {
      //配送中但是无地图的情况也要显示 WaitForDeliver 4
      if (estimateDeliveryTime && estimateDeliveryTime.length > 0 && estimateDeliveryTime.indexOf(' ') != -1) {
        const dateList = estimateDeliveryTime.split(' ');
        if (dateList && dateList.length == 2) {
          const timeStr = dateList[1];
          if (timeStr && timeStr.length > 0 && timeStr.indexOf(':') != -1) {
            const timeList = timeStr.split(':');
            if (timeList && timeList.length > 2) {
              const deliveryTimeStr = timeList[0] + ':' + timeList[1];
              showTips = true;
              tipText = (
                <span className="tip_text">
                  骑手配送中，预计
                  <span className="tip_red">{' ' + deliveryTimeStr + ' '}</span>
                  送达
                </span>
              );
            }
          }
        }
      }
    } else if (orderStatusTips && orderStatusTips.length > 0 && orderStatusTips.indexOf(splitStr) < 0) {
      //确保带splitStr的tips只在leftSeconds有值时显示
      showTips = true;
      tipText = <span className="tip_text">{orderStatusTips}</span>;
    }

    if (showTips) {
      return <div className="tip">{tipText}</div>;
    } else {
      return <div style={{ height: 0 }} />;
    }
  };
  //避免重复点击
  const checkBtnClick = (btnFunc: () => void) => {
    if (isJumping.current) {
      return;
    }

    isJumping.current = true;
    btnFunc && btnFunc();

    setTimeout(() => {
      isJumping.current = false;
    }, 2000);
  };
  const functionBtnClick = (action: string) => {
    switch (action) {
      case 'pay':
        checkBtnClick(() => {
          navigate(`${ORDER_PAY}?orderId=${orderId}&payId=${payId}&source=0`);
        });
        return;
      case 'invoice':
        checkBtnClick(() => {
          Toast.show('如需开票请到麦当劳APP中开票');
        });
        return;
      case 'comment':
        // 点评
        return;
      case 'another_one': //再来一单
        checkBtnClick(() => {
          navigate(`/product?orderType=${orderType}?orderId=${orderId}`);
        });
        return;
      case 'use_now': //立即使用
        checkBtnClick(() => {
          Toast.show('该功能暂不支持');
        });
        break;
      case 'refund':
        // 退款
        return;
      case 'reminder':
        return;
      case 'contact_rider':
        if (isJumping.current) {
          return;
        }
        isJumping.current = true;

        setTimeout(() => {
          isJumping.current = false;
        }, 300);
        return;
      case 'rider_position':
        checkBtnClick(() => {
          Toast.show('该功能暂不支持');
        });
        return;
      case 'cancel':
        checkBtnClick(() => {
          console.log('取消订单');
          submit();
        });
        return;
      case 'advise':
        checkBtnClick(() => {
          Toast.show('该功能暂不支持');
        });
        return;
        checkBtnClick(() => {});
        break;
      case 'use_self': //自己使用
        checkBtnClick(() => {
          Toast.show('该功能暂不支持');
        });
        break;
      case 'confirm_received': //确认收货
        checkBtnClick(() => {
          Toast.show('该功能暂不支持');
        });
        break;
      case 'watch_credentials': //查看凭证
        checkBtnClick(() => {
          Toast.show('该功能暂不支持');
        });
        break;
      default:
        return;
    }
  };
  const submit = () => {
    void Dialog.confirm({
      destroyOnClose: true,
      title: '订单取消确认',
      content: '是否提交申请',
      bodyClassName: 'cancel-wrapper',
      confirmText: '取消订单',
      cancelText: '我再想想',
      onConfirm: () => {
        fetchOrderCancle(orderId)
          .then((res) => {
            trackOrderCancelEvent('订单列表页', data);
            if (res?.confirmResult) Toast.show('订单已取消');
            console.log('===getOrderList=', getOrderList);
            getOrderList && getOrderList(1, 10);
          })
          .catch((err: Error) => {
            Toast.show(err.message || '操作失败！');
          });
      },
    });
  };
  function strFormat(i: number) {
    return ('00' + i.toString()).slice(-2);
  }
  const handletoPage = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate],
  );

  const filterOrderActionList = useMemo(
    () => (orderActionList || []).filter(({ code }) => ['pay', 'invoice', 'cancel'].includes(code!)),
    [orderActionList],
  );

  return (
    <div className="order-item-content">
      {renderTips()}
      <div
        className="order-item-container"
        style={{ borderRadius: isShow ? '0px 0px 10px 10px' : '10px' }}
        onClick={() => {
          handletoPage(ORDER_DETAIL + '?orderId=' + orderId);
        }}>
        <div className="header">
          <span className="store">{storeName}</span>
          <span className="status">{orderStatusCode === '1' && !isShow ? '已取消' : orderStatus}</span>
        </div>
        <div className={cls('product-wrapper', numName)}>
          <div className={cls('product-list', numName)}>
            {(orderProductList || []).map((i) => {
              const { productImage, productName, productCode, comboItemList } = i || {};
              return (
                <div key={productCode} className="item">
                  <img src={productImage} />
                  <div className="name-wrapper">
                    <Ellipsis className="name" content={productName} />
                    {numName === 'one' ? (
                      <Ellipsis
                        className="name-detail"
                        rows={2}
                        content={(comboItemList || []).map(({ name }) => name).join('+')}
                      />
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="total">
            <span className="money">
              <span className="symbol">¥</span>
              <span className="val">{realTotalAmount}</span>
            </span>
            <span className="count">共{(orderProductList || []).length}件</span>
          </div>
        </div>
        <div className={cls('btn-wrapper', { hasBtn: filterOrderActionList.length > 0 })}>
          {filterOrderActionList.map((i, idx) => {
            const { name, code } = i || {};
            const isLast = idx === 0;
            let btnClassName = 'order_list_card_btn_normal';
            let btnTextClassName = 'order_list_card_btn_text_normal';
            btnClassName = isLast ? 'order_list_card_btn_last' : 'order_list_card_btn_normal';
            btnTextClassName = isLast ? 'order_list_card_btn_text_last' : 'order_list_card_btn_text_normal';
            return (
              <Button
                className={`${btnClassName}`}
                key={name}
                color="primary"
                fill={isLast ? 'solid' : 'none'}
                shape="rounded"
                size="mini"
                onClick={(e) => {
                  e.stopPropagation();
                  functionBtnClick(code || '');
                }}>
                <span className={btnTextClassName}> {name}</span>
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default OrderItem;
