import type { NavigateFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const reg = /(http|https):\/\/([\w.]+\/?)\S*/;

const useNav = (): NavigateFunction => {
  const navgiate = useNavigate();

  return (...p) => {
    const [url] = p;
    if (typeof url === 'string' && reg.test(url)) {
      window.location.href = url;
    } else {
      navgiate(...(p as Parameters<NavigateFunction>));
    }
  };
};

export default useNav;
