import isEmpty from 'lodash/isEmpty';
import type { ProductInfoCustomization } from '@/apis/product/types';

export const getCustomizationItemsTotalPrice = (items?: ProductInfoCustomization['items']) => {
  let _price = 0;
  if (items && !isEmpty(items)) {
    items.forEach((i) => {
      if (i.values) {
        const _v = i.values.find((v) => v.checked);
        if (_v?.price && _v.quantity) {
          _price += _v.price * _v.quantity;
        }
      }
    });
  }
  return _price;
};
