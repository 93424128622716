import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { useStore } from '@/hooks/useStore';
import { useCartAction } from '@/hooks/useCart';
import useQuery from '@/hooks/useQuery';

/**
 * 通过 URL 控制是否使用缓存的 “预约时间” 信息
 * @param orderType
 */
export function useStoreDateInfoWithLocation(orderType?: number) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery<{ appointment?: string }>();
  const { curDateInfo: storeDateInfo, updateDeliveryDateInfo: updateStoreDeliveryDateInfo } = useStore(orderType);

  const appointment = useMemo(() => query?.appointment === '1', [query?.appointment]);
  const queryAppointmentStr = appointment ? '1' : '';

  const curDateInfo = useMemo(() => (appointment ? storeDateInfo : undefined), [appointment, storeDateInfo]);

  const updateDeliveryDateInfo = useCallback<typeof updateStoreDeliveryDateInfo>(
    (info) => {
      if (!appointment) {
        navigate(
          {
            pathname: location.pathname,
            search: new URLSearchParams({
              ...query,
              appointment: '1',
            }).toString(),
          },
          {
            replace: true,
          },
        );
      }
      if (!info?.date || !info.time) {
        navigate(
          {
            pathname: location.pathname,
            search: new URLSearchParams(omit(query, ['appointment'])).toString(),
          },
          {
            replace: true,
          },
        );
      }

      return updateStoreDeliveryDateInfo(info);
    },
    [appointment, location.pathname, navigate, query, updateStoreDeliveryDateInfo],
  );

  return { appointment, queryAppointmentStr, curDateInfo, updateDeliveryDateInfo };
}

/**
 * 通过 URL query 获取是否使用 cache 的状态
 */
export function useStoreNeedUseCacheWithLocation(orderType?: number) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery<{ withcache?: string }>();

  const { storeInfo, deliveryAddressInfo } = useStore(orderType);
  const { manualClearCart } = useCartAction();

  const canuseByQuery = useMemo(() => query?.withcache === '1', [query?.withcache]);
  const canuseStoreCache = useMemo(
    () =>
      canuseByQuery && (orderType === 2 ? !isEmpty(deliveryAddressInfo) && !isEmpty(storeInfo) : !isEmpty(storeInfo)),
    [canuseByQuery, deliveryAddressInfo, orderType, storeInfo],
  );

  const turnOnCacheStatus = useCallback(() => {
    if (!canuseStoreCache) {
      navigate(
        {
          pathname: location.pathname,
          search: new URLSearchParams({
            ...query,
            withcache: '1',
          }).toString(),
        },
        {
          replace: true,
          state: location.state,
        },
      );
    }
  }, [canuseStoreCache, location.pathname, location.state, navigate, query]);

  const turnOffCacheStatus = useCallback(() => {
    if (canuseStoreCache) {
      navigate(
        {
          pathname: location.pathname,
          search: new URLSearchParams(omit(query, ['withcache'])).toString(),
        },
        {
          replace: true,
          state: location.state,
        },
      );
    }
  }, [canuseStoreCache, location.pathname, location.state, navigate, query]);

  const turnOffCacheStatusRef = useRef(turnOffCacheStatus);
  turnOffCacheStatusRef.current = turnOffCacheStatus;

  // auto turnof cache
  useEffect(() => {
    if (canuseByQuery && !canuseStoreCache) {
      turnOffCacheStatusRef.current();
    }
  }, [canuseByQuery, canuseStoreCache]);

  // TODO faker start ---
  const firstRunCartClear = useRef(true);
  const manualClearCartRef = useRef(manualClearCart);
  manualClearCartRef.current = manualClearCart;
  useEffect(() => {
    if (!canuseByQuery && firstRunCartClear.current) {
      manualClearCartRef.current(orderType);
      firstRunCartClear.current = false;
    }
  }, [canuseByQuery, orderType]);
  // --- faker end

  return { canuseStoreCache, turnOnCacheStatus, turnOffCacheStatus };
}

export function useClearStoreNavigateForOrder(orderType = 1, pagecode?: 'menu' | 'search') {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery<{
    storeCode?: string;
    newStoreCode?: string;
    addressId?: string;
    newAddressId?: string;
  }>();

  const clearStoreCodoOrIdQuery = useCallback(() => {
    const { storeCode, newStoreCode, addressId, newAddressId } = query || {};
    if (orderType === 1 && (storeCode || newStoreCode)) {
      navigate(
        {
          pathname: location.pathname,
          search: new URLSearchParams(omit(query, ['storeCode', 'newStoreCode', 'withcache'])).toString(),
        },
        {
          replace: true,
          state: location.state,
        },
      );
    } else if (orderType === 2 && (addressId || newAddressId)) {
      navigate(
        {
          pathname: location.pathname,
          search: new URLSearchParams(omit(query, ['addressId', 'newAddressId', 'storeCode', 'withcache'])).toString(),
        },
        {
          replace: true,
          state: location.state,
        },
      );
    }
  }, [location.pathname, location.state, navigate, orderType, query]);

  const storeState = useStore();
  const cacheStoreCode = storeState.newStoreCode || storeState.storeInfo?.code || storeState.localCacheStoreCode;
  const cacheAddressId = storeState.newAddressId || storeState.deliveryAddressInfo?.id;

  const recoverStoreCode = useCallback(() => {
    const { storeCode, newStoreCode, addressId, newAddressId } = query || {};
    if (orderType === 1 && !storeCode && !newStoreCode && cacheStoreCode) {
      navigate(
        {
          pathname: location.pathname,
          search: new URLSearchParams({ ...query, storeCode: cacheStoreCode }).toString(),
        },
        {
          replace: true,
          state: location.state,
        },
      );
    } else if (
      orderType === 2 &&
      !storeCode &&
      !newStoreCode &&
      !addressId &&
      !newAddressId &&
      cacheStoreCode &&
      cacheAddressId
    ) {
      navigate(
        {
          pathname: location.pathname,
          search: new URLSearchParams({ ...query, storeCode: cacheStoreCode, addressId: cacheAddressId }).toString(),
        },
        {
          replace: true,
          state: location.state,
        },
      );
    }
  }, [cacheAddressId, cacheStoreCode, location.pathname, location.state, navigate, orderType, query]);

  const recoverStoreCodeRef = useRef(recoverStoreCode);
  recoverStoreCodeRef.current = recoverStoreCode;

  useEffect(() => {
    if (pagecode === 'search') {
      recoverStoreCodeRef.current();
    }
  }, [pagecode]);

  return { clearStoreCodoOrIdQuery };
}
