// 首页
export const HOME = '/main/home';
// 我的订单
export const ORDER = '/main/order';
export const ORDER_DETAIL = '/orderDetail';
// 我的
export const MY = '/main/my';

// 我的信息
export const USER_INFO = '/userInfo';
//条款与规则
export const PROTOCOL = '/protocol';
//设置
export const OPTIONS = '/options';
//开票提示
export const INVOICE = '/invoice';
//注销账号
export const LOGOUT = '/logout';

// 卡券
export const CARD_LIST = '/cardList';
export const AVAILABLE_CARD_LIST = '/cardAvailableList';
export const COUPONS_DETAIL = '/couponsDetail';
export const CARD_DETAIL = '/cardDetail';

export const MY_ADDRESS = '/address';
export const SELECT_PLACE = '/address/select-place';
export const SEARCH_PLACE = '/address/search-place';
export const SELECT_CITY = '/address/select-city';
export const EDIT_ADDRESS = '/address/edit';

//h5 积分记录
export const OUT_POINT = 'https://www.mcd.cn/integral.html?pointType=0&sid=6472e7f0f1c592c8f63d791172ae57d2_';

// 餐厅
export const SELECT_CANTEEN = '/canteen/select';
export const SEARCH_CANTEEN = '/canteen/search';
export const CANTEEN_DETAIL = '/canteen/detail/:id';

// 订单确认
export const ORDER_CONFIRM = '/order/confirm';

export const ORDER_PAY = '/order/pay';
