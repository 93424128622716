import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Ellipsis } from 'antd-mobile';
import { updateCommonConfig } from '@/store/commonConfigSlice';
import type { RootState } from '@/store/';
import { fetchUserInfo, fetchCommonConfig } from '@/apis/home';
import type { UserInfo } from '@/apis/home';
import { getCDNAssets, getPointUrl } from '@/utils';
import './index.less';
import { OUT_POINT } from '@/constants/path';
import { useCookies } from 'react-cookie';
import useLogin from '@/hooks/useLogin';
import { trackEvent } from '@/utils/tracker';
const aioDefaultImg: string = getCDNAssets('default.png');
interface iProps {
  greeting: string;
  nonLoginDesc: string;
  memberBackImg: string;
  onLoginSuccess?: () => void;
}

const UserInfoView: React.FC<iProps> = (props) => {
  const pointTxt = useSelector((state: RootState) => state.commonConfig.pointsText);
  const [cookie] = useCookies<string, { [key: string]: string }>(['sid']);
  const { greeting, nonLoginDesc, memberBackImg, onLoginSuccess } = props || {};
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [useInfo, setUseInfo] = useState<UserInfo>();
  const [intPoint, setIntPoint] = useState('0');
  const [smallPoint, setSmallPoint] = useState('0');

  const handleLoginSuccess = useCallback(() => {
    setIsLogin(true);
    onLoginSuccess && onLoginSuccess();
  }, [onLoginSuccess]);

  const { navLogin, btn: btnDom } = useLogin({ onSuccess: handleLoginSuccess, ignoreInit: true });

  const dispatch = useDispatch();
  const getCommonConfig = useCallback(() => {
    fetchCommonConfig()
      .then((res) => {
        console.log('commonconfig', res);
        dispatch(updateCommonConfig(res?.config));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const getUserInfo = useCallback(() => {
    fetchUserInfo()
      .then((res) => {
        res && setUseInfo(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handletoH5Page = useCallback(() => {
    const url = getPointUrl();
    if (cookie?.sid) {
      const realUrl = url + '&sid=' + cookie?.sid;
      trackEvent('iconClick', {
        belong_page: '首页',
        module_name: '会员信息',
        rank: 3,
        icon_name: '积分明细',
        url: realUrl,
      });
      window.location.href = realUrl;
    }
  }, []);
  useEffect(() => {
    setIsLogin(cookie?.sid ? true : false);
    if (isLogin) {
      getUserInfo();
      getCommonConfig();
    }
  }, [cookie, isLogin]);
  useEffect(() => {
    if (useInfo?.availablePoints) {
      if (useInfo.availablePoints.indexOf('.') >= 0) {
        const arr = useInfo.availablePoints.split('.');
        setIntPoint(arr[0]);
        const small = arr[1] === '0' ? '' : '.' + arr[1];
        setSmallPoint(small);
      } else {
        setIntPoint(useInfo.availablePoints);
        setSmallPoint('');
      }
    } else {
      setIntPoint('0');
      setSmallPoint('');
    }
  }, [useInfo]);
  return (
    <div
      className="user-info-container"
      style={{
        backgroundImage: `url(${useInfo?.memberBackImg || memberBackImg}) `,
      }}>
      {isLogin ? (
        <div className="home_user_line_view">
          <div className="home_user_customer_left_view">
            {useInfo?.rightCardImg && <img className="customer_card_image" src={useInfo?.rightCardImg} />}
            <div className="customer_user_info_view">
              <Ellipsis className="customer_main_title" content={useInfo?.fullName || '麦麦'} />
              {
                <div className="customer_subline_view">
                  <div className="customer_subTitle">
                    {/* {useInfo?.greeting || nonLoginDesc} */}
                    喜欢您来麦当劳
                    {/* <span className="arrow">{`>`}</span> */}
                    {/* <img src="{aioDefaultImg}" className="customer_right_arrow"></img> */}
                  </div>
                </div>
              }
            </div>
          </div>
          <div
            className="home_user_right_point_view"
            onClick={() => {
              handletoH5Page();
            }}>
            <div className="home_user_right_point_title_view">
              <div className="home_user_right_point_title">{pointTxt?.nameCn}</div>
              <img className="home_user_right_point_icon" src={getCDNAssets('home_index_yellow_triangle.png')} />
            </div>
            <div className="home_user_right_point_num">
              <div className="home_user_right_intPoint_num">{intPoint}</div>
              <div className="home_user_right_smallPoint_num">{smallPoint}</div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="home_user_line_view"
          onClick={() => {
            trackEvent('iconClick', {
              belong_page: '首页',
              module_name: '会员信息',
              rank: 2,
              icon_name: '请登录',
              url: '',
            });
            navLogin();
          }}>
          <div className="home_user_customer_left_view">
            <div className="customer_user_info_view">
              <Ellipsis className="customer_main_title" content={greeting} />
              <div className="customer_subline_view">
                <div className="customer_subTitle">成为麦当劳会员 享更多优惠</div>
              </div>
            </div>
          </div>
          <div className="home_user_login_button_view">
            <span className="home_user_login_button_text">请登录</span>
          </div>
        </div>
      )}
      {btnDom}
    </div>
  );
};

export default UserInfoView;
