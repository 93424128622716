import type { CSSProperties, FC } from 'react';
import classNames from 'classnames';

import { Image } from 'antd-mobile';
import { getCDNAssets } from '@/utils';

import styles from './styles/head.module.less';

export type CustomizationMultiTitleProps = {
  className?: string;
  style?: CSSProperties;
  img?: string;
};

export const CustomizationMultiTitle: FC<CustomizationMultiTitleProps> = (props) => {
  const { className, style, img } = props;

  return (
    <div className={classNames(styles.multiWrapper, className)} style={style}>
      <Image className={styles.multiImage} placeholder={null} src={img} />
      <Image className={styles.multiBack} placeholder={null} src={getCDNAssets('product_special_back.png')} />
      <div
        className={styles.multiRight}
        style={{ backgroundImage: `url(${getCDNAssets('product_special_right.png')})` }}
      />
    </div>
  );
};

export type CustomizationSingleTitleProps = {
  className?: string;
  style?: CSSProperties;
};

export const CustomizationSingleTitle: FC<CustomizationSingleTitleProps> = (props) => {
  const { className, style } = props;
  return (
    <div className={classNames(styles.singleImageWrapper, className)} style={style}>
      <Image className={styles.singleImage} placeholder={null} src={getCDNAssets('mccafe_detail_spe2.png')} />
      <Image className={styles.singleBarImg} placeholder={null} src={getCDNAssets('mccafe_detail_title_bar.png')} />
    </div>
  );
};
