import { useCallback, useEffect, useState } from 'react';
import Header from '@/components/Header';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Switch } from 'antd-mobile';
import './index.less?v=2';
import { getCDNAssets } from '@/utils';
import { fetchGetConfig, fetchSetConfig } from '@/apis/my';
import type { GetConfig } from '@/apis/my';
import { useCookies } from 'react-cookie';
import { LOGOUT } from '@/constants/path';
import useTitle from '@/hooks/useTitle';

const OptionsPage = () => {
  useTitle('设置');
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies<string, { [key: string]: string }>(['sid']);
  const [switchChecked, setSwitchChecked] = useState<boolean>(true);
  const rightArrow = getCDNAssets('right_arrow.png');
  const getConfig = useCallback(() => {
    fetchGetConfig()
      .then((res) => {
        if (res) {
          const guessFlag = res.guessFlag;
          setSwitchChecked(guessFlag === 1 ? true : false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const updateUserConfig = async () => {
    const params: GetConfig = {
      guessFlag: Number(!switchChecked),
    };
    await fetchSetConfig(params);

    setSwitchChecked(!switchChecked);
  };
  const onItemClick = () => {
    updateUserConfig()
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const doLogout = () => {
    navigate(LOGOUT);
  };
  const doQuit = () => {
    setCookie('sid', '', { path: '/' });
    navigate(-1);
  };
  useEffect(() => {
    getConfig();
  }, []);
  return (
    <div>
      {/* <Header title="设置" /> */}
      <div>
        <div className={'options-list'}>
          <div className={'options-item'} onClick={onItemClick}>
            <div className={'item-left'}>
              <div className={'item-title'}>个性化推荐</div>
              <div className={'item-tips'}>关闭后，菜单页不再进行餐品推荐</div>
            </div>
            <div className={'item-right'}>
              <Switch checked={switchChecked} />
            </div>
          </div>
          <div className={'options-item'} onClick={doLogout}>
            <div className={'item-left'}>
              <div className={'item-title'}>注销</div>
              <div className={'item-tips'}>注销后无法恢复，请谨慎操作</div>
            </div>
            <div className={'item-right'}>
              <div className={'item-title'}>注销</div>
              <img className={'icon'} src={rightArrow} />
            </div>
          </div>
        </div>
        <div className={'options-btm'}>
          <div className={'btm-quit'} onClick={doQuit}>
            退出登录
          </div>
        </div>
      </div>
    </div>
  );
};
export default OptionsPage;
