import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRequest } from '@axios-use/react';

import type { PromotionCouponProductsType } from '@/apis/product/types';
import type { GetCouponProductListQuery } from '@/apis/product';
import { getCouponProductList } from '@/apis/product';

export function useGetCouponProductList() {
  const [craeteCouponProductListReq, CouponProductListReqState] = useRequest(getCouponProductList);
  return {
    craeteCouponProductListReq,
    CouponProductListReqState,
  };
}

type LocationState = {
  coupon?: {
    couponCode?: string;
    couponId?: string;
    promotionId?: string;
  };
};

export type LocationAutoCouponOptions = {
  params?: Partial<GetCouponProductListQuery>;
  effect?: boolean;
  callback?: (item: PromotionCouponProductsType, coupon: Required<LocationState>['coupon']) => unknown;
};

export function useLocationAutoCoupon(options?: LocationAutoCouponOptions) {
  const { params, effect, callback } = options || {};
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;

  const { craeteCouponProductListReq } = useGetCouponProductList();
  const checkCoupon = useCallback(async () => {
    if (state?.coupon && state.coupon.couponCode && state.coupon.couponId && state.coupon.promotionId) {
      if (params && params.daypartCode && params.storeCode && params.orderType) {
        try {
          const [data] = await craeteCouponProductListReq({
            ...state.coupon,
            ...params,
          } as GetCouponProductListQuery).ready();

          const _coupon = { ...state.coupon };
          // clear state
          navigate(location, { replace: true, state: undefined });

          if (data && data?.productList && data?.productList.length > 0) {
            callback?.(data, _coupon);
          }
        } catch (error) {
          console.error('[useLocationAutoCoupon] fetch err', error);
          navigate(location, { replace: true, state: undefined });
        }
      }
    }
  }, [callback, craeteCouponProductListReq, location, navigate, params, state?.coupon]);

  const checkCouponRef = useRef(checkCoupon);
  useEffect(() => {
    checkCouponRef.current = checkCoupon;
  }, [checkCoupon]);

  useEffect(() => {
    if (effect) {
      void checkCouponRef.current();
    }
  }, [effect]);

  return checkCouponRef.current;
}
