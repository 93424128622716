import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

/**
 * { date, time } => Dayjs | undefined
 * @param info
 */
export const getDayByDateTime = (info?: { date?: string | null; time?: string | null }): Dayjs | undefined => {
  if (info && typeof info === 'object') {
    const { date, time } = info;
    if (date && time) {
      const _day = dayjs(`${date} ${time}`);
      if (_day.isValid()) {
        return _day;
      }
    }
  }
  return undefined;
};

export function mergeRefs<T = any>(refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
