import React from 'react';
import QRCode from 'qrcode.react';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Ellipsis } from 'antd-mobile';
import { fetchCardDetail } from '@/apis/cardlist';
import { getCDNAssets } from '@/utils';
import type { CardsItem, TagsItem } from '@/apis/cardlist';
import './index.less';
import useTitle from '@/hooks/useTitle';

const CouponsDetailPage = () => {
  useTitle('卡券详情');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') || '';
  const scene = searchParams.get('scene') || '';
  const [imgWidth, setImgWidth] = useState(0);
  let isClicked = false;
  const [coupon, setCoupon] = useState<CardsItem>();
  const barcoderef = useRef<SVGSVGElement>(null);

  let times = '';
  if (coupon?.tradeStartDate) {
    times += coupon.tradeStartDate.replace(/-/g, '.');
  }
  if (coupon?.tradeEndDate) {
    times += '-' + coupon?.tradeEndDate.replace(/-/g, '.');
  }
  if (coupon?.weekdays && coupon?.weekdays.length > 0) {
    times += '\n';
    coupon.weekdays.forEach((item, index) => {
      if (item == 1) {
        times += '周日';
      } else if (item == 2) {
        times += '周一';
      } else if (item == 3) {
        times += '周二';
      } else if (item == 4) {
        times += '周三';
      } else if (item == 5) {
        times += '周四';
      } else if (item == 6) {
        times += '周五';
      } else if (item == 7) {
        times += '周六';
      }
      if (index < coupon.weekdays.length - 1) {
        times += '、';
      }
    });
  }
  if (coupon?.tradeTimePeriods) {
    times += '\n';
    coupon.tradeTimePeriods.forEach((item) => {
      const startTime = item.startTime.split(':')[0] + ':' + item.startTime.split(':')[1];
      const endTime = item.endTime.split(':')[0] + ':' + item.endTime.split(':')[1];
      times = times + ' ' + startTime + '-' + endTime;
    });
  }
  const countText: string[] = [];
  if (coupon?.countText && coupon.countTextBinds) {
    let str = coupon.countText;
    let hasIndex = true;
    coupon.countTextBinds.forEach((_, index) => {
      if (!hasIndex) {
        return;
      }
      const tmp = str.split(`{${index + 1}}`);
      countText.push(tmp[0]);
      if (tmp.length > 1) {
        str = tmp[1];
      } else {
        hasIndex = false;
      }
    });
  }
  const handleButtonOnClick = () => {
    if (isClicked || coupon?.enable == 0) {
      return;
    }
    isClicked = true;
    setTimeout(() => {
      isClicked = false;
      //TODU 跳转去点餐详情页面
      navigate(`/product?orderType=${coupon?.orderType || ''}`, {
        state: {
          coupon: {
            couponCode: code,
            couponId: searchParams.get('id') || '',
            promotionId: coupon?.promotionId,
          },
        },
      });
    }, 1000);
  };

  const getCardDetail = useCallback(() => {
    fetchCardDetail(searchParams.get('code') || '', searchParams.get('id') || '')
      .then((res) => {
        if (res) {
          console.log('fetchCardDetail', res);
          setCoupon(res.coupon);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [searchParams]);
  useEffect(() => {
    getCardDetail();
  }, []);
  return (
    <div>
      <div style={{ height: '100vh' }}>
        <div className="detail-sv" onScroll={(e) => {}}>
          <div
            className="detail-bg"
            style={{ backgroundImage: 'url(' + getCDNAssets('user_coupon_detail_bg.png') + ')' }}>
            <div className="detail-bg-content">
              <div className="detail-bg-top">
                {coupon?.tags && (
                  <div className="detail-v-label">
                    {coupon.tags &&
                      coupon.tags.map((item: TagsItem) => {
                        if (item.type == 3) {
                          return (
                            !!item.label && (
                              <div
                                className="detail-content-v"
                                style={{ backgroundColor: item.color, borderColor: item.color }}>
                                <span className="detail-content-label" style={{ color: '#fff' }}>
                                  {item.label}
                                </span>
                              </div>
                            )
                          );
                        } else if (item.type == 2) {
                          return (
                            !!item.label && (
                              <div className="detail-content-v" style={{ borderColor: item.color }}>
                                <span className="detail-content-label" style={{ color: item.color }}>
                                  {item.label}
                                </span>
                              </div>
                            )
                          );
                        } else if (item.type == 1) {
                          return (
                            <img src={item.image} style={{ width: imgWidth.toString() + 'px' }} className="tag-img" />
                          );
                        }
                      })}
                  </div>
                )}
                <Ellipsis className="detail-bg-title" rows={2} content={coupon?.title || ''} />
                {/* <span className="detail-bg-title">{coupon?.title}</span> */}
                {!!coupon?.subtitle && <span className="detail-bg-subtitle">{coupon.subtitle}</span>}
                {!!coupon?.limitChannelsText && <span className="detail-bg-subtitle">{coupon?.limitChannelsText}</span>}
              </div>
              <div className="detail-bg-bottom">
                {countText.length > 0 && (
                  <div className="detail-bg-times">
                    {countText.map((item, index) => (
                      <div className="detail-bg-count" key={index}>
                        <span className="detail-title">{item}</span>
                        <span className="detail-number">{coupon?.countTextBinds[index]}</span>
                      </div>
                    ))}
                  </div>
                )}
                {!!times && <span className="detail-bg-valid-time">{times}</span>}
              </div>
            </div>
            <img className="detail-bg-img" src={coupon?.image} />
          </div>
          <div className="detail-content">
            {coupon?.showQr === 1 && (
              <QRCode
                value={code}
                fgColor="#000000"
                style={{ height: '160Px', width: '160Px', marginTop: '54Px', marginBottom: '10%' }}
              />
            )}
            {coupon?.showQr == 1 && <span style={{ fontSize: '14Px', color: '#222222' }}>{code}</span>}
            {coupon?.showQr == 1 && (
              <span style={{ marginTop: '5Px', color: '#666666', fontSize: '14Px' }}>请展示二维码以使用优惠券</span>
            )}
            <span className="detail-title">使用说明</span>
            <span className="detail-content-description">{coupon?.description}</span>
            <div className="detail-empty" />
          </div>
        </div>
        {coupon?.rightCardType != 3 && (
          <>
            {(coupon?.orderType == 1 || coupon?.orderType == 2) && <div className="product-coupon-divider" />}
            <div className="product-coupon-order">
              <div
                className="product-coupon-phone-view"
                style={coupon && coupon.enable == 1 ? { backgroundColor: '#FFBC0D' } : { backgroundColor: '#EEEEEE' }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleButtonOnClick();
                }}>
                {(coupon?.enable == 1 || (coupon?.enable == 0 && !coupon.disableBtnText)) &&
                  (coupon.orderType == 1 ? (
                    <span className="product-coupon-phone-text">手机自助点餐</span>
                  ) : (
                    <span className="product-coupon-phone-text">立即使用</span>
                  ))}
                {coupon?.enable == 0 && !!coupon.disableBtnText && (
                  <span className="product-coupon-phone-text">{coupon.disableBtnText}</span>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default CouponsDetailPage;
