import React, { useEffect } from 'react';
import { useResource } from '@axios-use/react';
import { useCookies } from 'react-cookie';
import { UPButton } from 'react-cup-ui';

import './styles.less';

export const MobileLogin = () => {
  const [, setCookie] = useCookies(['sid']);

  upsdk.setNavigationBarTitle({ title: 'M站title' });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const gps = upsdk.getLocationGps({
    success: (s) => {
      console.log('s', s);
    },
    fail: (e) => {
      console.log('e', e);
    },
  });
  console.log(gps);
  const [{ data }] = useResource(
    () => ({
      url: '/bff/member/login/mobile',
      params: {
        tel: '4ce3fc0777517a2f48a6243fa8ce8a01',
        code: '85599780bdfe907b71dec1390bd5fbbe',
      },
      method: 'POST',
    }),
    [],
  );

  useEffect(() => {
    if (data != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { sid } = data as any;
      setCookie('sid', sid, { path: '/' });
    }
  }, [data, setCookie]);

  return (
    <div style={{ maxWidth: '320px', padding: '20px' }}>
      <UPButton
        scope={'scope.mobile'}
        timeout={3000}
        className="custom-btn"
        onClick={(event, err, result) => {
          console.log(event, err, result);
        }}
        style={{ width: '1rem', height: '0.64rem', backgroundColor: 'red' }}>
        授权Button
      </UPButton>

      <pre>{JSON.stringify(data, null, 2)}</pre>
      <pre>{gps}</pre>
    </div>
  );
};
