import { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '@/store/';
import { updateLocationAndCityInfo } from '@/store/addressSlice';
import type { LocationInfo } from '@/utils';
import { getLocationInfo } from '@/utils';
import { fetchCityInfo } from '@/apis/address';
import { fetchNearByStores } from '@/apis/canteen';

// const defaultCityInfo = {
//   code: '310100',
//   hasStores: 1,
//   isHotCity: 1,
//   latitude: 31.230525,
//   longitude: 121.473667,
//   name: '上海市',
// };

const useLocationInfo = () => {
  const locationAndCityInfo = useSelector((state: RootState) => state.address.locationAndCityInfo);
  const dispatch = useDispatch();

  const locationAndCityInfoRef = useRef(locationAndCityInfo);
  locationAndCityInfoRef.current = locationAndCityInfo;

  useEffect(() => {
    if (
      (!locationAndCityInfoRef.current || !locationAndCityInfoRef.current?.hadFetch) &&
      !locationAndCityInfoRef.current?.loading
    ) {
      getLocationAndCityInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleErr = useCallback(() => {
    dispatch(
      updateLocationAndCityInfo({
        ...locationAndCityInfo,
        hadFetch: true,
        loading: false,
      }),
    );
  }, [dispatch, locationAndCityInfo]);

  const getCityByLocation = useCallback(
    (p: LocationInfo) => {
      fetchCityInfo({ ...p })
        .then((data) => {
          const { city } = data || {};
          if (!city) return;
          const { code, name, latitude, longitude } = city || {};
          fetchNearByStores({ latitude: p?.latitude, longitude: p?.longitude, distance: 10000, showType: 2 })
            .then((res) => {
              const { stores } = res || {};
              dispatch(
                updateLocationAndCityInfo({
                  hadFetch: true,
                  loading: false,
                  latitude: p.latitude,
                  longitude: p.longitude,
                  city: {
                    code,
                    name,
                    latitude,
                    longitude,
                  },
                  nearestStoreInfo: stores ? stores[0] : undefined,
                }),
              );
            })
            .catch((err: Error) => {
              handleErr();
            });
        })
        .catch((e) => {
          handleErr();
        });
    },
    [dispatch, handleErr],
  );

  const getLocationAndCityInfo = useCallback(() => {
    dispatch(
      updateLocationAndCityInfo({
        ...locationAndCityInfo,
        loading: true,
      }),
    );
    getLocationInfo()
      .then((res) => {
        getCityByLocation(res);
      })
      .catch((e) => {
        handleErr();
      });
  }, [getCityByLocation, handleErr, dispatch, locationAndCityInfo]);

  return { locationAndCityInfo, refetch: getLocationAndCityInfo };
};

export default useLocationInfo;
