import { FC, useEffect } from 'react';
import React, { useState, useMemo } from 'react';
import cls from 'classnames';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { TabBar, Popup } from 'antd-mobile';
import HomeNor from '@/assets/tab_home_nor.png';
import HomeSel from '@/assets/tab_home_sel.png';
import MyNor from '@/assets/tab_user_nor.png';
import MySel from '@/assets/tab_user_sel.png';
import OrderNor from '@/assets/tab_order_nor.png';
import OrderSel from '@/assets/tab_order_sel.png';
import { HOME, ORDER, MY } from '@/constants/path';
import useLogin from '@/hooks/useLogin';
import './index.less';
import { trackEvent } from '@/utils/tracker';

const tabsConfig = [
  {
    id: 'home',
    text: '首页',
    key: '/main/home',
    icon: HomeNor,
    selectedIcon: HomeSel,
    path: HOME,
  },
  {
    id: 'orders',
    text: '我的订单',
    key: '/main/order',
    icon: OrderNor,
    selectedIcon: OrderSel,
    path: ORDER,
  },
  {
    id: 'user',
    text: '我的',
    key: '/main/my',
    icon: MyNor,
    selectedIcon: MySel,
    path: MY,
  },
];

const Main = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const setRouteActive = (value: string) => {
    const newTab = tabsConfig.find(({ key }) => key === value);
    if (newTab) {
      trackEvent('bottomNagivationClick', {
        belong_page: newTab.text,
        button_id: 'home',
        rank: `${tabsConfig.indexOf(newTab) + 1}`,
        button_name: newTab.text,
      });
    }

    navigate(value);
  };

  // const { btn: btnDom } = useLogin();

  return (
    <div className="common-footer-container">
      <div className="outlet-wrapper">
        <Outlet />
      </div>

      <div className="tabs-wrapper">
        <TabBar activeKey={pathname} onChange={(value) => setRouteActive(value)}>
          {tabsConfig.map(({ text, key, icon, selectedIcon }) => (
            <TabBar.Item
              key={key}
              icon={(active) => <img src={active ? selectedIcon : icon} />}
              title={(active) => (
                <span
                  className={cls('title', {
                    active,
                  })}>
                  {text}
                </span>
              )}
            />
          ))}
        </TabBar>
      </div>
      {/* {btnDom} */}
    </div>
  );
};

export default Main;
