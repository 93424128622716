import { useCallback, useRef, useMemo, useEffect } from 'react';
import { useUnmount } from 'ahooks';
import { Modal } from 'antd-mobile';
import dayjs from 'dayjs';

import type { StoreDaypartItem } from '@/apis/product/types';

export type StoreDaypartTimerOptions = {
  dayparts?: StoreDaypartItem[];
  curDaypart?: number | null;
  endCallback?: () => unknown;
};

export function useStoreDaypartTimer(options?: StoreDaypartTimerOptions) {
  const { dayparts, curDaypart, endCallback } = options || {};

  const timerRef = useRef<ReturnType<typeof setInterval>>();
  const countDownNumRef = useRef(-1);

  const curDayParyItem = useMemo(
    () => (curDaypart && dayparts?.find((d) => d.daypartCode === curDaypart)) || undefined,
    [curDaypart, dayparts],
  );

  const storeDaypartEnd = useCallback(() => {
    if (curDayParyItem && curDayParyItem.daypartName) {
      void Modal.alert({
        content: `${curDayParyItem.daypartName}已结束，无法继续点餐`,
        confirmText: '确定',
        onConfirm: () => {
          endCallback?.();
        },
      });
    }
  }, [curDayParyItem, endCallback]);

  const run = useCallback(
    (second = 1) => {
      clearInterval(timerRef.current);

      timerRef.current = setInterval(() => {
        countDownNumRef.current -= second;

        if (countDownNumRef.current === 0) {
          storeDaypartEnd();
        }

        if (countDownNumRef.current < 0) {
          clearInterval(timerRef.current);
        }
      }, second * 1000);
    },
    [storeDaypartEnd],
  );

  const cancel = useCallback(() => {
    const _timer = timerRef.current;
    if (_timer) {
      clearInterval(_timer);
    }
    timerRef.current = undefined;
  }, []);

  const runRef = useRef(run);
  runRef.current = run;

  useEffect(() => {
    if (curDayParyItem) {
      const _now = dayjs();
      const _ymd = _now.format('YYYY-MM-DD');
      const _start = dayjs(`${_ymd} ${curDayParyItem.startTime}`);
      const _end = dayjs(`${_ymd} ${curDayParyItem.endTime}`);

      if (_end.isValid() && _start.isValid() && _end.isAfter(_start)) {
        if (_end.isAfter(_now)) {
          countDownNumRef.current = _end.unix() - _now.unix();
        } else {
          countDownNumRef.current = 1;
        }
        runRef.current();
      }
    }
  }, [curDayParyItem]);

  useUnmount(cancel);
}
