export const getHeaderBg = (mpOrderStatusCode: string, orderType?: string) => {
  if (!mpOrderStatusCode) {
    return '';
  }
  switch (mpOrderStatusCode) {
    case '10':
      return 'order_detail_bg_unpay.png';
    case '20':
    case '30':
      if (orderType == '1') {
        return 'order_detail_bg_shop_prepare.png';
      } else {
        return 'order_detail_bg_prepare.png';
      }
    case '31':
      return 'order_detail_bg_prepare.png';
    case '32':
      return 'order_detail_bg_delivery.png';
    case '33':
      return 'order_detail_bg_shop_prepare.png';
    case '60':
      return 'order_detail_bg_cancel.png';
    case '40':
    default:
      return 'order_detail_bg_complete.png';
  }
};
