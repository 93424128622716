import Header from '@/components/Header';
import React from 'react';
import useTitle from '@/hooks/useTitle';

import './index.less';

const ProtocolPage = () => {
  useTitle('发票管理');
  return (
    <div>
      {/* <Header title="发票管理" /> */}
      <div className="invoice-page">
        <h4>如需开票请到麦当劳APP中开票</h4>
      </div>
    </div>
  );
};
export default ProtocolPage;
