import React, { useCallback, useMemo } from 'react';
import { getCDNAssets } from '@/utils';
import type { OrderProduct_, CartItemIcon_Icon } from '@/apis/order';

interface IProps {
  storeName: string;
  list: OrderProduct_[];
  totalDiscountAmount: string;
  realPayAmount: string;
  realTotalAmount: string;
}

const ProductModule: React.FC<IProps> = (props) => {
  const { storeName, list, totalDiscountAmount, realPayAmount, realTotalAmount } = props;

  const renderPmtIcons = useCallback((pmtIcons?: CartItemIcon_Icon[]) => {
    const text = (pmtIcons || [])[0]?.text;
    if (!text) return null;
    return (
      <div className="order_detail_price-tip-view">
        <img className="order_detail_price-tip-image-left" src={getCDNAssets('cart_tip_left.png')} />
        <div
          className="order_detail_price-tip-image-middle"
          style={{
            backgroundImage: 'url(' + getCDNAssets('cart_tip_middle.png') + ')',
            backgroundSize: '100% 100%',
          }}>
          <span className="order_detail_price-tip-text">{text}</span>
        </div>
        <img className="order_detail_price-tip-image-right" src={getCDNAssets('cart_tip_right.png')} />
      </div>
    );
  }, []);

  return (
    <div className="product-module">
      <div className="shop-header">
        <div className="shop-header-content">
          <img className="shop-header-img" src={getCDNAssets('order_icon_shop.png')} />
          <span className="shop-header-text">{storeName}</span>
        </div>
      </div>
      <div className="product-index">
        {(list || []).map((product, index) => {
          const isLast = index === list.length - 1;
          const { uniqueKey, productImage, productName, comboItemList, quantity, subtotal, pmtIcons } = product || {};
          return (
            <div className="product-item" style={{ marginBottom: isLast ? '0' : '20Px' }} key={uniqueKey}>
              <img className="product-item-image" src={productImage} />
              <div className="product-item-content">
                <span className="product-item-title">{productName}</span>
                {(comboItemList || []).map((item, i) => {
                  return (
                    <span className="product-item-combo" key={i}>
                      {item.quantity} x {item.name}
                    </span>
                  );
                })}
                <div className="product-item-bottom">
                  <span className="product-item-quantity">{quantity}份</span>
                  <div className="product-item-price-view">
                    <div className="product-item-real-price-view">
                      <span className="product-item-price-unit">¥</span>
                      <span className="product-item-price">{subtotal}</span>
                    </div>
                    {renderPmtIcons(pmtIcons)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="bill-index">
        <div className="divider" style={{ marginTop: '20Px' }}>
          <div className="semi-circle-left" />
          <div
            className="dash-line"
            style={{
              width: '100%',
              marginLeft: '20Px',
              marginRight: '20Px',
            }}
          />
          <div className="semi-circle-right" />
        </div>
        <div className="bill-view">
          {totalDiscountAmount != '0' && (
            <div className="bill-discount-view">
              <span className="bill-discount-title">已优惠</span>
              <span className="bill-discount-unit">¥</span>
              <span className="bill-discount">{totalDiscountAmount}</span>
            </div>
          )}
          <div className="bill-discount-view" style={{ marginLeft: '10Px' }}>
            <span className="bill-discount-title">{!realPayAmount ? '合计' : '实付'}</span>
            <span className="bill-discount-unit" style={{ color: '#222222' }}>
              ¥
            </span>
            <span className="bill-discount" style={{ fontSize: '20Px', color: '#222222' }}>
              {!realPayAmount ? realTotalAmount : realPayAmount}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModule;
