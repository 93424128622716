export const back_img_url = 'https://img.mcd.cn/mini/main/images/custom_nav_back.png';
export const location_icon_url = 'https://img.mcd.cn/mini/main/images/address_list_item_location_icon.png';
export const edit_icon_url = 'https://img.mcd.cn/mini/main/images/address_list_item_edit_icon.png';
export const delete_icon_url = 'https://img.mcd.cn/mini/main/images/address_list_item_delete_icon.png';

export const star_normal = 'https://img.mcd.cn/mini/main/images/store_list_fav_normal.png';
export const star_selected = 'https://img.mcd.cn/mini/main/images/store_list_fav_selected.png';

export const order_shop_icon = 'https://img.mcd.cn/mini/main/images/uc_order_shop_icon.png';
export const check_icon = 'https://img.mcd.cn/mini/main/images/check.png';
// 环保
export const ep_icon = 'https://img.mcd.cn/mini/main/images/ep.png';

export const right_arrow_icon = 'https://img.mcd.cn/mini/main/images/right_arrow.png';

export const daypartMap = {
  '1': '早餐',
  '2': '正餐',
  '4': '下午茶',
  '5': '夜市',
  '6': '宵夜',
  '8': '午餐',
};
