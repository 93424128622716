import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.less';

import type { OrderInfo } from '@/apis/home';
import { getCDNAssets } from '@/utils';

interface IProps {
  data?: OrderInfo;
}

const OrderDetailInfo = (props: IProps) => {
  const navigate = useNavigate();

  const { data } = props;
  const { url, showContent, yellowTip, orderStatus, opeInfo } = data || {};
  // const { url, showContent, yellowTip, orderStatus, opeInfo } = {
  //   url: 'http://baidu.com',
  //   showContent: '19:35',
  //   yellowTip: '预计送达时间',
  //   orderStatus: '餐品配送中',
  //   opeInfo: '联系骑手',
  // };

  const jumpToPage = useCallback(() => {
    url && navigate(url);
  }, [url, navigate]);

  // if (!data) return null;

  return (
    <div className="home_order_detail_info_bg" onClick={jumpToPage}>
      <div className="home_order_detail_info_view">
        <div className="home_order_detail_left_view">
          {showContent && (
            <div className="home_order_detail_time_bg">
              <div className="home_order_detail_time_view">{showContent}</div>
              <div className="home_order_detail_time_yellow_line" />
            </div>
          )}
          {yellowTip && <div className="home_order_detail_tag">{yellowTip}</div>}
        </div>

        <div className="home_order_detail_right_view">
          <div className="home_order_detail_right_desc_bg">
            {orderStatus && <div className="home_order_detail_right_desc_title">{orderStatus}</div>}
            {opeInfo && <div className="home_order_detail_right_desc_detail">{opeInfo}</div>}
          </div>
          <img className="home_order_detail_right_arrow" src={getCDNAssets('home_order_arrow.png')} />
        </div>
      </div>
    </div>
  );
};

export default OrderDetailInfo;
