import React from 'react';
import './index.less';
interface IProps {
  text: string;
}
const Coupon = (props: IProps) => {
  const { text } = props;
  return (
    <div className="coupon-container">
      <span className="symbol">券</span>
      <span className="coupon-name-wrapper">
        <span>{text}</span>
      </span>
      <img src="https://img.mcd.cn/mini/main/images/coupon_right.png" className="after-bg"></img>
    </div>
  );
};

export default Coupon;
