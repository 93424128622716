import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { refreshSession, loginUnionPay } from '@/apis/tool';
import { UPButton } from 'react-cup-ui';

interface IProps {
  ignoreInit?: boolean;
  onSuccess?: () => void;
}

const useLogin = (props?: IProps) => {
  const { ignoreInit, onSuccess } = props || {};
  const [cookie, setCookie] = useCookies<string, { [key: string]: string }>(['sid']);
  const { sid } = cookie || {};

  const navLogin = useCallback(() => {
    const evt = document.createEvent('MouseEvents');
    evt.initEvent('click', true, true);
    const btn = document.querySelectorAll('.custom-login-btn'); //获取触发事件源
    if (btn.length > 0) {
      btn[btn.length - 1].dispatchEvent(evt); //触发配置事件
    }
  }, []);

  const refresh = useCallback(() => {
    void refreshSession();
  }, []);

  useEffect(() => {
    if (!ignoreInit) {
      refresh();
      navLogin();
    }
  }, []);

  const loginUnionPayByCode = useCallback(
    (code: string) => {
      loginUnionPay({ code })
        .then((res) => {
          res?.sid && setCookie('sid', res.sid, { path: '/' });
          onSuccess && onSuccess();
        })
        .catch(() => {});
    },
    [onSuccess, setCookie],
  );

  const btn = (
    <div style={{ display: 'block', opacity: 0 }}>
      <UPButton
        scope={'scope.mobile'}
        timeout={3000}
        className="custom-login-btn"
        onClick={(event, err, result) => {
          console.log('======', event, err, result);
          result?.code && loginUnionPayByCode(result?.code);
        }}
        style={{ width: '100%' }}>
        授权Button
      </UPButton>
    </div>
  );

  return { sid, btn, navLogin };
};

export default useLogin;
