import React, { useEffect, useCallback, useState, useMemo } from 'react';
import cls from 'classnames';
import { Popup, Button, PickerView } from 'antd-mobile';

import { fetchStoreDetailInfo } from '@/apis/canteen';
import type { StoreDetail } from '@/apis/canteen';
import { order_shop_icon, check_icon } from '@/constants';
import type { ConfirmationDeliveryInfo_, ConfirmationDayPart_ } from '@/apis/orderConfirm.type';
import type { Gende } from '@/utils';
// import arrowUrl from '@/assets/arrow2.png';
import { right_arrow_icon } from '@/constants';
import { getCDNAssets, getGendertitle } from '@/utils';
import './index.less';
interface IProps {
  deliveryInfo?: ConfirmationDeliveryInfo_;
  dayPart?: ConfirmationDayPart_;
  onAddressChange?: () => void;
  onDeliveryTimeCodeChange?: (code: string) => void;
  addressId?: string;
}

const StoreInfo: React.FC<IProps> = (props) => {
  const { deliveryInfo, dayPart, onAddressChange, onDeliveryTimeCodeChange, addressId } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [selectDeliveryTime, setSelectDeliveryTime] = useState<string[]>([]);

  const switchVisible = useCallback(() => {
    setVisible(true);
  }, []);

  const {
    deliveryAddress,
    deliveryContactName,
    deliveryContactsSex,
    deliveryContactPhone,
    expectDeliveryTitle,
    expectDeliveryTimeValue,
    expectDeliveryTimeOptions,
    expectDeliveryTimeOptionsDialogTitle,
    nowDeliveryTitle,
    nowDeliveryExpectTime,
  } = deliveryInfo || {};

  const { dayPartName } = dayPart || {};

  useEffect(() => {
    const selectedOption = (expectDeliveryTimeOptions || []).find(
      ({ deliveryDateCode, deliveryDateText, deliveryTime }) => {
        return (deliveryTime || []).find(({ selected }) => selected);
      },
    );
    if (!selectedOption) return;
    const { deliveryDateText, deliveryDateCode, deliveryTime } = selectedOption;
    const selectedDeliveryTime = (deliveryTime || []).find(({ selected }) => selected);
    if (deliveryDateCode && dayPartName && selectedDeliveryTime?.code) {
      setSelectDeliveryTime([deliveryDateCode, dayPartName, selectedDeliveryTime.code]);
    }
  }, [expectDeliveryTimeOptions, dayPartName]);

  const columns = useMemo(() => {
    const day = (expectDeliveryTimeOptions || []).map(({ deliveryDateText, deliveryDateCode }) => ({
      label: deliveryDateText,
      value: deliveryDateCode,
    }));
    const time = (
      (expectDeliveryTimeOptions || []).find(({ deliveryDateCode }) => deliveryDateCode === selectDeliveryTime[0])
        ?.deliveryTime || []
    ).map(({ code, text }) => ({ label: text, value: code }));
    return [[...day], [dayPartName], [...time]];
  }, [expectDeliveryTimeOptions, dayPartName, selectDeliveryTime]);

  const handleDeliverytimeChange = useCallback(() => {
    const [dayCode, _t, timeCode] = selectDeliveryTime;
    onDeliveryTimeCodeChange && onDeliveryTimeCodeChange(`${dayCode} ${timeCode}`);
  }, [selectDeliveryTime, onDeliveryTimeCodeChange]);

  const [deliveryTitleStr, deliveryTimeStr] = useMemo(() => {
    let titleStr = expectDeliveryTitle || '';
    let timeStr = expectDeliveryTimeOptionsDialogTitle || '';
    (expectDeliveryTimeOptions || []).forEach((e, i) => {
      if (e.deliveryTime && e.deliveryTime.length > 0) {
        e.deliveryTime.forEach((element, index) => {
          if (element.selected) {
            titleStr = expectDeliveryTitle || '';
            timeStr = e.deliveryDateCode! + ' ' + element.code!;
            if (element.text === nowDeliveryTitle) {
              titleStr = nowDeliveryTitle || '';
              timeStr = nowDeliveryExpectTime || '';
            }
          }
        });
      }
    });
    return [titleStr, timeStr];
  }, [
    expectDeliveryTitle,
    expectDeliveryTimeOptionsDialogTitle,
    expectDeliveryTimeOptions,
    nowDeliveryTitle,
    nowDeliveryExpectTime,
  ]);

  if (!deliveryInfo) return null;

  return (
    <div className="address-info-container">
      <div className="base-info">
        {addressId ? (
          <div className="left">
            <div className="name">
              {deliveryContactName}
              {deliveryContactsSex ? `（${getGendertitle(deliveryContactsSex as Gende)}）` : ''} {deliveryContactPhone}
            </div>
            <div className="address">{deliveryAddress}</div>
          </div>
        ) : (
          <div
            className="left"
            onClick={() => {
              onAddressChange && onAddressChange();
            }}>
            <span className="shop_name_text">请选择配送地址</span>
            <div className="no_address_bg">
              <span className="no_address_text">请确认配送地址及联系方式</span>
            </div>
          </div>
        )}
        <div
          className="right"
          onClick={() => {
            onAddressChange && onAddressChange();
          }}>
          <img src={order_shop_icon} />
          <div className="btn-wrapper">
            <span className="btn">更换地址</span>
          </div>
        </div>
      </div>
      <div className="delivery-time-container">
        <div className="delivery-time-content" onClick={switchVisible}>
          <span className="label">{deliveryTitleStr}</span>
          <span className="time">
            <span>{deliveryTimeStr}</span>
            <img src={right_arrow_icon} />
          </span>
        </div>
        <Popup
          visible={visible}
          onMaskClick={() => {
            setVisible(false);
          }}
          bodyStyle={{ height: 'max-content' }}>
          <div className="time-popup-content">
            <div className="title">就餐时间</div>
            <div className="time-wrapper">
              <PickerView
                // @ts-ignore
                columns={columns}
                // defaultValue={defaultSelect!}
                value={selectDeliveryTime}
                onChange={(val, extend) => {
                  // @ts-ignore
                  setSelectDeliveryTime(val);
                  console.log('onChange', val, extend.items);
                }}
              />
            </div>
            <div className="btn-wrapper">
              <Button
                color="primary"
                shape="rounded"
                size="middle"
                onClick={() => {
                  setVisible(false);
                  handleDeliverytimeChange();
                }}>
                确定
              </Button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default StoreInfo;
