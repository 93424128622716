import type { WritableDraft } from 'immer/dist/internal';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PutUpdateCartBody, PutClearCartsBody, PutCartResponseDataType } from '@/apis/product/index';
import { getPutUpdateCartReqConfig, getPutClearCartsReqConfig, getCartsInfoReqConfig } from '@/apis/product';
import type { CartDetailResponse, CartInfoParams } from '@/apis/cart';
import type { CommonResponse } from '@/apis/request';
import request from '@/apis/request';

type CartInfoState = {
  /** orderType = 1 到店取餐 */
  pickup: {
    value?: CartDetailResponse | null;
    productQuantityGroup?: [string, number][];
    requestQuery?: CartInfoParams;
  };

  /** orderType = 2 外送 */
  delivery: {
    value?: CartDetailResponse | null;
    productQuantityGroup?: [string, number][];
    requestQuery?: CartInfoParams;
  };
  status: 'idle' | 'loading' | 'failed';
};

const initialState: CartInfoState = {
  pickup: {
    value: null,
  },
  delivery: {
    value: null,
  },
  status: 'idle',
};

const upCartDataFunc = (
  state: WritableDraft<CartInfoState>,
  action: PayloadAction<CartDetailResponse | null | undefined>,
) => {
  if (Number(action.payload?.orderType) === 1) {
    state.pickup.value = action.payload;
    state.pickup.productQuantityGroup = getProductQuantityGroup(action.payload?.products);
  } else if (Number(action.payload?.orderType) === 2) {
    state.delivery.value = action.payload;
    state.delivery.productQuantityGroup = getProductQuantityGroup(action.payload?.products);
  }
};

const getProductQuantityGroup = (products?: CartDetailResponse['products']): [string, number][] => {
  if (products && Array.isArray(products)) {
    // 使用券的商品，不统计数量
    const arr = products.filter((p) => p.code && !p.coupon);
    const _map = new Map<string, number>();

    arr.forEach((p) => {
      const _code = p.code;
      const _quantity = p.quantity || 0;
      if (_code) {
        if (_map.has(_code)) {
          _map.set(_code, (_map.get(_code) || 0) + _quantity);
        } else {
          _map.set(_code, _quantity);
        }
      }
    });

    return Array.from(_map);
  }

  return [];
};

export const getServeCartAsync = createAsyncThunk('cartinfo/sync', async (params: CartInfoParams) => {
  const response = (await request(getCartsInfoReqConfig(params))) as CommonResponse<CartDetailResponse>;

  if (!response.success) {
    throw new Error(response.message);
  }

  return response.data;
});

export const updateCartAsync = createAsyncThunk('cartinfo/updatecart', async (body: PutUpdateCartBody) => {
  const response = (await request(getPutUpdateCartReqConfig(body))) as CommonResponse<{
    cartDetail: CartDetailResponse;
  }>;

  if (!response.success) {
    throw new Error(response.message);
  }

  return response.data?.cartDetail;
});

export const updateCartAsyncWithoutThrow = createAsyncThunk(
  'cartinfo/updatecartwithoutthrow',
  async (body: PutUpdateCartBody) => {
    const response = (await request(getPutUpdateCartReqConfig(body))) as CommonResponse<PutCartResponseDataType>;

    return response;
  },
);

export const clearCartAsync = createAsyncThunk('cartinfo/clearcart', async (body: PutClearCartsBody) => {
  const response = (await request(getPutClearCartsReqConfig(body))) as CommonResponse<CartDetailResponse>;

  if (!response.success) {
    throw new Error(response.message);
  }

  return response.data;
});

export const cartInfoSlice = createSlice({
  name: 'cartinfo',
  initialState,
  reducers: {
    updateCart: (state, action: PayloadAction<CartDetailResponse>) => {
      upCartDataFunc(state, action);
    },
    clearCart: (state, action: PayloadAction<{ orderType?: number }>) => {
      if (Number(action.payload?.orderType) === 1) {
        state.pickup.value = null;
      } else if (Number(action.payload?.orderType) === 2) {
        state.delivery.value = null;
      }
    },
    updateRequestQuery: (state, action: PayloadAction<CartInfoParams | undefined>) => {
      if (Number(action.payload?.orderType) === 1) {
        state.pickup.requestQuery = action.payload;
      } else if (Number(action.payload?.orderType) === 2) {
        state.delivery.requestQuery = action.payload;
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(updateCartAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCartAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        upCartDataFunc(state, action);
      })
      .addCase(updateCartAsync.rejected, (state) => {
        state.status = 'failed';
      });

    builder
      .addCase(clearCartAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clearCartAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        upCartDataFunc(state, action);
      })
      .addCase(clearCartAsync.rejected, (state) => {
        state.status = 'failed';
      });

    builder
      .addCase(getServeCartAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getServeCartAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        upCartDataFunc(state, action);
      })
      .addCase(getServeCartAsync.rejected, (state) => {
        state.status = 'failed';
      });

    builder
      .addCase(updateCartAsyncWithoutThrow.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCartAsyncWithoutThrow.fulfilled, (state, action) => {
        if (action.payload?.success) {
          state.status = 'idle';
          const _orderType = Number(action.payload.data?.cartDetail?.orderType);
          const _cartDetail = action.payload.data?.cartDetail;
          if (_orderType === 1) {
            state.pickup.value = _cartDetail;
            state.pickup.productQuantityGroup = getProductQuantityGroup(_cartDetail?.products);
          } else if (_orderType === 2) {
            state.delivery.value = _cartDetail;
            state.delivery.productQuantityGroup = getProductQuantityGroup(_cartDetail?.products);
          }
        } else {
          state.status = 'failed';
        }
      })
      .addCase(updateCartAsyncWithoutThrow.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { updateCart, clearCart, updateRequestQuery } = cartInfoSlice.actions;

export default cartInfoSlice.reducer;
