import type { CSSProperties, MouseEventHandler, ForwardRefRenderFunction } from 'react';
import { useMemo, forwardRef } from 'react';
import classNames from 'classnames';
import { Image } from 'antd-mobile';

import type { StoreItem } from '@/apis/product/types';
import { getCDNAssets } from '@/utils';
import { getDayByDateTime } from '../../utils';

import styles from './store.module.less';

const getOrderTypeText = (type?: number) => {
  switch (type) {
    case 1:
      return '到店取餐';
    case 2:
      return '麦乐送';
    default:
      return '';
  }
};

const getOrderTypeImg = (type?: number) => {
  switch (type) {
    case 1:
      return getCDNAssets('store_pickup.png');
    case 2:
      return getCDNAssets('store_menu_mds.png');
    default:
      return '';
  }
};

const getTipString = (data?: StoreItem | null) => {
  if (data) {
    const { businessStatus, onlineBusinessStatus } = data;
    const isClose = Number(businessStatus) === 0;

    if (isClose && !onlineBusinessStatus) {
      return '暂停营业';
    }
    if (isClose) {
      return '休息中';
    }
  }
  return undefined;
};

export type ProductMenuStoreInfoProps = {
  className?: string;
  style?: CSSProperties;
  data?: StoreItem | null;
  appointmentDate?: { date?: string; time?: string };
  address?: string;
  orderType?: number;
  hideDaypartDisplay?: boolean;
  showSwitchAddress?: boolean;
  onChangeAddress?: MouseEventHandler<HTMLDivElement>;
  onSwitchTime?: MouseEventHandler<HTMLDivElement>;
};

const ProductMenuStoreInfo: ForwardRefRenderFunction<HTMLDivElement, ProductMenuStoreInfoProps> = (props, ref) => {
  const {
    className,
    style,
    data,
    address,
    orderType,
    appointmentDate,
    hideDaypartDisplay,
    showSwitchAddress,
    onChangeAddress,
    onSwitchTime,
  } = props;

  const [type, typeImg] = useMemo(() => {
    return [getOrderTypeText(orderType), getOrderTypeImg(orderType)];
  }, [orderType]);

  const tipString = useMemo(() => orderType === 1 && getTipString(data), [data, orderType]);
  const daypart = useMemo(() => data?.dayparts?.find((d) => d.daypartFlag), [data?.dayparts]);
  const mainTitle = useMemo(
    () => (orderType === 2 ? address || '请选择配送地址' : data?.shortName || data?.name || '请选择餐厅'),
    [address, data?.name, data?.shortName, orderType],
  );

  const daypartTitle = useMemo(
    () => (orderType === 2 ? '预约' : tipString || daypart?.daypartName),
    [daypart?.daypartName, orderType, tipString],
  );
  const estimatedDeliveryTime = useMemo(() => {
    if (appointmentDate) {
      const _d = getDayByDateTime(appointmentDate);
      if (_d) {
        return `预计${_d.format('MM-DD HH:mm')}送达`;
      }

      return data?.estimatedDeliveryTime;
    }
  }, [appointmentDate, data?.estimatedDeliveryTime]);

  const isTakeout = !tipString && orderType === 2;

  return (
    <div ref={ref} className={classNames(styles.container, className)} style={style}>
      <div>
        <div className={styles.storeName} onClick={showSwitchAddress ? onChangeAddress : undefined}>
          <div className={styles.storeNameText}>{mainTitle}</div>
          {showSwitchAddress && mainTitle && (
            <Image className={styles.switchAddressIcon} placeholder={null} src={getCDNAssets('store_down.png')} />
          )}
        </div>
        <div className={styles.orderType}>
          <Image className={styles.orderTypeImg} placeholder={null} src={typeImg} />
          <div className={styles.orderTypeText}>{type}</div>
          {orderType === 1 && data?.distanceText && (
            <div className={styles.orderTypeDistance}>距离{data?.distanceText}</div>
          )}
          {orderType === 2 && estimatedDeliveryTime && (
            <div className={styles.orderTypeDistance}>{estimatedDeliveryTime}</div>
          )}
        </div>
      </div>

      {!hideDaypartDisplay && daypartTitle && (
        <div className={styles.daypartWrapper} onClick={isTakeout ? onSwitchTime : undefined}>
          <div className={styles.daypartTitle} data-ordertype={orderType} data-active={!tipString}>
            {daypartTitle}
          </div>
          {isTakeout && (
            <Image
              className={styles.daypartSwitchIcon}
              src="https://img.mcd.cn/mini/main/images/menu_list_time_change.png"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default forwardRef(ProductMenuStoreInfo);
