import { getCDNAssets } from '@/utils';
import { EMenusPageErrStatus, EMenusPageErrEvent } from './types';

/**
 *
 * @param status
 * @param orderType
 */
export function getPageErrInfo(status?: EMenusPageErrStatus | false, orderType?: number, subErrCode?: number) {
  const _ordertype = Number(orderType);

  if (_ordertype === 1) {
    switch (status) {
      case EMenusPageErrStatus.LOCATION_NOT_AUTHORIZED:
        return {
          trackReason: '定位服务未授权',
          text: '定位服务未授权，无法获取到您附近的餐厅',
          img: getCDNAssets('nostore.png'),
          btn: '选择餐厅',
          btnEvent: EMenusPageErrEvent.SWITCH_STORE,
        };
      case EMenusPageErrStatus.LOCATION_ERR:
        return {
          trackReason: '定位失败',
          text: '无法获取到位置信息，您可手动选择餐厅',
          img: getCDNAssets('nostore.png'),
          btn: '选择餐厅',
          btnEvent: EMenusPageErrEvent.SWITCH_STORE,
        };
      case EMenusPageErrStatus.NO_NEARBY:
        return {
          trackReason: '不在配送范围',
          text: '附近暂无营业中的餐厅，您可手动选择',
          img: getCDNAssets('nostore.png'),
          btn: '选择餐厅',
          btnEvent: EMenusPageErrEvent.SWITCH_STORE,
        };
      case EMenusPageErrStatus.SUSPEND:
        return {
          trackReason: '餐厅暂停营业',
          text: '餐厅暂停营业，建议选择附近其他餐厅',
          img: getCDNAssets('storeclosed.png'),
          btn: '选择餐厅',
          btnEvent: EMenusPageErrEvent.SWITCH_STORE,
        };
      case EMenusPageErrStatus.REST:
        return {
          trackReason: '附近餐厅已打烊',
          text: '餐厅休息中，您可看看其他餐厅',
          img: getCDNAssets('storeclosed.png'),
          btn: '选择餐厅',
          btnEvent: EMenusPageErrEvent.SWITCH_STORE,
        };
      case EMenusPageErrStatus.EMPTY_MENUS:
        return {
          trackReason: '没有获取到菜单数据',
          title: '没有获取到菜单数据',
          text: '没有获取到菜单数据，请稍后再试',
          img: getCDNAssets('menu_empty.png'),
          btn: '重新加载',
          btnEvent: EMenusPageErrEvent.RELOAD_MENUS,
        };
      default:
        return undefined;
    }
  }

  if (_ordertype === 2) {
    switch (status) {
      case EMenusPageErrStatus.LOCATION_NOT_AUTHORIZED:
        return {
          trackReason: '定位服务未授权',
          text: '定位服务未授权，无法获取到您的位置',
          img: getCDNAssets('nolocation.png'),
          btn: '选择地址',
          btnEvent: EMenusPageErrEvent.CHANGE_ADDRRSS,
        };
      case EMenusPageErrStatus.LOCATION_ERR:
        return {
          trackReason: '定位失败',
          text: '无法获取到位置信息，您可手动选择地址',
          img: getCDNAssets('nolocation.png'),
          btn: '选择地址',
          btnEvent: EMenusPageErrEvent.CHANGE_ADDRRSS,
        };
      case EMenusPageErrStatus.NO_NEARBY:
        if (subErrCode === 10102002) {
          return {
            trackReason: '不在配送范围',
            title: '附近的餐厅繁忙',
            text: '暂时无法配送，您可以选择到店取餐\n感谢您对麦当劳的喜爱！',
            img: getCDNAssets('nostore.png'),
          };
        }
        if (subErrCode === 10102003) {
          return {
            trackReason: '不在配送范围',
            title: '附近的餐厅暂不营业',
            text: '您可以试试其他配送地址\n感谢您对麦当劳的喜爱！',
            img: getCDNAssets('storeclosed.png'),
            btn: '选择地址',
            btnEvent: EMenusPageErrEvent.CHANGE_ADDRRSS,
          };
        }
        return {
          trackReason: '不在配送范围',
          text: '您可以试试其他配送地址，也可以选择到店取餐\n感谢您对麦当劳的喜爱！',
          img: getCDNAssets('nolocation.png'),
          btn: '选择地址',
          btnEvent: EMenusPageErrEvent.CHANGE_ADDRRSS,
        };
      case EMenusPageErrStatus.SUSPEND:
        return {
          trackReason: '餐厅暂停营业',
          text: '您可以试试其他配送地址，也可以选择到店取餐\n感谢您对麦当劳的喜爱！',
          img: getCDNAssets('nolocation.png'),
          btn: '选择地址',
          btnEvent: EMenusPageErrEvent.CHANGE_ADDRRSS,
        };
      case EMenusPageErrStatus.REST:
        return {
          trackReason: '附近餐厅已打烊',
          title: '附近餐厅已打烊',
          text: '您可以预约点餐，也可以试试其他配送地址\n感谢您对麦当劳的喜爱！',
          img: getCDNAssets('storeclosed.png'),
          btn: '预约点餐',
          btnEvent: EMenusPageErrEvent.APPOINTMENT,
        };
      case EMenusPageErrStatus.EMPTY_MENUS:
        return {
          trackReason: '没有获取到菜单数据',
          title: '没有获取到菜单数据',
          text: '没有获取到菜单数据，请稍后再试',
          img: getCDNAssets('menu_empty.png'),
          btn: '重新加载',
          btnEvent: EMenusPageErrEvent.RELOAD_MENUS,
        };
      default:
        return undefined;
    }
  }
  return undefined;
}
