import httpInstance from './request';
import type { CommonResponse } from './request';

const URLS = {
  RIGHTCARDS: '/bff/promotion/coupons/rightCards',
  ACCOUNT: '/bff/member/point/account',
  POINTPRODUCTS: '/bff/member/mcCafe/point/products',
  USERINFO: '/bff/member/user/portal/info',
  BALANCE: '/bff/member/mwallet/balance',
  EXCHANGEMCCAFE: '/bff/member/mcCafe/point/exchange',
  USEREDIT: '/bff/member/user/edit',
  PROTOCOL: '/bff/common/conf/termsAndRules',
  ALIPAY_VIP_INFO: '/bff/club/cards/alipay/info',
  ALIPAY_VIP_ADD: '/bff/club/cards/alipay/open',
  WEAPP_VIP_INFO: '/bff/club/cards/wechat/card',
  ADVISE: '/bff/common/cas/list',
  SUBMITADVISE: '/bff/common/cas/submit',
  GET_VERIFICATION_CODE: '/bff/passport/verifyCode/send',
  CHANGE_MOBILE: '/bff/member/user/mobile/change',
  INVOICE_HISTORY: '/bff/order/orders/invoices',
  GET_USER_CONFIG: '/bff/member/user/config/get',
  SET_USER_CONFIG: '/bff/member/user/config/set',
  CANCEL_CONFIG: '/bff/member/user/cancel/config',
  SET_CANCEL: '/bff/member/user/cancel',
};
export interface CardsItem {
  title: string;
  cardType: number;
}
export interface MemberAssetsItem {
  type: string;
  image: string;
  subTitle: string;
  title: string;
  url: string;
  subTitleNum: number;
}
export interface CouponCategoriesItem {
  categoryType: string;
  count: number;
}
export interface QuickEntranceItem {
  title: string;
  image: string;
}
interface RightCardsInfo {
  cards: CardsItem[];
  couponCategories: CouponCategoriesItem[];
  memberAssets: MemberAssetsItem[];
  quickEntrance: QuickEntranceItem[];
}
export interface ProtocolItem {
  title: string;
  url: string;
}
export interface GetConfig {
  guessFlag: number;
}
interface GetCancelConfig {
  cancelConfirm: string;
  cancelLegalLine: string;
  cancelReasons: cancelReasonItem[];
}
export interface cancelReasonItem {
  reason: string;
  reasonDesc: string;
}
export interface SetCancel {
  cancelReason: any;
  cancelReasonDesc: any;
  code: string;
  tel: string;
}
// 我的卡券 TODO headers 测试参数先写死
export const fetchRightCards = (scene = 3) =>
  httpInstance
    .get<CommonResponse<RightCardsInfo>>(URLS.RIGHTCARDS, {
      params: { scene },
      headers: { language: 'zh' },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });

// 条款与规则 TODO headers 测试参数先写死
export const fetchProtocol = (scene = 3) =>
  httpInstance
    .get<CommonResponse<ProtocolItem[]>>(URLS.PROTOCOL, {
      params: { scene },
      headers: { language: 'zh' },
    })
    .then((res) => {
      console.log('--', res);
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });

// 设置 TODO headers 测试参数先写死
export const fetchGetConfig = (scene = 3) =>
  httpInstance
    .get<CommonResponse<GetConfig>>(URLS.GET_USER_CONFIG, {
      params: { scene },
      headers: { language: 'zh' },
    })
    .then((res) => {
      console.log('--', res);
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
export const fetchSetConfig = (params: { guessFlag: number }) =>
  httpInstance
    .post<CommonResponse<GetConfig>>(URLS.SET_USER_CONFIG, {
      params,
      headers: { language: 'zh' },
    })
    .then((res) => {
      console.log('--', res);
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
export const fetchCancelConfig = (scene = 3) =>
  httpInstance
    .get<CommonResponse<GetCancelConfig>>(URLS.CANCEL_CONFIG, {
      params: { scene },
      headers: { language: 'zh' },
    })
    .then((res) => {
      console.log('--', res);
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
export const fetchSetCancel = (params: SetCancel) =>
  httpInstance
    .post<CommonResponse<GetCancelConfig>>(URLS.SET_CANCEL, {
      params,
      headers: { language: 'zh' },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      }
      throw new Error(message || '操作失败');
    });
export const fetchLogoutVerificationl = (params: { tel: string; type: number }) =>
  httpInstance
    .post<CommonResponse<GetCancelConfig>>(URLS.GET_VERIFICATION_CODE, {
      params,
      headers: { language: 'zh' },
    })
    .then((res) => {
      console.log('--', res);
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
// 权益卡列表(小图版)http://api-docs.mcd.com.cn/project/249/interface/api/34727
// const getRightCards = ({ scene = 3 }) =>
//   httpRequest.get(URLS.RIGHTCARDS, {
//     scene,
//   });

// 查询用户积分汇总信息http://api-docs.mcd.com.cn/project/253/interface/api/35372
// const getAccount = ({}) => httpRequest.get(URLS.ACCOUNT);
