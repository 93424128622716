import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './index.less';
import { Tabs, Selector } from 'antd-mobile';
import { fetchCardTabs } from '@/apis/cardlist';
import { getCDNAssets } from '@/utils';
import type {
  RightCardInfo,
  ProductCouponInfo,
  FiltersItem,
  CouponsItem,
  CardsItem,
  RightCardsItem,
} from '@/apis/cardlist';
import CommonCouponCard from './components/CommonCouponCard';
import EmptyView from './components/EmptyView';
import useTitle from '@/hooks/useTitle';
const CardListPage = () => {
  useTitle('我的卡券');
  const [searchParams] = useSearchParams();
  const [rightCardInfo, setRightCardInfo] = useState<RightCardInfo>();
  const [productCouponInfo, setProductCouponInfo] = useState<ProductCouponInfo>();
  const [filtersList, setFiltersList] = useState<FiltersItem[]>([]);
  const [couponsList, setCouponsList] = useState<CouponsItem[]>([]);
  const [oldCouponsList, setOldCouponsList] = useState<CouponsItem[]>([]);
  const [cardsList, setCardsList] = useState<RightCardsItem[]>([]);
  const [tabCardTitle, setTabCardTitle] = useState<string>('');
  const [tabCouponTitle, setTabCouponTitle] = useState<string>('');
  const [tagvalue, setTagValue] = useState<string>(searchParams.get('filterValue') || 'current_available');
  const getCardlist = useCallback(() => {
    fetchCardTabs()
      .then((res) => {
        console.log('getCardlist', res);
        if (res) {
          const { rightCardType: rightCard, productCouponType: productCoupon } = res || {};
          rightCard && setRightCardInfo(rightCard);
          productCoupon && setProductCouponInfo(productCoupon);
          rightCard && setTabCardTitle(getTitle(rightCard?.name, rightCard?.count));
          productCoupon && setTabCouponTitle(getTitle(productCoupon?.name, productCoupon?.count));
          setFiltersList(productCoupon?.filters);
          setCouponsList(productCoupon?.coupons);
          setOldCouponsList(productCoupon?.coupons);
          setCardsList(rightCard?.cards);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const getTitle = (name: string, count: string) => {
    const titleStr: string = name + '(' + count + ')';
    return titleStr;
  };
  const selectCoupon = (tv: string) => {
    const newCouponsList = oldCouponsList?.filter((con) => {
      const newCoupon = (con?.coupons || []).filter((item: CardsItem) => {
        return (item?.filterValues || []).includes(tv);
      });
      return (newCoupon || []).length > 0;
    });
    setCouponsList(newCouponsList || []);
  };
  const onGroupCouponSelect = (groupCardItem: CardsItem) => {
    const couoponIndex = couponsList.findIndex((item: CouponsItem) => {
      return item.id === groupCardItem.id;
    });
    if (couoponIndex >= 0) {
      couponsList[couoponIndex].fold = !couponsList[couoponIndex].fold;
      couponsList[couoponIndex].coupons.slice(1).map((item: CardsItem) => {
        item.isSelected = false;
      });
      const couopons = couponsList.map((item: CouponsItem) => {
        return item;
      });
      console.log('couponsList', couopons);
      setCouponsList(couopons);
    }
  };
  useEffect(() => {
    getCardlist();
  }, []);
  return (
    <div>
      <div className="cardlist-page">
        <div className="content-view">
          <div className="coupon-filter">
            <Selector
              style={{
                '--border-radius': '5px',
                '--border': 'solid #eeeeee 1px',
                '--checked-border': 'solid var(--adm-color-primary) 2px',
                '--padding': '6px 10px',
                '--color': 'transparent',
                '--checked-color': 'transparent',
                '--text-color': '#666666',
                '--checked-text-color': '#222222',
              }}
              showCheckMark={false}
              options={filtersList}
              defaultValue={[tagvalue]}
              onChange={(v) => {
                if (v.length) {
                  setTagValue(v[0]);
                  selectCoupon(v[0]);
                } else {
                  setTagValue('');
                  setCouponsList(productCouponInfo?.coupons || []);
                }
              }}
            />
          </div>

          <div className="product-coupon-view">
            {couponsList.map((coup, idx: number) => {
              const isGroupCoupon = coup.coupons.length > 1;
              return (
                <div key={idx}>
                  <CommonCouponCard
                    card={coup.coupons[0]}
                    coupList={couponsList}
                    idx={idx}
                    onGroupCouponSelect={onGroupCouponSelect}
                    isGroupCoupon={isGroupCoupon}
                    scene={3}
                    showMoreCoupon={true}
                    showInstructions={true}
                    showBtm={true}
                    showCouponCardBottom={true}
                  />
                  {coup.fold &&
                    coup.coupons.slice(1).map((subItem: CardsItem, subidx: number) => {
                      return (
                        <CommonCouponCard
                          key={subidx}
                          idx={idx}
                          coupList={couponsList}
                          scene={3}
                          card={subItem}
                          showBtm={false}
                        />
                      );
                    })}
                </div>
              );
            })}
            {(!couponsList || (couponsList && couponsList.length === 0)) && <EmptyView></EmptyView>}
          </div>
          {/* <Tabs>
            <Tabs.Tab title={tabCouponTitle} key="优惠券"></Tabs.Tab>
            <Tabs.Tab title={tabCardTitle} key="权益卡">
              <div className="product-coupon-view">
                {cardsList &&
                  cardsList.map((card, index) => {
                    return (
                      <div
                        key={index}
                        className="interest-card-bg"
                        style={{ backgroundImage: 'url(' + card?.imageUrl + ')' }}>
                        {card.cardType === 2 && !!card.leftDaysText && (
                          <div className="interest-card-content">
                            <img className="interest-card-image" src={getCDNAssets('user_card_notice.png')} />
                            <span className="interest-card-text">{card.leftDaysText}</span>
                          </div>
                        )}
                        {card.cardType === 2 && !!card.todayLeftCount && !!card.todayLeftCountText && (
                          <div className="interest-card-left">
                            <span className="interest-card-left-title">{card.todayLeftCountText}</span>
                            <div className="interest-card-left-view">
                              <span className="interest-card-left-text">{card.todayLeftCount}</span>
                              <span className="interest-card-left-text-1">/{card.todayTotalCount}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                {(!cardsList || (cardsList && cardsList.length === 0)) && <EmptyView></EmptyView>}
              </div>
            </Tabs.Tab>
          </Tabs> */}
        </div>
      </div>
    </div>
  );
};
export default CardListPage;
