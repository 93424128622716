import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchCardDetail } from '@/apis/cardlist';
import { getCDNAssets } from '@/utils';
import type { CardsItem } from '@/apis/cardlist';
import './index.less';

const CardDetailPage = () => {
  const [searchParams] = useSearchParams();
  const picWidth = window.innerWidth - 40;
  const picHeight = (picWidth * 190) / 335;
  const scrollMarginTop = picHeight - 5;

  const [couponDetail, setCouponDetail] = useState<CardsItem>();
  const cardNumSplitByTag = (cardNum: string) => {
    if (!cardNum) {
      return '';
    }
    const target: string[] = [];
    Array.from(cardNum)
      .reverse()
      .map((value, index) => {
        if (index % 4 === 0) {
          target.push(' ');
          target.push(value);
        } else {
          target.push(value);
        }
      });
    return target.reverse().join('');
  };
  const timeCard = (title: string, decs: string, iconStr: string) => {
    return (
      <div className="recharge-card-time-bg">
        <img className="recharge-card-time-icon" src={iconStr} />
        <div className="recharge-card-time-desc-bg">
          <span className="recharge-card-time-title">{title}</span>
          <span className="recharge-card-time-desc">{decs}</span>
        </div>
      </div>
    );
  };
  const timeCardDesc = () => {
    return (
      <div className="recharge-card-time-row-bg">
        {timeCard('有效期截止', couponDetail?.tradeEndDate || '', getCDNAssets('mcd_uc_recharge_time_icon1.png'))}
        <div className="recharge-card-time-row-line" />
        {timeCard('生效日期', couponDetail?.receiveTime || '', getCDNAssets('mcd_uc_recharge_gift_icon1.png'))}
      </div>
    );
  };
  const getCardDetail = useCallback(() => {
    fetchCardDetail(searchParams.get('code') || '', searchParams.get('id') || '')
      .then((res) => {
        if (res) {
          console.log('fetchCardDetail', res);
          setCouponDetail(res.coupon);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [searchParams]);
  useEffect(() => {
    getCardDetail();
  }, []);
  return (
    <div>
      <div style={{ backgroundColor: '#EEE' }}>
        <div className="recharge-detail-pic-bg" style={{ width: picWidth, height: picHeight }}>
          <img className="recharge-detail-pic" src={couponDetail?.image || ''} />
          <div className="recharge-detail-pic-content-bg">
            {couponDetail?.denomination > 0 && (
              <div className="recharge-detail-pic-content-content" style={{ marginTop: ((111 - 4) / 190) * picHeight }}>
                <span className="recharge-detail-unit">￥</span>
                <span className="recharge-detail-amount">{couponDetail?.denomination / 100 || 0}</span>
              </div>
            )}
            <div className="recharge-detail-cardId">{cardNumSplitByTag(couponDetail?.code || '')}</div>
          </div>
        </div>
        <div
          style={{ height: '100%', zIndex: 10 }}
          // showsVerticalScrollIndicator={false}
        >
          <div
            className="recharge-detail-desc-bg"
            style={{
              marginTop: scrollMarginTop,
            }}>
            {timeCardDesc()}
            <div className="recharge-detail-title">使用说明</div>
            <div className="recharge-detail-desc">
              <span className="recharge-detail-richtext">{couponDetail?.description}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardDetailPage;
