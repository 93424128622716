import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SwipeAction, Dialog, Button, Ellipsis, SearchBar } from 'antd-mobile';
import cls from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '@/store/';
import { setSelectCityTag, SelectCityTag, updatePlace, updateNewStore } from '@/store/addressSlice';
import AddressFooter from '@/components/AddressFooter';

import AddressSearchBar from '@/components/AddressSearchBar';
import StoreList from '../components/StoreList';
import type { StoreItem } from '@/apis/canteen';
import { fetchStoreTag, searchStore } from '@/apis/canteen';
import type { HotTagsItem, SearchStoreParams } from '@/apis/canteen';
import { EDIT_ADDRESS, SELECT_CITY } from '@/constants/path';
import Tags from '../components/Tags';
import useTitle from '@/hooks/useTitle';

import './index.less';

const SearchCanteen = () => {
  useTitle('搜索餐厅');
  const { storeInfo, storeExtraInfo } = useSelector((state: RootState) => ({
    storeInfo: state.address.storeInfo,
    storeExtraInfo: state.address.storeExtraInfo,
  }));

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [storeList, setStoreList] = useState<StoreItem[]>([]);
  const [isErr, setIsErr] = useState<boolean>(false);
  const [tags, setTags] = useState<HotTagsItem[]>([]);
  const [activeTagCode, setActiveTagCode] = useState<string>();
  const [selectCode, setSelectCode] = useState<string>();
  const [keyword, setKeyword] = useState<string>();

  const contentRef = useRef<HTMLDivElement>();
  const searchRef = useRef<HTMLDivElement>();

  const getHotTags = useCallback(() => {
    fetchStoreTag()
      .then((res) => {
        res && setTags(res);
      })
      .catch(() => {});
  }, []);

  const searchPlace = useCallback((p: SearchStoreParams) => {
    searchStore({ ...p })
      .then((res) => {
        setStoreList(res?.stores || []);
        setIsErr(false);
      })
      .catch((err) => {
        setIsErr(true);
      });
  }, []);

  const init = useCallback(() => {
    // searchPlace('成山');
    getHotTags();
  }, []);
  useEffect(() => {
    init();
  }, [init]);

  const getSearchPlace = useCallback(() => {
    if (storeInfo?.cityCode) {
      searchPlace({
        cityCode: storeInfo.cityCode,
        hotTagCode: activeTagCode,
        keyword,
      });
    }
  }, [storeInfo, activeTagCode, keyword, searchPlace]);

  useEffect(() => {
    getSearchPlace();
  }, [storeInfo, activeTagCode, keyword]);

  const canComplete = useMemo(() => {
    const realSelectStore = (storeList || []).find(({ code }) => code === selectCode);
    if (realSelectStore?.code && realSelectStore?.businessStatus) {
      return true;
    } else {
      setSelectCode('');
    }
  }, [selectCode, storeList]);

  const handleClick = useCallback(() => {
    const selectedStoreInfo = storeList.find(({ code }) => code === selectCode);
    // StoreItem的类型定义不一致
    // @ts-ignore
    selectedStoreInfo?.code && dispatch(updateNewStore(selectedStoreInfo.code));
    selectedStoreInfo?.code && window.localStorage.setItem('lastStoreCode', selectedStoreInfo?.code);
    if (storeExtraInfo?.enterFrom) {
      // const url: string = storeExtraInfo?.isMenu
      //   ? `${storeExtraInfo.enterFrom || ''}&newStoreCode=${selectedStoreInfo?.code || ''}`
      //   : storeExtraInfo?.enterFrom || '';
      // navigate(url, { replace: true });
      if (storeExtraInfo?.isMenu) {
        navigate(`${storeExtraInfo.enterFrom || ''}&newStoreCode=${selectedStoreInfo?.code || ''}`, { replace: true });
      } else {
        navigate(-2);
      }
    }
  }, [dispatch, selectCode, navigate, storeList, storeExtraInfo]);

  const { cityCode, cityName } = storeInfo || {};

  return (
    <div className="search-canteen-container">
      {/* <Header title="搜索餐厅" /> */}
      <div className="content" ref={contentRef as React.LegacyRef<HTMLDivElement>}>
        <AddressSearchBar
          cityInfo={{ code: cityCode, name: cityName }}
          // @ts-ignore
          ref={searchRef}
          onChangeCity={() => {
            dispatch(setSelectCityTag(SelectCityTag.canteen));
            navigate(SELECT_CITY);
          }}
          onSearch={(val) => {
            setKeyword(val);
          }}
          // onChange={(val) => {
          //   setKeyword(val);
          // }}
          searchBarPlaceholder="搜索餐厅"
        />
        <div className="tags-wrapper">
          <Tags
            tagList={tags}
            activeTagCode={activeTagCode}
            onSelect={(code) => {
              setActiveTagCode(code);
            }}
          />
        </div>

        {isErr ? (
          <div className="err-content">
            <div>未找到符合要求的餐厅，请更换关键词</div>
          </div>
        ) : (
          <div className="canteen-container">
            <StoreList
              list={storeList}
              onUpdateFav={() => {
                getSearchPlace();
              }}
              selectCode={selectCode}
              onSelect={(code: string) => {
                setSelectCode(code);
              }}
              fromPage={storeExtraInfo?.isMenu ? '点餐页' : '订单确认页'}
            />
          </div>
        )}
      </div>
      <AddressFooter text={'去点餐'} disabled={!canComplete} handleClick={handleClick} />
    </div>
  );
};

export default SearchCanteen;
