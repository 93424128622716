/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useRef, useState } from 'react';
import JsBarcode from 'jsbarcode';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dialog, Toast } from 'antd-mobile';
import './index.less';
import { getCDNAssets } from '@/utils';
import { fetchOrderDetail, fetchOrderCancle } from '@/apis/order';
import type { Order, OrderActionVo, OrderProduct_ } from '@/apis/order';
import type { OrderDetail } from '@/apis/order.type';
import { ORDER_PAY } from '@/constants/path';
import useTitle from '@/hooks/useTitle';
import { copyText } from '@/utils';
import StatusModel from './components/StatusModel';
import { trackEvent } from '@/utils/tracker';
import { trackOrderCancelEvent } from '../orderEventTracker';

let timeId: number = 0 as const;

const OrderDetailPage = () => {
  useTitle('订单详情', '0xFFEEEEEE');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showError, setShowError] = useState<boolean>(false);
  const [showMore, setShowMore] = useState(false);
  const [showNetworkError, setShowNetworkError] = useState(false);
  const [initData, setInitData] = useState<OrderDetail>();
  const isCabinet = initData && initData?.orderStatusTitleType == 2;
  const isCode = initData && initData.orderStatusTitleType == 1;
  const isMds = (initData && initData.orderType != '1') || false;
  const [listViewStatus, setListViewStatus] = useState('normal');
  const [barCodeFlag, setBarCodeFlag] = useState(false);
  const [barcode, setBarcode] = useState('');
  const barcoderef = useRef<SVGSVGElement>(null);
  const [showTopTipsView, setShowTopTipsView] = useState(false);

  const reFetch = useCallback(() => {
    setTimeout(() => {});
  }, []);

  const barCodeView = (showBarCode: boolean) => {
    return (
      <div
        style={{
          height: showBarCode ? '70Px' : '0',
          marginBottom: showBarCode ? '15Px' : '0',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <svg ref={barcoderef} style={{ width: '300Px', height: showBarCode ? '70Px' : '0' }} />
      </div>
    );
  };
  const infoItem = (
    title: string,
    info1 = '',
    info2 = '',
    info3 = '',
    isLast = false,
    isShowArrow = false,
    url = '',
    isCopyable = false,
    barCode = false,
    showBarCode = false,
  ) => {
    return (
      <div
        className="info-index"
        onClick={(e) => {
          e.stopPropagation();
          if (barCode) {
            setBarCodeFlag(!barCodeFlag);
            JsBarcode(barcoderef.current, barcode, {
              displayValue: false,
              width: 1.5, // 画布区域的宽度
              height: 50, // 画布区域的高度
            });
          }
        }}>
        <div className="info-view">
          <span className="info-title">{title}</span>
          <div className="info-info-view">
            <div className="info-info-list">
              {!!info1 && <div className="info-info">{info1}</div>}
              {!!info2 && <div className="info-info">{info2}</div>}
              {!!info3 && <div className="info-info">{info3}</div>}
            </div>
            {!!isShowArrow && <img className="info-arrow" src={getCDNAssets('order_icon_right_arrow.png')} />}
            {!!isCopyable && (
              <img
                className="info-copy"
                onClick={() => {
                  info1 &&
                    copyText(
                      info1,
                      () => {
                        Toast.show('复制成功！');
                      },
                      () => {
                        Toast.show('复制失败！');
                      },
                    );
                }}
                src={getCDNAssets('order_icon_copy.png')}
              />
            )}
            {!!barCode && (
              <img
                className="info-payment"
                style={{ transform: showBarCode ? 'rotate(180deg)' : 'rotate(0deg)' }}
                src={getCDNAssets('order_icon_paymentid.png')}
              />
            )}
          </div>
        </div>
        {!!barCode && barCodeView(showBarCode)}
        {!isLast && <div className="dash-line" style={{ marginLeft: '20Px', marginRight: '20Px' }} />}
      </div>
    );
  };
  const titleItem = (title: string) => {
    return (
      <div className="title-view">
        <div className="title-title-view">
          <span className="title-title">{title}</span>
        </div>
        <div className="title-divider" />
      </div>
    );
  };
  const invoiceItem = (title: string, info: string, isShowArrow: boolean, url: string, isLeft = false) => {
    return (
      <div
        className="invoice-view"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <span className="invoice-title">{title}</span>
        <div className={'invoice-info-view'}>
          <span className="invoice-info" style={isLeft ? { textAlign: 'left' } : {}}>
            {info}
          </span>
          {!!isShowArrow && <img className="invoice-arrow" src={getCDNAssets('order_icon_right_arrow.png')} />}
        </div>
      </div>
    );
  };
  const titleStyle = () => {
    if (!initData) {
      return 'PingFangSC-Semibold';
    }
    switch (initData.orderStatusTitleType) {
      case 1:
        return 'SpeedeeApp-Bold';
      default:
        return 'PingFangSC-Semibold';
    }
  };
  const getHeaderBg = () => {
    if (!initData?.mpOrderStatusCode) {
      return '';
    }
    switch (initData?.mpOrderStatusCode) {
      case '10':
        return 'order_detail_bg_unpay.png';
      case '20':
      case '30':
        if (initData.orderType == '1') {
          return 'order_detail_bg_shop_prepare.png';
        } else {
          return 'order_detail_bg_prepare.png';
        }
      case '31':
        return 'order_detail_bg_prepare.png';
      case '32':
        return 'order_detail_bg_delivery.png';
      case '33':
        return 'order_detail_bg_shop_prepare.png';
      case '60':
        return 'order_detail_bg_cancel.png';
      case '40':
      default:
        return 'order_detail_bg_complete.png';
    }
  };
  const headerBg = () => {
    return listViewStatus == 'normal' ? (
      <div
        className="header-bg"
        style={{
          top: '0',
          backgroundImage: 'url(' + getCDNAssets(getHeaderBg()) + ')',
          backgroundSize: 'contain',
        }}>
        <div className="header-bg-title-view">
          <span
            className="header-bg-title"
            style={{
              fontSize: !isCode ? '26Px' : '34Px',
              fontFamily: titleStyle(),
            }}>
            {initData?.orderStatusTitle}
          </span>
          {isCode && (
            <div className="header-bg-title-image">
              <span className="header-bg-image">取餐码</span>
            </div>
          )}
          {initData?.channelTag && (
            <div className="header-channel-bg-title-image">
              <span className="header-channel-bg-image">{initData?.channelTag}</span>
            </div>
          )}
        </div>
        {!!initData?.orderStatusSubTitle && (
          <div className="header-bg-sub-view">
            <img className="header-bg-sub-image" src={getCDNAssets('order_detail_icon_brand.png')} />
            <span className="header-bg-subtitle">{initData.orderStatusSubTitle}</span>
          </div>
        )}
      </div>
    ) : (
      <div />
    );
  };
  const renderDTItemView = () => {
    // 单独的送餐通知模块
    // const haveOrderProcess = initData.orderProcessNodeHistory?.length > 0;
    // const haveBanner = bannerInfo?.length > 0;
    // const tip = dtHeaderTip?.current || '';
    // if (isNewDTDetail && tip?.length > 0 && !haveOrderProcess && (haveBanner || wechatCode)) {
    //   return (
    //     <div className="dt-banner" style={{ borderRadius: '10Px', marginBottom: '10Px' }}>
    //       <div className="dt_tip_area">
    //         {tip?.length > 0 && <img className="dt-icon" src={getCDNAssets('dt_tips.png')} />}
    //         <span className="dt-tips">{tip}</span>
    //       </div>
    //       {!dtIsArrived && (
    //         <div className="dt-notice-btn" onClick={handleDTCheckInStore}>
    //           通知送餐
    //         </div>
    //       )}
    //     </div>
    //   );
    // }
    return null;
  };
  const bottomBar = () => {
    const fillterOrderActionList = (initData?.orderActionList || []).filter(({ code }) =>
      ['pay', 'invoice', 'cancel'].includes(code!),
    );
    const count = fillterOrderActionList.length || 0;
    if (!initData || !fillterOrderActionList || count == 0) {
      return <div></div>;
    }
    return (
      <div className={'bottom-bar'} style={{ height: '60PX' }}>
        <div className={'bottom-bar-list'}>
          {count > 3 && (
            <div
              className={'bottom-bar-more-view'}
              onClick={(e) => {
                e.stopPropagation();
                setShowMore(!showMore);
              }}>
              <img className={'bottom-bar-more'} src={getCDNAssets('order_icon_more.png')} />
            </div>
          )}
          {count <= 3 && <div />}
          <div className={'bottom-bar-buttons'}>
            {fillterOrderActionList
              .filter((_, index) => {
                return index < 3;
              })
              .map((item, index) => {
                return bottomItem(item, index == 0, index);
              })}
          </div>
        </div>
      </div>
    );
  };
  const onItemClick = (item: OrderActionVo) => {
    setShowMore(false);
    const { name, code, url } = item;
    const { orderId, payId, orderType } = initData || {};
    switch (code) {
      case 'contact_rider':
      case 'customer_service':
        break;
      case 'cancel':
        console.log('取消订单');
        submit();
        break;
      case 'pay':
        navigate(`${ORDER_PAY}?orderId=${orderId || ''}&payId=${payId || ''}&source=0`);
        break;
      case 'invoice':
        Toast.show('如需开票请到麦当劳APP中开票');
        break;
      case 'comment':
        // 点评
        break;
      case 'another_one': //再来一单
        navigate(`/product?orderType=${orderType || ''}?orderId=${orderId || ''}`);
        break;
      case 'refund':
        // 退款
        break;
      case 'reminder':
        break;
      case 'advise':
        break;
      case 'drive_to_store':
        // eslint-disable-next-line no-case-declarations

        break;
      default:
        break;
    }
  };
  const submit = () => {
    void Dialog.confirm({
      destroyOnClose: true,
      title: '订单取消确认',
      content: '是否提交申请',
      bodyClassName: 'cancel-wrapper',
      confirmText: '取消订单',
      cancelText: '我再想想',
      onConfirm: () => {
        fetchOrderCancle(initData?.orderId || '')
          .then((res) => {
            initData && trackOrderCancelEvent('订单详情页', initData);
            if (res?.confirmResult) Toast.show('订单已取消');
            getOrderDetail();
          })
          .catch((err: Error) => {
            trackEvent('pageView', {
              belong_page: '订单详情页',
              fail_reason: err.message,
            });
            Toast.show(err.message || '操作失败！');
          });
      },
    });
  };
  const bottomItem = (item: OrderActionVo, isLast: boolean, index: number) => {
    return (
      <div
        className={'bottom-item'}
        key={index}
        style={{
          backgroundColor: 'has_reminder' == item.code ? (!isLast ? 'white' : '#EEEEEE') : isLast ? '#FFBC0D' : 'white',
          borderColor: 'has_reminder' == item.code ? (!isLast ? '#D9D9D9' : '#EEEEEE') : isLast ? '#FFBC0D' : '#666666',
          marginRight: isLast ? '0Px' : '10Px',
        }}
        onClick={(e) => {
          e.stopPropagation();
          onItemClick(item);
        }}>
        <span
          className={'bottom-item-title'}
          style={{ color: 'has_reminder' == item.code ? (!isLast ? '#C5C5C5' : '#B8B8B8') : '#222222' }}>
          {item.name}
        </span>
      </div>
    );
  };
  const getGender = (gender: string) => {
    if (gender == '男生') {
      return '（先生）';
    } else if (gender == '女生') {
      return '（女士）';
    } else {
      return ' ';
    }
  };
  const content = () => {
    // 配送
    let deliveryView;
    if (initData?.deliveryInfo) {
      const delivery = initData?.deliveryInfo;
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const info3 = delivery.customerNickname + getGender(delivery.gender!) + delivery.mobilePhone;

      deliveryView = (
        <div className="scrollview-list" style={{ marginTop: '10Px' }}>
          {titleItem('配送信息')}
          {!!initData.deliveryInfo.expectDeliveryTime && infoItem('预计送达', initData.deliveryInfo.expectDeliveryTime)}
          {!!initData.deliveryInfo?.riderNickName && infoItem('配送骑士', initData.deliveryInfo.riderNickName)}
          {!!initData.deliveryInfo.deliveryAddress &&
            !!initData.deliveryInfo.addressDetail &&
            infoItem('配送地址', initData.deliveryInfo.deliveryAddress, initData.deliveryInfo.addressDetail, info3)}
          {!!initData.deliveryInfo.deliveryAddress &&
            !initData.deliveryInfo.addressDetail &&
            infoItem('配送地址', initData.deliveryInfo.deliveryAddress, info3)}
          {!initData.deliveryInfo.deliveryAddress &&
            !!initData.deliveryInfo.addressDetail &&
            infoItem('配送地址', initData.deliveryInfo.addressDetail || '', info3)}
          {!!initData.deliveryInfo.deliveryTypeLabel &&
            infoItem('配送方式', initData.deliveryInfo.deliveryTypeLabel, '', '', true)}
        </div>
      );
    }

    let refundView;
    // data.orderRefundReviewVo = {
    //   refundAmount: '25',
    //   reviewStatusString: '退款审核中',
    //   reviewStatusStringTip: '退款申请提交后48小时内完成处理，请耐心等待'
    // }
    // if (!!data.orderRefundReviewVo) {
    //   // 退款信息
    //   refundView = renderRefundView(data.orderRefundReviewVo);
    // }

    // 配送 end
    // 发票 start
    let invoiceInfo = '',
      invoiceArrow = false,
      invoiceView;
    if (initData?.invoice) {
      const { code, desc, actionUrl } = initData.invoice;
      if (code === 6) {
        invoiceView = (
          <div className="scrollview-list" style={{ marginTop: '10Px' }}>
            {titleItem('发票信息')}
            {initData.invoice && infoItem('发票类型')}
            {infoItem('发票内容', '查看发票', '', '', true, true, '', false, false, false)}
          </div>
        );
      } else {
        invoiceView = (
          <div className="scrollview-list" style={{ marginTop: '10Px' }}>
            {invoiceItem('发票信息', desc || '', actionUrl ? true : false, actionUrl || '', false)}
          </div>
        );
      }
    } else {
      let isLeft = false;
      if (
        !!initData?.invoiceActionUrl &&
        (initData?.orderStatusCode == '5' || initData.orderStatusCode == '6') &&
        initData.paymentChannel != 'ARCHCARD'
      ) {
        invoiceInfo = '开具电子发票';
        invoiceArrow = true;
      } else if (initData?.paymentChannel == 'ARCHCARD') {
        invoiceInfo = '麦当劳钱包支付订单消费时不支持开具发票，请在充值订单中开具';
      } else {
        isLeft = true;
        invoiceInfo =
          '订单完成后可开具电子发票\n' +
          '开票金额仅为实付金额，红包、优惠、麦钱包余额、麦当劳礼品卡支付部分等不在开票范围内';
      }
      invoiceView = (
        <div className="scrollview-list" style={{ marginTop: '10Px' }}>
          {invoiceItem('发票信息', invoiceInfo, invoiceArrow, initData?.invoiceActionUrl || '', isLeft)}
        </div>
      );
    }

    // 发票 end
    // 订单详情 start
    const orderDetail = (
      <div className="scrollview-list" style={{ marginTop: '10Px' }}>
        {titleItem('订单信息')}
        {!!initData?.orderId && infoItem('订单号', initData.orderId, '', '', false, false, '', true)}
        {!!initData?.paymentTransactionId &&
          infoItem('交易号', initData?.paymentTransactionId, '', '', false, false, '', false, true, barCodeFlag)}
        {!!initData?.eatTypeName && infoItem('就餐方式', initData?.eatTypeName)}
        {!!initData?.carLicensePlate && infoItem('车牌号码', initData?.carLicensePlate)}
        {!!initData?.expectPickUpTime && infoItem('预计取餐', initData?.expectPickUpTime)}
        {!!initData?.pickupCode && infoItem('取餐码', initData?.pickupCode)}
        {!!initData?.displayOrderId && infoItem('取餐码', initData?.displayOrderId, '', '', false, false, '', true)}
        {!!initData?.tablewareInfo &&
          infoItem(
            '餐具需求',
            initData.tablewareInfo,
            '',
            '',
            !initData.remark && !initData.saleTime && !initData.paymentChannelLabel,
          )}
        {!!initData?.paymentChannelLabel &&
          infoItem('支付方式', initData?.paymentChannelLabel, '', '', !initData.remark && !initData.saleTime)}
        {!!initData?.saleTime && infoItem('下单时间', initData.saleTime, '', '', !initData.remark)}
        {!!initData?.remark && infoItem('订单备注', initData.remark, '', '', true)}
      </div>
    );
    // 订单详情 end
    return (
      <div className="scrollview" onScroll={(e) => {}}>
        {!isCabinet && headerBg()}
        {!isCabinet && <div className="scrollview-empty-header" />}
        <div className="scrollview-list" style={{ backgroundColor: 'transparent' }}>
          {renderDTItemView()}
        </div>
        <div className="scrollview-list">
          <StatusModel
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            orderProcessNodeHistory={initData?.orderProcessNodeHistory}
            orderStatusDetail={initData?.orderStatusDetail}
            expectDeliveryTimeShort={initData?.expectDeliveryTimeShort}
            orderType={Number(initData?.orderType)}
          />
          {shopHeader()}
          {productList()}
          {couponList()}
          {/* {mccafeCouponList()} */}
          {billItem()}
        </div>
        {deliveryView}
        {refundView}
        {invoiceView}
        {orderDetail}
        <div style={{ height: '110Px' }} />
      </div>
    );
  };
  const shopHeader = () => {
    return (
      <div className="shop-header">
        <div className="shop-header-content">
          <img className="shop-header-img" src={getCDNAssets('order_icon_shop.png')} />
          <span className="shop-header-text">{initData?.storeName}</span>
        </div>
      </div>
    );
  };
  const productList = () => {
    if (!initData?.orderProductList) {
      return <div />;
    }
    return (
      <div className="product-index">
        {initData?.orderProductList.map((item, index) => {
          const isLast = index == (initData.orderProductList || []).length - 1;
          return productItem(item, isLast, index);
        })}
      </div>
    );
  };
  const productItem = (product: OrderProduct_, isLast: boolean, index: number) => {
    if (!product) {
      return <div />;
    }
    return (
      <div className="product-item" key={index} style={{ marginBottom: isLast ? '0' : '20Px' }}>
        <img className="product-item-image" src={product.productImage} />
        <div className="product-item-content">
          <span className="product-item-title">{product.productName}</span>
          {!!product.comboItemList &&
            product.comboItemList.map((item, i) => {
              return (
                <span className="product-item-combo" key={i}>
                  {item.quantity} x {item.name}
                </span>
              );
            })}
          <div className="product-item-bottom">
            <span className="product-item-quantity">{product.quantity}份</span>
            <div className="product-item-price-view">
              <div className="product-item-real-price-view">
                <span className="product-item-price-unit">¥</span>
                <span className="product-item-price">{product.subtotal}</span>
              </div>
              {renderProductPriceTipView(product)}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderProductPriceTipView = (product: OrderProduct_) => {
    if (product?.pmtIcons && product?.pmtIcons?.length > 0) {
      if (product?.pmtIcons[0]?.text || ''?.length > 0) {
        const text = product?.pmtIcons[0]?.text || '';
        return (
          <div className="order_detail_price-tip-view">
            <img className="order_detail_price-tip-image-left" src={getCDNAssets('cart_tip_left.png')} />
            <div
              className="order_detail_price-tip-image-middle"
              style={{
                backgroundImage: 'url(' + getCDNAssets('cart_tip_middle.png') + ')',
                backgroundSize: '100% 100%',
              }}>
              <span className="order_detail_price-tip-text">{text}</span>
            </div>
            <img className="order_detail_price-tip-image-right" src={getCDNAssets('cart_tip_right.png')} />
          </div>
        );
      }
      return null;
    }
    return null;
  };

  /*
  copy from weapp
 */
  const couponItem = (
    text: string,
    subTitle: string,
    price: string,
    realPrice: string,
    isCoupon: boolean,
    cardTypeName = '',
    cardName = '',
    isPacking = false,
  ) => {
    const priceStyle = isCoupon ? { color: '#DB0007' } : { color: '#222222' };
    const clazz = 'coupon-item-text';

    // const clazz = isCoupon && !cardTypeName ? 'coupon-item-text-coupon' : 'coupon-item-text';
    return (
      <div className="coupon-item">
        <div className="coupon-item-text-view">
          <span
            className={clazz}
            onClick={() => {
              if (isPacking) {
                // openPackingModal();
              }
            }}>
            {text}
          </span>
          {isPacking && (
            <img
              className={'coupon-item-packing-icon'}
              src={getCDNAssets('cart_info.png')}
              // onClick={openPackingModal}
            />
          )}
          {!!cardTypeName && (
            <div
              className="coupon-item-card-type"
              style={{
                borderTopRightRadius: '3Px',
                borderBottomRightRadius: '3Px',
                marginLeft: !text ? '0Px' : '10Px',
              }}>
              <span className="coupon-item-card-type-text">{cardTypeName}</span>
            </div>
          )}
          {!!subTitle && <span className="coupon-item-text-sub">{subTitle}</span>}
        </div>
        <div className="coupon-item-price-view">
          {realPrice != price && (
            <span className="coupon-item-price-unit" style={{ color: '#CCCCCC', textDecoration: 'line-through' }}>
              ¥
            </span>
          )}
          {realPrice != price && (
            <span
              className="coupon-item-price"
              style={{
                fontSize: '14Px',
                color: '#CCCCCC',
                textDecoration: 'line-through',
                marginRight: '10Px',
              }}>
              {price}
            </span>
          )}
          {isCoupon && (
            <span className="coupon-item-price-unit" style={priceStyle}>
              -
            </span>
          )}
          <span className="coupon-item-price-unit" style={priceStyle}>
            ¥
          </span>
          <span className="coupon-item-price" style={priceStyle}>
            {realPrice}
          </span>
        </div>
      </div>
    );
  };

  const couponRedItem = (
    text: string,
    subTitle: string,
    price: string,
    realPrice: string,
    cardTypeName = '',
    cardName = '',
    isFirst: boolean,
    isLast: boolean,
    showPmtType = 1,
  ) => {
    const clazz = !cardTypeName ? 'coupon-item-text-coupon' : 'coupon-item-text';
    return (
      <div
        className="coupon-item"
        style={{
          marginTop: isFirst ? '10Px' : '20Px',
          marginBottom: isLast ? '10Px' : '0Px',
          marginLeft: '10Px',
          marginRight: '10Px',
        }}>
        <div className="coupon-item-text-view">
          {showPmtType && showPmtType == 1 && <span className={clazz}>{text}</span>}
          {showPmtType && showPmtType == 2 && <span className={'promotion-item-text-coupon'}>{text}</span>}
          {!!cardTypeName && (
            <div
              className="coupon-item-card-type"
              style={{
                borderTopRightRadius: '3Px',
                borderBottomRightRadius: '3Px',
                marginLeft: !text ? '0Px' : '10Px',
              }}>
              <span className="coupon-item-card-type-text">{cardTypeName}</span>
            </div>
          )}
          {!!subTitle && <span className="coupon-item-text-sub">{subTitle}</span>}
        </div>
        <div className="coupon-item-price-view">
          {realPrice != price && (
            <span className="coupon-item-price-unit" style={{ color: '#CCCCCC', textDecoration: 'line-through' }}>
              ¥
            </span>
          )}
          {realPrice != price && (
            <span
              className="coupon-item-price"
              style={{
                fontSize: '14Px',
                color: '#CCCCCC',
                textDecoration: 'line-through',
                marginRight: '10Px',
              }}>
              {price}
            </span>
          )}
          <span className="coupon-item-price-unit" style={{ color: '#DB0007' }}>
            -
          </span>
          <span className="coupon-item-price-unit" style={{ color: '#DB0007' }}>
            ¥
          </span>
          <span className="coupon-item-price" style={{ color: '#DB0007' }}>
            {realPrice}
          </span>
        </div>
      </div>
    );
  };

  const couponList = () => {
    const showStaffCard = initData?.staffCardDiscountAmount && initData.staffCardDiscountAmount != '0'; //员工卡优惠为0不展示
    const showCouponList = initData?.couponList && initData.couponList.length > 0;
    if (
      initData?.orderType == '1' &&
      !showStaffCard &&
      !showCouponList &&
      !initData.tablewarePrice &&
      !initData.realPackingFeeTotalPrice
    ) {
      return <div />;
    }
    let subTitle = Number(initData?.realDeliveryPrice) == 0 ? '免外送费' : '';
    let deliveryCardTypeName = '',
      deliveryCardName = '';
    if (
      !!initData?.freightCouponList &&
      initData.freightCouponList.length > 0 &&
      !!initData.freightCouponList[0].couponName
    ) {
      if (initData?.freightCouponList[0].cardTypeName) {
        deliveryCardTypeName = initData.freightCouponList[0].cardTypeName;
        deliveryCardName = initData.freightCouponList[0].cardName!;
      }
      subTitle = initData.freightCouponList[0].couponName;
    }
    return (
      <div className="coupon-index">
        <div
          className="dash-line"
          style={{
            width: 'calc(100% - 40Px)',
            marginLeft: '20Px',
            marginRight: '20Px',
          }}
        />
        {initData?.orderType != '1' &&
          couponItem('商品小计', '', initData?.productTotalPrice!, initData?.productTotalPrice!, false)}
        {initData?.orderType != '1' &&
          couponItem(
            '配送费',
            subTitle,
            initData?.deliveryPrice!,
            initData?.realDeliveryPrice!,
            false,
            deliveryCardTypeName,
            deliveryCardName,
          )}
        {!!initData?.realPackingFeeTotalPrice &&
          couponItem(
            '打包费',
            '',
            initData.realPackingFeeTotalPrice,
            initData.realPackingFeeTotalPrice,
            false,
            '',
            '',
            true,
          )}
        {!!initData?.tablewarePrice &&
          couponItem('餐具费', '', initData.tablewarePrice, initData.tablewarePrice, false)}
        {showStaffCard &&
          couponItem('员工卡优惠', '', initData?.staffCardDiscountAmount!, initData?.staffCardDiscountAmount!, true)}
        {!!initData?.payDiscountAmount &&
          couponItem('支付优惠', '', initData?.payDiscountAmount, initData?.payDiscountAmount, true)}
        {showCouponList && (
          <div
            style={{
              background: 'linear-gradient(#FFFAF1, #FFFBF5)',
              borderRadius: '6Px',
              marginTop: '10Px',
              marginLeft: '10Px',
              marginRight: '10Px',
            }}>
            {(initData?.couponList || []).map((item, index) => {
              const isFirst = index == 0;
              const isLast = index == (initData?.couponList || []).length - 1;
              return couponRedItem(
                '',
                item.couponName,
                item.discountAmount,
                item.discountAmount,
                item.cardTypeName,
                item.cardName,
                isFirst,
                isLast,
                item?.showPmtType ?? 1,
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const billItem = () => {
    return (
      <div className="bill-index">
        <div className="divider" style={{ marginTop: '20Px' }}>
          <div className="semi-circle-left" />
          <div
            className="dash-line"
            style={{
              width: '100%',
              marginLeft: '20Px',
              marginRight: '20Px',
            }}
          />
          <div className="semi-circle-right" />
        </div>
        <div className="bill-view">
          {/* {renderRebateView()} */}
          {initData?.totalDiscountAmount != '0' && (
            <div className="bill-discount-view">
              <span className="bill-discount-title">已优惠</span>
              <span className="bill-discount-unit">¥</span>
              <span className="bill-discount">{initData?.totalDiscountAmount}</span>
            </div>
          )}
          <div className="bill-discount-view" style={{ marginLeft: '10Px' }}>
            <span className="bill-discount-title">{!initData?.realPayAmount ? '合计' : '实付'}</span>
            <span className="bill-discount-unit" style={{ color: '#222222' }}>
              ¥
            </span>
            <span className="bill-discount" style={{ fontSize: '20Px', color: '#222222' }}>
              {!initData?.realPayAmount ? initData?.realTotalAmount : initData?.realPayAmount}
            </span>
          </div>
        </div>
      </div>
    );
  };
  const popView = () => {
    const orderActionList = initData?.orderActionList;
    if (!orderActionList || !orderActionList) {
      return <div />;
    }
    if (showMore) {
      return (
        <div className="pop_view" style={{ bottom: '60Px' }}>
          <div className="pop_list">
            {orderActionList
              .filter((_, index) => {
                return index > 2;
              })
              .map((e, i) => {
                return (
                  <div
                    key={i}
                    className="card"
                    onClick={(event) => {
                      event.stopPropagation();
                      //   onItemClick(e);
                    }}>
                    {i > 0 && <div className="pop_line" />}
                    <span className="text">{e.name}</span>
                  </div>
                );
              })}
          </div>
          <img className="top_arrow" src={getCDNAssets('order_list_pop_arrow.png')} />
        </div>
      );
    } else {
      return <div />;
    }
  };
  const getOrderDetail = useCallback(() => {
    fetchOrderDetail(searchParams.get('orderId') || '')
      .then((res) => {
        if (res) {
          console.log('fetchOrderDetail', res);
          setInitData(res);
          if (res.paymentTransactionId) {
            setBarcode(res.paymentTransactionId);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [searchParams]);
  useEffect(() => {
    getOrderDetail();
  }, []);

  useEffect(() => {
    if (initData?.orderStatusCode) {
      if (['6', '7', '8'].includes(initData.orderStatusCode)) {
        timeId && clearInterval(timeId);
        return;
      }
      timeId = setInterval(() => {
        getOrderDetail();
      }, 3000) as unknown as number;
    }
    return () => {
      timeId && clearInterval(timeId);
    };
  }, [initData, reFetch, getOrderDetail]);

  return (
    <div>
      <div className="detail-index">
        {!showError && !showNetworkError && !!initData && !!initData.orderType && content()}
        {!showError && !showNetworkError && listViewStatus != 'mid' && bottomBar()}
        {showMore && (
          <div
            className={'more-mask'}
            onClick={(e) => {
              e.stopPropagation();
              setShowMore(false);
            }}
          />
        )}
        {showMore && popView()}
        {!showError && !showNetworkError && isMds && showTopTipsView && initData?.deliveryInfo?.riderHealthInfoText && (
          <div className="detail_top_tips_view">
            <img className="detail_top_tips_left_icon" src={getCDNAssets('order_detail_mds_top_img.png')} />
            <div className="detail_top_tips_txt">{initData.deliveryInfo.riderHealthInfoText}</div>
            <img className="detail_top_tips_right_icon" src={getCDNAssets('right_arrow.png')} />
          </div>
        )}
        {!showError && !showNetworkError && isMds && showTopTipsView && (
          <img
            className={'refresh'}
            onClick={(e) => {
              e.stopPropagation();
              getOrderDetail();
            }}
            src={getCDNAssets('order_icon_refresh.png')}
          />
        )}
      </div>
    </div>
  );
};
export default OrderDetailPage;
