import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SwipeAction, Dialog, Button, Ellipsis, SearchBar } from 'antd-mobile';
import cls from 'classnames';
import { useParams } from 'react-router-dom';
import Header from '@/components/Header';

import { fetchStoreDetailInfo } from '@/apis/canteen';
import type { StoreDetail } from '@/apis/canteen';
import { getCDNAssets } from '@/utils';
import useTitle from '@/hooks/useTitle';
import './index.less';

interface TagInfoItem {
  title: string;
  code?: string;
  imageUrl?: string;
}

const CanteenDetail = () => {
  useTitle('餐厅详情');

  const { id } = useParams();

  const [detail, setDetail] = useState<StoreDetail>();

  const getDetail = useCallback((code: string) => {
    fetchStoreDetailInfo(code)
      .then((res) => {
        res && setDetail(res);
        console.log(res);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    id && getDetail(id);
  }, [id]);

  const renderTag = useCallback((i: TagInfoItem) => {
    if (i) {
      const { title, imageUrl, code } = i;
      return (
        <div key={code} className={cls('item', { tag_first_item_view: !imageUrl })}>
          {imageUrl && <img src={imageUrl} />}
          <span>{title}</span>
        </div>
      );
    }
    return null;
  }, []);

  const {
    shortName,
    hotTagCodes,
    businessStatus,
    tags,
    onlineBusinessStatus,
    startTime,
    endTime,
    tips,
    cityName,
    address,
    telephone,
  } = detail || {};

  const tagInfos = useMemo(() => {
    const tagInfoMap: TagInfoItem[] = [
      {
        code: 'mcCafe',
        title: '麦咖啡',
        imageUrl: getCDNAssets('detail_store_icon_mcf.png'),
      },
      {
        code: 'kiosk',
        title: '甜品站',
        imageUrl: getCDNAssets('detail_store_icon_sweet.png'),
      },
      {
        code: 'DT',
        title: '得来速',
        imageUrl: getCDNAssets('detail_store_icon_dt.png'),
      },
      {
        code: 'breakfast',
        title: '早餐',
        imageUrl: getCDNAssets('detail_store_icon_breakfast.png'),
      },
      {
        code: '24h',
        title: '24小时营业',
        imageUrl: getCDNAssets('detail_store_icon_24hours.png'),
      },
      {
        code: 'wifi',
        title: 'WIFI',
        imageUrl: getCDNAssets('detail_store_icon_wifi.png'),
      },
      {
        code: 'childrenPlayground',
        title: '亲子活动',
        imageUrl: getCDNAssets('detail_store_icon_childplayground.png'),
      },
      {
        code: 'amily',
        title: '家庭聚会',
        imageUrl: getCDNAssets('detail_store_icon_family.png'),
      },
    ];
    const showTags = [];
    if (tags && tags.length > 0 && tags[0].label) {
      const firstItem = {
        title: tags[0].label,
        imageUrl: '',
      };
      showTags.push(firstItem);
    }
    (hotTagCodes || []).forEach((tagCode) => {
      const tagInfo = tagInfoMap.find(({ code }) => code === tagCode);
      tagInfo && showTags.push(tagInfo);
    });
    return showTags;
  }, [hotTagCodes, tags]);

  return (
    <div className="canteen-detail-container">
      {/* <Header /> */}
      <div className="detail-content">
        <div className="base-info">
          <div className="name">{shortName}</div>
          <div className="hot-tag-wrapper">{tagInfos.map((i) => renderTag(i))}</div>
          <div className="business-info">
            {businessStatus || onlineBusinessStatus ? (
              <div>
                今日营业时间：{startTime} - {endTime}
              </div>
            ) : (
              <div className="break">
                <img src={getCDNAssets('onlineStoreClose.png')} />
                <span>{tips || '餐厅暂停营业，建议选择其他就近餐厅'}</span>
              </div>
            )}
          </div>
        </div>
        <div className="address-info">
          <div className="address-wrapper">
            <div>
              地址：{cityName}
              {address}
            </div>
            <div>联系方式：{telephone}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanteenDetail;
