import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import type { GroupsItem, ActivityItem } from '@/apis/home.type';
import { trackEvent } from '@/utils/tracker';
import './index.less';
import debounce from 'lodash/debounce';
import HomeVideo from './homeVideo';
import HomeBrokenView from './homeBrokenView';
import HomeAPositionView from './homeAPositionView';

const homeAStyle = {
  oneImage: 1, //只有一个image
  leftRight: 2, //左右均分
  topBottom: 3, //上下均分
  leftAndTopBottom: 4, //左 上下
  topAndLeftRight: 5, //上 左右
};

const homeAShowStyle = {
  brokenView: 1, //破窗
  positionView: 2, //位移
};

interface IPrps {
  groups: GroupsItem[];
  handleClick?: (url: string) => void;
}

const HomeActivity: FC<IPrps> = (props) => {
  const { groups, handleClick } = props;

  const [list, setList] = useState<GroupsItem[]>([]);

  useEffect(() => {
    const groupList = (groups || []).filter((item) => {
      return checkValidActivityData(item);
    });
    setList(groupList);
  }, [groups]);

  const jumpEvent = (groupTitle: string, groupIdx: number, itemData: ActivityItem, itemIdx: number) => {
    trackEvent('operationClick', {
      belong_page: '首页',
      module_name: '热门活动',
      module_rank: '10',
      rank: groupIdx + 1,
      Operation_bit_name: groupTitle,
      url: itemData.url,
      is_operation_group: true,
      group_operation_bit_name: itemData.title,
      group_operation_bit_rank: itemIdx + 1,
    });
    handleClick && handleClick(itemData.url);
  };

  const jumpEventTimeout = (groupTitle: string, groupIdx: number, itemData: ActivityItem, itemIdx: number) => {
    setTimeout(() => {
      jumpEvent(groupTitle, groupIdx, itemData, itemIdx);
    }, 200);
  };

  const jumpEventForBetter = useRef(debounce(jumpEventTimeout, 500, { leading: true, trailing: false })).current;

  //校验图片数量和style是否匹配，如果图片少了则不展示
  const checkValidActivityData = (groupData: GroupsItem) => {
    let checkValid = true;
    if (groupData.showStyle == homeAShowStyle.brokenView) {
      //破窗过滤
      if ((groupData?.list?.length ?? 0) < 1 || (groupData.list[0]?.images?.length ?? 0) < 2) {
        return false;
      }
    } else if (groupData.showStyle == homeAShowStyle.positionView) {
      //位移过滤
      if ((groupData?.list?.length ?? 0) < 1 || (groupData.list[0]?.images?.length ?? 0) < 2) {
        return false;
      }
    } else if (groupData.groupStyle == homeAStyle.oneImage) {
      if ((groupData?.list?.length ?? 0) < 1) {
        checkValid = false;
      }
    } else if (groupData.groupStyle == homeAStyle.topBottom || groupData.groupStyle == homeAStyle.leftRight) {
      if ((groupData?.list?.length ?? 0) < 2) {
        checkValid = false;
      }
    } else if (
      groupData.groupStyle == homeAStyle.leftAndTopBottom ||
      groupData.groupStyle == homeAStyle.topAndLeftRight
    ) {
      if ((groupData?.list?.length ?? 0) < 3) {
        checkValid = false;
      }
    }
    return checkValid;
  };

  //渲染破窗
  const renderBrokenView = (groupData: GroupsItem, groupIdx: number) => {
    const firstItem = groupData.list?.[0];
    if (!firstItem || firstItem.images?.length < 2) {
      return null;
    }
    return (
      <div
        className="home_activity_floor_view"
        id={`home_activity_floor_view_${groupIdx}`}
        onClick={() => {
          //   $(`#home_activity_floor_view_${groupIdx}`)?.addClass('home_activity_floor_one_view_selected');
          setTimeout(() => {
            jumpEventForBetter(groupData.title, groupIdx, firstItem, 0);
            // $(`#home_activity_floor_view_${groupIdx}`)?.removeClass('home_activity_floor_one_view_selected');
          }, 200);
        }}
        key={`home_broken_${groupIdx}`}>
        <HomeBrokenView imageData={firstItem} groupIdx={groupIdx} />
      </div>
    );
  };
  //渲染位移
  const renderPositionView = (groupData: GroupsItem, groupIdx: number) => {
    const firstItem = groupData.list?.[0];
    if (!firstItem || firstItem.images?.length < 1) {
      return null;
    }
    return (
      <div
        className="home_activity_floor_view"
        id={`home_activity_floor_view_${groupIdx}`}
        onClick={() => {
          //   $(`#home_activity_floor_view_${groupIdx}`)?.addClass('home_activity_floor_one_view_selected');
          setTimeout(() => {
            jumpEventForBetter(groupData.title, groupIdx, firstItem, 0);
            // $(`#home_activity_floor_view_${groupIdx}`)?.removeClass('home_activity_floor_one_view_selected');
          }, 200);
        }}
        key={`home_position_${groupIdx}`}>
        <HomeAPositionView imageData={firstItem} groupIdx={groupIdx} />
      </div>
    );
  };

  const renderFloorView = (groupData: GroupsItem, groupIdx: number) => {
    // if (groupData.showStyle == homeAShowStyle.brokenView) {
    //   //破窗
    //   return renderBrokenView(groupData, groupIdx);
    // } else if (groupData.showStyle == homeAShowStyle.positionView) {
    //   //位移
    //   return renderPositionView(groupData, groupIdx);
    // }

    trackEvent('operationExpose', {
      belong_page: '首页',
      module_name: '热门活动',
      rank: groupIdx + 1,
      module_rank: 5,
      Operation_bit_name: groupData.title,
    });

    return (
      <div
        className="home_activity_floor_view"
        id={`home_activity_floor_view_${groupIdx}`}
        onClick={() => {
          //   $(`#home_activity_floor_view_${groupIdx}`)?.addClass('home_activity_floor_one_view_selected');
          setTimeout(() => {
            // $(`#home_activity_floor_view_${groupIdx}`)?.removeClass('home_activity_floor_one_view_selected');
          }, 200);
        }}>
        {groupData.groupStyle == homeAStyle.oneImage && renderFloorSingleView(groupData, groupIdx)}
        {groupData.groupStyle == homeAStyle.topBottom && renderFloorTopAndBottomView(groupData, groupIdx)}
        {groupData.groupStyle == homeAStyle.leftRight && renderFloorLeftAndRightView(groupData, groupIdx)}
        {groupData.groupStyle == homeAStyle.leftAndTopBottom && renderFloorLeftAndTopBottomView(groupData, groupIdx)}
        {groupData.groupStyle == homeAStyle.topAndLeftRight && renderFloorTopAndLeftRightView(groupData, groupIdx)}
      </div>
    );
  };

  const renderVideoView = (groupTitle: string, groupIdx: number, videoItem: ActivityItem) => {
    return (
      <div
        className="home_activity_floor_one_view"
        onClick={() => {
          jumpEventForBetter(groupTitle, groupIdx, videoItem, 0);
        }}>
        {videoItem.videoUrl && <HomeVideo videoUrl={videoItem.videoUrl} videoIdx={groupIdx} />}
      </div>
    );
  };

  //只有一张图 结构
  const renderFloorSingleView = (groupData: GroupsItem, groupIdx: number) => {
    const firstItem = groupData.list[0];
    // if (firstItem?.isVideo) {
    //   return renderVideoView(groupData.title, groupIdx, firstItem);
    // }
    return (
      <img
        className="home_activity_floor_one_view"
        onClick={() => {
          jumpEventForBetter(groupData.title, groupIdx, firstItem, 0);
        }}
        src={firstItem.image}
      />
    );
  };

  //上下均分结构
  const renderFloorTopAndBottomView = (groupData: GroupsItem, groupIdx: number) => {
    return (
      <div className="home_activity_floor_one_view">
        {(groupData?.list || []).map((item, index) => {
          if (index < 2) {
            return (
              <img
                className="home_activtiy_floor_top_bottom_item"
                onClick={() => {
                  jumpEventForBetter(groupData.title, groupIdx, item, index);
                }}
                src={item.image}
              />
            );
          }
        })}
      </div>
    );
  };
  //左右均分结构
  const renderFloorLeftAndRightView = (groupData: GroupsItem, groupIdx: number) => {
    return (
      <div className="home_activity_floor_one_view">
        {groupData?.list?.map((item, index) => {
          if (index < 2) {
            return (
              <img
                className="home_activtiy_floor_left_right_item"
                onClick={() => {
                  jumpEventForBetter(groupData.title, groupIdx, item, index);
                }}
                src={item.image}
              />
            );
          }
        })}
      </div>
    );
  };
  //左半 右上下结构
  const renderFloorLeftAndTopBottomView = (groupData: GroupsItem, groupIdx: number) => {
    const firstItem = groupData.list[0];
    return (
      <div className="home_activity_floor_one_view">
        <img
          className="home_activtiy_floor_leftTB_left_item"
          onClick={() => {
            jumpEventForBetter(groupData.title, groupIdx, firstItem, 0);
          }}
          src={groupData?.list?.[0].image}
        />
        <div className="home_activtiy_floor_leftTB_right_item">
          {groupData?.list?.map((item, index) => {
            if (index == 1 || index == 2) {
              return (
                <img
                  className="home_activtiy_floor_leftTB_top_item"
                  onClick={() => {
                    jumpEventForBetter(groupData.title, groupIdx, item, index);
                  }}
                  src={item.image}
                />
              );
            }
          })}
        </div>
      </div>
    );
  };
  //上半 下左右结构
  const renderFloorTopAndLeftRightView = (groupData: GroupsItem, groupIdx: number) => {
    return (
      <div className="home_activity_floor_one_view">
        {groupData?.list?.map((item, index) => {
          const classNameString =
            index == 0 ? 'home_activtiy_floor_topLR_top_item' : 'home_activtiy_floor_topLR_left_item';
          if (index < 3) {
            return (
              <img
                className={classNameString}
                onClick={() => {
                  jumpEventForBetter(groupData.title, groupIdx, item, index);
                }}
                src={item.image}
              />
            );
          }
        })}
      </div>
    );
  };

  return <div className="home_activity_bg">{(list || []).map((item, index) => renderFloorView(item, index))}</div>;
};

export default HomeActivity;
