import { useState, useEffect } from 'react';

let isSupportWebkitSafeArea = 0;

export const supportSafeArea = (): boolean => {
  if (isSupportWebkitSafeArea !== 0) {
    return isSupportWebkitSafeArea === 1;
  }
  const div = document.createElement('div');
  const id = 'test-check-safe-area';
  const styles = [
    'position: fixed',
    'z-index: -1',
    'height: constant(safe-area-inset-bottom)',
    'height: env(safe-area-inset-bottom)',
  ];
  div.style.cssText = styles.join(';');
  div.id = id;
  document.body.appendChild(div);
  const areaDiv = document.getElementById(id);
  if (areaDiv) {
    isSupportWebkitSafeArea = areaDiv.offsetHeight > 0 ? 1 : -1;
    areaDiv.parentNode?.removeChild(areaDiv);
  }

  return isSupportWebkitSafeArea === 1;
};

/**
 * check support `safe-area-inset-bottom` env
 */
export function useSupportSafeArea() {
  const [support, setSupport] = useState(true);

  useEffect(() => {
    setSupport(supportSafeArea());
  }, []);

  return support;
}
