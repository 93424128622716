import type { CSSProperties, FC } from 'react';
import { useMemo } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import type { ProductData, ProductInfoCustomization, ProductInfoCustomizationOption } from '@/apis/product/types';

import { CustomizationMultiTitle } from './Head';
import MultipSpecialView from './MultipSpecial';
import type { SingleSpecialViewProps } from './SingleSpecial';
import SingleSpecialView from './SingleSpecial';
import CafeSingleSpecialView from './CafeSingleSpecial';
import type { ComboGroupRadioProps } from './ComboGroup';
import ComboGroupRadio from './ComboGroup';
import type { ComboDetailProps } from './ComboDetail';
import ComboDetail from './ComboDetail';

export type ProductDetailOptionsProps = {
  className?: string;
  style?: CSSProperties;
  data?: ProductData['product'];
  matchedSpecsCode?: string;
  activedSpecsGroupCodes?: string[];
  extraCustomizationData?: Record<string, ProductInfoCustomization>;
  onSpecsCodeSelect?: ComboGroupRadioProps['onSelect'];
  onCustomizationOptionSelect?: (productCode?: string, option?: ProductInfoCustomizationOption) => void;
  onCustomizationOptionDeSelect?: (productCode?: string, option?: ProductInfoCustomizationOption) => void;
  onCustomizationItemSelect?: (
    productCode?: string,
    ...args: Parameters<NonNullable<SingleSpecialViewProps['onSelect']>>
  ) => void;
  onComboItemsProductSelect?: (
    productCode?: string,
    ...args: Parameters<NonNullable<ComboDetailProps['onSelect']>>
  ) => void;
  onComboItemsProductCustom?: (
    productCode?: string,
    ...args: Parameters<NonNullable<ComboDetailProps['onCustom']>>
  ) => void;
};

const ProductDetailOptions: FC<ProductDetailOptionsProps> = (props) => {
  const {
    className,
    style,
    data,
    matchedSpecsCode,
    activedSpecsGroupCodes,
    extraCustomizationData,
    onSpecsCodeSelect,
    onCustomizationOptionSelect,
    onCustomizationOptionDeSelect,
    onCustomizationItemSelect,
    onComboItemsProductSelect,
    onComboItemsProductCustom,
  } = props;

  const [comboItems, curSelectProduct] = useMemo(() => {
    if (matchedSpecsCode && data?.products) {
      const _p = data.products.find((p) => p.matchedSpecsCode === matchedSpecsCode);

      return [_p?.comboItems, _p];
    }

    return [data?.comboItems, data];
  }, [data, matchedSpecsCode]);

  const isNotEmptyCustomizationItems = useMemo(
    () => curSelectProduct?.customization?.items && !isEmpty(curSelectProduct.customization.items),
    [curSelectProduct?.customization?.items],
  );

  const matchedSpecsCodeArr = useMemo(() => (matchedSpecsCode ? matchedSpecsCode.split(',') : []), [matchedSpecsCode]);

  return (
    <div className={classNames(className)} style={style}>
      {data?.customization && data.customizationMode === 1 && !isEmpty(data.customization.options) && (
        <div>
          <CustomizationMultiTitle img={data.image} />
          <MultipSpecialView
            items={data.customization.options}
            onSelect={(i) => onCustomizationOptionSelect?.(data.code, i)}
            onDeSelect={(i) => onCustomizationOptionDeSelect?.(data.code, i)}
          />
        </div>
      )}

      {/* 套餐 specTypes */}
      {data?.type === 3 &&
        data?.products &&
        data?.specTypes &&
        !isEmpty(data.specTypes) &&
        data.specTypes.map((t) => (
          <ComboGroupRadio
            key={`spectypes${String(t.code)}`}
            item={t}
            matchedSpecsCode={matchedSpecsCodeArr.find((i) => t.code && i.indexOf(t.code) === 0)}
            activedSpecsGroupCodes={activedSpecsGroupCodes}
            onSelect={onSpecsCodeSelect}
          />
        ))}

      {comboItems && !isEmpty(comboItems) && (
        <ComboDetail
          items={comboItems}
          itemsExtraCustomizationObj={extraCustomizationData}
          onSelect={(...args) => onComboItemsProductSelect?.(curSelectProduct?.code, ...args)}
          onCustom={(...args) => onComboItemsProductCustom?.(curSelectProduct?.code, ...args)}
        />
      )}

      {curSelectProduct?.type === 1 && curSelectProduct.bu !== 2 && isNotEmptyCustomizationItems && (
        <SingleSpecialView
          showTitleBar={curSelectProduct.customization?.items && curSelectProduct.customization.items.length > 1}
          items={curSelectProduct.customization?.items}
          onSelect={(i, v) => onCustomizationItemSelect?.(curSelectProduct.code, i, v)}
        />
      )}

      {curSelectProduct?.type === 1 && curSelectProduct.bu === 2 && isNotEmptyCustomizationItems && (
        <CafeSingleSpecialView
          showTitleBar={curSelectProduct.customization?.items && curSelectProduct.customization.items.length > 1}
          items={curSelectProduct.customization?.items}
          onSelect={(i, v) => onCustomizationItemSelect?.(curSelectProduct.code, i, v)}
        />
      )}
    </div>
  );
};

export default ProductDetailOptions;
