export enum EMediaType {
  IMG = 1,
  VIDEO = 2,
}

export type MenuBannerItem = {
  crowdId: string;
  crowdInclude: number;
  crowdName?: string;
  duration: number;
  extraInfo?: string;
  mediaType: EMediaType;
  mediaUrl: string;
  redirectUrl: string;
  title: string;
};

export type MenuListProductItem = {
  maxPurchaseQuantity: number;
  limitedQuantity: number;
  productCode: string;
  productName: string;
  productImage: string;
  productType: number;
  /** 氛围中图  取pcm的皮肤中图，前端会依据此字段有没有，来判断是否展示氛围样式	 */
  productMidImage?: string;
  isChoices: number;
  memo: string;
  priceInfo: {
    deliveryPrice?: number;
    deliveryPriceText?: string;
    eatInPrice?: number;
    eatInPriceText?: string;
    priceShowStyle: number;
    separatePrice?: number;
    separatePriceText?: string;
  };
  saleStatus: number;
  hasCustomization: number;
  bu: number;
  supportChoice: number;
  defaultComboItemsText: string;
  midImage: string;
  productTagList: string[];
  tags: {
    name: string;
    style: number;
    color: string;
    image?: string;
  }[];
  style: {
    button: {
      btnImage: string;
      btnText: string;
      btnTextColor: string;
    };
    /** 大卡颜色模式 0:浅色 1:深色  默认0 */
    displayMode?: number;
    /** 价格颜色 大卡模式使用 */
    priceColor?: string;
    /** 划线价颜色 大卡模式使用 */
    separatePriceColor?: string;
    /** 副标题颜色 大卡模式使用 */
    subTitleColor?: string;
    /** 标题颜色 大卡模式使用  */
    titleColor?: string;
  };
  productPromotions: [];
  cardTagImage?: string;
  cardTagText?: string;
  cardId?: string;
  cardType?: number;
  cardName?: string;
  groupList?: {
    cardType?: number;
    isDefault?: number;
    priceInfo?: {
      deliveryPrice?: number;
      deliveryPriceText?: string;
      discountPrice?: number;
      discountPriceText?: string;
      eatInPrice?: number;
      eatInPriceText?: string;
      priceShowStyle?: number;
      separatePrice?: number;
      separatePriceText?: string;
      takeOutPrice?: number;
      takeOutPriceText?: string;
    };
    productType?: number;
    promotionInfo?: {
      allowedQty?: number;
      cardId?: string;
      cardType?: number;
      couponCode?: string;
      couponId?: string;
      hasRight?: boolean;
      pmtType?: number;
      promotionId?: string;
      withOrder?: boolean;
    };
    showName?: string;
    subProductCode?: string;
  }[];
};

export type MenuListCategoryItem = {
  categoryCode: string;
  categoryName: string;
  productList: MenuListProductItem[];
  categoryTags: string[];
  mcCafeFlag: false;
  hasSkin: false;
  showSkin: {
    isShow: number;
    showMaxQuantity: number;
  };
};

export type MenuListItem = MenuListCategoryItem & {
  categories: MenuListCategoryItem[];
  image: string;
  // TODO unknown
  banners: any[];
};

export type MenuListData = {
  menu: MenuListItem[];
  enableExclusive: boolean;
  userInfo: {
    headImg: string;
    membershipFlag: number;
  };
};

export type PromotionCouponsInfo = {
  code: string;
  name: string;
  shortName: string;
  telephone: null;
  distance: number;
  distanceText: null;
  address: string;
  latitude: number;
  longitude: number;
  cityCode: string;
  cityName: string;
  communityQrcode: string;
  deliveryFeeCode: string;
  duration: null;
  deliveryTime: null;
  estimatedDeliveryTime: null;
  hotTagCodes: string[];
  businessHours: null;
  dayparts: {
    daypartCode: number;
    daypartName: string;
    startTime: string;
    endTime: string;
    daypartFlag: boolean;
  }[];
  startTime: string;
  endTime: string;
  businessStatus: number;
  beCode: null;
  beType: null;
  isActive: boolean;
  tags: string[];
  businessStartDay: string;
  newStore: number;
  newStoreEndDate: string;
  newBe: null;
  newBeEndDate: null;
  welcomeMsg: string;
  isDt: null;
  isCollected: null;
  tips: null;
  needPop: null;
  popText: null;
  onlineBusinessStatus: boolean;
  storeFrontPic: null;
  storePictures: null;
  orderChannelCodes: string[];
  datetime: string;
  qrCodeTitle: string;
  qrCodeDesc: string;
  qrCodeDescImg: string;
  qrCodeBadgeImg: string;
  needBanner: boolean;
  hasKitchenVideo: boolean;
  kitchenVideoTitle: string;
  date: string;
  time: string;
};

export type PromotionCouponsCardDiscountItem = {
  /** 按钮图片 */
  btnImg?: string;
  /** 按钮跳转链接 */
  btnUrl?: string;
  /** 优惠券券码 */
  code?: string;
  /** 优惠券ID */
  id?: string;
  /** 优惠券图标 */
  image?: string;
  multipleProducts?: true;
  productCodes?: string[];
  /** 促销ID */
  promotionId?: string;
  /** 优惠券副标题 */
  subtitle?: string;
  /** 使用时间范围 */
  timeRange?: string;
  /** 优惠券标题 */
  title?: string;
};

export type PromotionCouponsCardMenuDiscount = {
  /** 背景图片 */
  bgImage?: string;
  /** 优惠券列表 */
  discountItems?: PromotionCouponsCardDiscountItem[];
  /** 模块图片 */
  moduleImage?: string;
  retainCouponStyle?: {
    /** 券剩余天数 */
    remainDays?: number;
    retainCoupon?: {
      code?: string;
      id?: string;
      image?: string;
      title?: string;
      tradeEndDate?: string;
      tradeStartDate?: string;
      tradeStartToEndDate?: string;
    };
    /** 挽留提示信息 */
    retainSubTitle?: string;
    /** 挽留Titel */
    retainTitle?: string;
  };
  /** 用户可用优惠券总数 */
  totalCouponCount?: number;
};

export type PromotionCouponsCardType = {
  banner?: {
    homeClickTrack?: null;
    image?: string;
    remainDay?: null;
    rightCardType?: null;
    title?: string;
    type?: number;
    url?: string;
  };
  cards?: {
    bubble?: {
      bubbleImg?: string;
      button?: string;
      buttonBackColor?: string;
      buttonColor?: string;
      buttonImg?: string;
      buttonUrl?: string;
      number?: string;
      numberColor?: string;
      text?: string;
      textColor?: string;
      /** 气泡类型 1=纯文字样式 2=续卡样式 */
      type?: number;
    };
    cardBackImg?: string;
    cardId?: string;
    cardNo?: string;
    /** 券列表 */
    coupons?: {
      couponCode?: string;
      couponId?: string;
      couponImg?: string;
      couponName?: string;
      promotionId?: string;
      tradeDateText?: string;
    }[];
    personImg?: string;
    /** 权益卡名称 */
    rightCardName?: string;
    /** 权益卡类型 权益卡类型：0-OH麦卡；1-麦乐送卡 2=早餐卡 3=麦咖啡月享卡 6=宝贝卡 */
    rightCardType?: number;
    /** 权益卡文字图片 */
    titleImg?: string;
  }[];
  couponLoyat?: {
    /** 按钮文案 */
    buttonText?: string;
    /** 可领券的数量 */
    couponAmount?: number;
    /** 文案 */
    hintTip?: string;
    /** 前端动作类型：1 去领取，2 去使用 */
    showAction?: string;
    /** 前端展示类型 1 按钮+文案 ，2 单行文案 */
    showStyle?: string;
  };
  /** 菜单页 可用券数量 有卡有券才展示 */
  couponNum?: number;
  /** 小图标  菜单页的小图标 */
  icon?: string;
  /** 会员信息模块跳转地址 */
  jumpUrl?: string;
  /** 小图标  首页麦乐送宫格小图标 */
  mdsIcon?: string;
  menuDiscount?: PromotionCouponsCardMenuDiscount;
  retainCouponStyle?: {
    /** 券剩余天数 */
    remainDays?: number;
    retainCoupon?: {
      code?: string;
      id?: string;
      image?: string;
      title?: string;
      tradeEndDate?: string;
      tradeStartDate?: string;
      tradeStartToEndDate?: string;
    };
    /** 挽留提示信息 */
    retainSubTitle?: string;
    retainTitle?: string;
  };
};

export type ProductSearchData = {
  maxPurchaseQuantity: number;
  productList: MenuListProductItem[];
  cardId: string;
  cardType: number;
};

export type StoreDaypartItem = {
  daypartCode: number;
  daypartName: string;
  // '05:00:00';
  startTime: string;
  // '13:29:59';
  endTime: string;
  daypartFlag: boolean;
};

export type StoreItem = {
  code: string;
  name: string;
  shortName: string;
  telephone?: string;
  distance: number;
  distanceText: string;
  address: string;
  latitude: number;
  longitude: number;
  cityCode: string;
  cityName: string;
  communityQrcode: string;
  deliveryFeeCode: string;
  duration: null;
  deliveryTime: string | null;
  estimatedDeliveryTime: string | null;
  hotTagCodes?: string[];
  businessHours: {
    dayPartList?: {
      businessHour?: { endTime?: string; startTime?: string };
      dayPartCode?: number;
      dayPartNameCn?: string;
      dayPartNameEn?: string;
    }[];
    endTime?: string;
    startTime?: string;
    weekday?: string;
  }[];
  dayparts: StoreDaypartItem[];
  startTime: string;
  endTime: string;
  businessStatus: number;
  beCode: string | null;
  beType: string | null;
  isActive: true;
  tags: { label?: string; value?: string }[];
  businessStartDay?: string;
  newStore: number;
  newStoreEndDate: null;
  newBe: null;
  newBeEndDate: null;
  welcomeMsg: null;
  isDt: null;
  isCollected: boolean;
  tips: null;
  needPop: null;
  popText: null;
  onlineBusinessStatus: boolean;
  storeFrontPic: null;
  storePictures: null;
  orderChannelCodes: null;
  originDistance?: number;
  originDistanceText?: string;
};

export type StoresVicinityData = {
  datetime: string;
  mdsStore?: StoreItem[];
  pickupStore?: StoreItem[];
  stores?: StoreItem[];
};

export type ProductInfoCustomizationOption = {
  /** 是否选中:1-是;0-否;(默认值通常为1) */
  checked?: number;
  code?: string;
  /** 特制显示方式: 1.平铺选项, 2.勾选要不要 */
  displayMode?: number;
  /** 特制图片路径 */
  image?: string;
  /** 是否变更: 0-否;1-是; */
  isModify?: number;
  maxQuantity?: number;
  minQuantity?: number;
  /** 特制方式: 1:固定选项; 2:自定义数量 */
  mode?: number;
  /** 特制名称，如：冰量 */
  name?: string;
  /** 价格,单位分。(暂时用不到) */
  price?: number;
  /** 特制默认数量 */
  quantity?: number;
  /** 是否售罄 */
  soldOut?: boolean;
};

export type ProductInfoCustomizationItemValueItem = {
  /** 是否选中(替代 isDefault 默认项): 1-是;0-否 */
  checked?: number;
  code?: string;
  image?: string;
  /** 是否变更: 0-否;1-是; */
  isModify?: number;
  /** 特制选项名称, 如：标准、去冰、少冰、多冰 */
  name?: string;
  /** 特制价格，以分为单位 */
  price?: number;
  /** 特制选项数量 */
  quantity?: number;
  /** 特调选中时的图片 */
  selectedImage?: string;
  /** 差价 */
  spread?: string;
  /** 特调未选中时的图片 */
  unselectedImage?: string;
};

export type ProductInfoCustomizationItem = {
  code?: string;
  /** 特制显示方式: 1.平铺选项, 2.勾选要不要 */
  displayMode?: number;
  /** 特制图片路径 */
  image?: string;
  /** 是否变更: 0-否;1-是 */
  isModify?: number;
  /** 是否多选:0-单选;1-多选;(目前都是单选) */
  isMultiple?: number;
  /** 特制最大可设数量 */
  maxQuantity?: number;
  /** 特制最小可设数量 */
  minQuantity?: number;
  /** 特制方式: 1:固定选项; 2:自定义数 */
  mode?: number;
  /** 特制名称，如：冰量 */
  name?: string;
  /** 特制默认数量 */
  quantity?: number;
  /** 是否售罄 */
  soldOut?: boolean;
  /** (单选类型)特调选项 */
  values?: ProductInfoCustomizationItemValueItem[];
};

export type ProductInfoCustomization = {
  /** 餐品特制(定制)信息 */
  options: ProductInfoCustomizationOption[];
  /** 特制选项列表(单选类型，中台叫平铺选项类型) */
  items: ProductInfoCustomizationItem[];
};

export type ProductPromotionInfo = {
  cardId?: string;
  /** 是否有权益 */
  hasRights?: boolean;
  /** 会籍id */
  membershipCode?: string;
  /** 付费会员名称 */
  membershipName?: string;
  /** 0 无促销  1 卡券 2 促销 3 付费会员 */
  pmtType?: number;
  /** 促销id */
  promotionId?: string;
  /** 是否随单购 */
  withOrder?: boolean;
};

export type ProductTag = {
  /** 颜色 */
  color?: string;
  /** 标签图片 */
  image?: string;
  /** 限定条件 */
  limit?: string[];
  /** 标签名称 */
  name?: string;
  /** 标签样式(1:实心标签，2:线框标签，3:图片标签) */
  style?: number;
};

export type PromotionTag = {
  name?: string;
  /** 促销类型名称 */
  title?: string;
  /** 1 权益卡 2 促销 3 普通券 */
  type?: number;
};

export type ProductRightInfo = {
  /** 标签-后部 */
  backTag?: string;
  banner?: string;
  /** banner上价格色值 */
  bannerPriceColor?: string;
  /** 按钮色值 */
  buttonColor?: string;
  /** 按钮上字体色值 */
  buttonFontColor?: string;
  /** 按钮图片 */
  buttonImg?: string;
  /** 按钮文字 */
  buttonText?: string;
  /** 权益卡名称 */
  cardName?: string;
  /** 权益卡类型 */
  cardType?: number;
  desc?: string;
  /** 过期文案 */
  expire?: string;
  /** 标签-字体色值 */
  fontColor?: string;
  /** 标签-前部 */
  frontTag?: string;
  /** 标签-中部 */
  middleTag?: string;
  /** 权益价 */
  price?: number;
  /** 查看权益文本 */
  rightsText?: string;
  /** 查看权益url */
  rightsUrl?: string;
  /** 规格标签背景色 */
  specTagBgColor?: string;
  /** 规格标签字体颜色 */
  specTagFontColor?: string;
  /** 规格标签文本 */
  specTagText?: string;
  /** banner上的图片 */
  tagImage?: string;
};

export type ProductInfoComboProductType = {
  alcPrice?: number;
  bu?: number;
  code?: string;
  /** 特制选项列表(单选类型，中台叫平铺选项类型) */
  customization?: ProductInfoCustomization | null;
  /** 是否可特制:1-是;0-否; */
  customizationMode?: number;
  /** 与默认项的差价，单位分，用于计算 */
  diffPrice?: number;
  /** 与默认项的差价，单位元，用于展示 */
  diffPriceText?: string;
  /** 与原始默认项的差价，单位分，用于计算 */
  gapPrice?: number;
  /** 是否可特制/定制: 0-否;1-是; */
  hasCustomization?: number;
  image?: string;
  /** 是否默认项: 0-否;1-是 */
  isDefault?: number;
  /** 餐品名称 */
  name?: string;
  /** 餐品原价格, 单位分 */
  originalPrice?: number;
  /** 餐品价格 */
  price?: number;
  /** 餐品价格(用于展示) */
  priceText?: string;
  /** 商品促销 */
  promotionId?: string;
  promotionInfo?: ProductPromotionInfo;
  /** 商品促销最大可用数量 */
  promotionLimitNum?: number;
  /** 促销标签 */
  promotionTags?: PromotionTag[];
  /** 动态套餐-默认数量 */
  quantity?: number;
  /** 皮肤小图 for kids  sok */
  skinSmallImage?: string;
  /** 餐品促销价格 */
  smartPrice?: number;
  /** 商品标签列表 */
  tags?: ProductTag[];
  /** 餐品类型 */
  type?: number;
};

export type ProductInfoComboItem = {
  /** 可选组编码 */
  choicesCode?: string;
  /** 套餐构成分类: 1-主食;2-小食;3-饮料;4-玩具;5-配料 */
  classification?: number;
  /** 套餐组成项(可选餐品) */
  comboProducts?: ProductInfoComboProductType[];
  /** 是否包含成本: 0-否;1-是 */
  costInclusive?: number;
  /** 排序 */
  idx?: number;
  /** 是否可选项: 0-否;1-是 */
  isChoices?: number;
  /** 是否在已包含round中展示: 0-否;1-是 */
  isIncludeRound?: number;
  /** 动态套餐-最大数量 */
  maxQuantity?: number;
  /** 动态套餐-最小数量 */
  minQuantity?: number;
  name?: string;
  /** 动态套餐-默认数量 */
  quantity?: number;
  /** 轮次 */
  round?: number;
};

export type ProductInfo = {
  code: string;
  /** 餐品类型 */
  type: number;
  name: string;
  image: string;
  bu: number;
  alcPrice?: number;
  /** 餐品原价格, 单位分 */
  originalPrice?: number;
  /** 餐品价格 */
  price?: number;
  /** 餐品价格(用于展示) */
  priceText: string;
  /** 餐品完整名称 */
  longName: string;
  desc: string;
  customization?: ProductInfoCustomization;
  /** 是否可特制:1-是;0-否; */
  customizationMode: number;
  links?: { title?: string; type?: number; url?: string }[];
  /** 套餐信息(套餐里的组成项) */
  comboItems?: ProductInfoComboItem[];
  promotionId?: string;
  promotionInfo?: ProductPromotionInfo;
  /** 商品促销最大可用数量 */
  promotionLimitNum?: number;
  promotionTags?: PromotionTag[];
  tags?: ProductTag[];
  rightInfo?: ProductRightInfo;
  skinSmallImage?: string;
  smartPrice?: number;
  matchedSpecsCode?: string;
};

export type ProductSpecType = {
  /** 规格类型编码 */
  code?: string;
  /** 规格类型名称 */
  name?: string;
  /** 规格类型下规格(值)列表 */
  specs?: {
    code?: string;
    name?: string;
    product?: ProductInfo;
    /** 选中图片 */
    selectedImage?: string;
    /** 差价 */
    spread?: string;
    /** 未选中图片 */
    unselectedImage?: string;
  }[];
};

export type ProductData = {
  product: ProductInfo & {
    /** 餐品组(规格->规格值->餐品) */
    specTypes?: ProductSpecType[];
    products?: ProductInfo[];
  };
  /** 最大可购买数量 */
  maxPurchaseQuantity: number;
  /** 是否调用价格计算 */
  calcPrice: boolean;
};

export type PoisGeocodeItem = {
  address: string;
  cityCode?: string | null;
  cityName?: string | null;
  latitude: number;
  longitude: number;
  name: string;
};

export type MemberAddressItem = {
  address?: string;
  channel?: string;
  cityCode?: string;
  cityName?: string;
  defaultAddress?: string;
  detail?: string;
  displayFullText?: string;
  fullName?: string;
  /** 性别: 0-未知; 1-male; 2-female; */
  gender: number;
  id: string;
  latitude?: number;
  longitude?: number;
  phone?: string;
  streetAddress?: string;
  tags?: string;
};

export type DaypartsTimeOption = {
  date?: string;
  dateText?: string;
  daypartOptions?: {
    daypartCode?: number;
    daypartName?: string;
    timeOptions?: {
      time?: string;
      timeText?: string;
    }[];
  }[];
};

export type PromotionCouponProductsPriceInfo = {
  deliveryPrice?: number;
  deliveryPriceText?: string;
  discountPrice?: number;
  discountPriceText?: string;
  eatInPrice?: number;
  eatInPriceText?: string;
  priceShowStyle?: number;
  separatePrice?: number;
  separatePriceText?: string;
  takeOutPrice?: number;
  takeOutPriceText?: string;
};

export type PromotionCouponProductsType = {
  cardImg?: string;
  cardName?: string;
  cardText?: string;
  /** 是否预付费券:0-否;1-是; */
  isPrepaid?: number;
  maxPurchaseQuantity: number;
  multipleProducts: boolean;
  /** 权益卡类型：0-OH麦卡；1-麦乐送卡 2=早餐卡 3=麦咖啡月享卡 6=宝贝卡 */
  rightCardType?: number;
  productList?: {
    categoryCodeList?: string;
    count?: number;
    /** 默认套餐组成文案 */
    defaultComboItemsText?: string;
    groupList: {
      isDefault?: number;
      priceInfo: PromotionCouponProductsPriceInfo;
      showName?: string;
      subProductCode?: string;
    }[];
    /** 是否可选项: 0-否;1-是; */
    isChoices?: number;
    maxPurchaseQuantity?: number;
    /** 餐品备注 */
    memo?: string;
    priceInfo: PromotionCouponProductsPriceInfo;
    productCode?: string;
    productDesc?: string;
    productImage?: string;
    productLongName?: string;
    productName?: string;
    productTagList: string[];
    /** 产品类型 1：单品 2：套餐 3：产品组 4: 外送费 */
    productType?: number;
    /** 之前状态是否可售:0-否，不可售;1-是，可售; */
    saleStatus?: number;
    /** 是否支持打开详情页进行更新套餐Choice:0-不打开;1-打开; */
    supportChoice?: number;
    tagImg?: string;
  }[];
};
