/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback, useEffect, useRef, useState } from 'react';
import type { FC, CSSProperties, ReactEventHandler, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper } from 'antd-mobile';
import UserInfoView from './components/UserInfoView';
import OrderDetailInfo from './components/OrderDetailInfo';
import HomeActivity from './components/HomeActivity';
import { fetchHomeConfig, fetchOrderInfo, fetchMoreActivity, fetchHotActivity } from '@/apis/home';
import type { BannerItem, CategoryItemDetail, ActivityItem, OrderInfo } from '@/apis/home';
import type { GroupsItem } from '@/apis/home.type';
import useNav from '@/hooks/useNav';
import useTitle from '@/hooks/useTitle';
import './styles.less';
import styles from '../Product/Banner/banner.module.less';
import classNames from 'classnames';
import { getCDNAssets } from '@/utils';
import useLocationInfo from '@/hooks/useLocationInfo';
import { trackEvent } from '@/utils/tracker';
type BannerVideoProps = React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> & {
  type?: string;
  wrapperClassName?: string;
  wrapperStyle?: CSSProperties;
  showPlayButton?: boolean;
};

// 默认上海
const defaultCityCode = '310100';

const BannerVideo = (props: BannerVideoProps) => {
  const {
    wrapperClassName,
    wrapperStyle,
    type = 'video/mp4',
    showPlayButton,
    onPlay: propsOnPlay,
    onEnded: propsOnEnded,
    ...rest
  } = props;
  const ref = useRef<HTMLVideoElement>(null);
  const [showPlayBtn, setShowPlayBtn] = useState(false);

  const playClick = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.stopPropagation();

    if (ref.current) {
      ref.current.pause();
      ref.current.currentTime = 0;
      ref.current.load();
    }
  }, []);

  const onVideoPlay = useCallback<ReactEventHandler<HTMLVideoElement>>(
    (e) => {
      if (showPlayButton) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const el = e.target as any;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (el?.currentTime !== el?.duration) {
          setShowPlayBtn(false);
        }
      }
      propsOnPlay?.(e);
    },
    [propsOnPlay, showPlayButton],
  );
  const onVideoEnded = useCallback<ReactEventHandler<HTMLVideoElement>>(
    (e) => {
      if (showPlayButton) {
        setShowPlayBtn(true);
      }
      propsOnEnded?.(e);
    },
    [propsOnEnded, showPlayButton],
  );

  return (
    <div className={classNames(styles.wrapperClassName)} style={wrapperStyle}>
      <video ref={ref} playsInline onPlay={onVideoPlay} onEnded={onVideoEnded} {...rest}>
        <source src={rest.src} type={type} />
      </video>
      {showPlayBtn && (
        <div className={styles.bannerVideoPlatBtn} style={{ bottom: '100px' }} onClick={playClick}>
          <img
            className={styles.bannerVideoPlatBtnIcon}
            style={{ width: '14px', height: '14px' }}
            src={getCDNAssets('home_banner_replay.png')}
          />
          <div className={styles.bannerVideoPlatBtnTxt}>重新播放</div>
        </div>
      )}
    </div>
  );
};

const HomePage = () => {
  useTitle('首页');
  const navigate = useNavigate();
  const { locationAndCityInfo } = useLocationInfo();
  const nav = useNav();
  const [bannerList, setBannerList] = useState<BannerItem[]>([]);
  const [bigCategoryList, setBigCategoryList] = useState<CategoryItemDetail[]>([]);
  const [activityList, setActivityList] = useState<GroupsItem[]>([]);
  const [greeting, setGreeting] = useState<string>('');
  const [nonLoginDesc, setNonLoginDesc] = useState<string>('');
  const [memberBackImg, setMemberBackImg] = useState<string>('');

  const [orderInfo, setOrderInfo] = useState<OrderInfo>();

  const getInitData = useCallback((cityCode: string) => {
    fetchHomeConfig(cityCode)
      .then((res) => {
        const { categoryList, banner, activity } = res || {};
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        setBannerList(banner?.list!);
        const bigCategoryListData = (categoryList || []).find(({ templateId }) => templateId === 3);
        if (bigCategoryListData && bigCategoryListData.list && bigCategoryListData.list.length > 0) {
          setBigCategoryList(bigCategoryListData.list);
        }
        // if (activity && activity.count) {
        //   setActivityList(activity.list);
        // }
        res?.greeting && setGreeting(res.greeting);
        res?.nonLoginDesc && setNonLoginDesc(res.nonLoginDesc);
        res?.memberBackImg && setMemberBackImg(res.memberBackImg);
      })
      .catch((err) => {
        console.log('err', err);
      });

    // fetchMoreActivity({ cityCode })
    //   .then((res) => {
    //     const { list, count } = res || {};
    //     if (count && list) {
    //       setActivityList(list);
    //     }
    //   })
    //   .catch(() => {});

    fetchHotActivity({ cityCode })
      .then((res) => {
        const { groups } = res || {};
        setActivityList(groups || []);
      })
      .catch(() => {});
  }, []);

  const getOrderInfo = useCallback(() => {
    fetchOrderInfo()
      .then((res) => {
        res?.orderInfo && setOrderInfo(res.orderInfo);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    const { city, loading } = locationAndCityInfo || {};
    if (!loading) {
      getInitData(city?.code || defaultCityCode);
    }
  }, [locationAndCityInfo, getInitData]);

  useEffect(() => {
    getOrderInfo();
  }, []);

  const handleClickBanner = useCallback(
    (url: string) => {
      url && nav(url);
    },
    [nav],
  );
  const handletoPage = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate],
  );
  const [autoplay, setAutoplay] = useState(true);

  const onVideoPlay = useCallback<ReactEventHandler<HTMLVideoElement>>((e) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const el = e.target as any;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (el?.currentTime !== el?.duration) {
      setAutoplay(false);
    }
  }, []);
  const onVideoEnded = useCallback(() => {
    setAutoplay(true);
  }, []);

  const handleLoginSuccess = useCallback(() => {
    getOrderInfo && getOrderInfo();
  }, [getOrderInfo]);

  return (
    <div className="home-page">
      <div className="banner">
        <Swiper autoplay={autoplay} loop={true} indicator={() => null}>
          {(bannerList || []).map((item, idx) => {
            const { image, url, videoFlag } = item || {};
            trackEvent('operationExpose', {
              belong_page: '首页',
              module_name: 'banner',
              rank: idx + 1,
              module_rank: 2,
              Operation_bit_name: item.title,
            });
            return (
              <Swiper.Item key={idx}>
                <div
                  className="item"
                  onClick={() => {
                    trackEvent('operationClick', {
                      belong_page: '首页',
                      module_name: 'banner',
                      rank: idx + 1,
                      module_rank: 2,
                      Operation_bit_name: item.title,
                    });
                    handleClickBanner(url);
                  }}>
                  {videoFlag ? (
                    <BannerVideo
                      controls={false}
                      autoPlay
                      muted
                      className={styles.swiperItemVideo}
                      showPlayButton
                      src={image}
                      onPlay={onVideoPlay}
                      onEnded={onVideoEnded}
                    />
                  ) : (
                    <img src={image} />
                  )}
                </div>
              </Swiper.Item>
            );
          })}
        </Swiper>
      </div>
      <div className="user">
        <UserInfoView
          greeting={greeting}
          nonLoginDesc={nonLoginDesc}
          memberBackImg={memberBackImg}
          onLoginSuccess={handleLoginSuccess}
        />
      </div>

      <div className="big-category">
        <div className="category-wrapper">
          {bigCategoryList.map((i, idx) => {
            const { type, iconImg, image, title, subTitle, subTitleFontColor, titleFontColor } = i || {};
            return (
              <div
                onClick={() => {
                  // TODO 临时使用
                  const urlMap = {
                    mds: '/product?orderType=2',
                    pickup: '/product?orderType=1',
                  };
                  const url = urlMap[type as keyof typeof urlMap];
                  trackEvent('iconClick', {
                    belong_page: '首页',
                    module_name: '宫格入口',
                    rank: 1 + idx,
                    icon_name: title,
                    url: url,
                  });
                  handletoPage(url);
                }}
                key={idx}
                className="item"
                style={{ backgroundImage: `url(${image})` }}>
                <div className="home_big_item_title_view">
                  <span className="home_big_item_title" style={{ color: titleFontColor }}>
                    {title}
                  </span>
                  <img className="home_big_item_icon" src={iconImg} />
                </div>
                <span className="home_big_item_subTitle" style={{ color: subTitleFontColor }}>
                  {subTitle}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      {orderInfo && <OrderDetailInfo data={orderInfo} />}
      {(activityList || []).length > 0 ? (
        <div className="activity">
          <HomeActivity groups={activityList} handleClick={handleClickBanner} />
          {/* {activityList.map((item, idx) => {
          const { image, url } = item || {};
          trackEvent('operationExpose', {
            belong_page: '首页',
            module_name: '热门活动',
            rank: idx + 1,
            module_rank: 5,
            Operation_bit_name: item.title ? item.title : item.subTitle,
          });
          return (
            <div
              key={idx}
              className="item"
              onClick={() => {
                trackEvent('operationClick', {
                  belong_page: '首页',
                  module_name: '热门活动',
                  rank: idx + 1,
                  module_rank: 5,
                  Operation_bit_name: item.title ? item.title : item.subTitle,
                });
                handleClickBanner(url);
              }}>
              <img src={image} />
            </div>
          );
        })} */}
        </div>
      ) : null}
    </div>
  );
};

export default HomePage;
