import type { CSSProperties, FC } from 'react';
import { useMemo } from 'react';
import classNames from 'classnames';

import type { ProductSpecType } from '@/apis/product/types';

import styles from './styles/combogroup.module.less';

export type ComboGroupRadioProps = {
  className?: string;
  style?: CSSProperties;
  item: ProductSpecType;
  matchedSpecsCode?: string;
  /** ['17-1', '17-2', '17-3'] or ['17-1,18-1', '17-1,18-2', '17-2,18-1', '17-2,18-2'] */
  activedSpecsGroupCodes?: string[];
  haveMonthEnojyCard?: boolean;
  onSelect?: (code: string, item: ProductSpecType, child: NonNullable<ProductSpecType['specs']>[0]) => void;
};

const ComboGroupRadio: FC<ComboGroupRadioProps> = (props) => {
  const { className, style, item, matchedSpecsCode, activedSpecsGroupCodes, haveMonthEnojyCard, onSelect } = props;

  const activedSpecsCodes = useMemo(() => {
    if (matchedSpecsCode && activedSpecsGroupCodes) {
      const _specTypeCode = matchedSpecsCode.split('-')[0];
      return activedSpecsGroupCodes.map((c) => c.split(',').find((cc) => cc.indexOf(_specTypeCode) === 0));
    }

    return activedSpecsGroupCodes;
  }, [activedSpecsGroupCodes, matchedSpecsCode]);

  const [activeSpecs, soldOutSpecs] = useMemo(() => {
    const _specs = item?.specs?.filter((s) => s?.code);
    const _code = item.code;

    if (_code && _specs && _specs.length > 0 && activedSpecsCodes) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const _a1 = _specs.filter((s) => activedSpecsCodes.includes(`${_code}-${s.code!}`));
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const _a2 = _specs.filter((s) => !activedSpecsCodes.includes(`${_code}-${s.code!}`));

      return [_a1, _a2];
    }

    return [[], _specs];
  }, [activedSpecsCodes, item.code, item?.specs]);

  if (!item) {
    return null;
  }
  return (
    <div className={classNames(className)} style={style}>
      <div className={styles.name}>{item.name}</div>
      <div className={styles.itemBox}>
        {activeSpecs?.map((i) => {
          const _code = (item.code && i.code && `${item.code}-${i.code}`) || '';
          const isSelected = _code && _code === matchedSpecsCode;
          const hasSpread = item.specs?.length === 2 && !haveMonthEnojyCard && i.spread;

          return (
            <div
              key={`ComboGroupRadioItem${String(item.code)}${String(i.code)}`}
              className={styles.item}
              data-selected={isSelected}
              onClick={() => onSelect?.(_code, item, i)}>
              <div className={styles.itemText} data-spread={hasSpread}>
                {i.name}
              </div>
              {hasSpread && <span className={styles.itemSpread}>{i.spread}</span>}
            </div>
          );
        })}
        {soldOutSpecs?.map((i) => {
          return (
            <div
              key={`ComboGroupRadioItem${String(item.code)}${String(i.code)}`}
              className={styles.item}
              data-disbaled="true">
              <div className={styles.itemText}>{i.name}</div>
              <div className={styles.itemSoldOut}>售罄</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ComboGroupRadio;
