import type { FC, PropsWithChildren } from 'react';
import type { AxiosInstance } from 'axios';
import { RequestProvider } from '@axios-use/react';
import { ConfigProvider } from 'antd-mobile';
import zhCN from 'antd-mobile/es/locales/zh-CN';

import requestCtx from '@/apis/request';

const MCDProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <ConfigProvider locale={zhCN}>
      {/* [TODO] axios ins faker */}
      <RequestProvider instance={requestCtx as unknown as AxiosInstance}>{children}</RequestProvider>
    </ConfigProvider>
  );
};

export default MCDProvider;
