import React, { useCallback, useEffect, useMemo, useRef, useState, forwardRef } from 'react';
import { SwipeAction, Dialog, Button, Ellipsis, SearchBar } from 'antd-mobile';

import { useNavigate } from 'react-router-dom';

import arrowUrl from '@/assets/arrow.png';

import type { CityInfo } from '@/apis/address';
import { SELECT_CITY } from '@/constants/path';
import './index.less';

interface IProps {
  cityInfo: Partial<CityInfo>;
  searchBarPlaceholder?: string;
  onFocus?: () => void;
  onChange?: (keyword: string) => void;
  onSearch?: (keyword: string) => void;
  onChangeCity?: () => void;
}

const AddressSearchBar = forwardRef<React.HTMLProps<IProps>, IProps>((props: IProps, ref) => {
  const { cityInfo, searchBarPlaceholder, onFocus, onSearch, onChangeCity, onChange } = props;

  const navigate = useNavigate();

  const handleFocus = useCallback(() => {
    onFocus && onFocus();
  }, [onFocus]);
  const handleChange = useCallback(
    (val: string) => {
      onChange && onChange(val);
    },
    [onChange],
  );

  const { code, name: cityName = '' } = cityInfo || {};
  return (
    <div className="search-wrapper" ref={ref as React.LegacyRef<HTMLDivElement>}>
      <div
        className="left"
        onClick={() => {
          if (onChangeCity) {
            onChangeCity();
            return;
          }
          navigate(SELECT_CITY);
        }}>
        <Ellipsis content={code ? cityName : '请选择城市'} />
        <img src={arrowUrl} />
      </div>
      <div className="search">
        <SearchBar
          placeholder={searchBarPlaceholder || '搜索路名/小区/写字楼/学校等'}
          style={{ '--background': '#ffffff' }}
          onFocus={handleFocus}
          onSearch={(keyword) => {
            onSearch && onSearch(keyword);
          }}
          onChange={handleChange}
        />
      </div>
    </div>
  );
});

AddressSearchBar.displayName = 'AddressSearchBar';

export default AddressSearchBar;
