import type { CSSProperties, FC, PropsWithChildren } from 'react';
import { useMemo, useDeferredValue, useEffect } from 'react';
import classNames from 'classnames';

import styles from './popup.module.less';

const canIUseDocBody = typeof document !== 'undefined' && Boolean(document?.body);

export type SinglePopupProps = {
  className?: string;
  style?: CSSProperties;
  bodyClassName?: string;
  bodyStyle?: CSSProperties;
  visible?: boolean;
  destroyOnClose?: boolean;
  onMaskClick?: () => void;
};

const SinglePopup: FC<PropsWithChildren<SinglePopupProps>> = (props) => {
  const { className, style, bodyClassName, bodyStyle, visible, destroyOnClose, onMaskClick, children } = props;

  const deferredVisible = useDeferredValue(visible);

  const childrenNode = useMemo(() => {
    if (destroyOnClose) {
      if (visible) {
        return children;
      } else {
        return null;
      }
    }
    return children;
  }, [children, destroyOnClose, visible]);

  useEffect(() => {
    if (canIUseDocBody) {
      if (deferredVisible) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
    return () => {
      if (canIUseDocBody) {
        document.body.style.overflow = '';
      }
    };
  }, [deferredVisible]);

  return (
    <div data-visible={visible} className={classNames(styles.wrapper, className)} style={style}>
      <div className={styles.popupMask} onClick={onMaskClick} />
      <div data-visible={deferredVisible} className={classNames(styles.popupBody, bodyClassName)} style={bodyStyle}>
        {childrenNode}
      </div>
    </div>
  );
};

export default SinglePopup;
