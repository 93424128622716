import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState, AppDispatch } from '@/store/';
import { getServerOrderConfirm, updateOrderConfimData, updateOrderConfirmDataAsync } from '@/store/orderConfirmSlice';
import type { ConfirmInfoResponseVo_, Carts } from '@/apis/orderConfirm.type';

import type { OrderConfirmationParams } from '@/apis/orderConfirm';

export const useOrderConfirmInfoInit = (params: Partial<OrderConfirmationParams>) => {
  const [OrderConfirmState, status] = useOrderConfimState();

  const dispatch = useDispatch<AppDispatch>();
  const checkOrderConfimState = useCallback(() => {
    const { dayPartCode, storeCode, cartType, orderType, ...restParams } = params || {};
    if (!OrderConfirmState && status === 'idle' && params && orderType && dayPartCode && storeCode && cartType) {
      void dispatch(getServerOrderConfirm({ dayPartCode, storeCode, cartType, orderType, ...restParams }));
    }
  }, [OrderConfirmState, params, dispatch, status]);

  useEffect(() => {
    checkOrderConfimState();
  }, [checkOrderConfimState]);
};

export const useOrderConfimState = () => {
  const { data, status } = useSelector((s: RootState) => s.orderConfirm);
  return [data, status] as const;
};

/**
 * 购物车修改与清除
 */
export function useOrderConfirmAction() {
  const dispatch = useDispatch<AppDispatch>();

  const updateOrderConfirmDataBySwitch = useCallback(
    (body: ConfirmInfoResponseVo_) => {
      return dispatch(updateOrderConfimData(body));
    },
    [dispatch],
  );

  const updateOrderConfirmData = useCallback(
    (body: Carts) => {
      return dispatch(updateOrderConfirmDataAsync(body));
    },
    [dispatch],
  );

  return { updateOrderConfirmDataBySwitch, updateOrderConfirmData };
}
