import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import fromPairs from 'lodash/fromPairs';

const useQuery = <T extends Record<string, string | string[]> = Record<string, string | string[]>>(
  initQuery?: T,
): T | undefined => {
  const [params] = useSearchParams(initQuery);
  const [query, seQuery] = useState<T | undefined>(fromPairs(Array.from(params.entries())) as T);

  useEffect(() => {
    const current = fromPairs(Array.from(params.entries())) as T;
    seQuery(current);
  }, [params]);

  return query;
};

export default useQuery;
