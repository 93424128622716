import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';

interface IProps {
  expirePayTime: string;
  onEnd?: () => void;
}

interface RestTime {
  y: number;
  M: number;
  d: number;
  h: number;
  ms: number;
  m: number;
  s: number;
}

const useCountDown = ({ expirePayTime, onEnd }: IProps) => {
  const [restTime, setRestTime] = useState<RestTime>();
  const [isShow, setIshow] = useState<boolean>(false);
  const timerRef = useRef<number>();
  const calcRestTime = useCallback(
    (endTime: string) => {
      if (moment(endTime).valueOf() > moment().valueOf()) {
        setIshow(true);
        const duration = moment.duration(moment(endTime).diff(moment()));
        const y = duration.get('y');
        const M = duration.get('M');
        const d = duration.get('d');
        const h = duration.get('h');
        const m = duration.get('m');
        const s = duration.get('s');
        const ms = duration.get('ms');
        setRestTime({
          y,
          M,
          d,
          h,
          m,
          s,
          ms,
        });
        //   @ts-ignore
        timerRef.current = setTimeout(() => {
          calcRestTime(endTime);
        }, 1000);
      } else {
        timerRef.current && clearTimeout(timerRef.current);
        setIshow(false);
        onEnd && onEnd();
      }
    },
    [onEnd],
  );

  useEffect(() => {
    expirePayTime && calcRestTime(expirePayTime);
    if (!expirePayTime) {
      setIshow(false);
      timerRef.current && clearTimeout(timerRef.current);
    }
  }, [calcRestTime, expirePayTime]);

  return { restTime, isShow };
};

export default useCountDown;
