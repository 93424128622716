import { useCallback, useMemo, useRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { useResource, useRequest } from '@axios-use/react';
import type { DeliveryDateInfoType } from '@/store/productSlice';
import { useStore } from '@/hooks/useStore';
import type { MenuBizCodeObj, GetStoreMenuQuery, GetMenuBannerQuery, GetMenuRightCardInfoQuery } from '@/apis/product';
import type { StoreDaypartItem, StoreItem } from '@/apis/product/types';
import {
  getNearByStoreReqConfig,
  getStoreByStoreCodeReqConfig,
  getMenuBannerReqConfig,
  getStoreMenuReqConfig,
  getMenuRightCardInfoReqConfig,
  getMemberAddressDetail,
  getPoisGeocodeListReqConfig,
} from '@/apis/product';
import { getLocationInfo } from '@/utils';

import { useStoreDateInfoWithLocation, useStoreNeedUseCacheWithLocation } from './external';
import { EMenusPageErrStatus } from './Error/types';

const getStoreErrStatus = (store: StoreItem) => {
  if (store.businessStatus !== 1) {
    return EMenusPageErrStatus.REST;
  }
  const { dayparts } = store;
  if (dayparts && Array.isArray(dayparts)) {
    const _curDaypart = dayparts.find((i) => i.daypartFlag) || dayparts.find((i) => i.daypartFlag);
    if (_curDaypart && _curDaypart.startTime && _curDaypart.endTime) {
      const { startTime, endTime } = _curDaypart;
      const _now = dayjs();
      const _todayDate = _now.format('YYYY-MM-DD');
      const _start = dayjs(`${_todayDate} ${startTime}`);
      const _end = dayjs(`${_todayDate} ${endTime}`);

      if (_start.isValid() && _end.isValid()) {
        if (_end.isAfter(_start) && _now.isAfter(_end)) {
          return EMenusPageErrStatus.REST;
        }
      } else {
        return EMenusPageErrStatus.REST;
      }
    }
  }

  return undefined;
};

const checkStore = (store?: StoreItem) => {
  if (store && !getStoreErrStatus(store)) {
    return true;
  }
  return false;
};

/**
 * 经纬度与 storeCode 不同时出现
 */
export type ProductQuery = {
  orderType?: number;
  storeCode?: string;
  addressId?: string;
} & MenuBizCodeObj;

export function useStoreInfo(query?: ProductQuery, curDateInfo?: DeliveryDateInfoType) {
  const { orderType = 1, storeCode = '', addressId = '', isGroupMeal, beType } = query || {};
  const {
    storeInfo: storeCache,
    localCacheStoreCode,
    newStoreCode,
    updateStore,
    newAddressId,
    deliveryAddressInfo,
    curLocatioon: curLocationCache,
    updateDeliveryAddress,
    updateDeliveryCurLocation,
    clearExternalStoreData,
  } = useStore(orderType);

  const { canuseStoreCache, turnOnCacheStatus } = useStoreNeedUseCacheWithLocation(orderType);

  const curStoreCode = storeCode || newStoreCode || storeCache?.code || localCacheStoreCode || undefined;
  const curAddressId = addressId || newAddressId || deliveryAddressInfo?.id;

  const blockStoreReq = canuseStoreCache && storeCache?.code === curStoreCode;
  const blockAddressReq = canuseStoreCache && deliveryAddressInfo?.id === curAddressId;

  const [fetchLoading, setFetchLoading] = useState(true);
  const [fetchError, setFetchError] = useState<EMenusPageErrStatus>();

  const [createPoisGeocodeListReq, poisGeocodeListReqReqState] = useRequest(getPoisGeocodeListReqConfig);
  const [createMemberAddressDetailReq, memberAddressDetailReqState] = useRequest(getMemberAddressDetail);

  const onStoreCompletedNext = useCallback(
    (storeInfo?: StoreItem) => {
      updateStore(storeInfo);
      turnOnCacheStatus();
      clearExternalStoreData();
    },
    [clearExternalStoreData, turnOnCacheStatus, updateStore],
  );

  const [storesVicinityRes, fetchStoresVicinity] = useResource(getNearByStoreReqConfig, false, {
    cache: false,
    onCompleted: (data) => {
      if (!data) {
        setFetchError(EMenusPageErrStatus.SUSPEND);
      }

      onStoreCompletedNext(data?.stores?.[0]);
    },
  });
  const vicinityStatusCode = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    () => (storesVicinityRes?.response as any)?.code as number | undefined,
    [storesVicinityRes?.response],
  );

  const deliveryRun = useCallback(
    async (id?: string) => {
      try {
        setFetchLoading(true);
        if (id) {
          const [_address] = await createMemberAddressDetailReq(id).ready();
          if (_address && _address.latitude && _address.longitude) {
            updateDeliveryAddress(_address);
            // 根据当前地址获取周边商店
            fetchStoresVicinity({
              longitude: _address.longitude,
              latitude: _address.latitude,
              orderType,
              isGroupMeal,
              beType,
              addressId: id,
            });
            fetchError && setFetchError(undefined);

            return;
          } else {
            updateDeliveryAddress(undefined);
            // 无效地址信息
          }
        }

        const _upsdkLocation = await getLocationInfo();
        if (!(_upsdkLocation && _upsdkLocation.latitude != null && _upsdkLocation.longitude != null)) {
          throw new Error('Invalid location');
        }
        const [geocodeList] = await createPoisGeocodeListReq({
          longitude: _upsdkLocation.longitude,
          latitude: _upsdkLocation.latitude,
        }).ready();
        const _firstLocation = geocodeList?.[0];
        updateDeliveryCurLocation(_firstLocation);
        if (_firstLocation && _firstLocation.latitude && _firstLocation.longitude) {
          fetchStoresVicinity({
            longitude: _firstLocation.longitude,
            latitude: _firstLocation.latitude,
            orderType,
            isGroupMeal,
            beType,
          });
          fetchError && setFetchError(undefined);
        } else {
          setFetchError(EMenusPageErrStatus.NO_NEARBY);
        }
      } catch (error) {
        console.error('[useStoreInfo]-deliveryRun err:', id, error);
        setFetchError(EMenusPageErrStatus.LOCATION_ERR);
      } finally {
        setFetchLoading(false);
      }
    },
    [
      beType,
      createMemberAddressDetailReq,
      createPoisGeocodeListReq,
      fetchError,
      fetchStoresVicinity,
      isGroupMeal,
      orderType,
      updateDeliveryAddress,
      updateDeliveryCurLocation,
    ],
  );
  const deliveryRunRef = useRef(deliveryRun);
  deliveryRunRef.current = deliveryRun;

  const [createThatStoreReq, thatStoreReqState] = useRequest(getStoreByStoreCodeReqConfig);

  const pickupRun = useCallback(
    async (code?: string) => {
      try {
        setFetchLoading(true);
        if (code) {
          const [thatStoreRes] = await createThatStoreReq({ storeCode: code, isGroupMeal, beType }).ready();
          if (checkStore(thatStoreRes) || storeCode) {
            onStoreCompletedNext(thatStoreRes);

            return;
          }
        }

        const _upsdkLocation = await getLocationInfo();
        if (!(_upsdkLocation && _upsdkLocation.latitude != null && _upsdkLocation.longitude != null)) {
          throw new Error('Invalid location');
        }

        fetchStoresVicinity({
          latitude: _upsdkLocation.latitude,
          longitude: _upsdkLocation.longitude,
          orderType,
          isGroupMeal,
          beType,
        });
      } catch (error) {
        console.error('[useStoreInfo]-pickupRun err:', code, error);
        setFetchError(EMenusPageErrStatus.LOCATION_ERR);
      } finally {
        setFetchLoading(false);
      }
    },
    [beType, createThatStoreReq, fetchStoresVicinity, isGroupMeal, onStoreCompletedNext, orderType, storeCode],
  );
  const pickupRunRef = useRef(pickupRun);
  pickupRunRef.current = pickupRun;

  const refreshStoreOrAddressInfo = useCallback(() => {
    if (orderType === 1) {
      void pickupRunRef.current(curStoreCode);
    } else if (orderType === 2) {
      void deliveryRunRef.current(curAddressId);
    }
  }, [curAddressId, curStoreCode, orderType]);

  useEffect(() => {
    if (orderType === 1) {
      if (!blockStoreReq) {
        void pickupRunRef.current(curStoreCode);
      } else {
        setFetchLoading(false);
      }
    }
  }, [blockStoreReq, curStoreCode, orderType]);
  useEffect(() => {
    if (orderType === 2) {
      if (!blockAddressReq) {
        void deliveryRunRef.current(curAddressId);
      } else {
        setFetchLoading(false);
      }
    }
  }, [blockAddressReq, curAddressId, orderType]);

  const [currentStore, currentStoreCode, daypart, daypartCode] = useMemo(() => {
    const _findDaypartItem = (is?: StoreDaypartItem[]): StoreDaypartItem | undefined => {
      if (is) {
        const _default = is.find((i) => i.daypartFlag);
        if (orderType === 2 && curDateInfo?.daypartCode) {
          const _d = is.find((i) => i.daypartCode === curDateInfo?.daypartCode);
          return _d || _default;
        }
        return _default;
      }
      return undefined;
    };

    const _currentStore = storeCache;
    const _daypart = _findDaypartItem(_currentStore?.dayparts);
    return [_currentStore, _currentStore?.code, _daypart, _daypart?.daypartCode];
  }, [curDateInfo?.daypartCode, orderType, storeCache]);

  const [addressInfo, tempLocation] = useMemo(() => {
    if (orderType === 2) {
      if (deliveryAddressInfo) {
        return [deliveryAddressInfo, undefined];
      }
      if (curLocationCache) {
        return [undefined, curLocationCache];
      }
    }
    return [undefined, undefined];
  }, [curLocationCache, deliveryAddressInfo, orderType]);

  const loading =
    fetchLoading ||
    storesVicinityRes.isLoading ||
    (orderType === 2
      ? poisGeocodeListReqReqState.hasPending || memberAddressDetailReqState.hasPending
      : thatStoreReqState.hasPending);

  const curErrStatusForStore = useMemo<EMenusPageErrStatus | false>(() => {
    if (fetchError) {
      return fetchError;
    }

    if (currentStore) {
      if (orderType === 1 && !currentStore.onlineBusinessStatus) {
        return EMenusPageErrStatus.SUSPEND;
      }

      if (!(orderType === 2 && curDateInfo?.date && curDateInfo.daypartCode && curDateInfo.time)) {
        const _err = getStoreErrStatus(currentStore);
        if (_err) {
          return _err;
        }
      }
    }

    if (daypartCode == null) {
      return EMenusPageErrStatus.REST;
    }

    return false;
  }, [
    curDateInfo?.date,
    curDateInfo?.daypartCode,
    curDateInfo?.time,
    currentStore,
    daypartCode,
    fetchError,
    orderType,
  ]);

  return {
    loading,
    curErrStatusForStore,

    currentStore,
    currentStoreCode,
    daypart,
    daypartCode,
    vicinityStatusCode,

    addressInfo,
    tempLocation,

    refreshStoreOrAddressInfo,
  };
}

/**
 *
 * 经纬度与 storeCode 不同时出现
 *
 * @param query
 */
export default function useProductData(query?: ProductQuery) {
  const { orderType = 1, isGroupMeal } = query || {};

  const { queryAppointmentStr, curDateInfo, updateDeliveryDateInfo } = useStoreDateInfoWithLocation(orderType);

  const {
    loading: storeOrAddressLoading,
    curErrStatusForStore,

    currentStore,
    currentStoreCode,
    daypart,
    daypartCode,
    vicinityStatusCode,

    addressInfo,
    tempLocation,

    refreshStoreOrAddressInfo,
  } = useStoreInfo(query, curDateInfo);

  const activeStore = useMemo(
    () => Boolean(currentStore && !curErrStatusForStore),
    [currentStore, curErrStatusForStore],
  );

  const [{ data: menuList, isLoading: menuListLoaing }, , refreshMenuList] = useResource(
    getStoreMenuReqConfig,
    [
      {
        storeCode: currentStoreCode,
        orderType,
        dayPartCode: daypartCode,
        isGroupMeal,
        beCode: currentStore?.beCode,
        beType: currentStore?.beType,
        date: curDateInfo?.date,
        time: curDateInfo?.time,
      } as GetStoreMenuQuery,
      undefined,
      storeOrAddressLoading,
    ],
    {
      filter: (p, c, l) => l === false && activeStore && Boolean(p && p.storeCode && p.orderType && p.dayPartCode),
      cacheKey: `menuList${currentStoreCode || ''}${orderType}${daypartCode || ''}`,
    },
  );

  const [{ data: menuBanner, isLoading: menuBannerLoaing }, fetchMenuBanner] = useResource(
    getMenuBannerReqConfig,
    [
      {
        storeCode: currentStoreCode,
        orderType,
        daypartCode,
        isGroupMeal,
        beCode: currentStore?.beCode,
        beType: currentStore?.beType,
      } as GetMenuBannerQuery,
      undefined,
      storeOrAddressLoading,
    ],
    {
      filter: (p, c, l) => l === false && activeStore && Boolean(p.storeCode && p.orderType && p.daypartCode),
      cacheKey: `menuBanner${currentStoreCode || ''}${orderType}${daypartCode || ''}`,
    },
  );
  const [
    { data: menuRightCardInfo, isLoading: menuRightCardInfoLoaing },
    fetchMenuRightCardInfo,
    refreshMenuRightCardInfo,
  ] = useResource(
    getMenuRightCardInfoReqConfig,
    [
      {
        storeCode: currentStoreCode,
        orderType,
        daypartCode,
        isGroupMeal,
        scene: '2',
        beCode: currentStore?.beCode,
        beType: currentStore?.beType,
        date: curDateInfo?.date,
        time: curDateInfo?.time,
      } as GetMenuRightCardInfoQuery,
      undefined,
      storeOrAddressLoading,
    ],
    {
      filter: (p, c, l) => l === false && activeStore && Boolean(p.storeCode && p.orderType && p.daypartCode),
      cacheKey: `menuRightCardInfo${currentStoreCode || ''}${orderType}${daypartCode || ''}`,
    },
  );

  const errorStatus = useMemo(() => {
    if (activeStore) {
      if (!storeOrAddressLoading && menuListLoaing === false && isEmpty(menuList?.menu)) {
        return EMenusPageErrStatus.EMPTY_MENUS;
      }
    }

    return curErrStatusForStore;
  }, [activeStore, curErrStatusForStore, menuList?.menu, menuListLoaing, storeOrAddressLoading]);

  const baseLoading = storeOrAddressLoading || menuListLoaing;

  return {
    activeStore,
    errorStatus,
    vicinityStatusCode,
    addressInfo,
    tempLocation,
    storeInfo: currentStore,
    refreshStoreOrAddressInfo,
    daypart,
    daypartCode,
    baseLoading,
    storeLoading: storeOrAddressLoading,
    menuList,
    menuListLoaing,
    refreshMenuList,
    menuBanner,
    menuBannerLoaing,
    fetchMenuBanner,
    menuRightCardInfo,
    menuRightCardInfoLoaing,
    fetchMenuRightCardInfo,
    refreshMenuRightCardInfo,

    queryAppointmentStr,
    curDateInfo,
    updateDeliveryDateInfo,
  };
}
