import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SwipeAction, Dialog, Button, Ellipsis, SearchBar } from 'antd-mobile';
import cls from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '@/store/';
import { updatePlace, setSelectCityTag, SelectCityTag } from '@/store/addressSlice';
import useEditing from '@/hooks/useEditing';
import Header from '@/components/Header';
import arrowUrl from '@/assets/arrow.png';
import AddressFooter from '@/components/AddressFooter';

import AddressSearchBar from '@/components/AddressSearchBar';
import AddressPlaceList from '@/components/AddressPlaceList';
import { searchAddressPOIInfo } from '@/apis/address';
import type { Place, SearchAddressParams } from '@/apis/address';
import { EDIT_ADDRESS, SELECT_CITY } from '@/constants/path';
import useTitle from '@/hooks/useTitle';
import './index.less';

const defaultCityInfo = {
  code: '310100',
  hasStores: 1,
  isHotCity: 1,
  latitude: 31.230525,
  longitude: 121.473667,
  name: '上海市',
};

const SearchPlace = () => {
  useTitle('搜索地址');

  const placeInfo = useSelector((state: RootState) => state.address.place);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [placeList, setPlaceList] = useState<Place[]>([]);
  const [selectIdx, setSelectIdx] = useState<number>();
  const [keyword, setKeyword] = useState<string>();

  const contentRef = useRef<HTMLDivElement>();
  const searchRef = useRef<HTMLDivElement>();

  const { isEditing } = useEditing();

  const searchPlace = useCallback((p: SearchAddressParams) => {
    // if (cityInfo && cityInfo.name && keyword) {
    // }
    searchAddressPOIInfo({ ...p })
      .then((res) => {
        setPlaceList(res || []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const init = useCallback(() => {
    // searchPlace('成山');
  }, []);
  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (placeInfo?.cityName) {
      searchPlace({
        cityName: placeInfo?.cityName,
        keyword,
      });
    }
  }, [placeInfo, keyword]);

  const calcPlaceListHeight = useCallback(() => {
    if (contentRef.current && searchRef.current) {
      return contentRef.current?.clientHeight - searchRef.current?.clientHeight;
    }
  }, [contentRef, searchRef]);

  const canComplete = useMemo(() => selectIdx || selectIdx === 0, [selectIdx]);

  const handleClick = useCallback(() => {
    const selectPlace = placeList[selectIdx as number];
    dispatch(updatePlace(selectPlace));
    // navigate(EDIT_ADDRESS);
    isEditing ? navigate(-1) : navigate(EDIT_ADDRESS);
  }, [selectIdx, placeList, dispatch, navigate, isEditing]);

  const { cityCode, cityName } = placeInfo || {};

  return (
    <div className="search-place-container">
      {/* <Header title="搜索地址" /> */}
      <div className="content" ref={contentRef as React.LegacyRef<HTMLDivElement>}>
        <AddressSearchBar
          cityInfo={{ code: cityCode, name: cityName }}
          // @ts-ignore
          ref={searchRef}
          onChangeCity={() => {
            dispatch(setSelectCityTag(SelectCityTag.address));
            navigate(SELECT_CITY);
          }}
          onSearch={(val) => {
            setKeyword(val);
          }}
        />

        <div className="place-container">
          <AddressPlaceList
            placeList={placeList}
            height={calcPlaceListHeight()}
            selectIdx={selectIdx}
            onSelect={(idx: number) => {
              setSelectIdx(idx);
            }}
          />
        </div>
      </div>
      <AddressFooter text={isEditing ? '保存地址' : '完善收货地址'} disabled={!canComplete} handleClick={handleClick} />
    </div>
  );
};

export default SearchPlace;
