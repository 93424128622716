import { useCallback, useEffect, useRef, useState } from 'react';
import './index.less';
import Header from '@/components/Header';
import { fetchCardTabs } from '@/apis/cardlist';
import { getCDNAssets } from '@/utils';
import type { RightCardInfo, ProductCouponInfo, FiltersItem, CouponsItem, CardsItem } from '@/apis/cardlist';
import useTitle from '@/hooks/useTitle';

const CardListPage = () => {
  useTitle('卡片权益');
  const [rightCardInfo, setRightCardInfo] = useState<RightCardInfo>();
  const [productCouponInfo, setProductCouponInfo] = useState<ProductCouponInfo>();
  const [filtersList, setFiltersList] = useState<FiltersItem[]>([]);
  const [couponsList, setCouponsList] = useState<CouponsItem[]>([]);
  const [cardsList, setCardsList] = useState<CardsItem[]>([]);
  const [tabCardTitle, setTabCardTitle] = useState<string>('');
  const [tabCouponTitle, setTabCouponTitle] = useState<string>('');
  const [tradeTime, setTradeTime] = useState<string>('');
  const [countnum, setCountnum] = useState<string>('');
  const [imgWidth, setImgWidth] = useState(0);
  const showBtm = true;
  const showInstructions = true;
  const showCouponCardBottom = true,
    multipleDonateChoice = false;
  const isLarger = window.innerWidth >= 390;

  const getCardlist = useCallback(() => {
    fetchCardTabs()
      .then((res) => {
        console.log('getCardlist', res);
        if (res) {
          const { rightCardType: rightCard, productCouponType: productCoupon } = res || {};
          rightCard && setRightCardInfo(rightCard);
          productCoupon && setProductCouponInfo(productCoupon);
          rightCard && setTabCardTitle(getTitle(rightCard?.name, rightCard?.count));
          productCoupon && setTabCouponTitle(getTitle(productCoupon?.name, productCoupon?.count));
          setFiltersList(productCoupon?.filters);
          setCouponsList(productCoupon?.coupons);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const getTitle = (name: string, count: string) => {
    const titleStr: string = name + '(' + count + ')';
    return titleStr;
  };
  const renderDiscountTitle = (card: CardsItem) => {
    const discountTips = (card?.reducePriceText?.indexOf('$') > 0 && card?.reducePriceText?.split('$')) || [];
    const txtRedTips = <span className={'discount-price'}>{card?.reducePrice}</span>;

    if (discountTips?.length > 0) {
      return (
        <div className={`discount-title`}>
          {discountTips.map((itemTips: any, itemIndex: number) => {
            return (
              <span key={itemIndex}>
                {itemTips}
                {/*最后一个不展示*/}
                {itemIndex < discountTips.length - 1 ? txtRedTips : ''}
              </span>
            );
          })}
        </div>
      );
    } else {
      return <div>{discountTips}</div>;
    }
  };
  const getTradeTime = (startTime: string, endTime: string) => {
    return setTradeTime(tradeTime + ' ' + startTime + '-' + endTime);
  };
  useEffect(() => {
    getCardlist();
  }, []);
  return (
    <div>
      {/* <Header title="卡片权益" /> */}
      <div className="index">
        <div className="content-view">
          <div>
            {couponsList.map((coup, idx: number) => {
              const { coupons } = coup || {};
              return (
                <div key={idx}>
                  {coupons.map((card, i) => {
                    const { tags } = card || {};
                    const imgBg = getCDNAssets(
                      card?.orderType === 1 ? 'user_bg_coupon_pickup.png' : 'user_bg_coupon_delivery.png',
                    );
                    if (card.tradeTimePeriods) {
                      card.tradeTimePeriods.forEach((item) => {
                        const startTime = item.startTime.split(':')[0] + ':' + item.startTime.split(':')[1];
                        const endTime = item.endTime.split(':')[0] + ':' + item.endTime.split(':')[1];
                        // getTradeTime(startTime, endTime);
                      });
                    }
                    const startTrade = card.tradeStartDate ? card.tradeStartDate.replace(/-/g, '.') : '';
                    const endTrade = card?.tradeEndDate ? card?.tradeEndDate.replace(/-/g, '.') : '';

                    if (card.totalCount > 1) {
                      // setCountnum(card.countText);
                      // card.countTextBinds?.map((item) => {
                      //   setCountnum(countnum.replace(/\{\d+\}/, item));
                      // });
                    }
                    return (
                      <div className="group-coupon-index-outside" key={i}>
                        <div className="group-coupon-index" style={{ width: 'calc(100vw - 46Px)' }}>
                          <img className="group-coupon-bg" src={imgBg} />
                          <div
                            className="group-coupon-content"
                            style={{
                              background: !showBtm ? 'linear-gradient(to right,#FFFFFF, #f9f9f9)' : '#fff',
                            }}>
                            {/*商品图*/}
                            <img
                              className="group-coupon-content-img"
                              src={card?.image}
                              style={{ marginLeft: isLarger ? '10px' : '0px' }}
                            />
                            {/*详情*/}
                            <div
                              className="group-coupon-content-text"
                              style={{ marginLeft: isLarger ? '20px' : '10px' }}>
                              {/*标签*/}
                              {tags && (
                                <div className="group-coupon-label">
                                  {tags.map((item, tidx) => {
                                    if (item.type === 3) {
                                      return (
                                        <span
                                          key={tidx}
                                          className="tag-text"
                                          style={{
                                            backgroundColor: item.color,
                                            borderColor: item.color,
                                            color: '#fff',
                                          }}>
                                          {item.label}
                                        </span>
                                      );
                                    }
                                    if (item.type === 1) {
                                      return (
                                        <img
                                          src={item.image}
                                          style={{ width: imgWidth.toString() + 'px' }}
                                          className="tag-img"
                                        />
                                      );
                                    }
                                    if (item.type === 2) {
                                      return (
                                        <span
                                          className="tag-text"
                                          style={{ color: item.color, borderColor: item.color }}>
                                          {item.label}
                                        </span>
                                      );
                                    }
                                  })}
                                </div>
                              )}
                              {/*标题*/}
                              <div className="group-coupon-title">{card.title}</div>
                              {!!card.reducePriceText && renderDiscountTitle(card)}
                              {/*副标题*/}
                              <span className="group-coupon-subtitle">{card.subtitle}</span>
                              {/*日期*/}
                              <div className="group-coupon-date">
                                {startTrade}-{endTrade} {tradeTime}
                              </div>
                              {/*次数*/}
                              {!!countnum && <span className="group-coupon-count">{countnum}</span>}
                              {showInstructions ? (
                                <div
                                  className="group-coupon-detail"
                                  style={countnum ? { marginTop: '2px' } : { marginTop: '10px' }}>
                                  <span className="group-coupon-detail-text">使用说明</span>
                                  <img className="group-coupon-detail-arrow" src={getCDNAssets('jdarrowblack.png')} />
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {card.channelIconUrls && card.channelIconUrls.length > 0 && (
                            <div className={'group-coupon-type-angle'}>
                              <div className={'group-coupon-type-angle-inside'} />
                            </div>
                          )}
                          {card.channelIconUrls && card.channelIconUrls.length > 0 && (
                            <div className={'group-coupon-type'}>
                              {card.channelIconUrls.map((item, index) => {
                                return (
                                  <img
                                    key={index}
                                    className={'group-coupon-type-image'}
                                    style={{ marginLeft: index === 0 ? '0' : '3Px' }}
                                    src={item}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </div>
                        {!!countnum && showCouponCardBottom && (
                          <div className="group-coupon-bottom" style={{ width: 'calc(100vw - 55Px)' }}>
                            <img
                              className="group-coupon-bottom-img"
                              src={getCDNAssets('user_coupon_bottom_left.png')}
                            />
                            <img
                              className="group-coupon-bottom-mid"
                              src={getCDNAssets('user_coupon_bottom_middle.png')}
                            />
                            <img
                              className="group-coupon-bottom-img"
                              src={getCDNAssets('user_coupon_bottom_right.png')}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardListPage;
