import { getDefaultAPIDomain } from '@/apis/request';
import { CONFIG_DISABLED_EVENT_TRACKER, getBooleanConfig } from '@/hooks/uesAppConfig';
import sensors from 'sa-sdk-javascript';

export const initTracker = () => {
  if (getBooleanConfig(CONFIG_DISABLED_EVENT_TRACKER, false)) {
    return;
  }

  // tracker server url
  const apiDomain = getDefaultAPIDomain() || '';
  let server = '';
  if (apiDomain.indexOf('api.mcd.cn') > -1) {
    server = 'https://tracking.mcdonalds.com.cn/sa?project=CMA';
  } else {
    server = 'https://tracking.mcdonalds.com.cn/sa?project=default';
  }

  sensors.init({
    name: 'sensors',
    server_url: server,
    // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
    is_track_single_page: true,
    use_client_time: true,
    send_type: 'beacon',
    heatmap: {
      //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
      clickmap: 'not_collect',
      //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
      scroll_notice_map: 'not_collect',
    },
  });

  sensors.registerPage({
    platform_type: 'unionpay',
    app_name: 'all in one',
  });

  sensors.quick('autoTrack');

  const time = new Date().toJSON().replace('T', ' ').replace('Z', '');
  sensors.setOnceProfile({
    first_visit_unionpay_time: time,
  });
};

export const trackEvent = (eventName: string, params: object) => {
  if (getBooleanConfig(CONFIG_DISABLED_EVENT_TRACKER, false)) {
    return;
  }

  try {
    sensors.track(eventName, params);
  } catch (error) {
    console.error('[tracker] track event err: %o', error);
  }
};
