import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Ellipsis, Toast } from 'antd-mobile';
import cls from 'classnames';
import { useNavigate } from 'react-router-dom';
import { star_normal, star_selected } from '@/constants';
import { updateStoreFav } from '@/apis/canteen';
import type { StoreItem, UpdateStoreFavParams, StoreTag_3 } from '@/apis/canteen';
import { CANTEEN_DETAIL } from '@/constants/path';
import arrowUrl from '@/assets/arrow2.png';

import './index.less';
import { trackEvent } from '@/utils/tracker';

interface IProps {
  list: StoreItem[];
  selectCode?: string;
  onSelect?: (idx: string) => void;
  onUpdateFav?: () => void;
  locationCityCode?: string;
  fromPage: string; // for event tracking
}

const StoreList: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const { list, selectCode, onSelect, onUpdateFav, locationCityCode, fromPage } = props || {};

  const handleUpdateFav = useCallback(
    (p: UpdateStoreFavParams) => {
      updateStoreFav({ ...p })
        .then((res) => {
          if (res?.storeCode) {
            onUpdateFav && onUpdateFav();
          }
        })
        .catch((e: Error) => {
          Toast.show(e.message || '操作失败');
        });
    },
    [onUpdateFav],
  );

  const goDetail = useCallback(
    (code: string) => {
      // TODO
      navigate(CANTEEN_DETAIL.replace(':id', code));
    },
    [navigate],
  );

  const calcLastSelectStoreCode = useCallback((code: string) => {
    const lastStoreCode = window.localStorage.getItem('lastStoreCode');
    return code === lastStoreCode;
  }, []);

  const renderTag = useCallback(
    (code: string, businessStatus: number, onlineBusinessStatus: boolean, tags: StoreTag_3[], cityCode: string) => {
      const isClose = businessStatus == 0;
      let tipString = '';

      if (isClose && !onlineBusinessStatus) {
        tipString = '暂停营业';
      } else if (isClose) {
        tipString = '休息中';
      } else if (calcLastSelectStoreCode(code)) {
        tipString = '最近浏览';
      } else if (tags && tags.length > 0) {
        const tagValue = tags[0].value;
        if (locationCityCode && locationCityCode == cityCode && tagValue == 'nearest') {
          tipString = tags[0].label!;
        }
      }

      const tipBgColor = {
        backgroundColor: isClose || (businessStatus == 0 && !onlineBusinessStatus) ? '#8E8E8E' : '#DB0007',
      };
      return tipString ? (
        <span className="tag" style={tipBgColor}>
          {tipString}
        </span>
      ) : null;
    },
    [],
  );

  //一小时内是否要打烊 copy from wx
  const getdiffTime = useCallback((endTime?: string) => {
    const date = new Date();
    const hour = parseInt(date.getHours().toString());
    const minute = parseInt(date.getMinutes().toString());
    const totalMinute = hour * 60 + minute;

    if (endTime) {
      const newData = endTime.split(':');
      if (newData && newData.length == 2) {
        const endHour = newData[0];
        const endMin = newData[1];
        const tomin = parseInt(endHour) * 60 + parseInt(endMin);
        const diff = tomin - totalMinute;
        if (diff <= 60 && diff > 0) {
          return true;
        }
      }
    }
    return false;
  }, []);

  return (
    <div className="store-place-list">
      {(list || []).map((item, idx) => {
        const {
          shortName,
          address,
          businessStatus,
          onlineBusinessStatus,
          startTime,
          endTime,
          code,
          distanceText,
          isCollected,
          tags,
          cityCode,
        } = item || {};
        const isSelected = code === selectCode;
        return (
          <div
            key={code}
            className={cls('item', { selected: isSelected, business: businessStatus })}
            onClick={() => {
              onSelect && onSelect(code!);
              trackEvent('selectRestaurant', {
                page_source: fromPage,
                is_default_restaurant: calcLastSelectStoreCode(code!),
                us_distance: item.distance,
                us_name: item.shortName,
                us_code: code,
              });
            }}>
            <div className="left">
              <div className="name">
                <div className="short-name-wrapper">
                  <Ellipsis className="short-name" content={shortName || ''} />
                  {renderTag(code!, businessStatus!, onlineBusinessStatus!, tags || [], cityCode!)}
                  {/* {calcLastSelectStoreCode(code!) && <span className="last-select">最近浏览</span>} */}
                </div>
                {/* {!businessStatus &&
                  (onlineBusinessStatus ? <span className="tag">休息中</span> : <span className="tag">暂停营业</span>)} */}
              </div>
              <div className="address">
                <Ellipsis content={address || ''} />
              </div>
              {!businessStatus && onlineBusinessStatus && <span className="business-time">{startTime}开始营业</span>}
              {businessStatus == 1 && getdiffTime(endTime) && (
                <span className="business-time">{endTime ? endTime + '结束营业' : ''}</span>
              )}
            </div>
            <div className="right">
              <div className="detail-info">
                <img
                  src={isCollected ? star_selected : star_normal}
                  onClick={(e: React.MouseEvent<HTMLImageElement>) => {
                    handleUpdateFav({ storeCode: code!, operationType: isCollected ? 2 : 1 });
                    e.stopPropagation();
                  }}
                />
                {businessStatus ? (
                  <span
                    className="go"
                    onClick={() => {
                      goDetail(code!);
                    }}>
                    <span>详情</span>
                    <img className="arrow" src={arrowUrl} />
                  </span>
                ) : null}
              </div>
              {locationCityCode === cityCode && (
                <div className="distance">{distanceText ? `距您${distanceText}` : ''}</div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StoreList;
