import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import JsBarcode from 'jsbarcode';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './index.less';
import { getCDNAssets } from '@/utils';
import { deliveryInfo, fetchOrderDetail } from '@/apis/order';
import type { Order, OrderActionVo, OrderProduct_ } from '@/apis/order';
import type { OrderDetail } from '@/apis/order.type';
import { getHeaderBg } from './utils';
import ProductModule from './components/ProductModule';
import OrderModule from './components/OrderModule';
import './index.less';

const OrderDetailPage = () => {
  const [searchParams] = useSearchParams();
  const [detail, setDetail] = useState<OrderDetail>();

  const getOrderDetail = useCallback(() => {
    const orderId = searchParams.get('orderId');
    if (orderId) {
      fetchOrderDetail(orderId)
        .then((res) => {
          res && setDetail(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [searchParams]);

  const init = useCallback(() => {
    getOrderDetail();
  }, []);

  useEffect(() => {
    init();
  }, []);

  const {
    mpOrderStatusCode,
    orderType,
    orderStatusTitleType,
    orderStatusTitle,
    channelTag,
    orderStatusSubTitle,
    storeName,
    orderProductList,
    totalDiscountAmount,
    realPayAmount,
    realTotalAmount,
  } = detail || {};

  const isCode = useMemo(() => orderStatusTitleType === 1, [orderStatusTitleType]);
  const isCabinet = useMemo(() => orderStatusTitleType === 2, [orderStatusTitleType]);

  return (
    <div className="detail-index">
      <div className="scrollview">
        {!isCabinet && (
          <>
            <div
              className="header-bg"
              style={{
                top: '0',
                backgroundImage: `url(${getCDNAssets(getHeaderBg(mpOrderStatusCode!, orderType))})`,
                backgroundSize: 'contain',
              }}>
              <div className="header-bg-title-view">
                <span
                  className="header-bg-title"
                  style={{
                    fontSize: !isCode ? '26Px' : '34Px',
                  }}>
                  {orderStatusTitle}
                </span>
                {isCode && (
                  <div className="header-bg-title-image">
                    <span className="header-bg-image">取餐码</span>
                  </div>
                )}
                {channelTag && (
                  <div className="header-channel-bg-title-image">
                    <span className="header-channel-bg-image">{channelTag}</span>
                  </div>
                )}
              </div>
              {orderStatusSubTitle && (
                <div className="header-bg-sub-view">
                  <img className="header-bg-sub-image" src={getCDNAssets('order_detail_icon_brand.png')} />
                  <span className="header-bg-subtitle">{orderStatusSubTitle}</span>
                </div>
              )}
            </div>
            <div className="scrollview-empty-header" />
          </>
        )}
        <div className="scrollview-list">
          <ProductModule
            storeName={storeName!}
            list={orderProductList || []}
            totalDiscountAmount={totalDiscountAmount!}
            realPayAmount={realPayAmount!}
            realTotalAmount={realTotalAmount!}
          />
        </div>
        <div className="scrollview-list" style={{ marginTop: '10px' }}>
          {/* <OrderModule info={detail}></OrderModule> */}
        </div>
      </div>
    </div>
  );
};
export default OrderDetailPage;
