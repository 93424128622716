import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { store } from './store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';

import { useViewHeight } from '@/hooks/useViewHeight';
import reportWebVitals from './reportWebVitals';
import { MCDProvider } from './context';
import routes from './routes';

import './styles/index.less';
import { initTracker } from '@/utils/tracker';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const App = () => {
  const routeElements = useRoutes(routes);
  useViewHeight();

  initTracker();

  // TODO page loading
  return <Suspense fallback={'...'}>{routeElements}</Suspense>;
};

root.render(
  <Provider store={store}>
    <MCDProvider>
      <Router>
        <App />
      </Router>
    </MCDProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
