import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Toast } from 'antd-mobile';
import type { RootState } from '@/store/';
import './index.less';
import { getCDNAssets } from '@/utils';
import { fetchUserInfo } from '@/apis/home';
import type { UserInfo } from '@/apis/home';
import useTitle from '@/hooks/useTitle';
import { copyText } from '@/utils';

const UserInfoPage = () => {
  useTitle('个人信息');
  const pointTxt = useSelector((state: RootState) => state.commonConfig.pointsText);
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const [useInfo, setUseInfo] = useState<UserInfo>();

  const getUserInfo = useCallback(() => {
    fetchUserInfo()
      .then((res) => {
        res && setUseInfo(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleGender = (item: any) => {
    //1 - 男 2 - 女
    return item ? (item === 1 ? '男' : '女') : '未完善';
  };
  useEffect(() => {
    if (isLogin) {
      getUserInfo();
    }
  }, [isLogin]);

  return (
    <div className="user_persional">
      {/* <Header title="个人信息" /> */}
      <div className="detail-sv">
        <div className="avatar">
          <img className="head-img" src={useInfo?.headImg || 'https://img.mcd.cn/gallery/0fc708ab0912a303.png'} />
        </div>
        <div className="item">
          <span className="label">昵称</span>
          <div className="value valueSingle">
            <span className="text">{useInfo?.fullName || '麦麦'}</span>
          </div>
        </div>
        <div className="item">
          <span className="label">性别</span>
          <div className="value valueSingle">
            <span className="text">{handleGender(useInfo?.gender)}</span>
          </div>
        </div>
        <div className="item">
          <span className="label">出生日期</span>
          {!useInfo?.birthDate && (
            <div className="value valueSingle">
              <span>未完善</span>
            </div>
          )}
          {useInfo?.birthDate && (
            <div className="value valueSingle">
              <div className="page-section">{useInfo?.birthDate}</div>
            </div>
          )}
        </div>
        <div className="item">
          <span className="label">联系电话</span>
          <div className="value valueSingle">
            <span className="text">{useInfo?.phoneNumber}</span>
          </div>
        </div>
        {useInfo?.meddyId && (
          <div className="item">
            <span className="label">会员ID</span>
            <div className={'value'}>
              <span className="text">{useInfo?.meddyId ?? ''}</span>
              <div className="icon">
                <img
                  src={getCDNAssets('order_icon_copy_gray.png')}
                  onClick={(e) => {
                    useInfo?.meddyId &&
                      copyText(
                        useInfo?.meddyId,
                        () => {
                          Toast.show('复制成功！');
                        },
                        () => {
                          Toast.show('复制失败！');
                        },
                      );
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="item">
          <span className="label">{pointTxt?.nameCn}</span>
          <div className="value valueSingle">
            <span className="text">{useInfo?.availablePoints}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserInfoPage;
