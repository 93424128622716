import React from 'react';
import { LocationFill } from 'antd-mobile-icons';
import './index.less';

interface IProps {
  isLocation?: boolean;
  handleClick?: () => void;
}

const CityBtn: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { isLocation, handleClick, children } = props;
  return (
    <div
      className="city-btn-wrapper"
      onClick={() => {
        handleClick && handleClick();
      }}>
      {isLocation ? <LocationFill /> : null}
      {children}
    </div>
  );
};

export default CityBtn;
