import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './index.less';
import { fetchCardAvailable } from '@/apis/cardlist';
import { getCDNAssets } from '@/utils';
import type { CouponsItem, CardsItem, AvailableCouponsItem } from '@/apis/cardlist';
import CommonCouponCard from '../components/CommonCouponCard';
import CouponItem from './components/couponItem';
import EmptyView from '../components/EmptyView';
import useTitle from '@/hooks/useTitle';
const CurCardListPage = () => {
  useTitle('可用优惠券');
  const [searchParams] = useSearchParams();
  const scene = searchParams.get('scene') || '';
  const beType = searchParams.get('beType') || '';
  const daypartCode = searchParams.get('daypartCode') || '';
  const storeCode = searchParams.get('storeCode') || '';
  const beCode = searchParams.get('beCode') || '';
  const orderType = searchParams.get('orderType') || '';
  const time = searchParams.get('time') || '';
  const cardId = searchParams.get('cardId') || '';
  const date = searchParams.get('date') || '';
  const rquestData = {
    scene,
    beType,
    daypartCode,
    storeCode,
    beCode,
    orderType,
    time,
    cardId,
    date,
  };
  const [couponsList, setCouponsList] = useState<AvailableCouponsItem[]>([]);
  const getCardlist = useCallback(() => {
    fetchCardAvailable(rquestData)
      .then((res) => {
        console.log('getAvailableCardlist', res);
        if (res) {
          const { couponlist } = res || {};
          setCouponsList(couponlist);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getCardlist();
  }, []);
  return (
    <div>
      <div className="curcardlist-page">
        <div className="product-coupon-view">
          {couponsList.map((item, idx: number) => {
            const { cardImageThumbUrl, cardImageUrl, totalText, coupons } = item || {};
            return (
              <div key={idx} className="coupon-item">
                <div className="top-wrapper">
                  <div className="top-banner" style={{ backgroundImage: `url(${cardImageUrl})` }}>
                    <div className="header">
                      <img src={cardImageThumbUrl}></img>
                      <span className="split-line"></span>
                      <span className="total-text">{totalText}</span>
                    </div>
                  </div>
                </div>
                <div className="coupon-wrapper">
                  <CouponItem coupons={coupons} />
                </div>
              </div>
            );
          })}
          {(!couponsList || (couponsList && couponsList.length === 0)) && <EmptyView></EmptyView>}
        </div>
      </div>
    </div>
  );
};
export default CurCardListPage;
