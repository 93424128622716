import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { ConfirmInfoResponseVo_, Carts, CartUpdateResponseVo_ } from '@/apis/orderConfirm.type';
import type { OrderConfirmationParams } from '@/apis/orderConfirm';
import { fetchOrderConfirmation, updateOrderConfirm } from '@/apis/orderConfirm';

interface OrderConfirmState {
  status: 'idle' | 'loading' | 'failed';
  data?: ConfirmInfoResponseVo_;
}

const initialState: OrderConfirmState = {
  status: 'idle',
  data: undefined,
};

export const getServerOrderConfirm = createAsyncThunk('orderConfirm/sync', async (params: OrderConfirmationParams) => {
  const res = await fetchOrderConfirmation(params).catch((err: Error) => {
    throw new Error(err.message);
  });
  return res;
});

export const updateOrderConfirmDataAsync = createAsyncThunk('orderConfirm/update', async (params: Carts) => {
  const res = await updateOrderConfirm(params).catch((err: Error) => {
    throw new Error(err.message);
  });
  return res;
});

export const orderConfirmSlice = createSlice({
  name: 'orderConfirm',
  initialState,
  reducers: {
    updateOrderConfimData: (state, action: PayloadAction<ConfirmInfoResponseVo_>) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServerOrderConfirm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getServerOrderConfirm.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload?.confirmInfo;
      })
      .addCase(getServerOrderConfirm.rejected, (state) => {
        state.status = 'failed';
      });

    builder
      .addCase(updateOrderConfirmDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOrderConfirmDataAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload?.confirmInfo;
      })
      .addCase(updateOrderConfirmDataAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { updateOrderConfimData } = orderConfirmSlice.actions;

export default orderConfirmSlice.reducer;
