import { useCallback, useEffect, useRef } from 'react';

export const canUseUpSDK = typeof upsdk !== 'undefined';

export type UpTitleStylesType = Parameters<UpsdkType['setTitleStyle']>[0] & {
  title?: string;
};

const defaultStyles: UpTitleStylesType = {};

/**
 *
 * @param options
 */
export function useSetTitleStyle(options?: UpTitleStylesType) {
  const setStyles = useCallback(
    (o?: UpTitleStylesType) => {
      const _options = o || options || defaultStyles;
      const { title = '', ...styles } = _options;

      if (canUseUpSDK) {
        upsdk.setNavigationBarTitle({ title });
        upsdk.setTitleStyle(styles);
      }
    },
    [options],
  );

  const setStylesRef = useRef(setStyles);
  useEffect(() => {
    setStylesRef.current = setStyles;
  }, [setStyles]);

  useEffect(() => {
    setStylesRef.current();

    // return () => {
    //   setStylesRef.current(defaultStyles);
    // };
  }, []);

  return setStyles;
}
