import type { FC } from 'react';

import { SearchOutline } from 'antd-mobile-icons';
import type { InputProps } from 'antd-mobile/es/components/input';
import { Input } from 'antd-mobile';

import './input.less';

export type InputSearchProps = Pick<
  InputProps,
  | 'style'
  | 'autoFocus'
  | 'pattern'
  | 'inputMode'
  | 'clearable'
  | 'placeholder'
  | 'disabled'
  | 'readOnly'
  | 'id'
  | 'maxLength'
  | 'minLength'
  | 'defaultValue'
  | 'value'
  | 'onChange'
  | 'onClear'
  | 'onFocus'
  | 'onBlur'
  | 'onClick'
  | 'onEnterPress'
  | 'enterKeyHint'
> & {
  className?: string;
};

const InputSearch: FC<InputSearchProps> = (props) => {
  const { className = '', ...rest } = props;

  return (
    <div className={`product-com-input-search ${className}`}>
      <span className="product-com-input-search-icon">
        <SearchOutline />
      </span>
      <Input
        style={{
          '--font-size': '14px',
          '--color': 'inherit',
          '--placeholder-color': '#999999',
        }}
        placeholder="请输入关键词"
        inputMode="search"
        clearable
        {...rest}
      />
    </div>
  );
};

export default InputSearch;
