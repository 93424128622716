import httpInstance from './request';
import { BIZ_CODE_100, BIZ_CODE_103 } from '@/constants/code';
import type { CommonResponse } from './request';
import type { OrderDetail } from './order.type';

const URLS = {
  ORDER_CATEGORIES: '/bff/order/orders/category', // 获取订单列表tabs
  GET_ORDERS: '/bff/order/orders', // 获取指定tab下订单列表信息，有分页
  GET_USERSELF_CONTENT: '/bff/ectrade/gift/own', // 自己使用
  GET_CONFIRM_RECEIPT_TIP: '/bff/ectrade/order/receive/apply', // 确认收货tips
  GET_CONFIRM_RECEIPT: '/bff/ectrade/order/receive/confirm', // 确认收货
  GET_REFUND_DETAIL: '/bff/order/orders', // 退款详情
  ORDER_DETAIL: '/bff/order/orders/',
  ORDER_CANCEL: '/bff/order/orders/{id}/cancellation',
};

// const getOrderCategories = (params) => httpRequest.get(URLS.ORDER_CATEGORIES, params);

export interface OrderCategory {
  greatGift?: OrderGreatGift;
  /**
   * 订单类别
   */
  list: OrderCategory[];
  /**
   * 查询订单时间提示语
   */
  queryOrderTimeTips: string;
}
export interface OrderGreatGift {
  /**
   * 超值好礼名称
   */
  name: string;
  /**
   * 超值好礼链接
   */
  url: string;
}
export interface OrderCategory {
  id?: number;
  name?: string;
  subs?: OrderSubCategory[];
}
export interface OrderSubCategory {
  name?: string;
  shopId?: number;
}

export interface OrderInfo {
  /**
   * 总数
   */
  count: number;
  /**
   * 列表
   */
  list: Order[];
  /**
   * 总页数
   */
  pages: number;
}
export interface Order {
  /**
   * beCode
   */
  beCode: string;
  /**
   * beType
   */
  beType: string;
  /**
   * 订单类别id，0全部订单、1到店取餐、2麦乐送
   */
  categoryId: number;
  /**
   * 订单类别name
   */
  categoryName: string;
  /**
   * 渠道
   */
  channel: string;
  /**
   * 渠道标签
   */
  channelTag?: string;
  /**
   * 下单时间
   */
  createTime?: string;
  /**
   * 到店取餐方式 1=堂食 2=外带  3=得来速 4=取餐柜 5=新得来速
   */
  eatType?: number;
  /**
   * 预计配送时间
   */
  estimateDeliveryTime?: string;
  /**
   * 期望支付时间
   */
  expirePayTime?: string;
  /**
   * 剩余支付秒数
   */
  leftPaySecond?: number;
  /**
   * 取餐柜码
   */
  lockerCode?: string;
  /**
   * 中台的订单状态code
   */
  mpOrderStatusCode: string;
  /**
   * 订单操作，pay去支付，contact_rider联系骑手，rider_position查看骑手位置，invoice开发票，comment去评价，another_one再来一单，cancel取消订单，refund退款，reminder催单，customer_service联系客服
   */
  orderActionList: OrderActionVo[];
  /**
   * 取消原因
   */
  orderCancelReasons?: string;
  /**
   * 订单id
   */
  orderId: string;
  /**
   * 订单商品
   */
  orderProductList: OrderProduct_[];
  /**
   * 订单状态
   */
  orderStatus: string;
  /**
   * 订单状态code，1待支付、2配餐中-已支付、4配送中、6已完成、7已取消、8已评价、10配餐中-餐厅确认配餐中
   */
  orderStatusCode: string;
  /**
   * 订单状态提示
   */
  orderStatusTips: string;
  /**
   * 订单类型
   */
  orderType: string;
  /**
   * 订单类型名
   */
  orderTypeName: string;
  /**
   * 支付单号id
   */
  payId: string;
  /**
   * 取餐码
   */
  pickupCode?: string;
  /**
   * 取餐等待分钟
   */
  pickupWaitMinute?: number;
  /**
   * 取餐等待订单数
   */
  pickupWaitOrder?: number;
  /**
   * 实际总金额
   */
  realTotalAmount: string;
  rider?: Rider;
  riderLocation?: RiderLocation;
  /**
   * 售卖时间
   */
  saleTime?: string;
  /**
   * 门店code
   */
  storeCode: string;
  /**
   * 门店是否在营业中
   */
  storeInBusiness: boolean;
  /**
   * 门店名
   */
  storeName: string;
  payTransactionId: string;
  invoice: invoice;
  invoiceActionUrl: string;
  paymentChannel: string;
  paymentTransactionId: string;
  eatTypeName: string;
  carLicensePlate: string;
  expectPickUpTime: string;
  displayOrderId: string;
  tablewareInfo: string;
  remark: string;
  paymentChannelLabel: string;
  orderStatusTitleType: number;
  orderStatusTitle: string;
  orderStatusSubTitle: string;
  totalDiscountAmount: string;
  realPayAmount: string;
  deliveryInfo: deliveryInfo;
  tag: tag;
  orderProcessNodeHistory: { isCurrent: number }[];
  orderStatusDetail: string;
  expectDeliveryTimeShort: string;
  daypartCode?: string;
}
export interface tag {
  name?: string;
}
export interface deliveryInfo {
  deliveryType?: number;
  deliveryTypeLabel?: string;
  mobilePhone: string;
  deliveryAddress: string;
  customerNickname: string;
  riderNickName: string;
  expectDeliveryTime: string;
  addressDetail: string;
  gender: string;
  riderHealthInfoText: string;
}
export interface invoice {
  code?: number;
  actionUrl?: string;
  desc?: string;
}
export interface OrderActionVo {
  code?: string;
  name?: string;
  url?: string;
}
export interface OrderProduct_ {
  /**
   * 子项列表
   */
  comboItemList: ProductComboItem_[];
  /**
   * 商品券
   */
  couponList?: Coupon_[];
  /**
   * 商品打包费单价,单位元
   */
  packingFeePrice?: string;
  /**
   * 未享受优惠图标标识
   */
  pmtIcons?: CartItemIcon_Icon[];
  /**
   * 原价,单位元
   */
  price: string;
  /**
   * 商品code
   */
  productCode: string;
  /**
   * 商品图片
   */
  productImage: string;
  /**
   * 商品名称
   */
  productName: string;
  /**
   * 1：单品 2：套餐
   */
  productType: string;
  /**
   * 数量
   */
  quantity: number;
  /**
   * 实际商品打包费总价,单位元
   */
  realPackingFeeTotalPrice?: string;
  /**
   * 实际总金额,单位元
   */
  realSubtotal: string;
  /**
   * 序号
   */
  sequence: number;
  /**
   * 总价,单位元
   */
  subtotal: string;
  /**
   * 唯一key
   */
  uniqueKey: string;
}
export interface ProductComboItem_ {
  /**
   * sok查看订单 套餐子项的特调定制信息单独给
   */
  grillText?: string;
  /**
   * 子项或特调定制信息
   */
  name: string;
  /**
   * 数量
   */
  quantity: number;
}
export interface Coupon_ {
  /**
   * 右上角角标
   */
  angleMark?: string;
  /**
   * 权益卡id
   */
  cardId?: string;
  /**
   * 权益卡名称
   */
  cardName?: string;
  /**
   * 权益卡类型
   */
  cardType?: number;
  /**
   * 权益卡类型名称
   */
  cardTypeName?: string;
  /**
   * 优惠券Code
   */
  couponCode: string;
  /**
   * 优惠Id
   */
  couponId: string;
  /**
   * 优惠券name
   */
  couponName: string;
  /**
   * 优惠券促销类型,1商品类、2订单类、3运费类
   */
  couponPromotionType: number;
  /**
   * 优惠券卡类型,0普通券、1员工卡、2权益卡劵
   */
  couponType: number;
  /**
   * 优惠金额
   */
  discountAmount: string;
  /**
   * 参与促销优惠的数量
   */
  eligibleItemQuantity?: number;
  /**
   * 小图标
   */
  icon?: string;
  /**
   * 小图标文案
   */
  iconText?: string;
  /**
   * 优惠券图标
   */
  image?: string;
  /**
   * 是否算在商品优惠里
   */
  inPrdCoupon?: boolean;
  /**
   * 付费会员会籍code
   */
  membershipCode?: string;
  /**
   * 模块标题
   */
  moduleTitle?: string;
  /**
   * 中台优惠券类型: 0-非预付券 1-预付券
   */
  mpCouponType: number;
  /**
   * 促销Id
   */
  promotionId: string;
  /**
   * 促销大类【单品：(10:单品 20:BOGO) 整单：(30:满减 40:买赠) 运费：(50:运费)】
   */
  promotionType: number;
  /**
   * 促销展示类型,1券,2促销,3付费会员
   */
  showPmtType?: number;
  /**
   * 今日剩余可用次数
   */
  todayLeftCount?: number;
}
export interface CartItemIcon_Icon {
  /**
   * 展示文字
   */
  text?: string;
  /**
   * 展示样式, 1无员工优惠样式
   */
  type?: number;
}
export interface Rider {
  /**
   * 骑手手机号
   */
  mobileNo?: string;
  /**
   * 骑手昵称
   */
  nickName?: string;
}
export interface RiderLocation {
  /**
   * 配送纬度
   */
  receiverLat?: number;
  /**
   * 配送经度
   */
  receiverLng?: number;
  /**
   * 骑手纬度
   */
  riderLat?: number;
  /**
   * 骑手经度
   */
  riderLng?: number;
  /**
   * 门店纬度
   */
  storeLat?: number;
  /**
   * 门店经度
   */
  storeLng?: number;
}
export interface CancleStatus {
  confirmResult: boolean;
}
export const fetchOrderCategory = () =>
  httpInstance
    .get<CommonResponse<OrderCategory>>(URLS.ORDER_CATEGORIES, {
      params: {},
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });

export const fetchOrder = (params: { orderCategoryId: number; page?: number; size?: number }) =>
  httpInstance
    .get<CommonResponse<OrderInfo>>(URLS.GET_ORDERS, {
      params: { ...params },
      headers: {
        apiVersion: '2.0',
      },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });

// 我的订单详情
export const fetchOrderDetail = (orderId: string) =>
  httpInstance
    .get<CommonResponse<OrderDetail>>(URLS.ORDER_DETAIL + orderId, {
      params: { orderId: orderId, scene: 3 },
      headers: { language: 'zh' },
      pathVars: [orderId],
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });

// 取消订单
export const fetchOrderCancle = (orderId: string) =>
  httpInstance
    .put<CommonResponse<CancleStatus>>(URLS.ORDER_CANCEL.replace('{id}', orderId), {
      params: { orderId: orderId, cancelReasonCode: '1', cancelRemark: '' },
      headers: { language: 'zh' },
      pathVars: [orderId],
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });
