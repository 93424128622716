import httpInstance from './request';
import { BIZ_CODE_100, BIZ_CODE_103 } from '@/constants/code';
import type { CommonResponse } from './request';

const URL_CONST = {
  CART: '/bff/cart/carts',
  EMPTY_CART: '/bff/cart/carts/empty',
  NEARBYSTORE: '/bff/store/stores/vicinity',
  MENULIST: '/bff/spc/menu',
  SEARCHMENU: '/bff/spc/products/search',
  // VALIDATION: '/bff/spc/carts/validation',
  VALIDATION_NEW: '/bff/order/confirmation/validationinfo',
  MENULISTBANNER: '/bff/store/menu/banners',
  PRODUCTDETAIL: '/bff/spc/products/',
  MCCAFEPRODUCTDETAIL: '/bff/spc/products/detail/',
  PRODUCTDESC: '/bff/spc/kvs/',
  ALLCITIES: '/bff/store/cities/group',
  CITYNAME: '/bff/store/cities',
  // NEARBYSTORES: HOST + ' /bff/store/stores/nearby',
  ALLSTORES: '/bff/store/stores',
  STORESFILTERTAG: '/bff/store/stores/hottags',
  FREQUENTSTORES: '/bff/store/stores/frequent',
  COUPONPRODUCTS: '/bff/promotion/coupons/products',
  // MENUCOUPONS: '/bff/promotion/menu/discount',
  ADDRESSMANAGER: '/bff/member/addresses',
  ADDRESSPOISEARCH: '/bff/member/addresses/poi',
  CUSTOMLOCATIONPOI: '/bff/store/pois/search',
  QUERYSTOREBYBECODE: '/bff/store/stores/be/',
  QUERYSTOREBYSTORECODE: '/bff/store/stores/',
  NEWSTOREDETAILINFO: '/bff/store/stores/detailPage/',
  FETCHMDSSWITCHDELIVERYTIME: '/bff/store/stores/{storeCode}/menu/daypartsTimeoption',
  OHMAICARDMENU: '/bff/spc/menu/card',
  STORERECOMMENDINFO: '/bff/portal/recommend/customer',
  COUPONDATALISTWITHSCENE: '/bff/promotion/coupons/v2',
  MENUANOTHERORDER: '/bff/order/orders/last',
  MENUBUYANOTHER: '/bff/order/orders',
  MENUMCCAFEPOINT: '/bff/promotion/menu/mcCafe/discount',
  DRIVINGDISTANCE: '/bff/store/pois/distance',
  MENURIGHTCARDINFO: '/bff/promotion/coupons/card',
  DCCONFIGUREPRICE: '/bff/promotion/price',
  STOREFAVLIST: '/bff/store/stores/collection/list',
  STOREUPDATEFAV: '/bff/member/store/collection',
  POIS_SEARCH_WALK: '/bff/store/pois/direction/walk',
  POIS_GEOCODE: '/bff/store/pois/geocode',
};

export interface Address {
  address: string;
  cityCode: string;
  cityName: string;
  defaultAddress?: number;
  detail: string;
  displayFullText?: string;
  fullName: string;
  gender: number;
  id: string;
  latitude: number;
  longitude: number;
  phone: string;
  streetAddress: string;
  tags?: string;
}

export interface Place {
  address: string;
  cityCode: string;
  cityName: string;
  latitude: number;
  longitude: number;
  name: string;
}

export interface CityInfo {
  code: string;
  latitude: number;
  longitude: number;
  name: string;
  hasStores?: number;
  isHotCity?: number;
  // 下面两个字段 仅供前端使用
  loading?: boolean;
  isErr?: boolean;
}

export interface CategoryInfo {
  initial: string;
  cities: CityInfo[];
}
export interface CityGroup {
  groups: CategoryInfo[];
  hotCities: CityInfo[];
}

// 我的地址
// https://api-docs.mcd.com.cn/project/253/interface/api/85941
export const fetchAddressList = () =>
  httpInstance
    .get<CommonResponse<Address[]>>(URL_CONST.ADDRESSMANAGER, {
      params: {},
      headers: { biz_from: '1003', biz_scenario: BIZ_CODE_103 },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });

export const createNewAddress = (p: Partial<Address>) =>
  httpInstance
    .post<CommonResponse<{ id: string }>>(URL_CONST.ADDRESSMANAGER, {
      params: p,
      headers: {
        biz_from: '1002',
        biz_scenario: BIZ_CODE_103,
      },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });

export const updateAddress = (p: Partial<Address>) => {
  const { id } = p;
  const pathVars = [id];
  return httpInstance
    .put<CommonResponse<{ id: string }>>(`${URL_CONST.ADDRESSMANAGER}/${id!}`, {
      params: p,
      headers: {
        biz_from: '1003',
        biz_scenario: BIZ_CODE_103,
      },
      pathVars,
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
};

export const deleteAddress = ({ addressId }: { addressId: string }) => {
  const pathVars = [addressId];
  return httpInstance
    .delete<CommonResponse<unknown>>(`${URL_CONST.ADDRESSMANAGER}/${addressId}`, {
      params: { _: null },
      headers: {
        biz_from: '1003',
        biz_scenario: BIZ_CODE_103,
      },
      pathVars,
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
};

// 获取附近的地点
export const customAMapAroundPOI = ({ latitude, longitude }: { longitude: number; latitude: number }) => {
  const params = {
    latitude,
    longitude,
    // abId: globalData.getMapABid(),
  };
  return httpInstance
    .get<CommonResponse<Place[]>>(URL_CONST.POIS_GEOCODE, {
      params,
      headers: {},
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
};

// 通过经纬度获取城市
export const fetchCityInfo = ({ latitude, longitude }: { longitude: number; latitude: number }) => {
  const params = {
    latitude,
    longitude,
  };
  return httpInstance
    .get<CommonResponse<{ city: CityInfo }>>(URL_CONST.CITYNAME, {
      params,
      headers: { biz_from: '1005', biz_scenario: BIZ_CODE_103 },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
};

export const fetchAllCities = (params = {}) => {
  return httpInstance
    .get<CommonResponse<CityGroup>>(URL_CONST.ALLCITIES, {
      params,
      headers: {
        biz_from: '1007',
        biz_scenario: BIZ_CODE_103,
      },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
};

// 搜索地点
export interface SearchAddressParams {
  cityName: string;
  keyword?: string;
}

export const searchAddressPOIInfo = ({ keyword = '', cityName }: SearchAddressParams) => {
  return httpInstance
    .get<CommonResponse<Place[]>>(URL_CONST.CUSTOMLOCATIONPOI, {
      params: {
        keyword,
        cityName,
      },
      headers: {
        biz_from: '1007',
        biz_scenario: BIZ_CODE_103,
      },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
};

export interface NearByStoreParams {
  latitude: number;
  longitude: number;
  orderType: number;
  addressId: string;
  beType?: number;
  isGroupMeal?: boolean;
}

export const fetchNearByStore = (params: NearByStoreParams) => {
  return httpInstance
    .get<CommonResponse<Place[]>>(URL_CONST.NEARBYSTORE, {
      params: {
        ...params,
      },
      headers: {
        biz_from: '1003',
        // biz_scenario: menuBizCode(params.isGroupMeal, params.beType),
      },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });
};
