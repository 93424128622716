import type { CSSProperties, FC, SyntheticEvent, DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import { useMemo, useCallback, useState, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';

import type { ProductInfoCustomizationItem, ProductInfoCustomizationItemValueItem } from '@/apis/product/types';

import type { CustomizationSingleTitleProps } from './Head';
import { CustomizationSingleTitle } from './Head';

import styles from './styles/cafesinglespecial.module.less';

type AutoSizeImageProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  defaultWidth?: number;
};

const AutoSizeImage: FC<AutoSizeImageProps> = (props) => {
  const { className, defaultWidth, style, onLoad, ...restProps } = props;
  const ref = useRef<HTMLImageElement>(null);
  const [width, setWidth] = useState(defaultWidth);

  const handleLoad = useCallback(
    (e: SyntheticEvent<HTMLImageElement, Event>) => {
      if (ref.current) {
        const _offsetWidth = ref.current.offsetWidth;
        setWidth((w = 0) => (w > _offsetWidth ? w : _offsetWidth));
      }
      onLoad?.(e);
    },
    [onLoad],
  );

  if (!restProps.src) {
    return null;
  }
  return (
    <span className={className} style={{ width, textAlign: 'center', ...style }}>
      <img
        ref={ref}
        style={{ height: '100%', width: 'auto', objectFit: 'scale-down' }}
        onLoad={handleLoad}
        {...restProps}
      />
    </span>
  );
};

export type CafeSingleSpecialViewProps = {
  className?: string;
  style?: CSSProperties;
  items?: ProductInfoCustomizationItem[];
  showTitleBar?: boolean;
  titleBarProps?: Partial<CustomizationSingleTitleProps>;
  onSelect?: (item: ProductInfoCustomizationItem, value: ProductInfoCustomizationItemValueItem) => void;
};

const CafeSingleSpecialView: FC<CafeSingleSpecialViewProps> = (props) => {
  const { className, style, items, showTitleBar, titleBarProps, onSelect } = props;

  const showBar = useMemo(() => items && !isEmpty(items) && showTitleBar, [items, showTitleBar]);

  return (
    <div className={className} style={style}>
      {showBar && <CustomizationSingleTitle {...titleBarProps} />}
      {items?.map((i, index) => {
        const vLength = i.values?.length || 0;

        return (
          <div key={`${String(i.code)}${index}`}>
            <div className={styles.specialTitle}>{i.name}</div>
            <div className={styles.specialWrapper} data-single={vLength === 1} data-more={vLength > 3}>
              {i.values?.map((v, idx) => {
                const checked = v.checked === 1;
                const hasSpread = vLength < 3 && v.spread;

                return (
                  <div
                    key={`${String(v.code)}${String(v.code)}${idx}`}
                    className={styles.specialItem}
                    onClick={() => (i.soldOut ? undefined : onSelect?.(i, v))}>
                    <div className={styles.specialItemContent} data-checked={checked} data-soldout={i.soldOut}>
                      {vLength < 3 && (
                        <AutoSizeImage
                          className={styles.specialItemImg}
                          src={checked ? v.selectedImage : v.unselectedImage}
                        />
                      )}
                      <div className={styles.specialItemName} data-spread={hasSpread}>
                        {v.name}
                      </div>
                      {hasSpread && <div className={styles.specialItemSpread}>{v.spread}</div>}
                      {i.soldOut && <div>售罄</div>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CafeSingleSpecialView;
