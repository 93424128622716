import httpInstance from './request';
import { BIZ_CODE_101, BIZ_CODE_102, BIZ_CODE_103, BIZ_CODE_600 } from '@/constants/code';
import type { CommonResponse } from './request';
import type { PayChannelResponse, CashierChannelsParams, PreorderParams, PrePayResponse } from './orderPay.type';

const netUrls = {
  //获取门店已授权渠道
  // getChannels: HOST + '/bff/order/payment/channels',
  //预支付
  // preorder: HOST + '/bff/order/payment/preorder',
  //查询支付订单状态
  // getPayStatus: HOST + '/bff/order/payment/',
  //订阅消息模板
  getMsgTemplate: '/bff/common/normal/message/template',
  //商城支付相关接口
  //6.0.22.0 点餐切接口成商城一致，老接口作废
  //update by MMF
  getMallChannels: '/bff/cashier/channels',
  mallPreorder: '/bff/cashier/preorder',
  getMallPayStatus: '/bff/cashier/',
  queryTaskProgress: '/bff/order/task/progress',
};

export const fetchCashierChannels = (p: CashierChannelsParams) => {
  return httpInstance
    .get<CommonResponse<PayChannelResponse>>(netUrls.getMallChannels, {
      params: { ...p },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });
};

export const preOrder = (p: PreorderParams) => {
  return httpInstance
    .post<CommonResponse<PrePayResponse>>(netUrls.mallPreorder, {
      params: { ...p },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });
};
