import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Popup, Button, Radio, TextArea } from 'antd-mobile';
import { right_arrow_icon } from '@/constants';
import type { TablewareInfo, InvoiceInfo } from '@/apis/orderConfirm.type';
import { ep_icon } from '@/constants';
import './index.less';
interface IProps {
  tablewareInfo?: TablewareInfo;
  invoiceInfo?: InvoiceInfo;
  orderType?: number;
  tablewareCode?: string;
  remark?: string;
  onChangeTablewareCode?: (code: string) => void;
  onChangeRemark?: (val: string) => void;
}
const Tableware: React.FC<IProps> = (props) => {
  const { tablewareInfo, invoiceInfo, orderType, tablewareCode, remark, onChangeTablewareCode, onChangeRemark } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [innerTablewareCode, setInnerTablewareCode] = useState<string>();
  const [remarkVisible, setRemarkVisible] = useState<boolean>(false);
  const [innerRemarkVal, setInnerRemarkVal] = useState<string>('');

  useEffect(() => {
    visible && setInnerTablewareCode(tablewareCode);
  }, [tablewareCode, visible]);

  useEffect(() => {
    remarkVisible && setInnerRemarkVal(remark || '');
  }, [remark, remarkVisible]);

  const switchVisible = useCallback(() => {
    setVisible(true);
  }, []);

  const handleTablewareCodeChange = useCallback(
    (code: string) => {
      onChangeTablewareCode && onChangeTablewareCode(code);
    },
    [onChangeTablewareCode],
  );
  const handleRemarkValChange = useCallback(
    (val: string) => {
      onChangeRemark && onChangeRemark(val);
    },
    [onChangeRemark],
  );

  const { title: invoiceInfoTitle, label: invoiceInfoLabel } = invoiceInfo || {};
  const { title, options } = tablewareInfo || {};

  const tablewareText = useMemo(() => {
    const selectOption = (options || []).find(({ code }) => code === tablewareCode);
    const { text, needIcon, code } = selectOption || {};
    if (code) {
      return (
        <div className="tableware-text">
          {needIcon && <img className="ep" src={ep_icon} />}
          <span>{text}</span>
        </div>
      );
    }
  }, [tablewareCode, options]);

  return (
    <div className="tableware-container">
      {orderType === 2 && (
        <div
          className="remark-content"
          onClick={() => {
            setRemarkVisible(true);
          }}>
          <div className="left">
            <div className="title">备注</div>
            <div className="desc">{remark || '点击添加更多用餐需求，可备注无接触配送'}</div>
          </div>
          <img className="arrow" src={right_arrow_icon} />
        </div>
      )}

      <div className="tableware-content" onClick={switchVisible}>
        <div className="left">
          <div className="title">{title || '餐具'}</div>
          <div className="desc">{tablewareText}</div>
        </div>
        <img className="arrow" src={right_arrow_icon} />
      </div>
      {invoiceInfo && (
        <div className="invoice">
          <div className="title">{invoiceInfoTitle}</div>
          <div className="invoice-content">
            <div>{invoiceInfoLabel}</div>
          </div>
        </div>
      )}

      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        bodyStyle={{ height: 'max-content' }}>
        <div className="tableware-popup-content">
          <div className="title">{title || '餐具'}</div>
          <div className="tableware-wrapper">
            <Radio.Group
              value={innerTablewareCode}
              onChange={(code) => {
                setInnerTablewareCode(code as string);
              }}>
              {(options || []).map((i) => {
                const { code, text, needIcon } = i || {};
                return (
                  <Radio
                    key={code}
                    className="item"
                    value={code}
                    style={{
                      '--font-size': '14px',
                    }}>
                    <span>
                      <div className="tableware-text">
                        {needIcon && <img className="ep" src={ep_icon} />}
                        <span>{text}</span>
                      </div>
                    </span>
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
          <div className="btn-wrapper">
            <Button
              color="primary"
              shape="rounded"
              size="middle"
              onClick={() => {
                setVisible(false);
                handleTablewareCodeChange(innerTablewareCode!);
              }}>
              确定
            </Button>
          </div>
        </div>
      </Popup>
      <Popup
        visible={remarkVisible}
        onMaskClick={() => {
          setRemarkVisible(false);
        }}
        bodyStyle={{ height: 'max-content' }}>
        <div className="remark-popup-content">
          <div className="title">订单备注</div>
          <div className="remark-wrapper">
            <TextArea
              value={innerRemarkVal}
              onChange={(val) => {
                setInnerRemarkVal(val);
              }}
              showCount
              maxLength={50}
              rows={4}
              placeholder="点击添加更多用餐需求，可备注无接触配送"
            />
          </div>
          <div className="btn-wrapper">
            <Button
              color="primary"
              shape="rounded"
              size="middle"
              onClick={() => {
                setRemarkVisible(false);
                handleRemarkValChange(innerRemarkVal);
              }}>
              提交
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Tableware;
