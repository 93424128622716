import CryptoES from 'crypto-es'

// 加密
const defaultSecretKey = 'w8ZJ4wrUl7dDB1A7';

export function aesEncrypt (word, secretKey) {
  let key = CryptoES.enc.Utf8.parse(secretKey || defaultSecretKey);
  let encrypted = CryptoES.AES.encrypt(word, key, {
    mode: CryptoES.mode.ECB,
    padding: CryptoES.pad.Pkcs7
  });
  return encrypted.ciphertext.toString();
}

// 解密
export function aesDecrypt (word, secretKey) {
  const key = CryptoES.enc.Utf8.parse(secretKey || defaultSecretKey);
  const encryptedHexStr = CryptoES.enc.Hex.parse(word);
  const srcWord = CryptoES.enc.Base64.stringify(encryptedHexStr);
  const decrypt = CryptoES.AES.decrypt(srcWord, key, {
    mode: CryptoES.mode.ECB,
    padding: CryptoES.pad.Pkcs7
  });
  return decrypt.toString(CryptoES.enc.Utf8);
}