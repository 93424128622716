import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SwipeAction, Dialog, Button, Ellipsis, SearchBar } from 'antd-mobile';
import cls from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '@/store/';
import { updateStoreInfo, setSelectCityTag, SelectCityTag, updateNewStore } from '@/store/addressSlice';
import AddressFooter from '@/components/AddressFooter';

import AddressSearchBar from '@/components/AddressSearchBar';
import StoreList from '../components/StoreList';
import { fetchNearByStores, fetchMyFavStoreList } from '@/apis/canteen';
import type { StoreItem, FavStoreParams } from '@/apis/canteen';
import { fetchStoreTag } from '@/apis/canteen';
import type { HotTagsItem } from '@/apis/canteen';
import { SEARCH_CANTEEN, SELECT_CITY } from '@/constants/path';
import arrowUrl from '@/assets/arrow2.png';
import Tags from '../components/Tags';
import { useStore } from '@/hooks/useStore';
import useLocationInfo from '@/hooks/useLocationInfo';
import useTitle from '@/hooks/useTitle';
import './index.less';

const defaultCityInfo = {
  code: '310100',
  hasStores: 1,
  isHotCity: 1,
  latitude: 31.230525,
  longitude: 121.473667,
  name: '上海市',
};

enum TabKey {
  near,
  fav,
}

const SelectCanteen = () => {
  useTitle('选择餐厅');
  const { storeInfo, storeExtraInfo } = useSelector((state: RootState) => ({
    storeInfo: state.address.storeInfo,
    storeExtraInfo: state.address.storeExtraInfo,
  }));
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { locationAndCityInfo } = useLocationInfo();

  const [activeTabKey, setActivetabKey] = useState<TabKey>(TabKey.near);
  const [isErr, setIsErr] = useState<boolean>(false);
  const [storeList, setStoreList] = useState<StoreItem[]>([]);
  const [showFav, setShowFav] = useState<boolean>(false);
  const [favStoreList, setFavStoreList] = useState<StoreItem[]>([]);
  const [tags, setTags] = useState<HotTagsItem[]>([]);
  const [showTag, setShowTag] = useState<boolean>(true);
  const [activeTagCode, setActiveTagCode] = useState<string>();
  const [selectCode, setSelectCode] = useState<string>();

  const getHotTags = useCallback(() => {
    fetchStoreTag()
      .then((res) => {
        res && setTags(res);
      })
      .catch(() => {});
  }, []);

  const getMyFavStoreList = useCallback((p?: FavStoreParams) => {
    fetchMyFavStoreList({ ...p })
      .then((res) => {
        res && setFavStoreList(res.stores || []);
        if (!(p && p.hotTagCode)) {
          // 全量
          const isShow = res && res.stores && res.stores.length > 0;
          setShowFav(Boolean(isShow));
        }
      })
      .catch(() => {});
  }, []);

  const init = useCallback(() => {
    /*
      1: 拿到坐标信息 获取附近的店
      2: 城市更新 获取附近的店
    */
    if (!storeInfo) {
      // TODO 获取位置信息
      const { city, latitude, longitude } = locationAndCityInfo || {};
      const { code, name } = city || {};
      dispatch(
        updateStoreInfo({
          cityCode: code || defaultCityInfo.code,
          cityName: name || defaultCityInfo.name,
          latitude: latitude || defaultCityInfo.latitude,
          longitude: longitude || defaultCityInfo.longitude,
        }),
      );
    }
    getHotTags();
    getMyFavStoreList();
  }, [dispatch, getHotTags, storeInfo, getMyFavStoreList, locationAndCityInfo]);

  useEffect(() => {
    init();
  }, [init]);

  const getNearByStores = useCallback(() => {
    const { latitude, longitude, cityCode } = storeInfo || {};
    let curLatitude = latitude;
    let curLongitude = longitude;

    const { city, latitude: locationLatitude, longitude: locationLongitude } = locationAndCityInfo || {};
    if (cityCode === city?.code && locationLatitude && locationLongitude) {
      curLatitude = locationLatitude;
      curLongitude = locationLongitude;
    }
    if (curLatitude && curLongitude) {
      fetchNearByStores({
        latitude: curLatitude,
        longitude: curLongitude,
        distance: 10000,
        showType: 2,
        hotTagCode: activeTagCode,
      })
        .then((res) => {
          const { stores } = res || {};
          setStoreList(stores!);
          setIsErr(false);
        })
        .catch((err: Error) => {
          setIsErr(true);
        });
    }
  }, [storeInfo, activeTagCode, locationAndCityInfo?.city]);

  useEffect(() => {
    getNearByStores();
  }, [storeInfo, activeTagCode, locationAndCityInfo?.city]);

  useEffect(() => {
    getMyFavStoreList({ hotTagCode: activeTagCode });
  }, [activeTagCode, getMyFavStoreList]);

  const tabList = useMemo(() => {
    let baseTabList = [{ title: '附近餐厅', key: TabKey.near }];
    if (showFav) {
      baseTabList = [...baseTabList, { title: '收藏餐厅', key: TabKey.fav }];
    }
    return baseTabList;
  }, [showFav]);

  const list = useMemo(() => {
    const realList = activeTabKey === TabKey.near ? storeList : favStoreList;
    return realList;
  }, [activeTabKey, storeList, favStoreList]);

  const handleClick = useCallback(() => {
    const selectedStoreInfo = list.find(({ code }) => code === selectCode);
    // StoreItem的类型定义不一致
    // @ts-ignore
    selectedStoreInfo?.code && dispatch(updateNewStore(selectedStoreInfo.code));
    selectedStoreInfo?.code && window.localStorage.setItem('lastStoreCode', selectedStoreInfo?.code);
    if (storeExtraInfo?.enterFrom) {
      // const url: string = storeExtraInfo?.isMenu
      //   ? `${storeExtraInfo.enterFrom || ''}&newStoreCode=${selectedStoreInfo?.code || ''}`
      //   : storeExtraInfo?.enterFrom || '';
      // navigate(url, { replace: true });
      if (storeExtraInfo?.isMenu) {
        navigate(`${storeExtraInfo.enterFrom || ''}&newStoreCode=${selectedStoreInfo?.code || ''}`, { replace: true });
      } else {
        navigate(-1);
      }
    }
  }, [dispatch, selectCode, list, storeExtraInfo, navigate]);

  const { cityCode, cityName } = storeInfo || {};

  const canComplete = useMemo(() => {
    const realSelectStore = (list || []).find(({ code }) => code === selectCode);
    const isSelected = realSelectStore?.code;
    setSelectCode(realSelectStore?.code || '');
    return isSelected && realSelectStore?.businessStatus;
  }, [selectCode, list]);

  return (
    <div className="select-canteen-container">
      {/* <Header /> */}
      <div className="content">
        <AddressSearchBar
          cityInfo={{ code: cityCode, name: cityName }}
          searchBarPlaceholder="搜索餐厅"
          onChangeCity={() => {
            dispatch(setSelectCityTag(SelectCityTag.canteen));
            navigate(SELECT_CITY);
          }}
          onFocus={() => {
            navigate(SEARCH_CANTEEN);
          }}
        />
        <div className="title-wrapper">
          {tabList.map(({ title, key }) => (
            <div
              key={key}
              className={cls('title', { selected: key === activeTabKey })}
              onClick={() => {
                setActivetabKey(key);
              }}>
              {title}
              {key === activeTabKey && <div className="bg" />}
            </div>
          ))}
          <div
            className={cls('filter-wrapper', { show: showTag })}
            onClick={() => {
              setShowTag(!showTag);
            }}>
            <span>筛选</span>
            <img src={arrowUrl} />
          </div>
        </div>
        {showTag && (
          <Tags
            tagList={tags}
            activeTagCode={activeTagCode}
            onSelect={(code) => {
              setActiveTagCode(code);
            }}
          />
        )}

        {isErr ? (
          <div className="err-content">
            <div>附近暂无餐厅，敬请期待</div>
          </div>
        ) : (
          <div className="canteen-container">
            <StoreList
              list={list}
              onUpdateFav={() => {
                getNearByStores();
                getMyFavStoreList({ hotTagCode: activeTagCode });
              }}
              selectCode={selectCode}
              onSelect={(code: string) => {
                setSelectCode(code);
              }}
              locationCityCode={locationAndCityInfo?.city?.code}
              fromPage={storeExtraInfo?.isMenu ? '点餐页' : '订单确认页'}
            />
          </div>
        )}
      </div>
      <AddressFooter text={'去点餐'} disabled={!canComplete} handleClick={handleClick} />
    </div>
  );
};

export default SelectCanteen;
