import React from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '@/store/';

const useEditing = () => {
  const detailInfo = useSelector((state: RootState) => state.address.detail);
  return {
    isEditing: Boolean(detailInfo?.id),
  };
};

export default useEditing;
