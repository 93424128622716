import { useCallback, useMemo, useEffect } from 'react';

import { useDeepMemo, useRefFn } from '@axios-use/react';
import { Toast } from 'antd-mobile';

import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from '@/store';
import {
  clearCart as manualClearCartAction,
  updateCartAsyncWithoutThrow,
  clearCartAsync,
  getServeCartAsync,
  updateRequestQuery,
} from '@/store/cartInfoSlice';
import type { PutUpdateCartBody, PutClearCartsBody } from '@/apis/product/index';
import type { CartInfoParams } from '@/apis/cart';

import useLogin from '@/hooks/useLogin';

/**
 * 获取对应购物车信息
 * @param orderType
 */
export function useCartState(orderType = 1) {
  const cartstate = useSelector((s: RootState) => s.cartinfo);

  const state = useMemo(() => {
    if (Number(orderType) === 1) {
      return cartstate.pickup;
    }

    if (Number(orderType) === 2) {
      return cartstate.delivery;
    }

    return undefined;
  }, [cartstate, orderType]);

  return [state, cartstate.status] as const;
}

/**
 * 购物车信息初始化
 * @param params
 */
export function useInitCart(params: Partial<CartInfoParams>) {
  const { orderType } = params || {};
  const [cartsInfo, cartStatus] = useCartState(orderType);
  const dispatch = useDispatch<AppDispatch>();

  const checkCartState = useCallback(() => {
    const { daypartCode, storeCode, cartType, ...restParams } = params || {};

    if (cartsInfo?.value) {
      if (
        cartsInfo.value.orderType === orderType &&
        cartsInfo.value.daypartCode === daypartCode &&
        cartsInfo.value.storeCode === storeCode &&
        cartsInfo.value.cartType === cartType &&
        cartsInfo.requestQuery?.date === restParams.date &&
        cartsInfo.requestQuery?.time === restParams.time
      ) {
        return;
      }
    }

    if (cartStatus === 'idle' && params && orderType && daypartCode && storeCode && cartType) {
      const _parasm = { daypartCode, storeCode, cartType, orderType, ...restParams };
      dispatch(getServeCartAsync(_parasm)).catch((e) => {
        console.error(e);
      });

      dispatch(updateRequestQuery(_parasm));
    }
  }, [
    cartStatus,
    cartsInfo?.requestQuery?.date,
    cartsInfo?.requestQuery?.time,
    cartsInfo?.value,
    dispatch,
    orderType,
    params,
  ]);

  const checkCartStateRef = useRefFn(checkCartState);

  useEffect(() => {
    checkCartStateRef.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepMemo([params]));

  return [checkCartState] as const;
}

/**
 * 购物车修改与清除
 */
export function useCartAction(options?: { onErrLoginSuc?: () => void }) {
  const { onErrLoginSuc } = options || {};
  const dispatch = useDispatch<AppDispatch>();

  // TODO 无脑业务需求
  const { btn: _loginBtnNode, navLogin } = useLogin({ onSuccess: onErrLoginSuc, ignoreInit: true });

  const loginBtnNode = useMemo(
    () => (
      <div style={{ position: 'absolute', zIndex: -10, display: 'none', overflow: 'hidden', height: 0 }}>
        {_loginBtnNode}
      </div>
    ),
    [_loginBtnNode],
  );

  const updateCart = useCallback(
    async (body: PutUpdateCartBody) => {
      const _res = await dispatch(updateCartAsyncWithoutThrow(body)).unwrap();

      if (_res.success) {
        // TODO delete
        const ERR_TOAST_DURATION = 3000;
        if (_res?.data?.cartDetail?.promptText) {
          Toast.show({ content: _res.data.cartDetail.promptText, duration: ERR_TOAST_DURATION, position: 'top' });
        }
        if (_res.data?.cartDetail?.tipsList) {
          const toastTipsList = _res.data.cartDetail.tipsList.filter((i) => i.type === 1);
          toastTipsList.forEach((i) => {
            if (i.text) {
              Toast.show({ content: i.text, duration: ERR_TOAST_DURATION, position: 'top' });
            }
          });
        }
        if (_res.data?.conflictCoupons?.text) {
          Toast.show({ content: _res.data.conflictCoupons.text, duration: ERR_TOAST_DURATION, position: 'top' });
        }
        if (_res.data?.pmtLimit?.text) {
          Toast.show({
            content: '多重优惠不同享',
            position: 'top',
          });
        }

        return _res;
      } else {
        if (_res.code === 401) {
          try {
            navLogin();
          } catch (error) {
            console.error('[useCartAction] navLogin err: %o', error);
          }
        }
        throw new Error(_res.message);
      }
    },
    [dispatch, navLogin],
  );
  const clearCart = useCallback(
    (body: PutClearCartsBody) => {
      return dispatch(clearCartAsync(body)).unwrap();
    },
    [dispatch],
  );
  const manualClearCart = useCallback(
    (orderType?: number) => {
      return dispatch(manualClearCartAction({ orderType }));
    },
    [dispatch],
  );

  /** get 同步服务端信息到本地 */
  const syncTheLatest = useCallback(
    (params: CartInfoParams) => {
      return dispatch(getServeCartAsync(params));
    },
    [dispatch],
  );

  return { updateCart, clearCart, manualClearCart, syncTheLatest, loginBtnNode };
}
