import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { CityInfo, Place, Address } from '@/apis/address';
import type { StoreItem } from '@/apis/canteen';
interface StoreInfo {
  cityCode?: string;
  cityName?: string;
  latitude: number;
  longitude: number;
}

interface StoreExtraInfo {
  enterFrom?: string;
  orderType?: number;
  isMenu?: boolean;
}

interface AddressExtraInfo {
  enterFrom?: string;
  orderType?: number;
  isGroupMeal?: boolean;
  beType?: number;
  isMenu?: boolean;
}

export interface LocationAndCityInfo {
  latitude?: number;
  longitude?: number;
  city?: CityInfo;
  nearestStoreInfo?: StoreItem;
  hadFetch?: boolean;
  loading?: boolean;
}
export interface AddressInfo {
  locationAndCityInfo?: LocationAndCityInfo;
  city?: Partial<CityInfo>;
  place?: Partial<Place>;
  detail?: Address;

  storeInfo?: Partial<StoreInfo>;
  storeExtraInfo?: Partial<StoreExtraInfo>;
  newStoreCode?: string;

  addressExtraInfo?: Partial<AddressExtraInfo>;
  newAddressId?: string;

  selectCityTag?: SelectCityTag;
}

// 选择城市的case
export enum SelectCityTag {
  canteen = 1,
  address,
}

const initialState: AddressInfo = {};

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    updateLocationAndCityInfo: (state, action: PayloadAction<LocationAndCityInfo>) => {
      state.locationAndCityInfo = action.payload;
    },

    // updateCity: (state, action: PayloadAction<Partial<CityInfo> | undefined>) => {
    //   state.city = action.payload;
    // },
    updatePlace: (state, action: PayloadAction<Partial<Place>>) => {
      state.place = action.payload;
    },
    updateDetail: (state, action: PayloadAction<Address | undefined>) => {
      state.detail = action.payload;
      // state.city = undefined;
      state.place = undefined;
    },
    updateStoreInfo: (state, action: PayloadAction<Partial<StoreInfo>>) => {
      state.storeInfo = action.payload;
    },

    updateStoreExtraInfo: (state, action: PayloadAction<Partial<StoreExtraInfo>>) => {
      state.storeExtraInfo = action.payload;
    },
    updateNewStore: (state, action: PayloadAction<Partial<string | undefined>>) => {
      state.newStoreCode = action.payload;
    },

    updateAddressExtraInfo: (state, action: PayloadAction<Partial<AddressExtraInfo>>) => {
      state.addressExtraInfo = action.payload;
    },
    updateNewAddress: (state, action: PayloadAction<Partial<string | undefined>>) => {
      state.newAddressId = action.payload;
    },

    setSelectCityTag: (state, action: PayloadAction<SelectCityTag>) => {
      state.selectCityTag = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateLocationAndCityInfo,
  // updateCity,
  updatePlace,
  updateDetail,
  updateStoreInfo,
  updateStoreExtraInfo,
  setSelectCityTag,
  updateNewStore,
  updateAddressExtraInfo,
  updateNewAddress,
} = addressSlice.actions;

export default addressSlice.reducer;
