import { configureStore } from '@reduxjs/toolkit';
import addressSlice from './addressSlice';
import commonConfigSlice from './commonConfigSlice';
import orderConfirmSlice from './orderConfirmSlice';
import cartInfoSlice from './cartInfoSlice';
import productSlice from './productSlice';

export const store = configureStore({
  reducer: {
    address: addressSlice,
    commonConfig: commonConfigSlice,
    orderConfirm: orderConfirmSlice,
    cartinfo: cartInfoSlice,
    product: productSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
