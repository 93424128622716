import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Popup, Radio, Checkbox, Button } from 'antd-mobile';
import type { ConfirmationProductPriceInfo_, ConfirmationProduct_ } from '@/apis/orderConfirm.type';
import { formatPrice } from '@/utils';
import arrowUrl from '@/assets/arrow2.png';
import { right_arrow_icon } from '@/constants';
import { ProductCardCountStepper } from '@/pages/Product/Menu/List/Card/NumButton';
import Coupon from '../Coupon';
import './index.less';

interface IProps {
  data?: ConfirmationProductPriceInfo_;
  tablewareCode: string;
  orderType: number;
  /** add to cart */
  onAdd?: (p: ConfirmationProduct_) => void;
  /** remove */
  onSub?: (p: ConfirmationProduct_) => void;
  onOrderCouponCodeChange?: (code?: string) => void;
  onFreightCouponPromotionIdChange?: (code?: string) => void;
}

const ProductList: React.FC<IProps> = (props) => {
  const { data, tablewareCode, orderType, onAdd, onSub, onOrderCouponCodeChange, onFreightCouponPromotionIdChange } =
    props;
  const {
    cartProductList,
    realProductTotalPrice,
    realTotalAmount,
    totalAmount,
    discountAmount,
    tablewarePrice,
    realDeliveryPrice,
    deliveryPrice,
    title,
    productCouponInfo,
    orderCouponInfo,
    freightCouponInfo,
  } = data || {};

  const [visible, setVisible] = useState<boolean>(false);
  const [innerCouponCode, setInnerCouponCode] = useState<string>();

  const [freightVisible, setFreightVisible] = useState<boolean>(false);
  const [innerFreightCouponPromotionId, setInnerFreightCouponPromotionId] = useState<string>();

  const selectedOrderCoupon = useMemo(() => {
    return (orderCouponInfo?.couponList || []).find(({ isSelect }) => isSelect);
  }, [orderCouponInfo]);

  const selectedFreightCoupon = useMemo(() => {
    return (freightCouponInfo?.couponList || []).find(({ isSelect }) => isSelect);
  }, [freightCouponInfo]);

  useEffect(() => {
    visible && setInnerCouponCode(selectedOrderCoupon?.code);
  }, [selectedOrderCoupon, visible]);

  useEffect(() => {
    freightVisible && setInnerFreightCouponPromotionId(selectedFreightCoupon?.promotionId);
  }, [selectedFreightCoupon, freightVisible]);

  // 操作 含有2代表可修改商品数量
  const canAddOrSub = useCallback((actions?: number[]) => (actions || []).includes(2), []);

  const calcDesc = useCallback((type: number) => {
    if (type === 1) {
      return '特价';
    }
    if (type === 3) {
      return '立减';
    }
  }, []);

  if (!data) return null;

  return (
    <div className="product-list-conatiner">
      <div className="title">{title || '餐品详情'}</div>
      <div className="product-content">
        {(cartProductList || [])
          // saleStatus 1可售
          .filter(({ saleStatus }) => saleStatus === 1)
          .map((i) => {
            const {
              uniqueKey,
              productImage,
              productName,
              quantity,
              subtotal,
              realSubtotal,
              comboItemList,
              actions,
              couponList,
            } = i || {};
            return (
              <div className="item" key={uniqueKey}>
                <img className="product-img" src={productImage} />
                <div className="detail">
                  <div className="name">{productName}</div>
                  <div className="comboItems">
                    {(comboItemList || []).map((comboItem, idx) => {
                      const { quantity: comboQuantity, name } = comboItem || {};
                      return (
                        <div key={`${name}-${idx}`} className="combo-item">
                          {comboQuantity} x {name}
                        </div>
                      );
                    })}
                  </div>

                  <div className="coupon-content">
                    {(couponList || []).map((couponItem) => {
                      const { couponId, couponName } = couponItem || {};
                      return (
                        <div key={couponId} className="coupon-item">
                          <span className="symbol">券</span>
                          <span className="coupon-name-wrapper">
                            <span>{couponName}</span>
                          </span>
                          <img src="https://img.mcd.cn/mini/main/images/coupon_right.png" className="after-bg"></img>
                        </div>
                      );
                    })}
                  </div>

                  <div className="price-wrapper">
                    {canAddOrSub(actions) ? (
                      <ProductCardCountStepper
                        count={quantity}
                        minCount={0}
                        onAdd={() => {
                          onAdd && onAdd(i);
                        }}
                        onSub={() => {
                          onSub && onSub(i);
                        }}
                      />
                    ) : (
                      <span>{quantity}份</span>
                    )}

                    <div className="price-content">
                      {subtotal !== realSubtotal && (
                        <span className="market">
                          <span className="ui-symbol">¥</span>
                          <span className="ui-val">{subtotal}</span>
                        </span>
                      )}
                      <span className="real">
                        <span className="ui-symbol">¥</span>
                        <span className="ui-val">{realSubtotal}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <div className="total-price">
        <span className="label">商品小计</span>
        <span className="val">
          <span className="ui-symbol">¥</span>
          <span className="ui-val">{realProductTotalPrice}</span>
        </span>
      </div>
      {tablewareCode === 'yes' && orderType === 1 && (
        <div className="tableware-price">
          <span className="label">餐具费</span>
          <span className="val">
            <span className="ui-symbol">¥</span>
            <span className="ui-val">{tablewarePrice}</span>
          </span>
        </div>
      )}
      {orderType === 2 &&
        (freightCouponInfo && freightCouponInfo.couponList && freightCouponInfo.couponList.length > 0 ? (
          <div
            className="delivery-price"
            onClick={() => {
              setFreightVisible(true);
            }}>
            <div className="label">
              <span>配送费</span>
              {selectedFreightCoupon && (
                <div className="coupon-title">
                  <span className="symbol">促</span>
                  <span>{selectedFreightCoupon?.title}</span>
                </div>
              )}
              {freightCouponInfo?.discountTips && <span className="tips">{freightCouponInfo?.discountTips}</span>}
            </div>
            <span className="val">
              {!selectedFreightCoupon?.promotionId ? (
                <Coupon text={`${freightCouponInfo?.usableNumber || 0}张可用`} />
              ) : (
                <span className="market-price">
                  <span className="symbol ui-symbol">¥</span>
                  <span className="price ui-val">{deliveryPrice}</span>
                </span>
              )}
              <div className="real-price">
                <span className="symbol ui-symbol">¥</span>
                <span className="price ui-val">{realDeliveryPrice}</span>
              </div>
              <img className="arrow" src={right_arrow_icon} />
            </span>
          </div>
        ) : (
          <div className="delivery-price">
            <div className="label">
              <span>配送费</span>
            </div>
            <span className="val">
              <span className="symbol ui-symbol">¥</span>
              <span className="price ui-val">{realDeliveryPrice || 0}</span>
            </span>
          </div>
        ))}
      {orderCouponInfo &&
      ((orderCouponInfo?.usedCount === 0 && orderCouponInfo?.usableNumber) ||
        Number(orderCouponInfo?.usedDiscountAmount)) ? (
        <div
          className="order-coupon-info"
          onClick={() => {
            setVisible(true);
          }}>
          <div className="label">
            <span>订单优惠</span>
            {selectedOrderCoupon && (
              <div className="coupon-title">
                <span className="symbol">券</span>
                <span>{selectedOrderCoupon?.title}</span>
              </div>
            )}
            {orderCouponInfo?.discountTips && <span className="tips">{orderCouponInfo?.discountTips}</span>}
          </div>
          <span className="val">
            {orderCouponInfo?.usedCount === 0 && orderCouponInfo?.usableNumber ? (
              <Coupon text={`${orderCouponInfo?.usableNumber}张可用`} />
            ) : (
              <>
                <span className="symbol ui-symbol">-¥</span>
                <span className="price ui-val">{orderCouponInfo?.usedDiscountAmount || 0}</span>
              </>
            )}

            <img className="arrow" src={right_arrow_icon} />
          </span>
        </div>
      ) : null}

      {productCouponInfo && productCouponInfo?.usedCount ? (
        <div className="product-coupon-info">
          <span className="label">
            商品优惠 <span className="desc">已用优惠{productCouponInfo?.usedCount}张</span>
          </span>
          <span className="val">
            <span className="symbol ui-symbol">-¥</span>
            <span className="price ui-val">{productCouponInfo?.usedDiscountAmount}</span>
          </span>
        </div>
      ) : null}
      <div className="division">
        <div className="line" />
        <span className="left" />
        <span className="right" />
      </div>
      <div className="real-total-price">
        {Number(discountAmount) ? (
          <div className="discountAmount-wrapper">
            已优惠
            <span className="val">
              <span className="ui-symbol">¥</span>
              <span className="price ui-val">{discountAmount}</span>
            </span>
          </div>
        ) : null}
        <div className="realTotalPrice-wrapper">
          合计
          <span className="val">
            <span className="symbol ui-symbol">¥</span>
            <span className="price ui-val total">{realTotalAmount}</span>
          </span>
        </div>
      </div>

      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        bodyStyle={{ height: 'max-content', maxHeight: '80%', overflowY: 'scroll' }}>
        <div className="order-coupon-popup-content">
          <div className="title">{'订单优惠'}</div>
          <div className="available-coupon">
            您有<span className="num">{orderCouponInfo?.usableNumber}</span>张优惠券可用
          </div>
          <div className="order-coupon-container">
            {(orderCouponInfo?.couponList || []).map((i) => {
              const {
                code,
                canSelect,
                id,
                discountType,
                discountUnit,
                discountValue,
                isSelect,
                title: couponTitle,
                currentDayAvailableCount,
                totalCount,
              } = i || {};
              return (
                <Checkbox
                  key={code}
                  checked={code === innerCouponCode}
                  onChange={(bool: boolean) => {
                    setInnerCouponCode(bool ? code : '');
                  }}
                  className="item"
                  disabled={!canSelect}
                  value={code}
                  style={{
                    '--font-size': '14px',
                  }}>
                  <div className="coupon-wrapper">
                    <div className="pattern">
                      <div className="detail">
                        <span className="desc">{calcDesc(discountType!)}</span>
                        <div className="val-wrapper">
                          <span className="val">{discountValue}</span>
                          <span className="unit">{discountUnit}</span>
                        </div>
                      </div>
                    </div>
                    <div className="info">
                      <div className="title">{couponTitle}</div>
                      <div className="count">
                        今日{currentDayAvailableCount}次，总共{totalCount}次
                      </div>
                    </div>
                  </div>
                </Checkbox>
              );
            })}
          </div>
          <div className="btn-wrapper">
            <Button
              color="primary"
              shape="rounded"
              size="middle"
              onClick={() => {
                setVisible(false);
                onOrderCouponCodeChange && onOrderCouponCodeChange(innerCouponCode);
              }}>
              确定
            </Button>
          </div>
        </div>
      </Popup>

      <Popup
        visible={freightVisible}
        onMaskClick={() => {
          setFreightVisible(false);
        }}
        bodyStyle={{ height: 'max-content', maxHeight: '80%', overflowY: 'scroll' }}>
        <div className="freight-coupon-popup-content">
          <div className="title">{'配送费订单优惠'}</div>
          <div className="available-coupon">
            您有<span className="num">{freightCouponInfo?.usableNumber}</span>张优惠券可用
          </div>
          <div className="freight-coupon-container">
            {(freightCouponInfo?.couponList || []).map((i) => {
              const {
                promotionId,
                canSelect,
                id,
                discountType,
                discountUnit,
                discountValue,
                isSelect,
                title: couponTitle,
                currentDayAvailableCount,
                totalCount,
              } = i || {};
              return (
                <Checkbox
                  key={promotionId}
                  checked={promotionId === innerFreightCouponPromotionId}
                  onChange={(bool: boolean) => {
                    setInnerFreightCouponPromotionId(bool ? promotionId : '');
                  }}
                  className="item"
                  disabled={!canSelect}
                  value={promotionId}
                  style={{
                    '--font-size': '14px',
                  }}>
                  <div className="coupon-wrapper">
                    <div className="pattern">
                      <div className="detail">
                        <span className="desc">{calcDesc(discountType!)}</span>
                        <div className="val-wrapper">
                          <span className="val">{discountValue}</span>
                          <span className="unit">{discountUnit}</span>
                        </div>
                      </div>
                    </div>
                    <div className="info">
                      <div className="title">{couponTitle}</div>
                    </div>
                  </div>
                </Checkbox>
              );
            })}
          </div>
          <div className="btn-wrapper">
            <Button
              color="primary"
              shape="rounded"
              size="middle"
              onClick={() => {
                setFreightVisible(false);
                onFreightCouponPromotionIdChange && onFreightCouponPromotionIdChange(innerFreightCouponPromotionId);
              }}>
              确定
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default ProductList;
