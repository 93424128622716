import { useState, useCallback, useEffect } from 'react';
import './index.less';
import { useNavigate } from 'react-router-dom';
import { getCDNAssets } from '@/utils';
import type { CouponsItem, CardsItem } from '@/apis/cardlist';
import { COUPONS_DETAIL } from '@/constants/path';
import { trackEvent } from '@/utils/tracker';
let isClicked = false;
interface iProps {
  card: CardsItem;
  scene?: number;
  coupList: CouponsItem[];
  idx: number;
  isGroupCoupon?: boolean;
  showMoreCoupon?: boolean;
  showInstructions?: boolean;
  showBtm: boolean;
  showCouponCardBottom?: boolean;
  isAvailable?: boolean;
  onGroupCouponSelect?: (card: CardsItem) => void;
}
/**
 * showInstructions 是否展示使用说明
 */
const CommonCouponCard: React.FC<iProps> = (props) => {
  const navigate = useNavigate();
  const {
    scene,
    card,
    showBtm = true,
    coupList,
    idx,
    showInstructions = true,
    showMoreCoupon = true,
    onGroupCouponSelect,
    showCouponCardBottom = true,
    isGroupCoupon,
    isAvailable,
  } = props || {};

  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    trackEvent('couponExposure', {
      belong_page: '我的卡包',
      card_id: card.id,
      coupon_name: card.title,
      coupon_type: card.orderType === 1 ? '到店专用' : '外送专用',
    });
  }, []);

  const imgBg = card.orderType === 1 ? 'bg-coupon-pickup-yellow' : 'bg-coupon-delivery-red';
  let tradeTime = '';
  if (card.tradeTimePeriods) {
    card.tradeTimePeriods.forEach((item) => {
      const startTime = item.startTime.split(':')[0] + ':' + item.startTime.split(':')[1];
      const endTime = item.endTime.split(':')[0] + ':' + item.endTime.split(':')[1];
      tradeTime = tradeTime + ' ' + startTime + '-' + endTime;
    });
  }
  const startTrade = card.tradeStartDate ? card.tradeStartDate.replace(/-/g, '.') : '';
  const endTrade = card.tradeEndDate ? card.tradeEndDate.replace(/-/g, '.') : '';
  let count = '';
  if (card.totalCount > 1) {
    count = card.countText;
    card.countTextBinds?.map((item) => {
      count = count.replace(/\{\d+\}/, item);
    });
    // count = '今日可用' + (card.currentDayAvailableCount > 10000 ? '不限' : card.currentDayAvailableCount)
    //   + '次，剩余可用' + (card.totalAvailableCount > 10000 ? '不限': card.totalAvailableCount ) + '次';
  }

  const isLarger = window.innerWidth >= 390;

  const renderDiscountTitle = () => {
    const discountTips = (card?.reducePriceText?.indexOf('$') > 0 && card?.reducePriceText?.split('$')) || [];
    const txtRedTips = <span className={'discount-price'}>{card?.reducePrice}</span>;

    if (discountTips?.length > 0) {
      return (
        <div className={`discount-title`}>
          {discountTips.map((itemTips: any, itemIndex: number) => {
            return (
              <span key={itemIndex}>
                {itemTips}
                {/*最后一个不展示*/}
                {itemIndex < discountTips.length - 1 ? txtRedTips : ''}
              </span>
            );
          })}
        </div>
      );
    } else {
      return <div>{discountTips}</div>;
    }
  };

  const handleButtonOnClick = (coupon: CardsItem) => {
    if (isClicked) {
      return;
    }
    isClicked = true;
    let realBeType: any = coupon.beType ?? 1;
    if (coupon?.beTypes || ([]?.length > 0 && coupon?.beTypes) || [][0] != null) {
      realBeType = coupon?.beTypes[0] ?? 1;
    }
    if (!coupon.orderType) {
      coupon.orderType = realBeType == 2 ? 2 : 1;
    }
    // 运费券不需要加购
    if (coupon?.showType != 102 && coupon.showType != 104) {
      const param = {
        couponId: coupon.id,
        couponCode: coupon.code,
        couponName: coupon.title,
        promotionId: coupon?.promotionId,
        pageSource: '3',
      };

      let rqUrl = `/product?orderType=${coupon.orderType}`;
      if (isAvailable) {
        rqUrl = `/product?orderType=${coupon.orderType}&withcache=1&appointment=1`;
      }
      //跳转去点餐详情页面
      navigate(rqUrl, {
        replace: isAvailable,
        state: {
          coupon: {
            couponCode: coupon.code,
            couponId: coupon.id,
            promotionId: coupon?.promotionId,
          },
        },
      });
    }
    setTimeout(() => {
      isClicked = false;
    }, 1000);
  };
  const handletoPage = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate],
  );
  const renderBottomBtn = () => {
    return (
      <>
        {card.enable !== 0 && (
          <div
            className="content-bottom-use"
            onClick={() => {
              handleButtonOnClick(card);
            }}>
            立即使用
          </div>
        )}
        {card.enable === 0 && <div className="content-bottom-cantuse">立即使用</div>}
      </>
    );
  };

  return (
    <div className="common-coupon-index-outside">
      <div className="common-coupon-index" style={{ width: 'calc(100vw - 40px)' }}>
        <div className={imgBg}></div>
        <div
          className="common-coupon-content"
          style={{
            background: !showBtm ? 'linear-gradient(to right,#FFFFFF, #f9f9f9)' : '#fff',
          }}>
          <div className="common-coupon-content-top">
            {/*商品图*/}
            <div className={'coupon-group'}>
              <img
                className="common-coupon-content-img"
                src={card.image}
                style={{ marginLeft: isLarger ? '10px' : '0px' }}
              />
              {isGroupCoupon && (
                <div
                  style={{
                    backgroundImage: 'url(' + getCDNAssets('group_coupon_more.png') + ')',
                  }}
                  onClick={() => {
                    onGroupCouponSelect!(card || {});
                  }}
                  className={'coupon-group-more'}>
                  <span className={'more-title'}>共{!coupList || (coupList && coupList[idx].coupons.length)}张</span>
                  {showMoreCoupon && (
                    <img
                      className="more-img"
                      src={
                        coupList[idx].fold ? getCDNAssets('group_coupon_nor.png') : getCDNAssets('group_coupon_sel.png')
                      }
                    />
                  )}
                </div>
              )}
            </div>

            {/*详情*/}
            <div className="common-coupon-content-text" style={{ marginLeft: isLarger ? '20px' : '10px' }}>
              <div className="common-coupon-content-t">
                {/*标签*/}
                {!!card.tags && (
                  <div className="common-coupon-label">
                    {card.tags.map((item, tidx) => {
                      if (item.type === 3) {
                        return (
                          <span
                            key={tidx}
                            className="tag-text"
                            style={{
                              backgroundColor: item.color,
                              borderColor: item.color,
                              color: '#fff',
                            }}>
                            {item.label}
                          </span>
                        );
                      }
                      if (item.type === 1) {
                        return (
                          <img
                            key={tidx}
                            src={item.image}
                            style={{ width: imgWidth.toString() + 'px' }}
                            className="tag-img"
                          />
                        );
                      }
                      if (item.type === 2) {
                        return (
                          <span key={tidx} className="tag-text" style={{ color: item.color, borderColor: item.color }}>
                            {item.label}
                          </span>
                        );
                      }
                    })}
                  </div>
                )}
                {/*标题*/}
                <div
                  className="common-coupon-title"
                  style={{
                    width: 'calc(100vw - 180Px)',
                  }}>
                  {card.title}
                </div>
                {!!card.reducePriceText && renderDiscountTitle()}
                {/*副标题*/}
                <div
                  className="common-coupon-subtitle"
                  style={{
                    width: 'calc(100vw - 180Px)',
                  }}>
                  {card.subtitle}
                </div>
              </div>

              <div className="common-coupon-content-b">
                {/*日期*/}
                <div
                  className="common-coupon-date"
                  style={{
                    width: 'calc(100vw - 180Px)',
                  }}>
                  {startTrade}-{endTrade} {tradeTime}
                </div>
                {/*次数*/}
                {!!count && (
                  <div
                    className="common-coupon-count"
                    style={{
                      width: 'calc(100vw - 180Px)',
                    }}>
                    {count}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* 下划线 */}
          <div className={`coupon-divider`}>
            <div className="semi-circle-left" />
            <div className="dash-line" />
            <div className="semi-circle-right" />
          </div>

          <div className="common-coupon-content-bottom">
            <div className="content-bottom-desc">
              使用说明
              <img className="common-coupon-detail-arrow" src={getCDNAssets('jdarrowblack.png')} />
            </div>
            <div className="content-bottom-btn">{renderBottomBtn()}</div>
          </div>
        </div>
        <div
          className="common-coupon-left"
          onClick={(e) => {
            e.stopPropagation();
            if (isClicked || !showInstructions) {
              return;
            }
            isClicked = true;
            trackEvent('couponClick', {
              belong_page: '我的卡包',
              card_id: card.id,
              coupon_name: card.title,
              coupon_type: card.orderType === 1 ? '到店专用' : '外送专用',
            });
            handletoPage(
              card?.id &&
                card?.code &&
                COUPONS_DETAIL + '?id=' + card?.id.toString() + '&code=' + card?.code.toString() + '&scene=3',
            );
            setTimeout(() => {
              isClicked = false;
            }, 1000);
          }}></div>
      </div>
      {!!count && showCouponCardBottom && (
        <div className="common-coupon-bottom" style={{ width: 'calc(100vw - 55)' }}>
          <img className="common-coupon-bottom-img" src={getCDNAssets('user_coupon_bottom_left.png')} />
          <img className="common-coupon-bottom-mid" src={getCDNAssets('user_coupon_bottom_middle.png')} />
          <img className="common-coupon-bottom-img" src={getCDNAssets('user_coupon_bottom_right.png')} />
        </div>
      )}
    </div>
  );
};

export default CommonCouponCard;
