import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cls from 'classnames';
import { Popup, Button, Radio } from 'antd-mobile';
import { fetchStoreDetailInfo } from '@/apis/canteen';
import type { StoreDetail } from '@/apis/canteen';
import type { ConfirmationPickupInfo_ } from '@/apis/orderConfirm.type';
import { order_shop_icon, check_icon } from '@/constants';
import type { PickUpTimeOption } from '../../constant';
import { right_arrow_icon } from '@/constants';
import { getCDNAssets } from '@/utils';
import './index.less';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {
  storePickUpInfo: ConfirmationPickupInfo_;
  // pickUpTimeOptions: PickUpTimeOption[];
  pickupTimeCode: string;
  onPickupTimeCodeChange?: (code: string) => void;
}

const StoreInfo: React.FC<IProps> = (props) => {
  const { storePickUpInfo, pickupTimeCode, onPickupTimeCodeChange } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [innerPickupTimeCode, setInnerPickupTimeCode] = useState<string>(pickupTimeCode);

  useEffect(() => {
    visible && setInnerPickupTimeCode(pickupTimeCode);
  }, [pickupTimeCode, visible]);

  const switchVisible = useCallback(() => {
    setVisible(true);
  }, []);

  const handlePickupTimeCodeChange = useCallback(
    (code: string) => {
      onPickupTimeCodeChange && onPickupTimeCodeChange(code);
    },
    [onPickupTimeCodeChange],
  );

  const { pickUpTimeTitle, pickUpTimeOptions } = storePickUpInfo || {};

  const pickupTimeText = useMemo(() => {
    return (pickUpTimeOptions || []).find(({ code }) => code === pickupTimeCode)?.text || '';
  }, [pickupTimeCode, pickUpTimeOptions]);

  if (!storePickUpInfo) return null;

  return (
    <div className="mealtime-container">
      <div className="mealtime-content">
        <span className="label">{pickUpTimeTitle || '就餐时间'}</span>
        <span className="time" onClick={switchVisible}>
          <span>{pickupTimeText}</span>
          <img src={right_arrow_icon} />
        </span>
      </div>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        bodyStyle={{ height: 'max-content' }}>
        <div className="time-popup-content">
          <div className="title">{pickUpTimeTitle || '就餐时间'}</div>
          <div className="time-wrapper">
            <Radio.Group
              value={innerPickupTimeCode}
              onChange={(code) => {
                setInnerPickupTimeCode(code as string);
              }}>
              {(pickUpTimeOptions || []).map((i) => {
                const { code, text } = i || {};
                return (
                  <Radio
                    key={code}
                    className="item"
                    value={code}
                    style={{
                      '--font-size': '14px',
                    }}>
                    <span>{text}</span>
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
          <div className="btn-wrapper">
            <Button
              color="primary"
              shape="rounded"
              size="middle"
              onClick={() => {
                setVisible(false);
                handlePickupTimeCodeChange(innerPickupTimeCode);
              }}>
              确定
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default StoreInfo;
