import { useCallback, useEffect, useState } from 'react';
import Header from '@/components/Header';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.less';
import { getCDNAssets } from '@/utils';
import { fetchProtocol } from '@/apis/my';
import type { ProtocolItem } from '@/apis/my';
import useTitle from '@/hooks/useTitle';

const ProtocolPage = () => {
  useTitle('条款与规则');
  const navigate = useNavigate();
  const [data, setData] = useState<ProtocolItem[]>([]);
  const getProtocol = useCallback(() => {
    fetchProtocol()
      .then((res) => {
        if (res) {
          setData(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handletoH5Page = useCallback(
    (url: string) => {
      window.location.href = url;
    },
    [navigate],
  );
  useEffect(() => {
    getProtocol();
  }, []);
  return (
    <div>
      {/* <Header title="条款与规则" /> */}
      <div className={'protocol-view'}>
        {data &&
          data.map((item, index) => {
            return (
              <div
                className={'item-view'}
                key={index}
                onClick={() => {
                  handletoH5Page(item?.url);
                }}>
                {index !== 0 && <div className={'item-divider'}></div>}
                <div className={'item-content'}>
                  <span className={'item-title'}>{item?.title}</span>
                  <img className={'item-arrow'} src={getCDNAssets('right_arrow.png')} />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default ProtocolPage;
