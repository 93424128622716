import React, { useMemo } from 'react';
import { Button } from 'antd-mobile';
import { formatPrice } from '@/utils';
import type { CartTips_1 } from '@/apis/orderConfirm.type';
import './index.less';

interface IProps {
  payTotalPrice: number;
  orderType: number;
  realDeliveryPrice: string;
  deliveryPrice: string;
  tipsList?: CartTips_1[];
  onPay?: () => void;
}

const FooterBar: React.FC<IProps> = (props) => {
  const { payTotalPrice, orderType, realDeliveryPrice, deliveryPrice, tipsList, onPay } = props;

  const couponText = useMemo(() => {
    const tip = (tipsList || []).find(({ type }) => type === 3);
    if (!tip) return;
    const { orderCouponText, orderCouponBalance } = tip;
    const symbol = '{$balance}';
    if (orderCouponText?.includes(symbol)) {
      const couponStr = orderCouponText.replace(symbol, `<span class="num">${orderCouponBalance || ''}</span>`);
      return <div dangerouslySetInnerHTML={{ __html: couponStr }}></div>;
    }
    return orderCouponText;
  }, [tipsList]);

  const showDeliverDiscount = useMemo(
    () => realDeliveryPrice && deliveryPrice && realDeliveryPrice !== deliveryPrice,
    [realDeliveryPrice, deliveryPrice],
  );

  const [intPrice, decimal] = useMemo(() => {
    return (String(payTotalPrice) || '').split('.');
  }, [payTotalPrice]);

  return (
    <div className="order-confirm-footer-bar">
      <div className="footer-bar-content">
        <div className="total">
          <div className="total-price">
            <div className="price">
              <span className="label">合计</span>¥
              <span className="num">
                <span className="int">{intPrice}</span>
                {decimal && <span className="decimal">.{decimal}</span>}
              </span>
            </div>
            {orderType === 2 && (
              <div className="delivery">
                {showDeliverDiscount && (
                  <span className="discount">
                    已减{Number((Number(deliveryPrice) - Number(realDeliveryPrice)).toFixed(2))}元
                  </span>
                )}
                <div className="real-price">
                  {realDeliveryPrice && <>另需配送费¥{realDeliveryPrice}</>}
                  {showDeliverDiscount && <span className="market-price">¥{deliveryPrice}</span>}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="btn">
          <Button
            color="primary"
            shape="rounded"
            onClick={() => {
              onPay && onPay();
            }}>
            去支付
          </Button>
        </div>
        {couponText && <div className="couponText">{couponText}</div>}
      </div>
    </div>
  );
};

export default FooterBar;
