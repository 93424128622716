import type { FC } from 'react';
import { useMemo, useCallback } from 'react';
import { useResource } from '@axios-use/react';
import { useImmer } from 'use-immer';
import { useDeepCompareEffect } from 'ahooks';
import isEmpty from 'lodash/isEmpty';

import type { PopupProps } from 'antd-mobile/es/components/popup';
import { Image, Button, DotLoading } from 'antd-mobile';
import type {
  ProductInfoComboProductType,
  ProductInfoCustomization,
  ProductInfoCustomizationOption,
  ProductInfoCustomizationItem,
  ProductInfoCustomizationItemValueItem,
} from '@/apis/product/types';
import type { PostMcProductDetailQuery } from '@/apis/product';
import { getPOSTMcProductDetailReqConfig } from '@/apis/product';
import { useSupportSafeArea } from '@/hooks/useSupportSafeArea';

import ProductCardPrice from '../../Menu/List/Card/Price';
import MultipSpecialView from '../Options/MultipSpecial';
import SingleSpecialView from '../Options/SingleSpecial';
import CafeSingleSpecial from '../Options/CafeSingleSpecial';

import Popup from '../../external/Popup';

import styles from './popup.module.less';

const mergeCustomization = (customization?: ProductInfoCustomization, init?: ProductInfoCustomization) => {
  if (customization && init) {
    const { items, options } = customization;

    const _items = items?.map((i) => {
      const _i = init.items?.find((ii) => ii.code === i.code);

      const _vs = i.values?.map((v) => {
        const _curv = _i?.values?.find((iv) => iv.code === v.code);
        const _checked = (_i?.values?.length === 1 && Boolean(_curv) && _curv?.checked == null) || _curv?.checked === 1;

        return { ...v, checked: _checked ? 1 : 0 };
      });
      return { ...i, values: _vs };
    });

    const _options = options?.map((o) => {
      const _o = init.options?.find((io) => io.code === o.code);
      if (_o) {
        return { ...o, checked: _o.checked === 1 ? 1 : 0 };
      }
      return o;
    });

    return { items: _items, options: _options };
  }
  return customization;
};

export type ProductCustomSelectorPopupProps = Pick<PopupProps, 'visible'> & {
  initCustomization?: ProductInfoCustomization;
  data?: ProductInfoComboProductType;
  params?: Partial<PostMcProductDetailQuery>;
  onClose?: () => void;
  onComfirm?: (customization?: ProductInfoCustomization, comboProduct?: ProductInfoComboProductType) => boolean | void;
};

const ProductCustomSelectorPopup: FC<ProductCustomSelectorPopupProps> = (props) => {
  const { visible, onClose, onComfirm, params, initCustomization, data } = props;

  const [proRes] = useResource(
    getPOSTMcProductDetailReqConfig,
    [
      {
        productCode: data?.code || params?.productCode || '',
        daypartCode: Number(params?.daypartCode),
        orderType: Number(params?.orderType),
        storeCode: String(params?.storeCode),
        beCode: params?.beCode,
        beType: params?.beType,
        date: params?.date,
        time: params?.time,
        pageSource: 0,
      },
    ],
    {
      filter: (p) => Boolean(p && p.productCode && p.storeCode && p.daypartCode && p.orderType),
      cache: false,
    },
  );

  const customization = useMemo(
    () => (proRes.isLoading === false ? proRes?.data?.product?.customization : undefined),
    [proRes?.data?.product?.customization, proRes.isLoading],
  );
  const [customizationCache, updateCustomizationCache] = useImmer(customization);
  useDeepCompareEffect(() => {
    updateCustomizationCache(mergeCustomization(customization, initCustomization));
  }, [initCustomization, customization]);

  const onOptionsSelect = useCallback(
    (option: ProductInfoCustomizationOption) => {
      updateCustomizationCache((d) => {
        const _index = d?.options.findIndex((o) => o.code === option.code);
        if (_index != null && d?.options) {
          d.options[_index].checked = 1;
        }
      });
    },
    [updateCustomizationCache],
  );
  const onOptionsDeSelect = useCallback(
    (option: ProductInfoCustomizationOption) => {
      updateCustomizationCache((d) => {
        const _index = d?.options.findIndex((o) => o.code === option.code);
        if (_index != null && d?.options) {
          d.options[_index].checked = 0;
        }
      });
    },
    [updateCustomizationCache],
  );
  const onItemsSelect = useCallback(
    (item: ProductInfoCustomizationItem, itemValue?: ProductInfoCustomizationItemValueItem) => {
      updateCustomizationCache((d) => {
        const _index = d?.items.findIndex((o) => o.code === item.code);
        if (_index != null && d?.items) {
          const _values = d.items[_index].values?.map((v) => {
            if (v.code === itemValue?.code) {
              return { ...v, checked: 1 };
            }
            return { ...v, checked: 0 };
          });

          d.items[_index].values = _values;
        }
      });
    },
    [updateCustomizationCache],
  );

  const onReset = useCallback(() => {
    updateCustomizationCache(customization);
  }, [customization, updateCustomizationCache]);

  const onComfirmClick = useCallback(() => {
    const bol = onComfirm?.(customizationCache, data);
    if (bol === true) {
      onClose?.();
    }
  }, [customizationCache, data, onClose, onComfirm]);

  const totalPrice = useMemo(() => {
    let _price = 0;

    if (customizationCache?.items && !isEmpty(customizationCache.items)) {
      customizationCache.items.forEach((i) => {
        i.values?.forEach((v) => {
          if (v.checked === 1 && v.quantity && v.price) {
            _price += v.quantity * v.price;
          }
        });
      });
    }

    return _price;
  }, [customizationCache?.items]);

  const supportSafeArea = useSupportSafeArea();

  return (
    <Popup bodyClassName={styles.popupBody} visible={visible} onMaskClick={onClose}>
      <div className={styles.productImgWrapper} onClick={onClose}>
        <Image className={styles.productImg} placeholder={null} fit="scale-down" src={data?.image} />
      </div>
      <div className={styles.productName}>{data?.name}</div>
      {proRes.isLoading && (
        <div className={styles.loadingWrapper}>
          <DotLoading />
        </div>
      )}
      <div className={styles.content}>
        {customizationCache?.options && !isEmpty(customizationCache.options) && (
          <>
            <div className={styles.specialTitle}>餐品定制(多选)</div>
            <MultipSpecialView
              items={customizationCache.options}
              onSelect={onOptionsSelect}
              onDeSelect={onOptionsDeSelect}
            />
          </>
        )}
        {proRes?.data?.product?.type === 1 && proRes.data.product.bu !== 2 && !isEmpty(customizationCache?.items) && (
          <SingleSpecialView items={customizationCache?.items} onSelect={onItemsSelect} />
        )}
        {proRes?.data?.product?.type === 1 && proRes.data.product.bu === 2 && !isEmpty(customizationCache?.items) && (
          <CafeSingleSpecial items={customizationCache?.items} onSelect={onItemsSelect} />
        )}
      </div>

      <div className={styles.footer} data-supportsafearea={supportSafeArea}>
        <div className={styles.footerBtnsWrapper}>
          <Button shape="rounded" className={styles.restBtn} disabled={proRes.isLoading} onClick={onReset}>
            恢复默认
          </Button>
          <Button
            shape="rounded"
            color="primary"
            className={styles.comfirmBtn}
            disabled={proRes.isLoading}
            onClick={onComfirmClick}>
            {!!totalPrice && <ProductCardPrice className={styles.btnPrice} originalPrice={totalPrice} />}
            选好了
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default ProductCustomSelectorPopup;
