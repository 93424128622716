import type { FC, PropsWithChildren } from 'react';

import type { ButtonProps } from 'antd-mobile/es/components/button';
import { SearchOutline } from 'antd-mobile-icons';
import { Button, Space } from 'antd-mobile';

const ProductFakerSearch: FC<PropsWithChildren<ButtonProps>> = (props) => {
  const { children, style, ...rest } = props;

  return (
    <Button
      style={{
        fontSize: 'var(--adm-font-size-6)',
        color: '#999999',
        padding: '0 11px',
        lineHeight: '30px',
        height: '32px',
        textAlign: 'left',
        background: '#F2F2F2',
        borderColor: '#F2F2F2',
        ...style,
      }}
      block
      size="small"
      shape="rounded"
      {...rest}>
      <Space style={{ '--gap': '6px' }}>
        <span style={{ fontSize: '20px' }}>
          <SearchOutline />
        </span>
        <span>{'请输入关键词' || children}</span>
      </Space>
    </Button>
  );
};

export default ProductFakerSearch;
