import { ep_icon } from '@/constants';
import { getCDNAssets } from '@/utils';

export interface PickUpTimeOption {
  code: string;
  text: string;
  itemType: string;
  selected?: boolean;
}

export const defaultEatTypeCode = 'eat-in';

export const eatTypeList = [
  {
    eatTypeCode: defaultEatTypeCode,
    title: '堂食',
    subTitle: '店内用餐',
    imgSrc: getCDNAssets('order_confirmation_eat.png'),
  },
  {
    eatTypeCode: 'take-in-store',
    title: '外带',
    subTitle: '店内自提',
    imgSrc: getCDNAssets('order_confirmation_instore.png'),
  },
];

export const defaultPickUpCode = 'now';

export const defaultPickUpTimeOptions = [
  {
    code: defaultPickUpCode,
    text: '现在取餐',
    itemType: '1',
    selected: true,
  },
  {
    code: 'ten',
    text: '10分钟后到店',
    itemType: '1',
  },
  {
    code: 'twenty',
    text: '20分钟后到店',
    itemType: '1',
  },
  {
    code: 'thirty',
    text: '30分钟后到店',
    itemType: '1',
  },
];

export enum TablewareCode {
  no = 'no',
  yes = 'yes',
}

// 餐具费用 单位分
export const tablewareCost = 10;

export const tablewareCodeOptions = [
  {
    code: TablewareCode.no,
    text: (
      <div className="tableware-text">
        <img className="ep" src={ep_icon} />
        <span>不需要</span>
      </div>
    ),
  },
  {
    code: TablewareCode.yes,
    text: (
      <div className="tableware-text">
        <span>需要</span>
      </div>
    ),
  },
];

export const imgSrcMap = {
  'eat-in': getCDNAssets('order_confirmation_eat.png'),
  'take-in-store': getCDNAssets('order_confirmation_instore.png'),
  'take-out-store': getCDNAssets('order_confirmation_outdoor.png'),
  'take-dt': getCDNAssets('order_confirmation_dls.png'),
};

export const filterEatTypeCodes = ['eat-in', 'take-out-store', 'take-in-store', 'take-dt'];

export const daypartMap = {
  '1': '早餐',
  '2': '正餐',
  '4': '下午茶',
  '5': '夜市',
  '6': '宵夜',
  '8': '午餐',
};
