import type { CSSProperties, FC } from 'react';
import { useMemo, useState, useCallback, useEffect, lazy, Suspense } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { Toast } from 'antd-mobile';
import useQuery from '@/hooks/useQuery';
import type {
  MenuListProductItem,
  PromotionCouponsCardDiscountItem,
  MenuBannerItem,
  PromotionCouponProductsType,
} from '@/apis/product/types';
import { useCartAction, useCartState } from '@/hooks/useCart';
import { useSetTitleStyle } from '@/hooks/useUpFuncs';
import { MY_ADDRESS, SELECT_CANTEEN, COUPONS_DETAIL, HOME, AVAILABLE_CARD_LIST } from '@/constants/path';

import ProductBanner from './Banner';
import {
  ProductMenuList,
  ProductMenuStoreInfo,
  ProductMenuCouponDiscount,
  ProductMenuListSkeleton,
  ProductMenuStoreInfoSkeleton,
  useStoreDaypartTimer,
} from './Menu';
import ProductFakerSearch from './Search/Faker';
import { EMenusPageErrEvent } from './Error/types';
import ProductError from './Error';

import useProductData from './useData';
import { useScrollLayout, BANNER_HEIGHT, SEARCH_NODE_HEIGHT } from './useScrollLayout';
import { useLocationAutoCoupon, useGetCouponProductList } from './useCoupon';
import { useGoToAddressBefore, useScrollState } from './external';
import Spin from './external/Spin';

import './index.less';
import { trackEvent } from '@/utils/tracker';

const ProductCartModule = lazy(() => import('./Cart'));
const DaypartDatePopup = lazy(() => import('./Menu/Store/DatePopup'));

type UrlQuery = {
  orderType?: string;
  // for orderType 1
  storeCode?: string;
  // TDDO unknown
  newStoreCode?: string;

  // for orderType 2
  addressId?: string;
  // TDDO unknown
  newAddressId?: string;

  /** 再来一单 订单ID */
  orderId?: string;
  /** 是否可以更改地址/餐厅 1 or 0 @default '1' */
  changeAddress?: string;

  /** 推荐的产品code, 将自动滚动到此处 */
  recommendProductCode?: string;
  /** 推荐产品分类code, 将自动滚动到此处 */
  recommendCategoryCode?: string;
};

export type ProductPageProps = {
  orderType?: number;
};

const defaultProps: Partial<ProductPageProps> = {
  orderType: 1,
};

const ProductPage: FC<ProductPageProps> = (props) => {
  const { orderType: propsOrderType } = props;
  const location = useLocation();
  const query = useQuery<UrlQuery>();
  const orderType = Number(query?.orderType) || propsOrderType;
  const changeAddress = Boolean(query?.changeAddress != null ? query.changeAddress === '1' : true);

  useSetTitleStyle({
    navBackgroundColor: '00FFFFFF',
    appletStyle: 'black',
    backBtnVisible: '1',
    appletTitleBarVisible: '1',
  });

  const [scrollState, scrollStateAction] = useScrollState();

  const [cartstate] = useCartState(orderType);
  const cartCouponCodes = useMemo(
    () => cartstate?.value?.products?.map((p) => p.coupon?.code)?.filter(Boolean) as string[],
    [cartstate?.value?.products],
  );

  const navigate = useNavigate();

  const [datePopupVisible, setDatePopupVisible] = useState(false);

  const {
    errorStatus,
    vicinityStatusCode,
    activeStore,
    baseLoading,
    storeLoading,
    addressInfo,
    tempLocation,
    storeInfo,
    refreshStoreOrAddressInfo,
    daypartCode,
    daypart,
    menuList,
    menuListLoaing,
    refreshMenuList,
    menuBanner,
    queryAppointmentStr,
    curDateInfo,
    updateDeliveryDateInfo,
    menuRightCardInfo,
    refreshMenuRightCardInfo,
  } = useProductData({
    orderType,
    storeCode: query?.newStoreCode || query?.storeCode,
    addressId: query?.newAddressId || query?.addressId,
  });

  useStoreDaypartTimer({
    dayparts: storeInfo?.dayparts,
    curDaypart: (!curDateInfo?.time || !curDateInfo?.date) && activeStore ? daypartCode : undefined,
    endCallback: () => {
      void refreshStoreOrAddressInfo();
    },
  });

  const { updateCart, loginBtnNode, syncTheLatest } = useCartAction({
    onErrLoginSuc: () => {
      refreshMenuRightCardInfo();

      if (cartstate?.requestQuery) {
        void syncTheLatest(cartstate?.requestQuery);
      }
    },
  });

  const { containerRef, listPanelRef, fullScreen, menuListRef, storeNodeRef, menuListOffsetTop } = useScrollLayout({
    storeEffectDeps: [!baseLoading, !!storeInfo?.name],
  });

  useEffect(() => {
    if (scrollState?.scrollY && containerRef.current) {
      containerRef.current?.scroll({ top: scrollState.scrollY });

      if (baseLoading === false && !errorStatus && activeStore) {
        setTimeout(() => {
          containerRef.current?.scroll({ top: scrollState.scrollY });
          scrollStateAction.set({ scrollY: undefined });
        }, 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStore, baseLoading, errorStatus, scrollState?.scrollY]);

  const onSearchClick = useCallback(() => {
    // cache scrollY
    scrollStateAction.set({ scrollY: containerRef.current?.scrollTop });
    trackEvent('searchButtonClick', {
      belong_page: orderType === 1 ? '到店取餐菜单页' : '麦乐送菜单页',
    });
    navigate({
      pathname: '/product/search',
      search: new URLSearchParams({
        storeCode: storeInfo?.code || '',
        daypartCode: String(daypartCode),
        orderType: String(orderType),
        beCode: storeInfo?.beCode || '',
        addressId: addressInfo?.id || '',
        appointment: queryAppointmentStr,
      }).toString(),
    });
  }, [
    addressInfo?.id,
    containerRef,
    daypartCode,
    navigate,
    orderType,
    queryAppointmentStr,
    scrollStateAction,
    storeInfo?.beCode,
    storeInfo?.code,
  ]);

  const onProductCardClick = useCallback(
    (p: MenuListProductItem) => {
      // cache scrollY
      scrollStateAction.set({ scrollY: containerRef.current?.scrollTop });

      navigate({
        pathname: `/product/${p.productCode}`,
        search: new URLSearchParams({
          storeCode: storeInfo?.code || '',
          daypartCode: String(daypartCode),
          orderType: String(orderType),
          beCode: storeInfo?.beCode || '',
          appointment: queryAppointmentStr,
        }).toString(),
      });
    },
    [
      containerRef,
      daypartCode,
      navigate,
      orderType,
      queryAppointmentStr,
      scrollStateAction,
      storeInfo?.beCode,
      storeInfo?.code,
    ],
  );

  const onProductAddAction = useCallback(
    async (item: MenuListProductItem) => {
      try {
        await updateCart({
          cartType: '1',
          orderType: Number(orderType),
          daypartCode: String(daypartCode),
          storeCode: storeInfo?.code || '',
          beCode: storeInfo?.beCode || '',
          date: curDateInfo?.date,
          time: curDateInfo?.time,
          products: [
            {
              quantity: 1,
              code: item.productCode,
              name: item.productName,
              type: item.productType,
            },
          ],
        });
      } catch (error) {
        const errMsg = (error as Error)?.message || '加入购物车失败';
        Toast.show({
          content: errMsg,
          position: 'top',
        });
      }
    },
    [curDateInfo?.date, curDateInfo?.time, daypartCode, orderType, storeInfo?.beCode, storeInfo?.code, updateCart],
  );
  const onProductSubAction = useCallback(
    async (item: MenuListProductItem) => {
      const curCartProduct = cartstate?.value?.products?.find((p) => p.code === item.productCode);

      if (curCartProduct) {
        try {
          await updateCart({
            cartType: '1',
            orderType: Number(orderType),
            daypartCode: String(daypartCode),
            storeCode: storeInfo?.code || '',
            beCode: storeInfo?.beCode || '',
            date: curDateInfo?.date,
            time: curDateInfo?.time,
            products: [
              {
                sequence: curCartProduct.sequence,
                quantity: -1,
                code: item.productCode,
                name: item.productName,
                type: item.productType,
              },
            ],
          });
        } catch (error) {
          const errMsg = (error as Error)?.message || '移除失败';
          Toast.show({
            content: errMsg,
            position: 'top',
          });
        }
      }
    },
    [
      cartstate?.value?.products,
      curDateInfo?.date,
      curDateInfo?.time,
      daypartCode,
      orderType,
      storeInfo?.beCode,
      storeInfo?.code,
      updateCart,
    ],
  );

  const [toAddressBeforeCache] = useGoToAddressBefore(orderType);
  const onChangeAddress = useCallback(() => {
    if (orderType === 1) {
      // storeAddress page start ---
      toAddressBeforeCache();
      // --- end storeAddress page
      navigate({
        pathname: SELECT_CANTEEN,
      });
    } else if (orderType === 2) {
      // storeAddress page start ---
      toAddressBeforeCache();
      // --- end storeAddress page
      navigate({
        pathname: MY_ADDRESS,
      });
    }
  }, [navigate, orderType, toAddressBeforeCache]);

  const onErrButtonClick = useCallback(
    (type?: EMenusPageErrEvent) => {
      if (type === EMenusPageErrEvent.APPOINTMENT) {
        setDatePopupVisible(true);
      } else if (type === EMenusPageErrEvent.RELOAD_MENUS) {
        refreshMenuList();
      } else {
        onChangeAddress();
      }
    },
    [onChangeAddress, refreshMenuList],
  );

  const { craeteCouponProductListReq } = useGetCouponProductList();
  const addCouponDiscountToCart = useCallback(
    async (
      multipleProducts?: boolean,
      obj?: Pick<PromotionCouponsCardDiscountItem, 'id' | 'code' | 'promotionId' | 'title'>,
      couponProducts?: PromotionCouponProductsType,
    ) => {
      if (obj?.code && obj?.id) {
        if (multipleProducts) {
          navigate({
            pathname: '/product/coupon/select',
            search: new URLSearchParams({
              storeCode: storeInfo?.code || '',
              daypartCode: String(daypartCode),
              orderType: String(orderType),
              beCode: storeInfo?.beCode || '',
              couponCode: obj.code || '',
              couponId: obj.id || '',
              promotionId: obj.promotionId || '',
              name: obj.title || '',
              appointment: queryAppointmentStr,
            }).toString(),
          });
        } else {
          let _couponProducts = couponProducts;
          if (!_couponProducts && obj.code && obj.id) {
            try {
              const [_res] = await craeteCouponProductListReq({
                storeCode: storeInfo?.code || '',
                daypartCode: String(daypartCode),
                orderType: String(orderType),
                beCode: storeInfo?.beCode || '',
                date: curDateInfo?.date,
                time: curDateInfo?.time,
                couponCode: obj.code,
                couponId: obj.id,
                promotionId: obj.promotionId,
              }).ready();
              _couponProducts = _res;
            } catch (error) {
              console.error('[addCouponDiscountToCart]-craeteCouponProductListReq error.', error);
            }
          }

          if (_couponProducts?.productList?.length && _couponProducts.productList.length > 0) {
            const _p = _couponProducts.productList[0];

            if (_p.supportChoice === 1 && _p.productCode && _p.productType && [1, 2, 3].includes(_p.productType)) {
              navigate({
                pathname: `/product/${_p.productCode}`,
                search: new URLSearchParams({
                  storeCode: storeInfo?.code || '',
                  daypartCode: String(daypartCode),
                  orderType: String(orderType),
                  beCode: storeInfo?.beCode || '',
                  couponCode: obj.code,
                  couponId: obj.id,
                  promotionId: obj.promotionId || '',
                  appointment: queryAppointmentStr,
                  disabledCount: '1',
                }).toString(),
              });
              return;
            }
          }
          try {
            await updateCart({
              cartType: '1',
              orderType: Number(orderType),
              daypartCode: String(daypartCode),
              storeCode: storeInfo?.code || '',
              beCode: storeInfo?.beCode || '',
              date: curDateInfo?.date,
              time: curDateInfo?.time,
              operation: 0,
              products: [
                {
                  operationType: 0,
                  couponCode: obj.code,
                  couponId: obj.id,
                  promotionId: obj.promotionId,
                  quantity: 1,
                },
              ],
            });
          } catch (error) {
            const errMsg = (error as Error)?.message || '加入购物车失败';
            Toast.show({
              content: errMsg,
              position: 'top',
            });
          }
        }
      }
    },
    [
      craeteCouponProductListReq,
      curDateInfo?.date,
      curDateInfo?.time,
      daypartCode,
      navigate,
      orderType,
      queryAppointmentStr,
      storeInfo?.beCode,
      storeInfo?.code,
      updateCart,
    ],
  );
  const onCouponDiscountAdd = useCallback(
    (item: PromotionCouponsCardDiscountItem) => {
      void addCouponDiscountToCart(item.multipleProducts, item);
    },
    [addCouponDiscountToCart],
  );
  // 购物券 路由参数检查
  const checkAutoAddCoupon = useLocationAutoCoupon({
    params: {
      storeCode: storeInfo?.code || '',
      daypartCode: String(daypartCode),
      orderType: String(orderType),
      beCode: storeInfo?.beCode || '',
      date: curDateInfo?.date,
      time: curDateInfo?.time,
    },
    callback: (item, state) =>
      addCouponDiscountToCart(
        item.multipleProducts,
        {
          code: state.couponCode,
          id: state.couponId,
          promotionId: state.promotionId,
          title: item?.productList?.[0].productName,
        },
        item,
      ),
  });
  useEffect(() => {
    if (activeStore) {
      void checkAutoAddCoupon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStore]);

  const onCouponDiscountCardClick = useCallback(
    (item: PromotionCouponsCardDiscountItem) => {
      if (item.code && item.id) {
        trackEvent('couponClick', {
          belong_page: orderType === 1 ? '到店取餐菜单页' : '麦乐送菜单页',
          card_id: item.id,
          coupon_name: item.title,
          coupon_type: orderType === 1 ? '到店专用' : '外送专用',
        });

        navigate({
          pathname: COUPONS_DETAIL,
          search: new URLSearchParams({
            code: item.code,
            id: item.id,
          }).toString(),
        });
      }
    },
    [navigate, orderType],
  );
  const onCouponDiscountShowMore = useCallback(() => {
    navigate({
      pathname: AVAILABLE_CARD_LIST,
      search: new URLSearchParams({
        storeCode: storeInfo?.code || '',
        daypartCode: String(daypartCode),
        orderType: String(orderType),
        beCode: storeInfo?.beCode || '',
        beType: storeInfo?.beType || '',
        date: curDateInfo?.date || '',
        time: curDateInfo?.time || '',
        scene: '2',
      }).toString(),
    });
  }, [
    curDateInfo?.date,
    curDateInfo?.time,
    daypartCode,
    navigate,
    orderType,
    storeInfo?.beCode,
    storeInfo?.beType,
    storeInfo?.code,
  ]);

  const onBannerItemClick = useCallback(
    (item: MenuBannerItem) => {
      if (item?.redirectUrl) {
        // 是否为当前页面
        const _isCurrentPath = /^\/product\?((=?&?[a-zA-Z0-9_-](\?)?)*)*$/i.test(item.redirectUrl);
        const _redirectUrl = _isCurrentPath ? `${item.redirectUrl}&withcache=1` : item.redirectUrl;
        navigate(_redirectUrl, { replace: _isCurrentPath });
      }
    },
    [navigate],
  );

  const onCartsNavToProductDetailCallback = useCallback(() => {
    // cache scrollY
    scrollStateAction.set({ scrollY: containerRef.current?.scrollTop });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollStateAction]);

  const onAutoscrollCompleteByUrl = useCallback(() => {
    const { recommendProductCode, recommendCategoryCode, ...restQuery } = query || {};
    navigate(
      {
        pathname: location.pathname,
        search: new URLSearchParams(restQuery as Record<string, string>).toString(),
      },
      { replace: true },
    );
  }, [location.pathname, navigate, query]);

  useEffect(() => {
    if (menuRightCardInfo?.menuDiscount?.discountItems) {
      menuRightCardInfo.menuDiscount.discountItems
        .filter((i) => i.code !== 'CPN_KINGS_LIFE')
        .forEach((i) => {
          trackEvent('couponExposure', {
            belong_page: orderType === 1 ? '到店取餐菜单页' : '麦乐送菜单页',
            card_id: i.id,
            coupon_name: i.title,
            coupon_type: orderType === 1 ? '到店专用' : '外送专用',
          });
        });
    }
  }, [menuRightCardInfo?.menuDiscount, orderType]);

  // [PATCH] switch address/store history
  useEffect(() => {
    const _goHome = () => {
      navigate(HOME, { replace: true });
    };
    window.addEventListener('popstate', _goHome);
    return () => window.removeEventListener('popstate', _goHome);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // --- patch end

  return (
    <div className="product-container" ref={containerRef}>
      <div
        className="product-search-wrapper"
        style={{ '--height': SEARCH_NODE_HEIGHT } as CSSProperties}
        data-full={fullScreen}>
        <ProductFakerSearch onClick={onSearchClick} />
      </div>
      <ProductBanner
        className="product-banner"
        items={menuBanner}
        itemHeight={BANNER_HEIGHT}
        loop
        autoplay={baseLoading === false && !fullScreen}
        autoplayInterval={5000}
        onItemClick={onBannerItemClick}
      />
      <div className="product-menu-list-wrapper" ref={listPanelRef} data-full={fullScreen}>
        {(!storeLoading && storeInfo) || !baseLoading ? (
          <ProductMenuStoreInfo
            ref={storeNodeRef}
            className="product-store-container"
            orderType={orderType}
            data={storeInfo}
            address={addressInfo?.address || tempLocation?.name}
            appointmentDate={{ date: curDateInfo?.date, time: curDateInfo?.time }}
            hideDaypartDisplay={!!errorStatus}
            showSwitchAddress={changeAddress}
            onChangeAddress={onChangeAddress}
            onSwitchTime={() => setDatePopupVisible(true)}
          />
        ) : (
          <ProductMenuStoreInfoSkeleton />
        )}
        {activeStore && menuRightCardInfo?.menuDiscount && (
          <Spin loading={baseLoading}>
            <ProductMenuCouponDiscount
              className="product-coupon-discount"
              selectedCodes={cartCouponCodes}
              data={menuRightCardInfo.menuDiscount}
              onAdd={onCouponDiscountAdd}
              onCardClick={onCouponDiscountCardClick}
              onMore={onCouponDiscountShowMore}
            />
          </Spin>
        )}
        {(menuListLoaing || baseLoading) && isEmpty(menuList?.menu) ? (
          <ProductMenuListSkeleton className="product-menu-list" />
        ) : errorStatus ? (
          <ProductError
            status={errorStatus}
            subErrCode={vicinityStatusCode}
            orderType={orderType}
            onBtnClick={onErrButtonClick}
          />
        ) : activeStore ? (
          <Spin loading={menuListLoaing || baseLoading}>
            <ProductMenuList
              ref={menuListRef}
              wrapperScrollRef={containerRef}
              className="product-menu-list"
              orderType={orderType}
              storeInfo={storeInfo}
              daypart={daypart}
              items={menuList?.menu}
              cartQuantityGroup={cartstate?.productQuantityGroup}
              sideCanAutoScroll={fullScreen}
              offsetTop={menuListOffsetTop}
              autoScroll={
                menuListLoaing === false && menuListOffsetTop != null
                  ? {
                      productCode: query?.recommendProductCode,
                      categoryCode: query?.recommendCategoryCode,
                      onComplete: onAutoscrollCompleteByUrl,
                    }
                  : undefined
              }
              onCardClick={onProductCardClick}
              onCardSelect={onProductCardClick}
              onCardAdd={onProductAddAction}
              onCardSub={onProductSubAction}
            />
          </Spin>
        ) : null}
      </div>

      <Suspense>
        {activeStore && !errorStatus && storeInfo && !baseLoading && (
          <ProductCartModule
            cartType={1}
            addressId={addressInfo?.id}
            orderType={orderType}
            storeCode={storeInfo?.code}
            daypartCode={String(daypartCode)}
            other={{
              beCode: storeInfo.beCode || undefined,
              beType: storeInfo.beType || undefined,
            }}
            navToProductDetailCallback={onCartsNavToProductDetailCallback}
          />
        )}
        {!baseLoading && storeInfo && orderType === 2 && (
          <DaypartDatePopup
            params={{
              storeCode: storeInfo.code,
              dayPartCode: daypartCode || undefined,
              beCode: storeInfo.beCode || undefined,
              beType: storeInfo.beType || undefined,
            }}
            visible={datePopupVisible}
            value={[curDateInfo?.date, daypartCode || undefined, curDateInfo?.time]}
            onClose={() => setDatePopupVisible(false)}
            onConfirm={(_d, _daypartCode, _time) => {
              updateDeliveryDateInfo({
                date: _d,
                daypartCode: _daypartCode,
                time: _time,
              });
            }}
          />
        )}
      </Suspense>

      {loginBtnNode}
    </div>
  );
};

ProductPage.defaultProps = defaultProps;

export default ProductPage;
