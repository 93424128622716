const md5 = require('md5');

const signKeys = {
  10: '71d414c0-8fe1-495d-ac55-207414632479',
  11: '71d414c0-8fe1-495d-ac55-207414632479',
  20: '59b891d7-8d5a-4993-9b16-37d905a16967',
  21: 'ad5bac4a-2f99-4728-a7e5-818b5770b559',
  30: '3c0860ff-fabe-436a-ba47-94d42669591b',
  31: '71d414c0-8fe1-495d-ac55-207414632479',
  32: '71d414c0-8fe1-495d-ac55-207414632479',
  33: '71d414c0-8fe1-495d-ac55-207414632479',
  34: '71d414c0-8fe1-495d-ac55-207414632479',
  36: '71d414c0-8fe1-495d-ac55-207414632479',
  37: '71fc1519-41d8-c7f9-f6e2-0a68aae263af',
  96: '71d414c0-8fe1-495d-ac55-207414632479',
  302: '71d414c0-8fe1-495d-ac55-207414632479',
  101: '97741bd7-21bb-4f0d-8b4a-501a089cd208',
  102: '97741bd7-21bb-4f0d-8b4a-501a089cd208',
};

const signKeysWithEnv = {
  dev: {
    37: '11d1193b-ed55-6199-da71-bb7a0454d48e',
  },
  sit: {
    37: 'f9eea639-6469-637d-c018-07e9c483b3e0',
  },
  uat: {
    37: '5278e39d-db01-93ea-4a5e-ce8ce4528211',
  },
  pt: {
    37: '22e65865-459a-ff8c-f59c-c3381ed76e78',
  },
  prd: {
    37: '71fc1519-41d8-c7f9-f6e2-0a68aae263af'
  }
}

function obj2values(object) {
  var values = [];
  for (var k in object) {
    values.push(object[k]);
  }
  return values;
}

function obj2keys(object) {
  var keys = [];
  for (var key in object) {
    keys.push(key);
  }
  return keys;
}

export const nakedSign = (config, options = {}) => {
  const arrangedConfig = Object.assign({
    data: {},
    params: {},
    path: [],
    headers: {},
  }, config);
   arrangedConfig.headers['ct'] = arrangedConfig.headers['ct'] || 10;

  const ct = arrangedConfig.headers['ct'];
  let env = config.env;
  let mapKey = signKeys[ct]; // 默认值
  if (!env) {
    if (arrangedConfig.url.match(/-dev\./)) {
      env = 'dev';
    } else if (arrangedConfig.url.match(/-sit\./)) {
      env = 'sit';
    } else if (arrangedConfig.url.match(/-uat\./)) {
      env = 'uat';
    } else if (arrangedConfig.url.match(/-pt\./)) {
      env = 'pt';
    } else {
      env = 'prd';
    }
  }
  if (signKeysWithEnv[env]) mapKey = signKeysWithEnv[env][ct] || signKeys[ct];

  if (!Array.isArray(arrangedConfig.path)) arrangedConfig.path = [];

  const arrangedParams = {};
  obj2keys(arrangedConfig.params).sort().forEach((key) => {
    if (arrangedConfig.params[key] === 0 
      ||  arrangedConfig.params[key] === true
      ||  arrangedConfig.params[key] === false
      ||  arrangedConfig.params[key]) {

      if (arrangedConfig.params[key] === true) {
        arrangedParams[key] = key + '=true';
      } else if (arrangedConfig.params[key] === false) {
        arrangedParams[key] = key + '=false';
      } else {
        arrangedParams[key] = key + '=' + arrangedConfig.params[key];
      }
    }
  });
  arrangedConfig.params = arrangedParams;

  const arrangedHeaders =  {};
  const allowedHeaderKeys = ['ct', 'language', 'ov', 'p', 'sid', 'token', 'v','st','nonce'];
  obj2keys(arrangedConfig.headers).sort().filter((key) => {
    if ((arrangedConfig.headers[key] === 0 || arrangedConfig.headers[key]) && allowedHeaderKeys.includes(key)) {
      arrangedHeaders[key] = key + '=' + arrangedConfig.headers[key];
    }
  });
  arrangedConfig.headers = arrangedHeaders;

  let unsignedArr = [];
  const SEP = (ct === 101 || ct === 102) ? '#' : '&';
  if (SEP === '#') unsignedArr.push(obj2values(arrangedConfig.headers).join(SEP));
  
  if (arrangedConfig.data) {
    const data = JSON.stringify(arrangedConfig.data);
    if (data !== '{}') unsignedArr.push(data);
  }

  const params = obj2values(arrangedConfig.params).join(SEP);
  if (params) unsignedArr.push(params);
  unsignedArr.push(arrangedConfig.path.join(','));
  if (SEP === '&') unsignedArr.push(obj2values(arrangedConfig.headers).join(SEP));

  unsignedArr = unsignedArr.filter(item => (item && item !== '{}'));

  let signed;
  if (unsignedArr.length > 0) {
    if (mapKey) {
      unsignedArr.push('key=' + mapKey);
    } else {
      console.warn(`[WARN] ct=${ct} is not in pre-defined keys.`);
    }
    
    const plainText = unsignedArr.join(SEP);
    if (options.debug) console.log('[DEBUG] @omc/api-signer - plainText: ', plainText);
    signed = md5(plainText);
  }

  return signed;
};

export const sign = (config, options = {}) => {
  config = config || {};
  const args = {};
  if (config.params) {
    args.params = config.params;
  }

  if (config.method.toLowerCase() !== 'get') {
    args.data = config.data;
  }

  if (config.channel && config.channel === 'mini') { // compatible miniApp
    if (config.method.toLowerCase() !== 'get') {
      args.params = {};
      args.data = config.data;
    } else {
      args.params = config.data;
      args.data = {};
    }
  }

  if (config.pathVars) {
    args.path = config.pathVars;
  }

  args.headers = config.headers || config.header; // config.header:compatible miniApp
  args.url = config.url;
  args.env = config.env;

  return nakedSign(args, options);
}

export default {
  sign,
  nakedSign
};