/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Toast } from 'antd-mobile';
import type { RootState } from '@/store/';
import {
  updateStoreInfo,
  updateStoreExtraInfo,
  updateNewStore,
  updateAddressExtraInfo,
  updateNewAddress,
} from '@/store/addressSlice';
import type { ChangePickTimeParams, ChangeDeliveryTimeParams } from '@/apis/orderConfirm';
import { changeStore, changePickTime, changeDeliveryTime, submitOrder, changeAddress } from '@/apis/orderConfirm';
import type {
  SwitchParams,
  SwitchAddressParams,
  OrderParams,
  Product_,
  ConfirmationProduct_,
  Carts,
  Coupon_2,
  ConfirmationDeliveryDateTimeOption_,
  CommonSelectOption,
  TablewareInfo,
  InvoiceInfo,
  Coupon_3,
} from '@/apis/orderConfirm.type';
import StoreInfo from './components/StoreInfo';
import AddressInfo from './components/AddressInfo';
import Mealtime from './components/Mealtime';
import ProductList from './components/ProductList';
import Tableware from './components/Tableware';
import FooterBar from './components/FooterBar';
import useTitle from '@/hooks/useTitle';
import { useStoreActionsForExternal } from '@/hooks/useStore';

import type { PickUpTimeOption } from './constant';
import {
  defaultPickUpTimeOptions,
  defaultPickUpCode,
  TablewareCode,
  tablewareCost,
  // defaultEatTypeCode,
  filterEatTypeCodes,
  daypartMap,
} from './constant';
import { SELECT_CANTEEN, MY_ADDRESS, ORDER_PAY, ORDER_DETAIL } from '@/constants/path';
import useQuery from '@/hooks/useQuery';
import { useOrderConfirmInfoInit, useOrderConfimState, useOrderConfirmAction } from '@/hooks/useOrderConfirm';

import './index.less';
import useLocationInfo from '@/hooks/useLocationInfo';
import { trackEvent } from '@/utils/tracker';

// TODO
type UrlQuery = {
  orderType: string;
  storeCode: string;
  daypartCode: string;
  cartType?: string;
  beCode?: string;
  addressId?: string;
  expectDeliveryTime?: string;
  beType?: string;
};

const OrderConfirm = () => {
  useTitle('订单确认', '0xFFEEEEEE');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newStoreCode = useSelector((state: RootState) => state.address.newStoreCode);
  const newAddressId = useSelector((state: RootState) => state.address.newAddressId);
  const { locationAndCityInfo } = useLocationInfo();
  console.log('=====locationAndCityInfo', locationAndCityInfo);

  const { updateProductNewCodeId } = useStoreActionsForExternal();

  const { updateOrderConfirmDataBySwitch, updateOrderConfirmData } = useOrderConfirmAction();

  const query = useQuery<UrlQuery>();
  const orderType = Number(query?.orderType) || 1;
  const storeCode = query?.storeCode;
  const daypartCode = query?.daypartCode;
  const cartType = Number(query?.cartType) || 1;
  let beCode = query?.beCode;
  let beType = query?.beType;
  let addressId = query?.addressId;
  const expectDeliveryTime = query?.expectDeliveryTime;
  useOrderConfirmInfoInit({
    cartType,
    orderType,
    storeCode,
    dayPartCode: daypartCode,
    beCode,
    beType,
    addressId,
    expectDeliveryTime,
  });

  const [data, status] = useOrderConfimState();
  const {
    storeInfo,
    storePickUpInfo,
    tablewareInfo: initTablewareInfo,
    invoiceInfo: initInvoiceInfo,
    productPriceInfo,
    deliveryInfo,
    dayPart,
    tipsList,
  } = data || {};

  // 切换之后的数据修正
  addressId = deliveryInfo?.deliveryAddressId || addressId;
  beCode = storeInfo?.beCode || beCode;
  beType = storeInfo?.beType || beType;

  const {
    realTotalAmount,
    tablewarePrice,
    productTotalPrice,
    realDeliveryPrice,
    deliveryPrice,
    orderCouponInfo,
    freightCouponInfo,
  } = productPriceInfo || {};

  const [eatTypeCode, setEatTypeCode] = useState<string>();
  const [pickupTimeCode, setPickupTimeCode] = useState<string>();
  const [tablewareCode, setTablewareCode] = useState<string>();
  const [remark, setRemark] = useState<string>();

  const [tablewareInfo, setTablewareInfo] = useState<TablewareInfo>();
  const [invoiceInfo, setInvoiceInfo] = useState<InvoiceInfo>();

  // 初始化餐具信息 以后会丢 所以初始保存
  useEffect(() => {
    initTablewareInfo && setTablewareInfo(initTablewareInfo);
  }, [initTablewareInfo]);

  // 初始化发票信息 以后会丢 所以初始保存
  useEffect(() => {
    initInvoiceInfo && setInvoiceInfo(initInvoiceInfo);
  }, [initInvoiceInfo]);

  // 初始化取餐方式
  useEffect(() => {
    const canUsedEatType = (storePickUpInfo?.eatTypeOptions || []).filter(({ code }) =>
      filterEatTypeCodes.includes(code!),
    );
    const defaultEatType = canUsedEatType.find(({ selected }) => selected);
    const defaultEatCode = defaultEatType?.code || canUsedEatType[0]?.code;
    if (!eatTypeCode && defaultEatCode) {
      setEatTypeCode(defaultEatCode);
    }
  }, [storePickUpInfo?.eatTypeOptions, eatTypeCode]);

  // 初始化就餐方式
  useEffect(() => {
    const defaultPickupTime = (storePickUpInfo?.pickUpTimeOptions || []).find(({ selected }) => selected);
    if (defaultPickupTime?.code) {
      setPickupTimeCode(defaultPickupTime?.code);
    }
  }, [storePickUpInfo?.pickUpTimeOptions, pickupTimeCode]);

  // 初始化餐具需求
  useEffect(() => {
    const defaultTableware = (tablewareInfo?.options || []).find(({ selected }) => selected);
    if (!tablewareCode && defaultTableware?.code) {
      setTablewareCode(defaultTableware?.code);
    }
  }, [tablewareInfo?.options, tablewareCode]);

  const init = useCallback(() => {}, []);

  useEffect(() => {
    init();
  }, []);

  // 切换店回流
  useEffect(() => {
    if (String(orderType) === '1' && storeInfo && storeInfo.storeCode && newStoreCode && daypartCode && eatTypeCode) {
      // 发生store更新
      if (newStoreCode !== storeInfo.storeCode) {
        const params = {
          cartItems: productPriceInfo?.cartProductList,
          eatTypeCode,
          realTotalAmount,
          storeCode: storeInfo?.storeCode || '',
          storeCodeNew: newStoreCode,
          orderType: String(orderType),
          dayPartCode: String(daypartCode),
          cartType,
          beCode,
        };
        // updateStore(tempStoreInfo);
        changeStore(params as unknown as SwitchParams)
          .then((res) => {
            const { switchResult, confirmInfo, validationInfo } = res || {};
            if (switchResult) {
              updateOrderConfirmDataBySwitch(confirmInfo!);
              dispatch(updateNewStore(newStoreCode));
              updateProductNewCodeId(newStoreCode);
            } else {
              const { hasChange } = validationInfo || {};
              if (hasChange) {
                dispatch(updateNewStore());
                Toast.show('存在商品信息更新，请返回菜单页重新选择');
              }
            }
          })
          .catch((err: Error) => {
            dispatch(updateNewStore());
            Toast.show(err.message || '请求失败！');
          })
          .finally(() => {});
      }
    }
  }, [
    dispatch,
    updateOrderConfirmDataBySwitch,
    storeInfo,
    cartType,
    orderType,
    daypartCode,
    realTotalAmount,
    eatTypeCode,
    newStoreCode,
    productPriceInfo,
    beCode,
    updateProductNewCodeId,
  ]);

  // 切换地址回流
  useEffect(() => {
    if (String(orderType) === '2' && newAddressId) {
      // 发生address更新
      if (newAddressId !== deliveryInfo?.deliveryAddressId) {
        const params = {
          cartItems: productPriceInfo?.cartProductList,
          eatTypeCode,
          realTotalAmount,
          addressId: deliveryInfo?.deliveryAddressId || '',
          addressIdNew: newAddressId,
          orderType: String(orderType),
          dayPartCode: String(daypartCode),
          cartType,
          beCode,
          beType,
          productTotalPrice,
          realDeliveryPrice,
          storeCode: storeInfo?.storeCode,
        };
        changeAddress(params as unknown as SwitchAddressParams)
          .then((res) => {
            const { switchResult, confirmInfo, validationInfo } = res || {};
            if (switchResult) {
              updateOrderConfirmDataBySwitch(confirmInfo!);
              dispatch(updateNewAddress(newAddressId));
              updateProductNewCodeId(confirmInfo?.storeInfo?.storeCode, newAddressId);
            } else {
              const { hasChange } = validationInfo || {};
              if (hasChange) {
                dispatch(updateNewAddress());
                Toast.show('存在商品信息更新，请返回菜单页重新选择');
              }
            }
          })
          .catch((err: Error) => {
            dispatch(updateNewAddress());
            Toast.show(err.message || '请求失败！');
          })
          .finally(() => {});
      }
    }
  }, [
    dispatch,
    beCode,
    beType,
    newAddressId,
    updateOrderConfirmDataBySwitch,
    deliveryInfo,
    cartType,
    orderType,
    daypartCode,
    realTotalAmount,
    eatTypeCode,
    newStoreCode,
    productPriceInfo,
    productTotalPrice,
    storeInfo,
    realDeliveryPrice,
    updateProductNewCodeId,
  ]);

  const handlePickupTimeChange = useCallback(
    (code: string) => {
      const params = {
        cartItems: productPriceInfo?.cartProductList,
        orderType,
        cartType,
        dayPartCode: daypartCode,
        realTotalAmount,
        storeCode: storeInfo?.storeCode,
        beCode,
        pickupTimeType: 1,
        pickupTimeCode,
        pickupTimeCodeNew: code,
        // pickUpTimeOptions,
      };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      changePickTime(params as unknown as ChangePickTimeParams)
        .then((res) => {
          const { switchResult, confirmInfo } = res || {};
          if (switchResult) {
            // setPickupTimeCode(code);
            updateOrderConfirmDataBySwitch(confirmInfo!);
          } else {
            throw new Error('操作失败！');
          }
        })
        .catch((err: Error) => {
          Toast.show(err.message || '请求失败！');
        });
    },
    [
      orderType,
      storeInfo,
      beCode,
      pickupTimeCode,
      cartType,
      daypartCode,
      realTotalAmount,
      productPriceInfo,
      updateOrderConfirmDataBySwitch,
    ],
  );

  const handleDeliveryTimeChange = useCallback(
    (expectDeliveryTimeNew: string) => {
      const params = {
        addressId,
        beType,
        beCode,
        realDeliveryPrice,
        cartItems: productPriceInfo?.cartProductList,
        orderType,
        cartType,
        dayPartCode: daypartCode,
        realTotalAmount,
        storeCode: storeInfo?.storeCode,
        pickupTimeType: 1,
        pickupTimeCode,
        // expectDeliveryTime: expectDeliveryTimeNew,
        expectDeliveryTimeNew,
        // pickUpTimeOptions,
      };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      changeDeliveryTime(params as unknown as ChangeDeliveryTimeParams)
        .then((res) => {
          const { switchResult, confirmInfo } = res || {};
          if (switchResult) {
            // setPickupTimeCode(code);
            updateOrderConfirmDataBySwitch(confirmInfo!);
          } else {
            throw new Error('操作失败！');
          }
        })
        .catch((err: Error) => {
          Toast.show(err.message || '请求失败！');
        });
    },
    [
      orderType,
      storeInfo,
      pickupTimeCode,
      cartType,
      daypartCode,
      realTotalAmount,
      productPriceInfo,
      addressId,
      beType,
      beCode,
      realDeliveryPrice,
      updateOrderConfirmDataBySwitch,
    ],
  );

  const handleStoreChange = useCallback(() => {
    // 透传城市餐厅信息
    // const { cityCode, cityName } = storeInfo || {};
    const { latitude, longitude, storeCityName, storeCityCode } = storePickUpInfo || {};
    if (storeCityCode) {
      dispatch(
        updateStoreInfo({
          cityCode: storeCityCode,
          cityName: storeCityName,
          latitude,
          longitude,
        }),
      );
      dispatch(
        updateStoreExtraInfo({
          // TODO
          enterFrom: location.pathname + location.search,
          orderType: orderType,
        }),
      );
    }

    navigate(SELECT_CANTEEN);
  }, [dispatch, storePickUpInfo, navigate, orderType]);

  const handleAddressChange = useCallback(() => {
    // 透传城市餐厅信息
    dispatch(
      updateAddressExtraInfo({
        enterFrom: location.pathname + location.search,
        orderType,
        // beType: 2,
        // isGroupMeal,
      }),
    );
    navigate(MY_ADDRESS);
  }, [navigate, dispatch, orderType]);

  // const { products, productTotalPrice, discountAmount, realTotalPrice } = cartDetail || {};

  const payTotalPrice = useMemo(
    () => (Number(realTotalAmount) || 0) + (tablewareCode === 'yes' ? Number(tablewarePrice) || 0 : 0),
    [realTotalAmount, tablewarePrice, tablewareCode],
  );

  const calcDeliveryTime = useCallback(() => {
    let chooseDate: ConfirmationDeliveryDateTimeOption_ = {};
    let chooseTime: CommonSelectOption = {};
    (deliveryInfo?.expectDeliveryTimeOptions || []).forEach((option) => {
      if (option && option.deliveryTime && option.deliveryTime.length > 0) {
        option.deliveryTime.forEach((e) => {
          if (e && e.selected) {
            chooseDate = option;
            chooseTime = e;
          }
        });
      }
    });

    const isReady = chooseDate && chooseTime;

    return {
      isReady,
      expectDeliveryDateCode: chooseDate?.deliveryDateCode,
      expectDeliveryTimeCode: chooseTime?.code,
    };
  }, [deliveryInfo?.expectDeliveryTimeOptions]);

  /**
   * for log
   */

  //----------埋点----------
  const getCardStock = useCallback(
    (param: OrderParams) => {
      const cartProductList = productPriceInfo?.cartProductList || [];
      let card = param;
      cartProductList?.map((product) => {
        const couponList = product?.couponList || [];
        couponList?.map((item) => {
          if (item.cardType != null) {
            // ts-ignore
            card = item as unknown as OrderParams;
            return;
          }
        });
      });
      return card;
    },
    [productPriceInfo],
  );

  const getCardStockStatus = useCallback(
    (cardId: any[] | string) => {
      let cartStockStatus = '首次购卡';
      const cartProductList = productPriceInfo?.cartProductList || [];
      let haveRightCard = false; // 是否使用了权益卡
      cartProductList?.map((product) => {
        const couponList = product?.couponList || [];
        couponList?.map((item) => {
          if (item.cardType != null) {
            haveRightCard = true;
            return;
          }
        });
      });
      if (productPriceInfo?.rightCardInfo?.cardHas === true) {
        // 用户有卡
        if (cardId?.length > 0) {
          cartStockStatus = '再次购卡';
        } else {
          if (haveRightCard) {
            cartStockStatus = '老客用卡';
          } else {
            cartStockStatus = '';
          }
        }
      } else {
        if (cardId?.length > 0) {
          cartStockStatus = '首次购卡';
        } else {
          if (haveRightCard) {
            cartStockStatus = '老客用卡';
          } else {
            cartStockStatus = '';
          }
        }
      }
      return cartStockStatus;
    },
    [productPriceInfo],
  );

  const logSubmitOrder = useCallback(
    (param: OrderParams, orderId: string) => {
      //   hasUsedCoupon.current = false;
      const { nearestStoreInfo } = locationAndCityInfo || {};
      let belongPage = '';
      if (orderType == 1) {
        belongPage = '到店取餐';
      } else if (orderType == 2) {
        belongPage = '麦乐送';
      }
      const card = getCardStock(param);
      const trackDic: any = {
        order_id: orderId,
        actual_order_amount: param.realTotalAmount,
        order_type: belongPage,
        ps: param.remark || '',
        need_ware: param.tablewareCode || '',
        original_order_price: param.productTotalPrice || '',
        cardstock_name: card?.cardName ?? '',
        cardstock_id: card?.cardId ?? '',
        cardstock_status: getCardStockStatus(param?.cardId ?? ''),
        loc_us_code: nearestStoreInfo?.code ?? '',
        loc_us_name: nearestStoreInfo?.name ?? '',
        loc_us_address: nearestStoreInfo?.address ?? '',
        is_loc_namecode: nearestStoreInfo?.code === param.storeCode,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        order_time: daypartMap[dayPart?.dayPartCode as keyof typeof daypartMap] || '',
      };
      if (orderType == 1) {
        //到店取餐
        let storeName = '';
        let storeAddress = '';
        if (storePickUpInfo) {
          storeName = storePickUpInfo.storeName || '';
          storeAddress = storePickUpInfo.storeCityName || ''; //storeAddress
        }
        trackDic.us_code = param.storeCode || '';
        trackDic.us_name = storeName;
        trackDic.us_address = storeAddress;
        trackDic.dinner_way = param.eatTypeCode || '';
        trackDic.takefood_time = param.pickupTimeCode || '';
      } else {
        if (deliveryInfo) {
          let receiverSex = '';
          if (deliveryInfo.deliveryContactsSex) {
            receiverSex = deliveryInfo.deliveryContactsSex == 1 ? '男' : '女';
          } else {
            receiverSex = '未知';
          }
          trackDic.transportation_costs = param.realDeliveryPrice || '';
          trackDic.deliver_time = (param.expectDeliveryDateCode || '') + (param.expectDeliveryTimeCode || '');
          trackDic.receiver_sex = receiverSex;
        }
      }
      console.log('====log=submitOrder', trackDic);
      //提交订单
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      trackEvent('submitOrder', trackDic);
      //提交订单详情
      if (param.cartItems && param.cartItems.length > 0) {
        param.cartItems.forEach((e: Product_) => {
          const analyticDic: any = {
            // @ts-ignore
            original_price: e.price,
            // @ts-ignore
            present_price: e.realPrice,
            total_price: e.realSubtotal,
            order_id: orderId,
            order_type: belongPage,
            is_use_card_stock: (param?.promotionList?.length as number) > 0 ? '使用' : '未使用',
            cardstock_name: param?.cardName ?? '',
            cardstock_id: param?.cardId ?? '',
            order_time: daypartMap[dayPart?.dayPartCode as keyof typeof daypartMap] || '',
            // cardstock_status: !confirmInfo?.productPriceInfo?.rightCardInfo?.cardHas ? "首次购卡" : "再次购卡", //
          };
          if (e.productType == '1') {
            analyticDic.commodity_id = e.productCode;
            analyticDic.commodity_name = e.productName;
            analyticDic.commodity_quantity = e.quantity;
          } else {
            analyticDic.combo_id = e.productCode;
            analyticDic.combo_name = e.productName;
            analyticDic.combo_quantity = e.quantity;
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          trackEvent('submitOrderDetail', analyticDic);
        });
      }
    },
    [orderType, locationAndCityInfo, dayPart, deliveryInfo, getCardStock, getCardStockStatus, storePickUpInfo],
  );

  //首先遍历商品下的优惠 再遍历整单优惠 看是否享受折扣优惠
  const constructCardLog = useCallback(
    (productList: Product_[] = [], promotionList: Coupon_3[] = []) => {
      let cardDataInfo = {
        useCardStock: '否',
        cardstockId: productPriceInfo?.rightCardInfo?.membershipCode ?? productPriceInfo?.rightCardInfo?.cardId ?? '',
        cardstockName:
          productPriceInfo?.rightCardInfo?.membershipName ?? productPriceInfo?.rightCardInfo?.typeName ?? '',
      };
      let allPromotionList: any = [];
      productList?.map((productItem) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        allPromotionList = allPromotionList.concat(productItem?.couponList || []);
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      allPromotionList = allPromotionList.concat(promotionList);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      allPromotionList?.some((couponItem: any) => {
        if (couponItem?.showPmtType == 3) {
          cardDataInfo = {
            useCardStock: '是',
            cardstockId: couponItem.membershipCode,
            cardstockName: couponItem.icon ?? couponItem?.angleMark,
          };
          return;
        } else if (couponItem?.cardId) {
          cardDataInfo = {
            useCardStock: '是',
            cardstockId: couponItem.cardId,
            cardstockName: couponItem.cardTypeName ?? couponItem?.angleMark,
          };
          return;
        }
      });
      return cardDataInfo;
    },
    [productPriceInfo],
  );

  useEffect(() => {
    const cartItems = productPriceInfo?.cartProductList as unknown as Product_[];
    const promotionList = productPriceInfo?.promotionList || [];
    const logCardInfo = constructCardLog(cartItems, promotionList) || {}; //是否使用权益卡优惠埋点
    window.localStorage.setItem('logCardInfo', JSON.stringify(logCardInfo));
  }, [productPriceInfo]);

  const handlePay = useCallback(() => {
    let extraP = {};
    if (String(orderType) === '2') {
      const { isReady, expectDeliveryDateCode, expectDeliveryTimeCode } = calcDeliveryTime();
      if (!isReady) {
        Toast.show('请选择配送时间');
        return;
      }
      if (!addressId) {
        Toast.show('请确认配送地址及联系方式');
        return;
      }
      extraP = {
        addressId,
        remark,
        expectDeliveryDateCode,
        expectDeliveryTimeCode,
      };
    }

    // @ts-ignore
    const p: OrderParams = {
      orderType: String(orderType),
      cartItems: productPriceInfo?.cartProductList as unknown as Product_[],
      promotionList: productPriceInfo?.promotionList || [],
      dayPartCode: daypartCode!,
      pickUpTimeOptions: storePickUpInfo?.pickUpTimeOptions,
      pickupTimeCode: pickupTimeCode!,
      // Manually
      pickupTimeCodeNew: pickupTimeCode!,
      tablewareCode: tablewareCode!,
      pickupTimeType: '1',
      eatTypeCode,
      // Manually
      productTotalPrice: productTotalPrice!,
      // 此时的 realTotalAmount=餐品+餐具费用
      realTotalAmount: String(payTotalPrice),
      // @ts-ignore
      storeCode: storeInfo?.storeCode,
      beCode,
      ...extraP,
    };
    submitOrder(p)
      .then((res) => {
        // TODO 订单创建成功
        const { orderId, payId, orderStatus } = res || {};

        // track event
        // trackEvent('submitOrder', {
        //   // order_time: daypartMap[confirmInfo?.dayPart?.dayPartCode || ''],
        //   order_id: orderId,
        //   actual_order_amount: payTotalPrice,
        //   original_order_price: productTotalPrice,
        //   order_type: orderType === 1 ? '到店取餐' : '麦乐送',
        //   ps: remark || '',
        //   need_ware: tablewareCode || '',
        //   us_code: storeInfo?.storeCode,
        //   us_name: storeInfo,
        //   us_address: storeAlertParams.current?.nearestStoreInfo?.address ?? '',
        // });

        logSubmitOrder(p, orderId!);

        if (orderStatus === '2') {
          orderId && navigate(`${ORDER_DETAIL}?orderId=${orderId}`, { replace: true });
        } else {
          payId && orderId && navigate(`${ORDER_PAY}?orderId=${orderId}&payId=${payId}&source=0`, { replace: true });
        }
      })
      .catch((err: Error) => {
        Toast.show(err.message || '提交失败');
      })
      .finally(() => {});
  }, [
    navigate,
    pickupTimeCode,
    orderType,
    tablewareCode,
    // realTotalAmount,
    payTotalPrice,
    productTotalPrice,
    daypartCode,
    eatTypeCode,
    productPriceInfo,
    storeInfo,
    storePickUpInfo?.pickUpTimeOptions,
    remark,
    beCode,
    addressId,
    calcDeliveryTime,
    logSubmitOrder,
  ]);

  const onProductAddAction = useCallback(
    (item: ConfirmationProduct_) => {
      void updateOrderConfirmData({
        cartItems: productPriceInfo?.cartProductList,
        // cartType: '1',
        changeProductList: [
          {
            ...item,
            changeQuantity: 1,
          },
        ],
        productCodes: [item.productCode],
        orderType: String(orderType),
        dayPartCode: String(daypartCode),
        storeCode: storeInfo?.storeCode || '',
        eatTypeCode,
        beCode,
        addressId,
        // pickUpTimeOptions: storePickUpInfo?.pickUpTimeOptions,
        // pickupTimeCodeNew: pickupTimeCode,
        // pickupTimeType: '1',
        // productTotalPrice,
        // realTotalAmount,
        // 来源,1走心推荐addOn、2运费券、3订单券、4更改商品数量、5随单购权益卡、6福袋addon、7后返券、8删除商品、9更新商品详情
        source: 4,
      } as unknown as Carts)
        .then((res) => {
          // @ts-ignore
          const { error } = res || {};
          if (error) {
            // @ts-ignore
            Toast.show((error as Error).message || '操作失败！');
          }
        })
        .catch((err: Error) => {
          // Toast.show(err.message || '操作失败！');
        });
    },
    [
      daypartCode,
      orderType,
      storeInfo,
      updateOrderConfirmData,
      eatTypeCode,
      beCode,
      productPriceInfo,
      addressId,
      // pickupTimeCode,
      // storePickUpInfo,
      // productTotalPrice,
      // realTotalAmount,
    ],
  );

  const onProductSubAction = useCallback(
    (item: ConfirmationProduct_) => {
      const p = {
        cartItems: productPriceInfo?.cartProductList,
        // cartType: '1',
        changeProductList: [
          {
            ...item,
            changeQuantity: -1,
          },
        ],
        productCodes: [item.productCode],
        orderType: String(orderType),
        dayPartCode: String(daypartCode),
        storeCode: storeInfo?.storeCode || '',
        eatTypeCode,
        beCode,
        addressId,
        // pickUpTimeOptions: storePickUpInfo?.pickUpTimeOptions,
        // pickupTimeCodeNew: pickupTimeCode,
        // pickupTimeType: '1',
        // productTotalPrice,
        // realTotalAmount,
        // 来源,1走心推荐addOn、2运费券、3订单券、4更改商品数量、5随单购权益卡、6福袋addon、7后返券、8删除商品、9更新商品详情
        source: 4,
      } as unknown as Carts;

      if (productPriceInfo?.cartProductList && productPriceInfo?.cartProductList.length === 1 && item.quantity === 1) {
        void Dialog.confirm({
          destroyOnClose: true,
          maskClassName: 'mcd-dialog-container',
          bodyStyle: { textAlign: 'center', fontSize: '13px', color: '#222' },
          // title: '删除地址',
          content: <div>删除最后一个餐品后，将返回到菜单列表</div>,

          onConfirm: () => {
            void updateOrderConfirmData(p).then(() => {
              navigate(`/product?orderType=${orderType}`, { replace: true });
            });
          },
          confirmText: '继续删除',

          cancelText: '不删除',
        });
      } else {
        updateOrderConfirmData(p)
          .then((res) => {
            // @ts-ignore
            const { error } = res || {};
            if (error) {
              // @ts-ignore
              Toast.show((error as Error).message || '操作失败！');
            }
          })
          .catch((err: Error) => {
            // Toast.show(err.message || '操作失败！');
          });
      }
    },
    [
      navigate,
      daypartCode,
      orderType,
      storeInfo,
      updateOrderConfirmData,
      eatTypeCode,
      beCode,
      addressId,
      productPriceInfo,
      // pickupTimeCode,
      // storePickUpInfo,
      // productTotalPrice,
      // realTotalAmount,
    ],
  );

  const handleOrderCouponCodeChange = useCallback(
    (selectedCode?: string) => {
      let useCouponList: Partial<(Coupon_2 | { quantity: number })[]> = [];
      const selectCoupon = (orderCouponInfo?.couponList || []).find(({ code }) => code === selectedCode);
      if (!selectCoupon) {
        if (!orderCouponInfo?.usedCouponList || orderCouponInfo.usedCouponList?.length === 0) return;
        useCouponList = (orderCouponInfo?.usedCouponList || []).map((i) => ({ ...i, quantity: -1 }));
      } else {
        const { code, id, promotionId, title } = selectCoupon;
        useCouponList = [
          {
            couponCode: code!,
            couponId: id!,
            couponName: title!,
            promotionId: promotionId!,
            promotionType: 30,
            quantity: 1,
          },
        ];
      }
      const p = {
        promotionList: productPriceInfo?.promotionList || [],
        cartItems: productPriceInfo?.cartProductList,
        orderType: String(orderType),
        dayPartCode: String(daypartCode),
        storeCode: storeInfo?.storeCode || '',
        eatTypeCode,
        pickUpTimeOptions: storePickUpInfo?.pickUpTimeOptions,
        pickupTimeCode: pickupTimeCode,
        pickupTimeType: '1',
        productTotalPrice,
        realTotalAmount,
        // 来源,1走心推荐addOn、2运费券、3订单券、4更改商品数量、5随单购权益卡、6福袋addon、7后返券、8删除商品、9更新商品详情
        source: 3,
        useCouponList: useCouponList,
        addressId,
        beCode,
        beType,
        expectDeliveryTime,
      } as unknown as Carts;
      updateOrderConfirmData(p)
        .then((res) => {
          // @ts-ignore
          const { error } = res || {};
          if (error) {
            // @ts-ignore
            Toast.show((error as Error).message || '操作失败！');
          }
        })
        .catch((err: Error) => {
          // Toast.show(err.message || '操作失败！');
        });
    },
    [
      orderCouponInfo,
      productPriceInfo,
      productTotalPrice,
      orderType,
      daypartCode,
      realTotalAmount,
      storeInfo,
      eatTypeCode,
      updateOrderConfirmData,
      storePickUpInfo,
      pickupTimeCode,
      addressId,
      beCode,
      beType,
      expectDeliveryTime,
    ],
  );

  const handleFreightCouponPromotionIdChange = useCallback(
    (selectedFreightCouponPromotionId?: string) => {
      let useCouponList: Partial<(Coupon_2 | { quantity: number })[]> = [];
      const selectFreightCoupon = (freightCouponInfo?.couponList || []).find(
        ({ promotionId }) => promotionId === selectedFreightCouponPromotionId,
      );
      if (!selectFreightCoupon) {
        if (!freightCouponInfo?.usedCouponList || freightCouponInfo.usedCouponList?.length === 0) return;
        useCouponList = (freightCouponInfo?.usedCouponList || []).map((i) => ({ ...i, quantity: -1 }));
      } else {
        const { code, id, promotionId, title } = selectFreightCoupon;
        useCouponList = [
          {
            couponCode: code!,
            couponId: id!,
            couponName: title!,
            promotionId: promotionId!,
            promotionType: 50,
            quantity: 1,
          },
        ];
      }
      const p = {
        promotionList: productPriceInfo?.promotionList || [],
        cartItems: productPriceInfo?.cartProductList,
        orderType: String(orderType),
        dayPartCode: String(daypartCode),
        storeCode: storeInfo?.storeCode || '',
        eatTypeCode,
        pickUpTimeOptions: storePickUpInfo?.pickUpTimeOptions,
        pickupTimeCode: pickupTimeCode,
        pickupTimeType: '1',
        productTotalPrice,
        realTotalAmount,
        // 来源,1走心推荐addOn、2运费券、3订单券、4更改商品数量、5随单购权益卡、6福袋addon、7后返券、8删除商品、9更新商品详情
        source: 2,
        useCouponList: useCouponList,
        addressId,
        beCode,
        beType,
        expectDeliveryTime,
      } as unknown as Carts;
      updateOrderConfirmData(p)
        .then((res) => {
          // @ts-ignore
          const { error } = res || {};
          if (error) {
            // @ts-ignore
            Toast.show((error as Error).message || '操作失败！');
          }
        })
        .catch((err: Error) => {
          // Toast.show(err.message || '操作失败！');
        });
    },
    [
      // orderCouponInfo,
      productPriceInfo,
      productTotalPrice,
      orderType,
      daypartCode,
      realTotalAmount,
      storeInfo,
      eatTypeCode,
      updateOrderConfirmData,
      storePickUpInfo,
      pickupTimeCode,
      addressId,
      beCode,
      beType,
      expectDeliveryTime,
      freightCouponInfo,
    ],
  );

  return (
    <div className="order-confirm-conatiner">
      {/* <Header title={'订单确定'} /> */}
      <div className="order-confirm-content">
        {orderType === 1 && (
          <>
            <StoreInfo
              storePickUpInfo={storePickUpInfo!}
              onStoreChange={handleStoreChange}
              eatTypeCode={eatTypeCode}
              onEatTypeCodeChange={(code) => {
                setEatTypeCode(code);
              }}
            />
            <Mealtime
              storePickUpInfo={storePickUpInfo!}
              pickupTimeCode={pickupTimeCode!}
              onPickupTimeCodeChange={(code) => {
                handlePickupTimeChange(code);
              }}
            />
          </>
        )}
        {orderType === 2 && (
          <AddressInfo
            deliveryInfo={deliveryInfo}
            dayPart={dayPart}
            addressId={addressId}
            onAddressChange={handleAddressChange}
            onDeliveryTimeCodeChange={(code: string) => {
              handleDeliveryTimeChange(code);
            }}
          />
        )}
        <ProductList
          orderType={orderType}
          data={productPriceInfo}
          tablewareCode={tablewareCode!}
          onAdd={(item) => {
            trackEvent('modifyShoppingCart', {
              belong_page: orderType === 1 ? '到店取餐订单确认页' : '麦乐送订单确认页',
              button_name: '新增商品（➕）',
            });
            onProductAddAction(item);
          }}
          onSub={(item) => {
            trackEvent('modifyShoppingCart', {
              belong_page: orderType === 1 ? '到店取餐订单确认页' : '麦乐送订单确认页',
              button_name: '新增商品（➖）',
            });
            onProductSubAction(item);
          }}
          onOrderCouponCodeChange={handleOrderCouponCodeChange}
          onFreightCouponPromotionIdChange={handleFreightCouponPromotionIdChange}
        />
        <Tableware
          tablewareInfo={tablewareInfo}
          invoiceInfo={invoiceInfo}
          orderType={orderType}
          tablewareCode={tablewareCode}
          remark={remark}
          onChangeTablewareCode={(code) => {
            setTablewareCode(code);
          }}
          onChangeRemark={(str: string) => {
            setRemark(str || '');
          }}
        />
        <FooterBar
          payTotalPrice={payTotalPrice}
          onPay={handlePay}
          orderType={orderType}
          realDeliveryPrice={realDeliveryPrice!}
          deliveryPrice={deliveryPrice!}
          tipsList={tipsList}
        />
      </div>
    </div>
  );
};
export default OrderConfirm;
