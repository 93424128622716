import type { FC } from 'react';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Image, Toast, Skeleton } from 'antd-mobile';
import isEmpty from 'lodash/isEmpty';

import type { ProductInfoCustomization, ProductInfoComboItem, ProductInfoComboProductType } from '@/apis/product/types';
import useQuery from '@/hooks/useQuery';
import { useSetTitleStyle } from '@/hooks/useUpFuncs';
import { useSupportSafeArea } from '@/hooks/useSupportSafeArea';
import { getCDNAssets } from '@/utils';

import DeatilFooterAction from './FooterAction';
import { ProductCardCountStepper } from '../Menu/List/Card/NumButton';
import type { ProductDetailOptionsProps } from './Options/index';
import ProductDetailOptions from './Options/index';
import useProductDetail from './useDetail';
import useProductDetailEditorCache, {
  getExtraCustomizationCacheKey,
  getProductComboAndCustomizationDiffPrice,
} from './useEditor';
import ProductCustomSelectorPopup from './CustomSelector/Popup';
import { useStoreDateInfoWithLocation } from '../external';

import './index.less';

const getImgBgPath = (daypartCode?: number | string) => {
  if (Number(daypartCode) === 1) {
    return getCDNAssets('detail_top_back_breakfast.png');
  }
  return getCDNAssets('detail_top_back_dinner.png');
};

type UrlPathParams = {
  productCode: string;
};

type UrlPathQuery = {
  daypartCode?: string;
  orderType?: string;
  storeCode?: string;
  beCode?: string;
  beType?: string;
  /** 是够来自于购物车 true: '1' */
  fromCart?: string;
  /** for `fromCart` 购物车中的 sequence */
  sequence?: string;
  couponCode?: string;
  couponId?: string;
  promotionId?: string;
  disabledCount?: string;
};

const ProductDetailPage: FC = () => {
  const params = useParams<UrlPathParams>();
  const query = useQuery<UrlPathQuery>();
  const navigate = useNavigate();

  useSetTitleStyle({
    navBackgroundColor: '00FFFFFF',
    appletStyle: 'black',
    backBtnVisible: '1',
    appletTitleBarVisible: '1',
  });

  const fromCart = query?.fromCart === '1';

  const { curDateInfo } = useStoreDateInfoWithLocation(query?.orderType ? Number(query?.orderType) : 1);

  const [count, setCount] = useState(1);
  const [matchedSpecsCode, setMatchedSpecsCode] = useState<string>();
  const [popupDataCache, setPopupDataCache] = useState<{
    productCode?: string | undefined;
    choicesType?: number;
    combo?: ProductInfoComboItem;
    comboProduct?: ProductInfoComboProductType;
    /** extraCustomizationCache key */
    extraCacheKey?: string;
  }>();

  const {
    productData: originProductRes,
    productDesc,
    productLoading,
    couponPrice,
  } = useProductDetail(
    {
      productCode: params.productCode,
      daypartCode: Number(query?.daypartCode),
      orderType: Number(query?.orderType),
      storeCode: query?.storeCode,
      pageSource: fromCart ? 2 : 1,
      beCode: query?.beCode,
      date: curDateInfo?.date,
      time: curDateInfo?.time,
      couponCode: query?.couponCode,
      couponId: query?.couponId,
      promotionId: query?.promotionId,
    },
    !fromCart,
  );

  const {
    productCache,
    restProductCache,
    extraCustomizationCache,
    updateExtraCustomizationCache,
    intCountFromCart,
    curCartProduct,
    initProductForCart,
    disabledCountStepper,
    changeCustomizationOptionCheck,
    changeCustomizationItemCheck,
    changeComboItemsProductDefault,
    addToCart,
    saveToCart,
    loginBtnNode,
  } = useProductDetailEditorCache(
    originProductRes?.product,
    fromCart
      ? {
          cartType: 1,
          daypartCode: query?.daypartCode,
          orderType: Number(query?.orderType),
          storeCode: query?.storeCode,
          sequence: Number(query.sequence),
        }
      : undefined,
    {
      couponCode: query?.couponCode,
      couponId: query?.couponId,
      promotionId: query?.promotionId,
    },
  );
  useEffect(() => {
    if (fromCart && intCountFromCart && intCountFromCart > 0) {
      setCount(intCountFromCart);
    }
  }, [fromCart, intCountFromCart]);

  const [defalutMatchedSpecsCode, activedSpecsGroupCodes] = useMemo(() => {
    const _specTypes = productCache?.specTypes;
    if (_specTypes && !isEmpty(_specTypes)) {
      const _codes = _specTypes
        .flatMap((t) => t.code && t.specs && t.specs?.map((s) => t.code && s.code && `${t.code}-${s.code}`))
        .filter(Boolean) as string[];
      const _activeCodes = (productCache?.products?.map((p) => p.matchedSpecsCode).filter(Boolean) as string[]) || [];

      const _defaultCode = _activeCodes.find((c) => c.split(',').every((cc) => _codes.includes(cc)));

      return [_defaultCode, _activeCodes];
    }

    return [undefined, undefined];
  }, [productCache?.products, productCache?.specTypes]);

  useEffect(() => {
    if (!matchedSpecsCode && defalutMatchedSpecsCode) {
      setMatchedSpecsCode(defalutMatchedSpecsCode);
    }
  }, [defalutMatchedSpecsCode, matchedSpecsCode]);

  const curProduct = useMemo(() => {
    const _products = productCache?.products;
    if (_products && !isEmpty(_products)) {
      if (matchedSpecsCode) {
        const _item = _products.find((p) => p.matchedSpecsCode === matchedSpecsCode);
        if (_item) return _item;
      }

      return _products[0];
    }

    return productCache;
  }, [productCache, matchedSpecsCode]);

  const maxPurchaseQuantity = useMemo(
    () => originProductRes?.maxPurchaseQuantity ?? 99,
    [originProductRes?.maxPurchaseQuantity],
  );

  const cartInitDiffPrice = useMemo(() => {
    if (fromCart && curCartProduct?.coupon) {
      return getProductComboAndCustomizationDiffPrice(initProductForCart?.value, initProductForCart?.extra);
    }
    return undefined;
  }, [curCartProduct?.coupon, fromCart, initProductForCart?.extra, initProductForCart?.value]);

  const totalPrice = useMemo(() => {
    if (curProduct?.price) {
      let _originUnitPrice = curProduct.price;

      _originUnitPrice += getProductComboAndCustomizationDiffPrice(curProduct, extraCustomizationCache);

      if (fromCart && cartInitDiffPrice != null && curCartProduct?.realPrice != null) {
        _originUnitPrice = _originUnitPrice - curProduct.price + curCartProduct.realPrice - cartInitDiffPrice;
      }

      const _couponOriginPrice = Number(query?.orderType) === 2 ? couponPrice?.deliveryPrice : couponPrice?.eatInPrice;
      if (
        !fromCart &&
        _couponOriginPrice &&
        _couponOriginPrice === curProduct?.price &&
        couponPrice?.discountPrice != null
      ) {
        const _diffPrice = _couponOriginPrice - couponPrice.discountPrice || 0;
        _originUnitPrice = _originUnitPrice - _diffPrice;
      }

      return _originUnitPrice * count;
    }
    return undefined;
  }, [
    cartInitDiffPrice,
    count,
    couponPrice,
    curCartProduct?.realPrice,
    curProduct,
    extraCustomizationCache,
    fromCart,
    query?.orderType,
  ]);

  const onCustomizationOptionSelect = useCallback<Required<ProductDetailOptionsProps>['onCustomizationOptionSelect']>(
    (code, option) => {
      changeCustomizationOptionCheck(1, code, option);
    },
    [changeCustomizationOptionCheck],
  );
  const onCustomizationOptionDeSelect = useCallback<
    Required<ProductDetailOptionsProps>['onCustomizationOptionDeSelect']
  >(
    (code, option) => {
      changeCustomizationOptionCheck(0, code, option);
    },
    [changeCustomizationOptionCheck],
  );
  const onCustomizationItemSelect = useCallback<Required<ProductDetailOptionsProps>['onCustomizationItemSelect']>(
    (code, item, itemValue) => {
      changeCustomizationItemCheck(code, item, itemValue);
    },
    [changeCustomizationItemCheck],
  );
  const onComboItemsProductSelect = useCallback<Required<ProductDetailOptionsProps>['onComboItemsProductSelect']>(
    (code, combo, p) => {
      changeComboItemsProductDefault(code, combo, p);
    },
    [changeComboItemsProductDefault],
  );
  const onComboItemsProductCustom = useCallback<Required<ProductDetailOptionsProps>['onComboItemsProductCustom']>(
    (code, type, combo, p) => {
      setPopupDataCache({
        productCode: code,
        choicesType: type,
        combo,
        comboProduct: p,
        extraCacheKey: p.code && getExtraCustomizationCacheKey(combo, p.code),
      });
    },
    [],
  );
  const onSpecsCodeSelect = useCallback<Required<ProductDetailOptionsProps>['onSpecsCodeSelect']>((code, item) => {
    setMatchedSpecsCode((_code) => {
      if (_code) {
        const _new = _code
          .split(',')
          .map((c) => (item.code && c.indexOf(item.code) === 0 ? code : c))
          .join(',');
        return _new;
      }
      return code;
    });
  }, []);

  const onCustomPopupComfirm = useCallback(
    (customization?: ProductInfoCustomization, comboProduct?: ProductInfoComboProductType) => {
      // TODO popupDataCache data
      const _code =
        popupDataCache?.combo &&
        comboProduct?.code &&
        getExtraCustomizationCacheKey(popupDataCache?.combo, comboProduct?.code);

      if (customization && _code) {
        updateExtraCustomizationCache((d) => {
          d[_code] = customization;
        });
      }
      return true;
    },
    [popupDataCache?.combo, updateExtraCustomizationCache],
  );

  const onReset = useCallback(() => {
    setMatchedSpecsCode(defalutMatchedSpecsCode);
    restProductCache();
  }, [defalutMatchedSpecsCode, restProductCache]);

  const onAddCart = useCallback(async () => {
    try {
      if (fromCart) {
        await saveToCart(count, matchedSpecsCode, {
          daypartCode: query?.daypartCode || '',
          orderType: Number(query?.orderType),
          storeCode: query?.storeCode || '',
          cartType: '1',
          beCode: query?.beCode,
          date: curDateInfo?.date,
          time: curDateInfo?.time,
        });

        navigate(-1);
      } else {
        await addToCart(count, matchedSpecsCode, {
          daypartCode: query?.daypartCode || '',
          orderType: Number(query?.orderType),
          storeCode: query?.storeCode || '',
          cartType: '1',
          beCode: query?.beCode,
          date: curDateInfo?.date,
          time: curDateInfo?.time,
        });

        navigate(-1);
        // navigate(
        //   {
        //     pathname: '/product',
        //     search: new URLSearchParams({
        //       storeCode: query?.storeCode || '',
        //       orderType: query?.orderType || '',
        //     }).toString(),
        //   },
        //   { replace: true },
        // );
      }
    } catch (err) {
      const catchErrMsg = (err as Error)?.message;
      const errMsg = typeof catchErrMsg === 'string' && catchErrMsg ? catchErrMsg : '加入购物车失败';

      Toast.show({
        content: errMsg,
        position: 'top',
      });
    }
  }, [
    addToCart,
    count,
    curDateInfo?.date,
    curDateInfo?.time,
    fromCart,
    matchedSpecsCode,
    navigate,
    query?.beCode,
    query?.daypartCode,
    query?.orderType,
    query?.storeCode,
    saveToCart,
  ]);

  const showActions = useMemo(() => productLoading === false && !isEmpty(productCache), [productCache, productLoading]);
  const showResetAction = useMemo(() => {
    const _p = productCache?.products
      ? productCache.products.find((p) => p.matchedSpecsCode === matchedSpecsCode)
      : productCache;

    if (_p?.customizationMode === 1 && _p.customization) {
      const { options, items } = _p.customization;
      if (!isEmpty(options) || !isEmpty(items)) {
        return true;
      }
    } else if (!isEmpty(_p?.comboItems)) {
      return true;
    }
    return false;
  }, [matchedSpecsCode, productCache]);

  const supportSafeArea = useSupportSafeArea();

  return (
    <div className="product-detail-container">
      <div className="product-detail-top-bg" style={{ backgroundImage: `url(${getImgBgPath(query?.daypartCode)})` }}>
        <Image className="product-detail-top-bg-img" placeholder={null} lazy fit="scale-down" src={curProduct?.image} />
      </div>
      <div className="product-detail-block">
        <div className="product-detail-block-header">
          {productLoading ? (
            <Skeleton animated style={{ '--height': '28px', '--width': '120px', margin: 0 }} />
          ) : (
            <div className="product-detail-block-header-title">{curProduct?.name}</div>
          )}
          {showActions && (
            <ProductCardCountStepper
              subDisabled={disabledCountStepper || query?.disabledCount === '1'}
              addDisabled={disabledCountStepper || query?.disabledCount === '1'}
              count={count}
              minCount={1}
              maxCount={maxPurchaseQuantity}
              isGaryStyle
              onAdd={() => setCount((i) => i + 1)}
              onSub={() => setCount((i) => i - 1)}
            />
          )}
        </div>
        <div className="product-detail-block-content">
          {productLoading && <Skeleton.Paragraph animated />}
          <p className="product-detail-block-content-desc">{curProduct?.desc}</p>

          <ProductDetailOptions
            data={productCache}
            matchedSpecsCode={matchedSpecsCode}
            activedSpecsGroupCodes={activedSpecsGroupCodes}
            extraCustomizationData={extraCustomizationCache}
            onSpecsCodeSelect={onSpecsCodeSelect}
            onCustomizationOptionSelect={onCustomizationOptionSelect}
            onCustomizationOptionDeSelect={onCustomizationOptionDeSelect}
            onCustomizationItemSelect={onCustomizationItemSelect}
            onComboItemsProductSelect={onComboItemsProductSelect}
            onComboItemsProductCustom={onComboItemsProductCustom}
          />

          {productDesc && (
            <div className="product-detail-block-content-explain">
              <b>说明</b>
              <p>{productDesc}</p>
            </div>
          )}
        </div>
        {showActions && (
          <div className="product-detail-block-footer" data-supportsafearea={supportSafeArea}>
            <DeatilFooterAction
              fromCart={fromCart}
              price={totalPrice}
              onReset={showResetAction ? onReset : undefined}
              onAddCart={onAddCart}
            />
          </div>
        )}

        <ProductCustomSelectorPopup
          visible={Boolean(popupDataCache)}
          onClose={() => setPopupDataCache(undefined)}
          initCustomization={
            popupDataCache?.extraCacheKey ? extraCustomizationCache[popupDataCache.extraCacheKey] : undefined
          }
          data={popupDataCache?.comboProduct}
          params={{
            daypartCode: Number(query?.daypartCode),
            orderType: Number(query?.orderType),
            storeCode: query?.storeCode,
            beCode: query?.beCode,
            beType: query?.beType,
            date: curDateInfo?.date,
            time: curDateInfo?.time,
          }}
          onComfirm={onCustomPopupComfirm}
        />
      </div>

      {loginBtnNode}
    </div>
  );
};

export default ProductDetailPage;
