import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Ellipsis } from 'antd-mobile';
import cls from 'classnames';

import type { Place } from '@/apis/address';

import './index.less';

interface IProps {
  placeList: Place[];
  height?: number;
  selectIdx?: number;
  onSelect?: (idx: number) => void;
}

const AddressPlaceList: React.FC<IProps> = (props) => {
  const { placeList, height, selectIdx, onSelect } = props || {};

  return (
    <div className="place-list" style={{ height }}>
      {(placeList || []).map((item, idx) => {
        const { name, address } = item || {};
        const isSelected = idx === selectIdx;
        return (
          <div
            key={idx}
            className={cls('item', isSelected ? 'selected' : '')}
            onClick={() => {
              onSelect && onSelect(idx);
            }}>
            <div className="name">
              <Ellipsis content={name} />
            </div>
            <div className="address">
              <Ellipsis content={address} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AddressPlaceList;
