import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { StoreItem, MenuListData, MenuBannerItem, MemberAddressItem, PoisGeocodeItem } from '@/apis/product/types';
import type { GetStoreByStoreCodeQuery } from '@/apis/product';
import { getStoreByStoreCodeReqConfig } from '@/apis/product';

import type { CommonResponse } from '@/apis/request';
import request from '@/apis/request';

export type DeliveryDateInfoType = {
  date?: string;
  daypartCode?: number;
  time?: string;
};

type ProductInfoState = {
  /** orderType = 1 到店取餐 */
  pickup: {
    curStore?: StoreItem | null;
    menus?: MenuListData;
    banners?: MenuBannerItem[];
    /** for external */
    newStoreCode?: string;
  };

  /** orderType = 2 外送 */
  delivery: {
    curStore?: StoreItem | null;
    address?: MemberAddressItem;
    /** 没有选择地址信息 (no addressid) 临时显示状态 */
    currentLocation?: PoisGeocodeItem;
    dateInfo?: DeliveryDateInfoType;
    menus?: MenuListData;
    banners?: MenuBannerItem[];
    // for external
    newAddressId?: string;
  };
  status: 'idle' | 'loading' | 'failed';
};

const initialState: ProductInfoState = {
  pickup: {
    curStore: null,
  },
  delivery: {
    dateInfo: {},
  },
  status: 'idle',
};

export const switchStoreAsync = createAsyncThunk(
  'product/switchstore',
  async (params: GetStoreByStoreCodeQuery & { orderType: number }) => {
    const { orderType, ...restParams } = params || {};
    const response = (await request(getStoreByStoreCodeReqConfig(restParams))) as CommonResponse<StoreItem>;

    if (!response.success) {
      throw new Error(response.message);
    }

    return { orderType, store: response.data };
  },
);

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    updateStore: (state, action: PayloadAction<{ orderType?: number; store?: StoreItem }>) => {
      if (Number(action.payload.orderType) === 1) {
        state.pickup.curStore = action.payload.store;
      } else if (Number(action.payload.orderType) === 2) {
        state.delivery.curStore = action.payload.store;
      }
    },
    /** only for delivery (orderType = 2) */
    updateAddress: (state, action: PayloadAction<MemberAddressItem | undefined>) => {
      state.delivery.address = action.payload;
    },
    /** only for delivery (orderType = 2) */
    updateCurrentLocation: (state, action: PayloadAction<PoisGeocodeItem | undefined>) => {
      state.delivery.currentLocation = action.payload;
    },
    /** only for delivery (orderType = 2) */
    updateDateInfo: (state, action: PayloadAction<DeliveryDateInfoType | undefined>) => {
      state.delivery.dateInfo = action.payload;
    },
    updateMenus: (state, action: PayloadAction<{ orderType?: number; menus?: MenuListData }>) => {
      if (Number(action.payload.orderType) === 1) {
        state.pickup.menus = action.payload.menus;
      } else if (Number(action.payload.orderType) === 2) {
        state.delivery.menus = action.payload.menus;
      }
    },
    updateBanner: (state, action: PayloadAction<{ orderType?: number; banners?: MenuBannerItem[] }>) => {
      if (Number(action.payload.orderType) === 1) {
        state.pickup.banners = action.payload.banners;
      } else if (Number(action.payload.orderType) === 2) {
        state.delivery.banners = action.payload.banners;
      }
    },
    /** for pickup */
    updataNewStoreCode: (state, action: PayloadAction<string | undefined>) => {
      state.pickup.newStoreCode = action.payload;
    },
    /** for delivery */
    updataNewAddressId: (state, action: PayloadAction<string | undefined>) => {
      state.delivery.newAddressId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(switchStoreAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(switchStoreAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        if (Number(action.payload.orderType) === 1) {
          state.pickup.curStore = action.payload.store;
        } else if (Number(action.payload.orderType) === 2) {
          state.delivery.curStore = action.payload.store;
        }
      })
      .addCase(switchStoreAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  updateStore,
  updateBanner,
  updateMenus,
  updateAddress,
  updateCurrentLocation,
  updateDateInfo,
  updataNewStoreCode,
  updataNewAddressId,
} = productSlice.actions;

export default productSlice.reducer;
