import { useEffect, useRef } from 'react';
import { useDebounceFn } from 'ahooks';

const canUseWindow = typeof window !== 'undefined';

const mobileRE =
  /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|samsungbrowser|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;
const notMobileRE = /CrOS/;

function isMobile() {
  let ua = '';
  if (typeof navigator !== 'undefined') ua = navigator.userAgent;
  if (typeof ua !== 'string') return false;

  let result = mobileRE.test(ua) && !notMobileRE.test(ua);

  if (
    !result &&
    navigator &&
    navigator.maxTouchPoints > 1 &&
    ua.indexOf('Macintosh') !== -1 &&
    ua.indexOf('Safari') !== -1
  ) {
    result = true;
  }

  return result;
}

export function useViewHeight() {
  function setVH() {
    if (canUseWindow) {
      const { innerHeight } = window;
      document.documentElement.style.setProperty('--vh', `${innerHeight * 0.01}px`);
    }
  }

  const { run: deSetVH } = useDebounceFn(setVH, { wait: 200 });
  const deSetVHRef = useRef(deSetVH);
  deSetVHRef.current = deSetVH;

  useEffect(() => {
    const deviceIsMobile = isMobile();

    deSetVHRef.current();

    if (canUseWindow) {
      if (deviceIsMobile) {
        window.addEventListener('orientationchange', deSetVHRef.current);
      } else {
        window.addEventListener('resize', deSetVHRef.current);
      }
    }
    return () => {
      if (canUseWindow) {
        if (deviceIsMobile) {
          window.removeEventListener('orientationchange', deSetVHRef.current);
        } else {
          window.removeEventListener('resize', deSetVHRef.current);
        }
      }
    };
  }, []);
}
