import type { FC } from 'react';
import { useMemo, useCallback, useEffect, useRef, lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { Toast } from 'antd-mobile';
import type { MenuListProductItem } from '@/apis/product/types';
import type { GetSearchProductQuery } from '@/apis/product';
import useQuery from '@/hooks/useQuery';
import { useCartAction, useCartState } from '@/hooks/useCart';
import { useSetTitleStyle } from '@/hooks/useUpFuncs';

import InputSearch from './InputSearch';
import ProductCard from '../Menu/List/Card';
import CartSafeGap from '../Cart/SafeGap';

import useProductSearch from './useSearch';
import { getProductCount } from '../Menu/List';
import { useScrollState, useStoreDateInfoWithLocation, useClearStoreNavigateForOrder } from '../external';

import styles from './styles/page.module.less';
import { trackEvent } from '@/utils/tracker';

const ProductCartModule = lazy(() => import('../Cart'));

const SUPPORT_PRODUCT_TYPES = [1, 2, 3];

type PathParams = Pick<GetSearchProductQuery, 'storeCode' | 'beCode' | 'beType' | 'isGroupMeal'> & {
  orderType?: string;
  daypartCode?: string;
  keyword?: string;
  addressId?: string;
};

const ProductSearchPage: FC = () => {
  const query = useQuery<PathParams>();
  const navigate = useNavigate();

  useSetTitleStyle({
    title: '搜索餐品',
    navBackgroundColor: 'FFFFFFFF',
    appletStyle: 'black',
    backBtnVisible: '1',
    appletTitleBarVisible: '1',
  });
  useClearStoreNavigateForOrder(Number(query?.orderType) || undefined, 'search');

  const listScrollElRef = useRef<HTMLDivElement>(null);
  const [scrollState, scrollStateAction] = useScrollState();

  const { queryAppointmentStr, curDateInfo } = useStoreDateInfoWithLocation(Number(query?.orderType));
  const [cartstate] = useCartState(Number(query?.orderType));
  const { updateCart, loginBtnNode, syncTheLatest } = useCartAction({
    onErrLoginSuc: () => {
      if (cartstate?.requestQuery) {
        void syncTheLatest(cartstate?.requestQuery);
      }
    },
  });

  const cartQuantityMap = useMemo(
    () => cartstate?.productQuantityGroup && new Map(cartstate.productQuantityGroup),
    [cartstate?.productQuantityGroup],
  );

  const { searchData, isLoading } = useProductSearch({
    storeCode: query?.storeCode,
    beCode: query?.beCode,
    beType: query?.beType,
    isGroupMeal: query?.isGroupMeal,
    keyword: query?.keyword,
    orderType: Number(query?.orderType) || 1,
    daypartCode: Number(query?.daypartCode),
    date: curDateInfo?.date,
    time: curDateInfo?.time,
  });
  const filteredProductList = useMemo(
    () => searchData?.productList.filter((p) => SUPPORT_PRODUCT_TYPES.includes(p.productType)),
    [searchData?.productList],
  );

  const { isSearchEmpty, isShowProducts } = useMemo(() => {
    const _isEmptyProducts = isEmpty(filteredProductList);
    const _isSearchEmpty = query?.keyword && isLoading === false && _isEmptyProducts;
    const _isShowProducts = query?.keyword && !_isEmptyProducts;

    return {
      isSearchEmpty: _isSearchEmpty,
      isShowProducts: _isShowProducts,
    };
  }, [isLoading, query?.keyword, filteredProductList]);

  useEffect(() => {
    if (scrollState?.scrollY && isLoading === false && listScrollElRef.current) {
      listScrollElRef.current.scroll({ top: scrollState.scrollY });
    }
  }, [isLoading, scrollState?.scrollY]);

  const onProductAddAction = useCallback(
    async (item: MenuListProductItem) => {
      try {
        await updateCart({
          cartType: '1',
          orderType: Number(query?.orderType),
          daypartCode: String(query?.daypartCode),
          storeCode: query?.storeCode || '',
          beCode: query?.beCode,
          date: curDateInfo?.date,
          time: curDateInfo?.time,
          products: [
            {
              quantity: 1,
              code: item.productCode,
              name: item.productName,
              type: item.productType,
            },
          ],
        });
      } catch (error) {
        const errMsg = (error as Error)?.message || '加入购物车失败';
        Toast.show({
          content: errMsg,
          position: 'top',
        });
      }
    },
    [
      curDateInfo?.date,
      curDateInfo?.time,
      query?.beCode,
      query?.daypartCode,
      query?.orderType,
      query?.storeCode,
      updateCart,
    ],
  );

  const onProductSubAction = useCallback(
    async (item: MenuListProductItem) => {
      const curCartProduct = cartstate?.value?.products?.find((p) => p.code === item.productCode);

      if (curCartProduct) {
        try {
          await updateCart({
            cartType: '1',
            orderType: Number(query?.orderType),
            daypartCode: String(query?.daypartCode),
            storeCode: query?.storeCode || '',
            beCode: query?.beCode,
            date: curDateInfo?.date,
            time: curDateInfo?.time,
            products: [
              {
                sequence: curCartProduct.sequence,
                quantity: -1,
                code: item.productCode,
                name: item.productName,
                type: item.productType,
              },
            ],
          });
        } catch (error) {
          const errMsg = (error as Error)?.message || '移除失败';
          Toast.show({
            content: errMsg,
            position: 'top',
          });
        }
      }
    },
    [
      cartstate?.value?.products,
      curDateInfo?.date,
      curDateInfo?.time,
      query?.beCode,
      query?.daypartCode,
      query?.orderType,
      query?.storeCode,
      updateCart,
    ],
  );

  const onProductCardClick = useCallback(
    (p: MenuListProductItem) => {
      // cache scroll
      scrollStateAction.set({ scrollY: listScrollElRef.current?.scrollTop });

      navigate({
        pathname: `/product/${p.productCode}`,
        search: new URLSearchParams({
          storeCode: query?.storeCode || '',
          daypartCode: query?.daypartCode || '',
          orderType: query?.orderType || '',
          beCode: query?.beCode || '',
          appointment: queryAppointmentStr,
        }).toString(),
      });
    },
    [
      navigate,
      query?.beCode,
      query?.daypartCode,
      query?.orderType,
      query?.storeCode,
      queryAppointmentStr,
      scrollStateAction,
    ],
  );

  const changeNavKeyword = useCallback(
    (value?: string) => {
      navigate(
        {
          pathname: '/product/search',
          search: new URLSearchParams({
            ...query,
            keyword: value || '',
          }).toString(),
        },
        { replace: true },
      );
    },
    [navigate, query],
  );

  const onCartsNavToProductDetailCallback = useCallback(() => {
    // cache scroll
    scrollStateAction.set({ scrollY: listScrollElRef.current?.scrollTop });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollStateAction]);

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <InputSearch
          autoFocus
          defaultValue={query?.keyword}
          onEnterPress={(e) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const val = (e.target as any)?.value as string;
            changeNavKeyword(val);
          }}
          onClear={() => changeNavKeyword(undefined)}
        />
      </div>
      {isSearchEmpty && <div className={styles.emptyHint}>抱歉，未找到您想要的餐品</div>}
      {isShowProducts && filteredProductList && (
        <div className={styles.list} ref={listScrollElRef}>
          {filteredProductList.map((p) => (
            <ProductCard
              key={p.productCode}
              orderType={Number(query?.orderType)}
              item={p}
              count={getProductCount(cartQuantityMap, p)}
              onAdd={onProductAddAction}
              onSub={onProductSubAction}
              onClick={() => {
                onProductCardClick(p);
                trackEvent('searchResultClick', {
                  belong_page: query?.orderType === '1' ? '到店取餐搜索结果页' : '麦乐送搜索结果页',
                  key_word: query?.keyword,
                  rank: filteredProductList.indexOf(p),
                  result_type: p.productType === 1 ? '商品' : '套餐',
                  item_code: p.productCode,
                  commodity_name: p.productType === 1 ? p.productName : null,
                  combo_name: p.productType !== 1 ? p.productName : null,
                });
              }}
              onSelect={onProductCardClick}
            />
          ))}
          <CartSafeGap />
        </div>
      )}

      <Suspense>
        {isShowProducts && (
          <ProductCartModule
            cartType={1}
            orderType={Number(query?.orderType)}
            addressId={query?.addressId}
            storeCode={query?.storeCode}
            daypartCode={query?.daypartCode}
            other={{
              beCode: query?.beCode,
              beType: query?.beType,
            }}
            navToProductDetailCallback={onCartsNavToProductDetailCallback}
          />
        )}
      </Suspense>

      {loginBtnNode}
    </div>
  );
};

export default ProductSearchPage;
