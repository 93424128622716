import { getBaseUrl } from '@/apis/request';

export const getCDNAssets = (fileName: string): string => {
  const baseCDNUrl = 'https://img.mcd.cn/mini/main/images/';
  return `${baseCDNUrl}${encodeURIComponent(fileName)}`;
};

// 性别: 0-未知; 1-male; 2-female;
export type Gende = 1 | 2 | 0;

export const getGendertitle = (gende: Gende) => {
  const genderMap = {
    1: '先生',
    2: '女士',
    0: '',
  };

  return genderMap[gende] || '';
};

export interface LocationInfo {
  latitude: number;
  longitude: number;
}

const DEFAULT_TIME_OUT = 3500;

export const getLocationInfo = (): Promise<LocationInfo> => {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('请求超时！'));
    }, DEFAULT_TIME_OUT);

    upsdk.getLocationGps({
      success: (d: LocationInfo | string) => {
        console.log(d);
        // TODO 区分ios与安卓
        if (typeof d === 'string') {
          try {
            d = JSON.parse(d) as LocationInfo;
          } catch (err) {
            console.log('[getLocationInfo] parse getLocationGps error', err);
          }
        }

        const { latitude, longitude } = (d as LocationInfo) || {};
        timer && clearTimeout(timer);
        resolve({ latitude, longitude });
      },
      fail: (e) => {
        console.log('fail not work @xuqing');
        timer && clearTimeout(timer);
        reject(e);
      },
    });
  });
};

export const formatPrice = (originalPrice?: number): number => {
  if (!originalPrice) return 0;
  return originalPrice / 100;
};

/**
 *
 * @param str
 * @returns
 */
export const parseObj = (str?: string | null) => {
  if (str && typeof str === 'string') {
    try {
      return JSON.parse(str) as Record<string, any>;
    } catch (error) {
      console.error('[utils] parseObj error: %s', str);
    }
  }

  return undefined;
};

/**
 * 复制文本
 * @param {string} text 复制的文本
 * @param {() => void} onSuccess
 * @param {() => void} onFailure
 */
export const copyText = (text: string, onSuccess?: () => void, onFailure?: () => void) => {
  const input = document.createElement('input');
  document.body.appendChild(input);
  input.setAttribute('readonly', 'readonly');
  input.setAttribute('value', text);
  input.select();
  input.setSelectionRange(0, text.length);
  try {
    document.execCommand('copy');
    onSuccess && onSuccess();
  } catch (err) {
    onFailure && onFailure();
  }
  document.body.removeChild(input);
};

const pointUrlMap = {
  sit: 'https://www-sit.mcdchina.net/integral.html?pointType=0',
  uat: 'https://www-uat.mcdchina.net/integral.html?pointType=0',
  prod: 'https://www.mcd.cn/integral.html?pointType=0',
};

export const getPointUrl = () => {
  let _env = 'prod';
  const apiUrl = getBaseUrl();
  if (/sit/.test(apiUrl)) {
    _env = 'sit';
  }
  if (/uat/.test(apiUrl)) {
    _env = 'uat';
  }
  return pointUrlMap[_env as keyof typeof pointUrlMap];
};
