import httpInstance from './request';
import { BIZ_CODE_100, BIZ_CODE_103 } from '@/constants/code';
import type { CommonResponse } from './request';

const URLS = {
  REFRESH_SESSIONID: '/bff/passport/login/refresh',
  ALIPAY_AUTH: '/bff/passport/ali/auth',
  ALIPAY_AUTH_REFRESH: 'ali/auth/refresh',
  COLLECT_LOG_INFO: '/bff/collector/collect',
  SHARE_CONFIG: '/bff/common/normal/share',
  MODULE_POSITION: '/bff/common/module/position',
  GET_REWARD: '/bff/order/loyalty/reward/',
  REFRESH_XID: '/bff/passport/login/virtual/refresh',
  LOGIN_UNIONPAY: '/bff/passport/login/unionPay',
};

export const refreshSession = (params?: any) =>
  httpInstance.post<CommonResponse<any>>(URLS.REFRESH_SESSIONID, {
    headers: {
      apiVersion: '2.0',
    },
  });

export const loginUnionPay = (params: { code: string }) =>
  httpInstance
    .post<CommonResponse<{ sid: string }>>(URLS.LOGIN_UNIONPAY, {
      params: {
        type: 5,
        ...params,
      },
      headers: {
        // apiVersion: '2.0',
      },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
