import type { FC, CSSProperties, ReactEventHandler, MouseEventHandler, MouseEvent } from 'react';
import { useState, useCallback, useRef } from 'react';
import classNames from 'classnames';
import type { SwiperProps } from 'antd-mobile/es/components/swiper';
import { Swiper, Image } from 'antd-mobile';

import type { MenuBannerItem } from '@/apis/product/types';
import { EMediaType } from '@/apis/product/types';
import { getCDNAssets } from '@/utils';

import styles from './banner.module.less';

type BannerVideoProps = React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> & {
  type?: string;
  wrapperClassName?: string;
  wrapperStyle?: CSSProperties;
  showPlayButton?: boolean;
};

const BannerVideo = (props: BannerVideoProps) => {
  const {
    wrapperClassName,
    wrapperStyle,
    type = 'video/mp4',
    showPlayButton,
    onPlay: propsOnPlay,
    onEnded: propsOnEnded,
    ...rest
  } = props;
  const ref = useRef<HTMLVideoElement>(null);
  const [showPlayBtn, setShowPlayBtn] = useState(false);

  const playClick = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.stopPropagation();

    if (ref.current) {
      ref.current.pause();
      ref.current.currentTime = 0;
      ref.current.load();
    }
  }, []);

  const onVideoPlay = useCallback<ReactEventHandler<HTMLVideoElement>>(
    (e) => {
      if (showPlayButton) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const el = e.target as any;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (el?.currentTime !== el?.duration) {
          setShowPlayBtn(false);
        }
      }
      propsOnPlay?.(e);
    },
    [propsOnPlay, showPlayButton],
  );
  const onVideoEnded = useCallback<ReactEventHandler<HTMLVideoElement>>(
    (e) => {
      if (showPlayButton) {
        setShowPlayBtn(true);
      }
      propsOnEnded?.(e);
    },
    [propsOnEnded, showPlayButton],
  );

  return (
    <div className={classNames(styles.wrapperClassName)} style={wrapperStyle}>
      <video ref={ref} playsInline onPlay={onVideoPlay} onEnded={onVideoEnded} {...rest}>
        <source src={rest.src} type={type} />
      </video>
      {showPlayBtn && (
        <div className={styles.bannerVideoPlatBtn} onClick={playClick}>
          <img className={styles.bannerVideoPlatBtnIcon} src={getCDNAssets('home_banner_replay.png')} />
          <div className={styles.bannerVideoPlatBtnTxt}>重新播放</div>
        </div>
      )}
    </div>
  );
};

export type ProductBannerProps = SwiperProps & {
  items?: MenuBannerItem[];
  itemHeight?: CSSProperties['height'];
  onItemClick?: (item: MenuBannerItem, e: MouseEvent<HTMLDivElement>) => void;
};

const ProductBanner: FC<ProductBannerProps> = (props) => {
  const { items, itemHeight, autoplay: propsAutoplay, onItemClick, ...rest } = props;
  const [autoplay, setAutoplay] = useState(true);

  const onVideoPlay = useCallback<ReactEventHandler<HTMLVideoElement>>((e) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const el = e.target as any;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (el?.currentTime !== el?.duration) {
      setAutoplay(false);
    }
  }, []);
  const onVideoEnded = useCallback(() => {
    setAutoplay(true);
  }, []);

  return (
    <div style={{ height: itemHeight, willChange: 'transform' }}>
      {/* create new BFC  */}
      <Swiper autoplay={propsAutoplay && autoplay} style={{ position: 'absolute' }} {...rest}>
        {items && items.length > 0 ? (
          items.map((i) => (
            <Swiper.Item key={i.mediaUrl} onClick={(e) => onItemClick?.(i, e)}>
              {i.mediaType === EMediaType.VIDEO ? (
                <div className={styles.swiperItemVideoWrapper} style={{ height: itemHeight }}>
                  <BannerVideo
                    controls={false}
                    autoPlay
                    muted
                    className={styles.swiperItemVideo}
                    showPlayButton
                    src={i.mediaUrl}
                    onPlay={onVideoPlay}
                    onEnded={onVideoEnded}
                  />
                </div>
              ) : (
                <Image className={styles.swiperItemImg} fit="cover" style={{ height: itemHeight }} src={i.mediaUrl} />
              )}
            </Swiper.Item>
          ))
        ) : (
          <Swiper.Item>
            <Image
              className={styles.swiperItemImg}
              style={{ height: itemHeight }}
              src="https://img.mcd.cn/mini/main/images/menu_banner_default_image.png"
            />
          </Swiper.Item>
        )}
      </Swiper>
    </div>
  );
};

export default ProductBanner;
