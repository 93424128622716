import type { CSSProperties, FC } from 'react';
import { useMemo } from 'react';
import classNames from 'classnames';

import { Button } from 'antd-mobile';
import { useLockFn } from 'ahooks';

import ProductCardPrice from '../../Menu/List/Card/Price';
import styles from './index.module.less';

export type DeatilFooterActionProps = {
  className?: string;
  style?: CSSProperties;
  fromCart?: boolean;
  showCard?: boolean;
  price?: number;
  onReset?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => unknown;
  onAddCart?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void> | void;
};

const DeatilFooterAction: FC<DeatilFooterActionProps> = (props) => {
  const { className, style, fromCart, showCard, price, onReset, onAddCart } = props;

  const addCart = useLockFn(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onAddCart) {
      await onAddCart(e);
    }
  });

  const cartText = useMemo(() => {
    if (fromCart) {
      return '保存修改';
    }
    if (showCard) {
      return '购买';
    }
    return '加入购物车';
  }, [fromCart, showCard]);

  return (
    <div className={classNames(styles.wrapper, className)} style={style}>
      {onReset && (
        <Button shape="rounded" className={styles.restButton} onClick={onReset}>
          恢复默认
        </Button>
      )}
      <Button shape="rounded" color="primary" className={styles.addCartButton} onClick={addCart}>
        {price != null && (
          <ProductCardPrice
            className={styles.addCartBtnPrice}
            priceNumClassName={styles.addCartBtnPriceNum}
            priceLastClassName={styles.addCartBtnPriceLast}
            originalPrice={price}
          />
        )}
        <span className={styles.addCartButtonTxt}>{cartText}</span>
      </Button>
    </div>
  );
};

export default DeatilFooterAction;
