import type { CSSProperties, FC } from 'react';
import { useMemo } from 'react';
import classNames from 'classnames';

import styles from './styles/price.module.less';

const splitPrice = (price?: string): [string, string] => {
  if (price && typeof price === 'string' && /\./.test(price)) {
    const arr = price.split('.');
    return [arr[0], arr[1]];
  }

  return [price ?? '', ''];
};

type StyleVar = '--symbol-gap';

export type ProductCardPriceProps = {
  className?: string;
  separateClassName?: string;
  separateStyle?: CSSProperties;
  priceNumClassName?: string;
  priceLastClassName?: string;
  style?: CSSProperties & Partial<Record<StyleVar, string>>;
  symbol?: string;
  price?: string;
  /** 单位（分） */
  originalPrice?: number;
  priceShowStyle?: number;
  separatePrice?: string;
};

const defaultProps: Partial<ProductCardPriceProps> = {
  symbol: '¥',
};

const ProductCardPrice: FC<ProductCardPriceProps> = (props) => {
  const {
    className,
    separateClassName,
    separateStyle,
    priceNumClassName,
    priceLastClassName,
    style,
    symbol,
    price,
    originalPrice,
    priceShowStyle,
    separatePrice,
  } = props;

  const curPriceTxt = useMemo(() => {
    if (originalPrice != null && typeof originalPrice === 'number') {
      return String(originalPrice / 100 || 0);
    }
    return price;
  }, [originalPrice, price]);

  const [priceInt, priceDec, isLargeNum] = useMemo(() => {
    const [_int, _dec] = splitPrice(curPriceTxt);
    return [_int, _dec, Number(_int) >= 99999];
  }, [curPriceTxt]);

  return (
    <div className={classNames(styles.wrapper, className)} style={style}>
      {symbol && <span className={styles.priceSymbol}>{symbol}</span>}
      {priceInt && (
        <div className={classNames(styles.priceNum, priceNumClassName)} data-large={isLargeNum}>
          {priceInt}
          {priceDec && (
            <small className={classNames(styles.priceLast, priceLastClassName)} data-large={isLargeNum}>
              .{priceDec}
            </small>
          )}
        </div>
      )}
      {priceShowStyle === 2 && <span className={styles.priceFrom}>起</span>}
      {separatePrice && (
        <span className={classNames(styles.priceSeparate, separateClassName)} style={separateStyle}>
          {symbol}
          {separatePrice}
        </span>
      )}
    </div>
  );
};

ProductCardPrice.defaultProps = defaultProps;

export default ProductCardPrice;
