/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback, useEffect, useState, useRef } from 'react';

const canUseGeolocation = typeof navigator !== undefined && 'geolocation' in navigator;

export type IGeolocationPositionError = {
  readonly code: number;
  readonly message: string;
  readonly PERMISSION_DENIED: number;
  readonly POSITION_UNAVAILABLE: number;
  readonly TIMEOUT: number;
};

export type GeoLocationSensorState = {
  loading: boolean;
  accuracy: number | null;
  altitude: number | null;
  altitudeAccuracy: number | null;
  heading: number | null;
  latitude: number | null;
  longitude: number | null;
  speed: number | null;
  timestamp: number | null;
  error?: Error | IGeolocationPositionError;
};

const defaultState: GeoLocationSensorState = {
  loading: true,
  accuracy: null,
  altitude: null,
  altitudeAccuracy: null,
  heading: null,
  latitude: null,
  longitude: null,
  speed: null,
  timestamp: Date.now(),
};

export type GeoLocationOptions = PositionOptions & {
  /** @default true */
  effect?: boolean;
};

export function useGeolocation(options?: GeoLocationOptions): [GeoLocationSensorState, () => void] {
  const { effect = true, ...resetOptions } = options || {};
  const [state, setState] = useState<GeoLocationSensorState>({
    loading: true,
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: null,
    longitude: null,
    speed: null,
    timestamp: Date.now(),
  });
  const mounted = useRef(true);
  const watchId = useRef<number>();

  const onEvent = useCallback((event: any) => {
    if (mounted.current) {
      setState({
        loading: false,
        accuracy: event.coords.accuracy,
        altitude: event.coords.altitude,
        altitudeAccuracy: event.coords.altitudeAccuracy,
        heading: event.coords.heading,
        latitude: event.coords.latitude,
        longitude: event.coords.longitude,
        speed: event.coords.speed,
        timestamp: event.timestamp,
      });
    }
  }, []);
  const onEventError = useCallback((error: IGeolocationPositionError) => {
    if (mounted.current) {
      setState((oldState) => ({ ...oldState, loading: false, error }));
    }
  }, []);

  const getCurrentPosition = useCallback(() => {
    if (canUseGeolocation) {
      console.info('getCurrentPosition app');
      setState(defaultState);
      navigator.geolocation.getCurrentPosition(onEvent, onEventError, resetOptions);
    }
  }, [onEvent, onEventError, resetOptions]);

  useEffect(() => {
    mounted.current = true;
    if (effect) {
      if (canUseGeolocation) {
        getCurrentPosition();

        watchId.current = navigator.geolocation.watchPosition(onEvent, onEventError, resetOptions);
      } else {
        setState((oldState) => ({ ...oldState, loading: false, error: new Error('can not use geolocation') }));
      }
    } else {
      setState((oldState) => ({ ...oldState, loading: false }));
    }

    return () => {
      mounted.current = false;
      if (effect && canUseGeolocation && watchId.current) {
        navigator.geolocation.clearWatch(watchId.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, getCurrentPosition];
}
