/* eslint-disable @typescript-eslint/unbound-method */
import type { AxiosRequestConfig, Method } from 'axios';
import type { OMCHttps } from '@omc/https';
import http from '@omc/https';

import ampHandler from '@omc/apm-upload';
import { CONFIG_API_DOMAIN, CONFIG_DISABLED_API_PERF, getBooleanConfig, getStringConfig } from '@/hooks/uesAppConfig';

export const REQUEST_HEADER_CT = process.env.DEV_CT_WX ? '31' : '37';
export const REQUEST_CHANNEL_CODE = process.env.DEV_CT_WX ? '45' : '96';
export const REQUEST_HEADER_VERSION = process.env.API_VERSION || '6.0.35.0';

export type CommonResponse<T = any> = {
  code: number;
  data?: T | null;
  datetime: string;
  message: string;
  success: boolean;
  traceId: string | null;
};

export const getDefaultAPIDomain = () => {
  return getStringConfig(CONFIG_API_DOMAIN, 'https://api-uat.mcdchina.net');
};

export const getBaseUrl = () => {
  const _env = process.env.APP_DEPLOY_ENV;
  switch (_env) {
    case 'prod':
      return 'https://api.mcd.cn';
    case 'sit':
      return 'https://api-sit.mcdchina.net';
    default:
      return getDefaultAPIDomain();
  }
};

const omcHttpsCtx = http({
  baseUrl: getBaseUrl(),
});

const FILTER_URLS = ['/common/proxy/tid'];

const customSendRequest = async <T = any, D = any>(config: AxiosRequestConfig) => {
  const { url = '', method, ...rest } = config || {};
  const res = await omcHttpsCtx.send<T, D>(url, mergeReqConfig(rest, method));

  try {
    if (!getBooleanConfig(CONFIG_DISABLED_API_PERF, false) && !FILTER_URLS.includes(url)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ampHandler.uploadPerformanceInfo(config, { ...res, responseTimestamp: Date.now() } as any, REQUEST_HEADER_CT, {
        ampHost: 'prod', // TODO !!! only test. will be deleted
      });
    }
  } catch (err) {
    console.error('[uploadPerformanceInfo] err', err);
  }

  return res;
};

const defaultHeaders: AxiosRequestConfig['headers'] = {
  ct: REQUEST_HEADER_CT,
  v: REQUEST_HEADER_VERSION,
};

const mergeReqConfig = (config?: AxiosRequestConfig, method: Method = 'get'): AxiosRequestConfig => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { headers, ...rest } = config || {};

  return {
    method,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...defaultHeaders,
      ...headers,
    },
    ...rest,
  };
};

// [PATCH] @omc/https config
Object.defineProperties(omcHttpsCtx, {
  get: {
    value: (url: string, ps?: AxiosRequestConfig) => customSendRequest({ url, ...mergeReqConfig(ps, 'get') }),
  },
  post: {
    value: (url: string, ps?: AxiosRequestConfig) => customSendRequest({ url, ...mergeReqConfig(ps, 'post') }),
  },
  put: {
    value: (url: string, ps?: AxiosRequestConfig) => customSendRequest({ url, ...mergeReqConfig(ps, 'put') }),
  },
  delete: {
    value: (url: string, ps?: AxiosRequestConfig) => customSendRequest({ url, ...mergeReqConfig(ps, 'delete') }),
  },
});

export type RequestCtxType<T = any, D = any> = {
  (config?: AxiosRequestConfig<D>): Promise<T>;
} & Omit<ReturnType<OMCHttps>, 'send'>;

const requestCtx: RequestCtxType = (config?: AxiosRequestConfig) => {
  const { url = '', ...rest } = config || {};
  return customSendRequest({ url, ...mergeReqConfig(rest) });
};

requestCtx.get = omcHttpsCtx.get;
requestCtx.post = omcHttpsCtx.post;
requestCtx.put = omcHttpsCtx.put;
requestCtx.delete = omcHttpsCtx.delete;

export default requestCtx;
