import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import omit from 'lodash/omit';
import type { AppDispatch } from '@/store';
import { updateStoreInfo, updateStoreExtraInfo, updateAddressExtraInfo } from '@/store/addressSlice';

import { useStore } from '@/hooks/useStore';
import useQuery from '@/hooks/useQuery';

export function useGoToAddressBefore(orderType = 1) {
  const { storeInfo } = useStore(orderType);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const query = useQuery<Record<string, string>>();

  const defaultUrl = useMemo(() => {
    if (query) {
      return `${location.pathname}?${new URLSearchParams(
        omit(query, 'storeCode', 'newStoreCode', 'addressId', 'newAddressId', 'orderId'),
      ).toString()}`;
    }
    return location.pathname;
  }, [location.pathname, query]);

  const beforeCache = useCallback(
    (curUrl?: string) => {
      if (storeInfo) {
        const { cityCode, cityName, latitude, longitude } = storeInfo;
        dispatch(
          updateStoreInfo({
            cityCode,
            cityName,
            latitude,
            longitude,
          }),
        );
      }

      if (orderType === 1) {
        dispatch(
          updateStoreExtraInfo({
            orderType,
            enterFrom: curUrl ?? defaultUrl,
            isMenu: true,
          }),
        );
      } else if (orderType === 2) {
        dispatch(
          updateAddressExtraInfo({
            orderType,
            enterFrom: curUrl ?? defaultUrl,
            isMenu: true,
          }),
        );
      }
    },
    [defaultUrl, dispatch, orderType, storeInfo],
  );

  return [beforeCache];
}
