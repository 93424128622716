export enum EMenusPageErrStatus {
  /** 未授权定位 */
  LOCATION_NOT_AUTHORIZED = 'LOCATION_NOT_AUTHORIZED',
  /** 定位失败 */
  LOCATION_ERR = 'LOCATION_ERR',
  /** 附近没有餐厅 */
  NO_NEARBY = 'NO_NEARBY',
  /** 暂停营业 */
  SUSPEND = 'SUSPEND',
  /** 休息中 */
  REST = 'REST',
  /** 无菜单数据 */
  EMPTY_MENUS = 'EMPTY_MENUS',
}

export enum EMenusPageErrEvent {
  /** 变更送餐地址 */
  CHANGE_ADDRRSS = 'CHANGE_ADDRRSS',
  /** 预约送餐时间 */
  APPOINTMENT = 'APPOINTMENT',
  /** 切换餐厅 */
  SWITCH_STORE = 'SWITCH_STORE',
  /** 刷新菜单 */
  RELOAD_MENUS = 'RELOAD_MENUS',
}
