import React, { useEffect } from 'react';
import { useResource } from '@axios-use/react';
import { useCookies } from 'react-cookie';

export const UserProfile = () => {
  const [, setCookie] = useCookies(['sid']);

  const [{ data }] = useResource(
    (cityCode?: string) => ({
      url: '/bff/member/user/portal/info',
      method: 'GET',
    }),
    [],
  );

  return (
    <div style={{ maxWidth: '320px', padding: '20px' }}>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};
