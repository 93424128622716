import type { CSSProperties, FC, MouseEvent } from 'react';
import { useCallback } from 'react';
import classNames from 'classnames';

import { Image } from 'antd-mobile';
import type { ProductInfoCustomizationOption } from '@/apis/product/types';
import { getCDNAssets } from '@/utils';

import styles from './styles/multipspecial.module.less';

export type MultipSpecialViewProps = {
  className?: string;
  style?: CSSProperties;
  items?: ProductInfoCustomizationOption[];
  onSelect?: (item: ProductInfoCustomizationOption) => void;
  onDeSelect?: (item: ProductInfoCustomizationOption) => void;
};

const MultipSpecialView: FC<MultipSpecialViewProps> = (props) => {
  const { className, style, items, onSelect, onDeSelect } = props;

  const onItemClick = useCallback(
    (e: MouseEvent<HTMLDivElement>, item: ProductInfoCustomizationOption) => {
      const isSelected = item.checked === 1;
      const soldOut = item.soldOut;

      if (isSelected) {
        onDeSelect?.(item);
      } else if (!soldOut) {
        onSelect?.(item);
      }
    },
    [onDeSelect, onSelect],
  );

  return (
    <div className={classNames(styles.wrapper, className)} style={style}>
      {items?.map((i) => {
        const isSelected = i.checked === 1;
        const soldOut = i.soldOut;

        return (
          <div key={i.code} className={styles.item} data-selected={isSelected} onClick={(e) => onItemClick(e, i)}>
            <div className={styles.itemView} data-selected={isSelected}>
              <Image
                className={styles.itemViewImg}
                placeholder={null}
                fallback={<Image placeholder={null} src={getCDNAssets('menu_list_default_img.png')} />}
                lazy
                src={i.image}
              />
              {isSelected && !soldOut && (
                <Image
                  className={styles.itemSelectIcon}
                  placeholder={null}
                  src={getCDNAssets('detail_special_white_selected.png')}
                />
              )}
              {soldOut && (
                <Image
                  className={styles.itemSoldOutIcon}
                  placeholder={null}
                  src={getCDNAssets('detail_special_white_soldout.png')}
                />
              )}
              {soldOut && <div className={styles.itemViewSoldOutMask} />}
            </div>
            <div className={styles.itemName} data-selected={isSelected}>
              {i.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MultipSpecialView;
