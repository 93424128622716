import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfiniteScroll, List } from 'antd-mobile';
import Header from '@/components/Header';
import { fetchOrder } from '@/apis/order';
import type { OrderCategory, Order } from '@/apis/order';
import OrderItem from '../OrderItem';
import './index.less';
import { getCDNAssets } from '@/utils';
import { useCookies } from 'react-cookie';

interface IProps {
  id?: number;
  queryOrderTimeTips?: string;
}

interface PageParams {
  pageNo: number;
  pageSize: number;
}

const CategoryItem: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [cookie] = useCookies<string, { [key: string]: string }>(['sid']);
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const swiperTop = '0';
  const categoryId = String(props.id);
  const { id } = props;
  const [{ pageNo, pageSize }, setPageParams] = useState<PageParams>({ pageNo: 1, pageSize: 10 });
  const [list, setList] = useState<Order[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [finished, setFinished] = useState(false);

  const handletoPage = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate],
  );
  // 无订单
  const renderEmptyView = () => {
    return (
      <div className="order_list_swiper_empty_bg">
        <div className="order_list_gradual_bg" style={{ top: swiperTop + 'px', zIndex: -1 }}>
          <div className="order_list_gradual_view" />
        </div>
        <img className="order_list_swiper_empty_img" src={getCDNAssets('order_empty.png')} />
        <span className="order_list_swiper_empty_text">暂无订单记录</span>
        <div
          className="order_list_swiper_empty_btn"
          onClick={() => {
            const orderType = categoryId === '2' ? 2 : 1;
            const urlMap = {
              2: '/product?orderType=2',
              1: '/product?orderType=1',
            };
            const url = urlMap[orderType];
            handletoPage(url);
          }}>
          <span className="order_list_swiper_btn_title">去下单</span>
        </div>
        {((list || []).length >= 0 || !isLogin) && <div className="order_list_swiper_footer_button"></div>}
      </div>
    );
  };

  const getOrderList = useCallback(
    (curPageNo: number, curPageSize: number) => {
      return new Promise<void>((resolve, reject) => {
        if (isLogin) {
          if (id || id === 0) {
            fetchOrder({ orderCategoryId: id, page: curPageNo, size: curPageSize })
              .then((res) => {
                // TODO: 分页
                setHasMore((res?.list || []).length > 0);
                if (curPageNo === 1) {
                  setList(res?.list || []);
                } else {
                  setList(list.concat(res?.list || []));
                }
                if (!hasMore) {
                  setFinished(true);
                } else {
                  setPageParams({ pageNo: curPageNo + 1, pageSize: 10 });
                }
                resolve();
              })
              .catch(() => {
                reject();
              });
          }
        }
      });
    },
    [id, isLogin, hasMore, list],
  );

  const loadMore = useCallback(
    (isRetry: boolean) => {
      return getOrderList(pageNo, pageSize);
    },
    [pageNo, pageSize, getOrderList],
  );

  useEffect(() => {
    setIsLogin(cookie?.sid ? true : false);
  }, []);

  const showEmpty = useMemo(() => (list || []).length === 0, [list]);

  return (
    <>
      <div className="category-item-container">
        {(list || []).map((i) => {
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          return <OrderItem data={i} key={i?.orderId} getOrderList={getOrderList} />;
        })}
        {showEmpty && renderEmptyView()}
      </div>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50}>
        {(innerHasMore) => {
          return showEmpty ? null : innerHasMore ? '加载中' : '没有更多了';
        }}
      </InfiniteScroll>
    </>
  );
};
export default CategoryItem;
