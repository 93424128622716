import type { CSSProperties, FC } from 'react';
import { useMemo, useCallback, useEffect } from 'react';
import classNames from 'classnames';

import { Image, Button } from 'antd-mobile';

import type { EMenusPageErrStatus, EMenusPageErrEvent } from './types';
import { getPageErrInfo } from './funcs';

import styles from './index.module.less';
import { trackEvent } from '@/utils/tracker';

export type ProductErrorProps = {
  className?: string;
  style?: CSSProperties;
  status?: EMenusPageErrStatus;
  subErrCode?: number;
  orderType?: number;
  onBtnClick?: (eventType?: EMenusPageErrEvent) => void;
};

const ProductError: FC<ProductErrorProps> = (props) => {
  const { className, style, status, subErrCode, orderType, onBtnClick } = props;

  const info = useMemo(() => getPageErrInfo(status, orderType, subErrCode), [orderType, status, subErrCode]);

  useEffect(() => {
    trackEvent('pageView', {
      belong_page: orderType === 1 ? '到店取餐菜单页' : '麦乐送菜单页',
      fail_reason: info?.trackReason,
    });
  }, []);

  const onBtnEventClick = useCallback(() => {
    onBtnClick?.(info?.btnEvent);
  }, [info?.btnEvent, onBtnClick]);

  return (
    <div className={classNames(styles.wrapper, className)} style={style}>
      {info?.img && <Image className={styles.img} placeholder={null} fit="scale-down" src={info.img} />}
      {info?.title && <b className={styles.title}>{info.title}</b>}
      {info?.text && <p className={styles.desc}>{info.text}</p>}
      {info?.btn && (
        <Button className={styles.button} shape="rounded" color="primary" data-blod onClick={onBtnEventClick}>
          {info.btn}
        </Button>
      )}
    </div>
  );
};

export default ProductError;
