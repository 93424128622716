import React, { useCallback, useEffect, useState } from 'react';
import './index.less';

const UserInfoView: React.FC = () => {
  return (
    <div>
      <div className="product-coupon-empty-view">
        <span className="product-coupon-empty-text">您还没有卡券</span>
        <span className="product-coupon-empty-sub-text">请更换筛选条件，或者去购买更多卡券</span>
      </div>
    </div>
  );
};

export default UserInfoView;
