import httpInstance from './request';
import { BIZ_CODE_100 } from '@/constants/code';
import type { CommonResponse } from './request';
import type { HotActivity } from './home.type';

const URLS = {
  HOME_CONFIG: '/bff/portal/home/index',
  HOME_COUPON: '/bff/promotion/coupons/v2',
  HOME_MORE_ACTIVITY: '/bff/portal/home/activity',
  RECOMMAND_STORE_INFO: '/bff/portal/recommend/product',
  HOME_RIGHT_CARDS: '/bff/promotion/coupons/rightCards',
  HOME_USER_INFO: '/bff/member/user/portal',
  HOME_ORDER_INFO: '/bff/portal/home/order',
  HOME_ALERT_ADVERTISE: '/bff/portal/richpop',
  RECEIVE_COUPONINFO: '/bff/market/lottery/common/draw',
  HOME_NEW_USER_GIFT: '/bff/portal/home/customer/config',
  NEW_USER_GIFT_COUPON_LIST: '/bff/member/gift/new/user',
  CMS_POSTER_LINK: '/bff/portal/proxy/cms/api/poster/link',
  QR_STORE_JUMP: '/bff/portal/qr/store/jump',
  BACK_APP_BTN_DATA: '/bff/common/normal/dict',
  COMMON_CONFIG_URL: '/bff/common/normal/config',
  ABTEST_CONFIG_URL: '/bff/common/normal/ab',
  HOME_RIGHTCARD_INFO: '/bff/promotion/coupons/card',
  HOME_HOT_ACTIVITY: '/bff/portal/home/hotActivity',
};

export interface BannerItem {
  angleIcon: string;
  crowdId: number;
  crowdInclude: number;
  crowdName: string;
  image: string;
  title: string;
  url: string;
  videoFlag: number;
}

interface Banner {
  title: string;
  list: BannerItem[];
}

export interface CategoryItemDetail {
  crowdId: number;
  crowdInclude: number;
  crowdName: string;
  image: string;
  title: string;
  url: string;
  extraInfo: any;
  iconImg: string;
  subTitle: string;
  subTitleFontColor: string;
  titleFontColor: string;
  type: string;
}

interface CategoryItem {
  list: CategoryItemDetail[];
  templateId: number;
  title: string;
}

export interface ActivityItem {
  image: string;
  needLogin: any;
  subTitle: string;
  title: string;
  url: string;
}

interface Activity {
  count: number;
  list: ActivityItem[];
  title: string;
}
interface HomeConfig {
  banner: Banner;
  categoryList: CategoryItem[];
  activity: Activity;
  nonLoginDesc: string;
  greeting: string;
  memberBackImg: string;
}

export interface UserInfo {
  fullName: string;
  memberBackImg: string;
  greeting: string;
  headImg: string;
  availablePoints: string;
  phoneNumber: string;
  meddyId: string;
  gender: any;
  birthDate: any;
  rightCardImg: string | null;
  tel: string;
}

export interface CommonConfig {
  /**
   * app图片版本
   */
  appImageVersion: string;
  /**
   * 其他配置
   */
  config: Config;
  /**
   * 开关 header中V必传 默认false关
   */
  normalFlag: boolean;
  /**
   * 安全url列表
   */
  safeUrl: string[];
}
export interface Config {
  pointsText: pointsText;
}
export interface pointsText {
  nameCn: string;
}

export interface OrderResponse {
  orderInfo?: OrderInfo;
}
export interface OrderInfo {
  /**
   * 操作信息：联系骑手
   */
  opeInfo?: string;
  /**
   * 订单状态：餐品配送中
   */
  orderStatus?: string;
  /**
   * 展示内容：19:35
   */
  showContent?: string;
  /**
   * 门户详情跳转地址
   */
  storeDetailUrl?: string;
  /**
   * 类型：pickup/mds/mccafe
   */
  type?: string;
  /**
   * 跳转地址
   */
  url?: string;
  /**
   * 提示名称：预计送达时间
   */
  yellowTip?: string;
}

// 首页配置信息
export const fetchHomeConfig = (cityCode: string) =>
  httpInstance
    .get<CommonResponse<HomeConfig>>(URLS.HOME_CONFIG, {
      params: { cityCode },
      headers: { biz_from: '1003', biz_scenario: BIZ_CODE_100 },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });

export const fetchCommonConfig = () =>
  httpInstance
    .get<CommonResponse<CommonConfig>>(URLS.COMMON_CONFIG_URL, {
      headers: { biz_from: '1003', biz_scenario: BIZ_CODE_100 },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });

export const fetchUserInfo = () =>
  httpInstance.get<CommonResponse<UserInfo>>(URLS.HOME_USER_INFO).then((res) => {
    const { code, data } = res || {};
    if (code === 200) {
      return data;
    }
  });

export const fetchOrderInfo = () =>
  httpInstance
    .get<CommonResponse<OrderResponse>>(URLS.HOME_ORDER_INFO, {
      headers: { biz_from: '1009', biz_scenario: BIZ_CODE_100 },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });

export const fetchMoreActivity = ({
  cityCode,
  pageNo = 1,
  pageSize = 10,
}: {
  cityCode: string;
  pageNo?: number;
  pageSize?: number;
}) =>
  httpInstance
    .get<CommonResponse<Activity>>(URLS.HOME_MORE_ACTIVITY, {
      params: { cityCode, pageNo, pageSize },
      headers: { biz_from: '1015', biz_scenario: BIZ_CODE_100 },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });

export const fetchHotActivity = ({ cityCode }: { cityCode: string }) =>
  httpInstance
    .get<CommonResponse<HotActivity>>(URLS.HOME_HOT_ACTIVITY, {
      params: { cityCode },
      headers: { biz_from: '1015', biz_scenario: BIZ_CODE_100 },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
