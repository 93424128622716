import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SwipeAction, Dialog, Button, Modal, Tag, Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '@/store/';
import { updateDetail, updateNewAddress } from '@/store/addressSlice';
import { fetchAddressList, deleteAddress, fetchNearByStore } from '@/apis/address';
import type { Address, NearByStoreParams } from '@/apis/address';
import AddressCard from './components/AddressCard';
import AddressFooter from '@/components/AddressFooter';
import { SELECT_PLACE } from '@/constants/path';
import useTitle from '@/hooks/useTitle';
import './index.less';
import { trackEvent } from '@/utils/tracker';

const AddressList = () => {
  useTitle('我的地址', '0x8FF9F9F9');
  const addressExtraInfo = useSelector((state: RootState) => state.address.addressExtraInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [addressList, setAddressList] = useState<Address[]>([]);
  const getAddressList = useCallback(() => {
    setLoading(true);
    fetchAddressList()
      .then((res) => {
        setAddressList(res!);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const deleteAddressById = useCallback(
    (id: string) => {
      deleteAddress({ addressId: id })
        .then(() => {
          getAddressList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [getAddressList],
  );

  const addAddress = useCallback(() => {
    if (addressList && addressList.length >= 100) {
      // TODO:超出100处理
      return;
    }
    dispatch(updateDetail());
    navigate(SELECT_PLACE);
  }, [addressList, navigate, dispatch]);

  const init = useCallback(() => {
    getAddressList();
  }, [getAddressList]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    trackEvent('addressListPage', {
      page_source: addressExtraInfo?.isMenu ? '商品详情页' : '订单确认页',
    });
  }, [addressExtraInfo]);

  const handleSelect = useCallback((p: Address) => {
    if (!addressExtraInfo?.enterFrom) return;
    const { longitude, latitude, id } = p || {};
    const params: NearByStoreParams = {
      latitude,
      longitude,
      addressId: id,
      orderType: 2,
    };
    fetchNearByStore(params)
      .then(() => {
        id && dispatch(updateNewAddress(id));
        if (addressExtraInfo?.enterFrom) {
          // const url: string = addressExtraInfo?.isMenu
          //   ? `${addressExtraInfo.enterFrom || ''}&newAddressId=${id || ''}`
          //   : addressExtraInfo?.enterFrom || '';
          // navigate(url, { replace: true });
          if (addressExtraInfo?.isMenu) {
            navigate(`${addressExtraInfo.enterFrom || ''}&newAddressId=${id || ''}`, { replace: true });
          } else {
            navigate(-1);
          }
        }
      })
      .catch((err: Error) => {
        void Modal.alert({
          title: '很抱歉',
          content: err.message || '操作失败',
          showCloseButton: true,
        });
      });
  }, []);

  const noData = useMemo(() => {
    return !loading && (!addressList || (addressList && addressList.length === 0));
  }, [loading, addressList]);

  return (
    <div className="address-list-container">
      {/* <Header title="我的地址" /> */}
      {noData ? (
        <div className="no-data">
          <div className="desc">您还没有收货地址</div>
          <Button className="btn" color="primary" shape="rounded" onClick={addAddress}>
            添加配送地址
          </Button>
        </div>
      ) : (
        <div className="address-content">
          {addressList.map((i) => {
            return <AddressCard data={i} key={i?.id} handleDelete={deleteAddressById} onSelect={handleSelect} />;
          })}
          <AddressFooter text="添加配送地址" handleClick={addAddress} />
        </div>
      )}
    </div>
  );
};

export default AddressList;
