import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Input, Dialog, Radio, Form, Space, Toast } from 'antd-mobile';
import { aesEncrypt } from '@omc/crypto';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import './index.less';
import { fetchCancelConfig, fetchSetCancel } from '@/apis/my';
import type { cancelReasonItem, SetCancel } from '@/apis/my';
import { fetchUserInfo } from '@/apis/home';
import type { UserInfo } from '@/apis/home';
import VerificationCodeText from './components/VerificationCodeText';
import AddressFooter from '@/components/AddressFooter';
import useTitle from '@/hooks/useTitle';
import { MY } from '@/constants/path';

const LogoutPage = () => {
  useTitle('注销账号');
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies<string, { [key: string]: string }>(['sid']);
  const [pageData, setPageData] = useState('');
  const [useInfo, setUseInfo] = useState<UserInfo>();
  const reasonArray = useRef<cancelReasonItem[]>([]);
  const verificationCode = useRef<string>('');
  const reasonSelect = useRef<cancelReasonItem>();
  const [cansubmit, setCansubmit] = useState(false);
  const cancelConfirm = useRef('');
  const getCancelConfig = useCallback(() => {
    fetchCancelConfig()
      .then((res) => {
        if (res) {
          reasonArray.current = res.cancelReasons;
          cancelConfirm.current = res.cancelConfirm;
          setPageData(res.cancelLegalLine);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const verifyCanSubmit = () => {
    if (!!verificationCode.current && reasonSelect.current != null) {
      setCansubmit(true);
    } else {
      setCansubmit(false);
    }
  };

  const fonfuseTel = (value: string) => {
    if (value && value.length == 11) {
      return value.slice(0, 3) + '****' + value.slice(7, 12);
    }
    return value || '';
  };

  const getUserInfo = useCallback(() => {
    fetchUserInfo()
      .then((res) => {
        res && setUseInfo(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getCancelConfig();
    getUserInfo();
  }, []);
  const tipView = (
    <div className="verify-tip">
      <div className="verify-tip-label">
        若当前号码已经不用或丢失，您可拨打
        <span
          className="verify-tip-phone"
          onClick={() => {
            const hotline = '4009200205';
          }}>
          400-920-0205
        </span>
        客服热线进行咨询
      </div>
    </div>
  );

  const requestCancel = async (getData: SetCancel) => {
    const data: any = await fetchSetCancel(getData)
      .then((res) => res)
      .catch((err: Error) => {
        Toast.show(err.message || '操作失败！');
      });
    if (data) {
      setCookie('sid', '', { path: '/' });
      Dialog.show({
        title: '注销成功',
        closeOnAction: true,
        bodyStyle: { textAlign: 'center' },
        actions: [
          {
            key: 'success',
            text: '确定',
            style: { color: '0xFFFFFF', fontSize: 16, textAlign: 'center', justifyContent: 'center' },
            onClick: () => {
              navigate(MY);
            },
          },
        ],
        content: '期待您再次使用麦当劳！',
      });
    } else {
      throw new Error();
    }
  };
  const submit = () => {
    Dialog.show({
      title: '注销确认',
      closeOnAction: true,
      actions: [
        [
          {
            key: 'cancel',
            text: '我再想想',
            style: {
              backgroundColor: 'white',
              borderColor: '#666666',
              borderWidth: 0.5,
              color: '0x222222',
              fontSize: 16,
            },
            onClick: () => {},
          },
          {
            key: 'delete',
            text: '注销账号',
            danger: true,
            style: {
              backgroundColor: '#D90007',
              borderColor: '#D90007',
              borderWidth: 0.5,
              color: '#FFFFFF',
              fontSize: 16,
            },
            onClick: () => {
              // const tel = aesEncrypt(mobile);
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
              const code = aesEncrypt(verificationCode.current);
              const params: SetCancel = {
                cancelReason: reasonSelect && reasonSelect.current && reasonSelect.current.reason,
                cancelReasonDesc: reasonSelect && reasonSelect.current && reasonSelect.current.reasonDesc,
                code,
                tel: useInfo?.tel || '',
              };
              requestCancel(params)
                .then((res) => {})
                .catch((err) => {
                  console.log(err);
                });
            },
          },
        ],
      ],
      content: cancelConfirm.current,
    });
  };

  return (
    <div>
      <div style={{ backgroundColor: '#fff' }}>
        <div className="content-logout">
          <span className="logout-desc">{pageData}</span>
          <span className="verify-phone-desc">验证手机号码</span>
          <div className="verify-phone">
            <span className="verify-phone-label">当前号码</span>
            <span className="verify-phone-phone">{useInfo?.phoneNumber}</span>
          </div>
          <VerificationCodeText
            tel={useInfo?.tel}
            vercodeSuccess={(code: string) => {
              verificationCode.current = code;
              verifyCanSubmit();
            }}></VerificationCodeText>
          {tipView}
          {reasonArray || [] ? (
            <Radio.Group>
              <Space direction="vertical" className="verify-reason">
                {reasonArray.current.map((item: cancelReasonItem) => {
                  return (
                    <Radio
                      style={{
                        '--icon-size': '22px',
                        '--font-size': '16px',
                        '--gap': '8px',
                      }}
                      className="verify-reason-radio"
                      key={item?.reason}
                      value={item.reason}
                      onChange={() => {
                        reasonSelect.current = item;
                        verifyCanSubmit();
                      }}>
                      <span className="verify-reason-text">{item.reasonDesc}</span>
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          ) : null}
        </div>
        <div className="logout-bottom">
          <AddressFooter text={'注销账号'} disabled={!cansubmit} handleClick={submit} />
        </div>
      </div>
    </div>
  );
};
export default LogoutPage;
