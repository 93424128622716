import type { FC, CSSProperties } from 'react';
import { Skeleton } from 'antd-mobile';

export type ProductMenuStoreInfoSkeletonProps = {
  className?: string;
  style?: CSSProperties;
};

const ProductMenuStoreInfoSkeleton: FC<ProductMenuStoreInfoSkeletonProps> = (props) => {
  const { className, style } = props;
  return (
    <div className={className} style={{ padding: '8px 20px', ...style }}>
      <Skeleton style={{ '--width': '120px', '--height': '22px' }} />
      <Skeleton style={{ '--width': '52px', '--height': '16px', marginTop: '8px', marginBottom: '10px' }} />
    </div>
  );
};

export default ProductMenuStoreInfoSkeleton;
