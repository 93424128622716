import httpInstance from './request';
import type { CommonResponse } from './request';

const URLS = {
  HOME_CONFIG: '/bff/portal/home/index',
  HOME_COUPON: '/bff/promotion/coupons/v2',
  HOME_MORE_ACTIVITY: '/bff/portal/home/activity',
  RECOMMAND_STORE_INFO: '/bff/portal/recommend/product',
  HOME_RIGHT_CARDS: '/bff/promotion/coupons/rightCards',
  HOME_USER_INFO: '/bff/member/user/portal',
  HOME_ORDER_INFO: '/bff/portal/home/order',
  HOME_ALERT_ADVERTISE: '/bff/portal/richpop',
  RECEIVE_COUPONINFO: '/bff/market/lottery/common/draw',
  HOME_NEW_USER_GIFT: '/bff/portal/home/customer/config',
  NEW_USER_GIFT_COUPON_LIST: '/bff/member/gift/new/user',
  CMS_POSTER_LINK: '/bff/portal/proxy/cms/api/poster/link',
  QR_STORE_JUMP: '/bff/portal/qr/store/jump',
  BACK_APP_BTN_DATA: '/bff/common/normal/dict',
  COMMON_CONFIG_URL: '/bff/common/normal/config',
  ABTEST_CONFIG_URL: '/bff/common/normal/ab',
  HOME_RIGHTCARD_INFO: '/bff/promotion/coupons/card',
  CARD_DETAIL: '/bff/promotion/coupons/rightCard/detail/v2',
  COUPON_DETAIL: '/bff/promotion/coupons/',
  COUPON_AVAILABLE: '/bff/promotion/coupons/group/available',
};

export interface RightCardInfo {
  name: string;
  count: string;
  cards: RightCardsItem[];
  sequence: string;
}
export interface RightCardsItem {
  cardType: number;
  imageUrl: string;
  leftDaysText: string;
  todayLeftCount: number;
  todayLeftCountText: string;
  todayTotalCount: number;
}
export interface ProductCouponInfo {
  name: string;
  count: string;
  giftCountLimit: string;
  sequence: string;
  coupons: CouponsItem[];
  filters: FiltersItem[];
}
interface CardTabsInfo {
  rightCardType: RightCardInfo;
  productCouponType: ProductCouponInfo;
}

export interface AvailableCouponsItem {
  id: string;
  coupons: CouponsItem[];
  cardImageUrl: string;
  cardImageThumbUrl: string;
  totalText?: string;
}

export interface CouponsItem {
  id: string;
  coupons: CardsItem[];
  fold: boolean;
}
export interface CardsItem {
  id: string;
  code: string;
  orderType: number;
  image: string;
  title: string;
  reducePriceText: string;
  reducePrice: string;
  subtitle: string;
  tradeStartDate: string;
  tradeEndDate: string;
  totalCount: number;
  countText: string;
  isGiveAway: number;
  isCanGive: number;
  enable: number;
  channelIconUrls: [];
  countTextBinds: [];
  tradeTimePeriods: PeriodsItem[];
  tags: TagsItem[];
  filterValues: string[];
  isSelected: boolean;
  limitChannelsText: string;
  weekdays: number[];
  denomination: any;
  receiveTime: string;
  description: string;
  disableBtnText: string;
  shareThemeId: string;
  showQr: number;
  rightCardType: number;
  supportReservation: string;
  beType: number[];
  promotionId: string;
  showType: number;
  beTypes: number[];
}
export interface TagsItem {
  type: number;
  label: string;
  color: string;
  value: string;
  image: string;
}
export interface PeriodsItem {
  endTime: string;
  startTime: string;
}
export interface FiltersItem {
  label: string;
  value: string;
}
export interface CouponInfo {
  coupon: CardsItem;
}

export interface AvailableItem {
  scene: string;
  beType: string;
  daypartCode: string;
  storeCode: string;
  beCode: string;
  orderType: string;
}
interface AvailableCardInfo {
  couponlist: [];
}
// 我的卡券tab列表 TODO headers 测试参数先写死
export const fetchCardTabs = (scene = 3) =>
  httpInstance
    .get<CommonResponse<CardTabsInfo>>(URLS.HOME_COUPON, {
      params: { scene },
      headers: { language: 'zh' },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
// 我的卡券详情
export const fetchCardDetail = (couponCode: string, couponId: string) =>
  httpInstance
    .get<CommonResponse<CouponInfo>>(URLS.COUPON_DETAIL + couponCode, {
      params: { couponId: couponId, scene: 3 },
      headers: { language: 'zh' },
      pathVars: [couponCode],
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });

// 当前可用卡券列表
export const fetchCardAvailable = (paramsData: AvailableItem) =>
  httpInstance
    .get<CommonResponse<AvailableCardInfo>>(URLS.COUPON_AVAILABLE, {
      params: paramsData,
      headers: { language: 'zh' },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });
