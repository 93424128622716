import React, { useCallback, useMemo } from 'react';
import { SwipeAction, Dialog, Button, Modal, Tag } from 'antd-mobile';
import type { Action } from 'antd-mobile/es/components/swipe-action';
import { useSelector, useDispatch } from 'react-redux';
import { Ellipsis } from 'antd-mobile';
import type { RootState } from '@/store/';
import { updateDetail } from '@/store/addressSlice';
import { useNavigate } from 'react-router-dom';
import type { Address } from '@/apis/address';
import { getGendertitle } from '@/utils';
import type { Gende } from '@/utils';
import { location_icon_url, edit_icon_url, delete_icon_url } from '@/constants';
import { EDIT_ADDRESS } from '@/constants/path';

import './index.less';

interface Iprops {
  data: Address;
  handleDelete: (id: string) => void;
  onSelect?: (p: Address) => void;
}

const AddressCard: React.FC<Iprops> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, handleDelete, onSelect } = props;
  const { address, fullName, phone, detail, gender, id, tags } = data;
  const genderTitle = useMemo(() => getGendertitle(gender as Gende), [gender]);

  const handleAction = useCallback(
    (_: Action, e: React.MouseEvent) => {
      e.stopPropagation();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Dialog.confirm({
        destroyOnClose: true,
        maskClassName: 'mcd-dialog-container',
        bodyStyle: { textAlign: 'center', fontSize: '13px', color: '#222' },
        title: '删除地址',
        content: <div>你确定要删除该地址吗</div>,

        onConfirm: () => {
          handleDelete(id);
        },
        confirmText: '删除',
        // (
        //   <Button color="primary" size="small" shape="rounded" style={{ width: '100%' }}>
        //     删除
        //   </Button>
        // ),
        cancelText: '取消',
        // (
        //   <Button size="small" style={{ width: '100%' }} shape="rounded">
        //     取消
        //   </Button>
        // ),
      });
    },
    [handleDelete, id],
  );

  const handleEdit = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const { id: addressId } = data || {};
      if (!addressId) return;
      dispatch(updateDetail(data));
      navigate(EDIT_ADDRESS);
    },
    [data, dispatch, navigate],
  );

  const handleSelect = useCallback(() => {
    onSelect && onSelect(data);
  }, [onSelect, data]);

  return (
    <div className="address-card-container" onClick={handleSelect}>
      <SwipeAction
        onAction={handleAction}
        rightActions={[
          {
            key: 'delete',
            text: (
              <div className="delete">
                <img src={delete_icon_url} />
                <span>删除</span>
              </div>
            ),
            color: '#f9f9f9',
          },
        ]}>
        <div className="address-card-content">
          <div className="info">
            <div className="address">
              <img src={location_icon_url} />
              <span className="address-detail">
                <Ellipsis content={address} />
                {/* {address} */}
              </span>
              {tags && (
                <Tag className="tag" color="#fcedec" round>
                  {tags}
                </Tag>
              )}
            </div>
            <div className="detail">
              <Ellipsis content={detail} />
            </div>
            <div className="user">
              <span className="name">
                {fullName}
                {genderTitle}
              </span>
              <span className="phone">{phone}</span>
            </div>
          </div>
          <div className="btn" onClick={handleEdit}>
            <img src={edit_icon_url} />
          </div>
        </div>
      </SwipeAction>
    </div>
  );
};

export default AddressCard;
