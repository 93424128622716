import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import './index.less';
import type { CouponsItem, CardsItem, RightCardsItem } from '@/apis/cardlist';
import CommonCouponCard from '../../../components/CommonCouponCard';
import EmptyView from '../../../components/EmptyView';

interface IProps {
  coupons: CouponsItem[];
}

const CouponItem: FC<IProps> = (props) => {
  const { coupons } = props || {};

  const [couponsList, setCouponsList] = useState<CouponsItem[]>([]);

  useEffect(() => {
    if (coupons && coupons.length > 0 && (!couponsList || couponsList.length === 0)) {
      setCouponsList([...coupons]);
    }
  }, [coupons, couponsList]);

  const onGroupCouponSelect = (groupCardItem: CardsItem) => {
    const couoponIndex = couponsList.findIndex((item: CouponsItem) => {
      return item.id === groupCardItem.id;
    });
    if (couoponIndex >= 0) {
      couponsList[couoponIndex].fold = !couponsList[couoponIndex].fold;
      couponsList[couoponIndex].coupons.slice(1).map((item: CardsItem) => {
        item.isSelected = false;
      });
      const couopons = couponsList.map((item: CouponsItem) => {
        return item;
      });
      setCouponsList(couopons);
    }
  };

  return (
    <div className="product-coupon-content">
      {couponsList.map((coup, idx: number) => {
        const isGroupCoupon = coup.coupons.length > 1;
        return (
          <div key={idx}>
            <CommonCouponCard
              card={coup.coupons[0]}
              coupList={couponsList}
              idx={idx}
              onGroupCouponSelect={onGroupCouponSelect}
              isGroupCoupon={isGroupCoupon}
              scene={3}
              showMoreCoupon={true}
              showInstructions={true}
              showBtm={true}
              showCouponCardBottom={true}
              isAvailable={true}
            />
            {coup.fold &&
              coup.coupons.slice(1).map((subItem: CardsItem, subidx: number) => {
                return (
                  <CommonCouponCard
                    key={subidx}
                    coupList={couponsList}
                    idx={idx}
                    scene={3}
                    card={subItem}
                    showBtm={false}
                  />
                );
              })}
          </div>
        );
      })}
      {(!couponsList || (couponsList && couponsList.length === 0)) && <EmptyView></EmptyView>}
    </div>
  );
};

export default CouponItem;
