import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export type ProductScrollStateItem = {
  scrollX?: number;
  scrollY?: number;
};

export type ProductScrollState = Record<string, ProductScrollStateItem | undefined>;

const scrollStates: ProductScrollState = {};

/**
 *
 * @param key
 */
export const getScrollState = (key?: string): ProductScrollStateItem | undefined => {
  if (scrollStates && key && scrollStates[key]) {
    return scrollStates[key];
  }

  return undefined;
};

/**
 *
 * @param key
 * @param state
 */
export const setScrollState = (key: string, state?: ProductScrollStateItem): void => {
  scrollStates[key] = state;
};

export function useScrollState() {
  const location = useLocation();
  const [state, setState] = useState<ProductScrollStateItem>();

  const updateState = useCallback(
    (s?: ProductScrollStateItem) => {
      if (location.key) {
        setScrollState(location.key, s);
      }
    },
    [location.key],
  );

  const getState = useCallback(
    (key?: string) => {
      const _k = key || location.key;

      return getScrollState(_k);
    },
    [location.key],
  );

  useEffect(() => {
    setState(getScrollState(location.key));
  }, [location.key]);

  return [state, { get: getState, set: updateState }] as const;
}
