import httpInstance from './request';
import { BIZ_CODE_101, BIZ_CODE_103 } from '@/constants/code';
import type { CommonResponse } from './request';

const URL_CONST = {
  CART: '/bff/cart/carts',
  EMPTY_CART: '/bff/cart/carts/empty',
  NEARBYSTORE: '/bff/store/stores/vicinity',
  MENULIST: '/bff/spc/menu',
  SEARCHMENU: '/bff/spc/products/search',
  // VALIDATION: '/bff/spc/carts/validation',
  VALIDATION_NEW: '/bff/order/confirmation/validationinfo',
  MENULISTBANNER: '/bff/store/menu/banners',
  PRODUCTDETAIL: '/bff/spc/products/',
  MCCAFEPRODUCTDETAIL: '/bff/spc/products/detail/',
  PRODUCTDESC: '/bff/spc/kvs/',
  ALLCITIES: '/bff/store/cities/group',
  CITYNAME: '/bff/store/cities',
  // NEARBYSTORES: HOST + ' /bff/store/stores/nearby',
  ALLSTORES: '/bff/store/stores',
  STORESFILTERTAG: '/bff/store/stores/hottags',
  FREQUENTSTORES: '/bff/store/stores/frequent',
  COUPONPRODUCTS: '/bff/promotion/coupons/products',
  // MENUCOUPONS: '/bff/promotion/menu/discount',
  ADDRESSMANAGER: '/bff/member/addresses',
  ADDRESSPOISEARCH: '/bff/member/addresses/poi',
  CUSTOMLOCATIONPOI: '/bff/store/pois/search',
  QUERYSTOREBYBECODE: '/bff/store/stores/be/',
  QUERYSTOREBYSTORECODE: '/bff/store/stores/',
  NEWSTOREDETAILINFO: '/bff/store/stores/detailPage/',
  FETCHMDSSWITCHDELIVERYTIME: '/bff/store/stores/{storeCode}/menu/daypartsTimeoption',
  OHMAICARDMENU: '/bff/spc/menu/card',
  STORERECOMMENDINFO: '/bff/portal/recommend/customer',
  COUPONDATALISTWITHSCENE: '/bff/promotion/coupons/v2',
  MENUANOTHERORDER: '/bff/order/orders/last',
  MENUBUYANOTHER: '/bff/order/orders',
  MENUMCCAFEPOINT: '/bff/promotion/menu/mcCafe/discount',
  DRIVINGDISTANCE: '/bff/store/pois/distance',
  MENURIGHTCARDINFO: '/bff/promotion/coupons/card',
  DCCONFIGUREPRICE: '/bff/promotion/price',
  STOREFAVLIST: '/bff/store/stores/collection/list',
  STOREUPDATEFAV: '/bff/member/store/collection',
  POIS_SEARCH_WALK: '/bff/store/pois/direction/walk',
  POIS_GEOCODE: '/bff/store/pois/geocode',
};

export type Hottags = 'CN' | 'EN';

export interface HotTagsItem {
  /**
   * 热门标签Code
   */
  code?: string;
  /**
   * 热门标签名称
   */
  label?: string;
}

export interface NearByStores {
  /**
   * 服务器时间
   */
  datetime?: string;
  mdsStore?: Store_;
  pickupStore?: Store_1;
  recommendAddress?: RecommendAddress_;
  /**
   * 附近的门店，按距离远近排序，近的在前。根据场景不同含义略有差异。
   */
  stores?: StoreItem[];
}
export interface Store_ {
  /**
   * 地址
   */
  address?: string;
  /**
   * 餐厅BE编码
   */
  beCode?: string;
  /**
   * 餐厅BE类型
   */
  beType?: number;
  /**
   * 营业时间列表
   */
  businessHours?: MpBusinessHour[];
  /**
   * 开店时间，格式: 2004-07-30
   */
  businessStartDay?: string;
  /**
   * 当前营业状态:0-未营业;1-营业中;
   */
  businessStatus?: number;
  /**
   * 所在城市编码
   */
  cityCode?: string;
  /**
   * 所在城市名称
   */
  cityName?: string;
  /**
   * 餐厅编码
   */
  code?: string;
  /**
   * 餐厅社区码
   */
  communityQrcode?: string;
  /**
   * 餐段列表
   */
  dayparts?: NoName[];
  /**
   * 配送费编码
   */
  deliveryFeeCode?: string;
  /**
   * 预计送达时间
   */
  deliveryTime?: string;
  /**
   * 距离, 单位: 米
   */
  distance?: number;
  /**
   * 距离展示值, 包含单位
   */
  distanceText?: string;
  /**
   * 送达时间，单位分钟
   */
  duration?: number;
  /**
   * 当天结束营业时间
   */
  endTime?: string;
  /**
   * 预计送达时间描述文字
   */
  estimatedDeliveryTime?: string;
  /**
   * 热门标签编码
   */
  hotTagCodes?: string[];
  /**
   * 是否启用(客户端无需考虑，已合并进 businessStatus )
   */
  isActive?: boolean;
  /**
   * 是否已收藏该餐厅
   */
  isCollected?: boolean;
  /**
   * 是否得来速餐厅
   */
  isDt?: boolean;
  /**
   * 纬度
   */
  latitude?: number;
  /**
   * 经度
   */
  longitude?: number;
  /**
   * 餐厅名称
   */
  name?: string;
  /**
   * 是否需要确认门店弹窗 true需要 1不是距离最近的门店（因为更近的门店当前不营业） 2 确实是距离最近的门店但是超过配置的距离
   */
  needPop?: boolean;
  /**
   * 是否新BE 0-否;1-是 (当 Store 与 BE 缝合时使用此字段)
   */
  newBe?: number;
  /**
   * 新BE到期日，格式 2021-05-19 23:50:00 (当 Store 与 BE 缝合时使用此字段)
   */
  newBeEndDate?: string;
  /**
   * 是否新店 0-否;1-是
   */
  newStore?: number;
  /**
   * 新店到期日，格式 2021-05-19 23:50:00
   */
  newStoreEndDate?: string;
  /**
   * 确认弹窗内容
   */
  popText?: string;
  /**
   * 餐厅简称
   */
  shortName?: string;
  /**
   * 当天开始营业时间
   */
  startTime?: string;
  /**
   * 门脸图片
   */
  storeFrontPic?: string;
  /**
   * 标签信息
   */
  tags?: StoreTag_[];
  /**
   * 餐厅联系电话
   */
  telephone?: string;
  /**
   * 门店提示信息
   */
  tips?: string;
  /**
   * 欢迎语
   */
  welcomeMsg?: string;
}

export interface MpBusinessHour {
  dayPartList?: MpDaypart[];
  endTime?: string;
  startTime?: string;
  weekday?: string;
}
export interface MpDaypart {
  businessHour?: TimePeriod;
  dayPartCode?: number;
  dayPartNameCn?: string;
  dayPartNameEn?: string;
}
export interface TimePeriod {
  endTime?: string;
  startTime?: string;
}
export interface NoName {
  /**
   * daypart编码
   */
  daypartCode?: number;
  /**
   * 是否是默认的daypart
   */
  daypartFlag?: boolean;
  /**
   * daypart名称
   */
  daypartName?: string;
  /**
   * daypart结束时间
   */
  endTime?: string;
  /**
   * daypart开始时间
   */
  startTime?: string;
}
export interface StoreTag_ {
  /**
   * 标签展示名称，如: 休息中、最近浏览、常去、去过、距离最近，未来可能支持英文
   */
  label?: string;
  /**
   * 标签值，用于程序处理，如: resting、recently_viewed、frequent、have_been、nearest
   */
  value?: string;
}
export interface Store_1 {
  /**
   * 地址
   */
  address?: string;
  /**
   * 餐厅BE编码
   */
  beCode?: string;
  /**
   * 餐厅BE类型
   */
  beType?: number;
  /**
   * 营业时间列表
   */
  businessHours?: MpBusinessHour1[];
  /**
   * 开店时间，格式: 2004-07-30
   */
  businessStartDay?: string;
  /**
   * 当前营业状态:0-未营业;1-营业中;
   */
  businessStatus?: number;
  /**
   * 所在城市编码
   */
  cityCode?: string;
  /**
   * 所在城市名称
   */
  cityName?: string;
  /**
   * 餐厅编码
   */
  code?: string;
  /**
   * 餐厅社区码
   */
  communityQrcode?: string;
  /**
   * 餐段列表
   */
  dayparts?: NoName1[];
  /**
   * 配送费编码
   */
  deliveryFeeCode?: string;
  /**
   * 预计送达时间
   */
  deliveryTime?: string;
  /**
   * 距离, 单位: 米
   */
  distance?: number;
  /**
   * 距离展示值, 包含单位
   */
  distanceText?: string;
  /**
   * 送达时间，单位分钟
   */
  duration?: number;
  /**
   * 当天结束营业时间
   */
  endTime?: string;
  /**
   * 预计送达时间描述文字
   */
  estimatedDeliveryTime?: string;
  /**
   * 热门标签编码
   */
  hotTagCodes?: string[];
  /**
   * 是否启用(客户端无需考虑，已合并进 businessStatus )
   */
  isActive?: boolean;
  /**
   * 是否已收藏该餐厅
   */
  isCollected?: boolean;
  /**
   * 是否得来速餐厅
   */
  isDt?: boolean;
  /**
   * 纬度
   */
  latitude?: number;
  /**
   * 经度
   */
  longitude?: number;
  /**
   * 餐厅名称
   */
  name?: string;
  /**
   * 是否需要确认门店弹窗 true需要 1不是距离最近的门店（因为更近的门店当前不营业） 2 确实是距离最近的门店但是超过配置的距离
   */
  needPop?: boolean;
  /**
   * 是否新BE 0-否;1-是 (当 Store 与 BE 缝合时使用此字段)
   */
  newBe?: number;
  /**
   * 新BE到期日，格式 2021-05-19 23:50:00 (当 Store 与 BE 缝合时使用此字段)
   */
  newBeEndDate?: string;
  /**
   * 是否新店 0-否;1-是
   */
  newStore?: number;
  /**
   * 新店到期日，格式 2021-05-19 23:50:00
   */
  newStoreEndDate?: string;
  /**
   * 确认弹窗内容
   */
  popText?: string;
  /**
   * 餐厅简称
   */
  shortName?: string;
  /**
   * 当天开始营业时间
   */
  startTime?: string;
  /**
   * 门脸图片
   */
  storeFrontPic?: string;
  /**
   * 标签信息
   */
  tags?: StoreTag_1[];
  /**
   * 餐厅联系电话
   */
  telephone?: string;
  /**
   * 门店提示信息
   */
  tips?: string;
  /**
   * 欢迎语
   */
  welcomeMsg?: string;
}
export interface MpBusinessHour1 {
  dayPartList?: MpDaypart1[];
  endTime?: string;
  startTime?: string;
  weekday?: string;
}
export interface MpDaypart1 {
  businessHour?: TimePeriod1;
  dayPartCode?: number;
  dayPartNameCn?: string;
  dayPartNameEn?: string;
}
export interface TimePeriod1 {
  endTime?: string;
  startTime?: string;
}
export interface NoName1 {
  /**
   * daypart编码
   */
  daypartCode?: number;
  /**
   * 是否是默认的daypart
   */
  daypartFlag?: boolean;
  /**
   * daypart名称
   */
  daypartName?: string;
  /**
   * daypart结束时间
   */
  endTime?: string;
  /**
   * daypart开始时间
   */
  startTime?: string;
}
export interface StoreTag_1 {
  /**
   * 标签展示名称，如: 休息中、最近浏览、常去、去过、距离最近，未来可能支持英文
   */
  label?: string;
  /**
   * 标签值，用于程序处理，如: resting、recently_viewed、frequent、have_been、nearest
   */
  value?: string;
}
export interface RecommendAddress_ {
  /**
   * POI 地址
   */
  address?: string;
  /**
   * 渠道(仅创建时需要): 03-New App; 04-NewWeb;
   */
  channel: string;
  /**
   * 城市编码
   */
  cityCode: string;
  /**
   * 城市名称
   */
  cityName?: string;
  /**
   * 是否设为默认地址,0-不设为默认地址;1-设为默认地址
   */
  defaultAddress?: number;
  /**
   * 门牌详细地址
   */
  detail: string;
  /**
   * 展示完整地址(非请求参数，返回体使用)
   */
  displayFullText?: string;
  /**
   * 距离，单位米
   */
  distance?: number;
  /**
   * 姓名
   */
  fullName: string;
  /**
   * 性别: 0-未知; 1-male; 2-female;
   */
  gender?: number;
  /**
   * 地址ID(创建时不需要)
   */
  id?: string;
  /**
   * 纬度
   */
  latitude: number;
  /**
   * 经度
   */
  longitude: number;
  /**
   * 手机号码
   */
  phone: string;
  store?: Store_2;
}
export interface Store_2 {
  /**
   * 地址
   */
  address?: string;
  /**
   * 餐厅BE编码
   */
  beCode?: string;
  /**
   * 餐厅BE类型
   */
  beType?: number;
  /**
   * 营业时间列表
   */
  businessHours?: MpBusinessHour2[];
  /**
   * 开店时间，格式: 2004-07-30
   */
  businessStartDay?: string;
  /**
   * 当前营业状态:0-未营业;1-营业中;
   */
  businessStatus?: number;
  /**
   * 所在城市编码
   */
  cityCode?: string;
  /**
   * 所在城市名称
   */
  cityName?: string;
  /**
   * 餐厅编码
   */
  code?: string;
  /**
   * 餐厅社区码
   */
  communityQrcode?: string;
  /**
   * 餐段列表
   */
  dayparts?: NoName2[];
  /**
   * 配送费编码
   */
  deliveryFeeCode?: string;
  /**
   * 预计送达时间
   */
  deliveryTime?: string;
  /**
   * 距离, 单位: 米
   */
  distance?: number;
  /**
   * 距离展示值, 包含单位
   */
  distanceText?: string;
  /**
   * 送达时间，单位分钟
   */
  duration?: number;
  /**
   * 当天结束营业时间
   */
  endTime?: string;
  /**
   * 预计送达时间描述文字
   */
  estimatedDeliveryTime?: string;
  /**
   * 热门标签编码
   */
  hotTagCodes?: string[];
  /**
   * 是否启用(客户端无需考虑，已合并进 businessStatus )
   */
  isActive?: boolean;
  /**
   * 是否已收藏该餐厅
   */
  isCollected?: boolean;
  /**
   * 是否得来速餐厅
   */
  isDt?: boolean;
  /**
   * 纬度
   */
  latitude?: number;
  /**
   * 经度
   */
  longitude?: number;
  /**
   * 餐厅名称
   */
  name?: string;
  /**
   * 是否需要确认门店弹窗 true需要 1不是距离最近的门店（因为更近的门店当前不营业） 2 确实是距离最近的门店但是超过配置的距离
   */
  needPop?: boolean;
  /**
   * 是否新BE 0-否;1-是 (当 Store 与 BE 缝合时使用此字段)
   */
  newBe?: number;
  /**
   * 新BE到期日，格式 2021-05-19 23:50:00 (当 Store 与 BE 缝合时使用此字段)
   */
  newBeEndDate?: string;
  /**
   * 是否新店 0-否;1-是
   */
  newStore?: number;
  /**
   * 新店到期日，格式 2021-05-19 23:50:00
   */
  newStoreEndDate?: string;
  /**
   * 确认弹窗内容
   */
  popText?: string;
  /**
   * 餐厅简称
   */
  shortName?: string;
  /**
   * 当天开始营业时间
   */
  startTime?: string;
  /**
   * 门脸图片
   */
  storeFrontPic?: string;
  /**
   * 标签信息
   */
  tags?: StoreTag_2[];
  /**
   * 餐厅联系电话
   */
  telephone?: string;
  /**
   * 门店提示信息
   */
  tips?: string;
  /**
   * 欢迎语
   */
  welcomeMsg?: string;
}
export interface MpBusinessHour2 {
  dayPartList?: MpDaypart2[];
  endTime?: string;
  startTime?: string;
  weekday?: string;
}
export interface MpDaypart2 {
  businessHour?: TimePeriod2;
  dayPartCode?: number;
  dayPartNameCn?: string;
  dayPartNameEn?: string;
}
export interface TimePeriod2 {
  endTime?: string;
  startTime?: string;
}
export interface NoName2 {
  /**
   * daypart编码
   */
  daypartCode?: number;
  /**
   * 是否是默认的daypart
   */
  daypartFlag?: boolean;
  /**
   * daypart名称
   */
  daypartName?: string;
  /**
   * daypart结束时间
   */
  endTime?: string;
  /**
   * daypart开始时间
   */
  startTime?: string;
}
export interface StoreTag_2 {
  /**
   * 标签展示名称，如: 休息中、最近浏览、常去、去过、距离最近，未来可能支持英文
   */
  label?: string;
  /**
   * 标签值，用于程序处理，如: resting、recently_viewed、frequent、have_been、nearest
   */
  value?: string;
}
export interface StoreItem {
  /**
   * 地址
   */
  address?: string;
  /**
   * 餐厅BE编码
   */
  beCode?: string;
  /**
   * 餐厅BE类型
   */
  beType?: number;
  /**
   * 营业时间列表
   */
  businessHours?: MpBusinessHour3[];
  /**
   * 开店时间，格式: 2004-07-30
   */
  businessStartDay?: string;
  /**
   * 当前营业状态:0-未营业;1-营业中;
   */
  businessStatus?: number;
  onlineBusinessStatus?: boolean;
  /**
   * 所在城市编码
   */
  cityCode?: string;
  /**
   * 所在城市名称
   */
  cityName?: string;
  /**
   * 餐厅编码
   */
  code?: string;
  /**
   * 餐厅社区码
   */
  communityQrcode?: string;
  /**
   * 餐段列表
   */
  dayparts?: NoName3[];
  /**
   * 配送费编码
   */
  deliveryFeeCode?: string;
  /**
   * 预计送达时间
   */
  deliveryTime?: string;
  /**
   * 距离, 单位: 米
   */
  distance?: number;
  /**
   * 距离展示值, 包含单位
   */
  distanceText?: string;
  /**
   * 送达时间，单位分钟
   */
  duration?: number;
  /**
   * 当天结束营业时间
   */
  endTime?: string;
  /**
   * 预计送达时间描述文字
   */
  estimatedDeliveryTime?: string;
  /**
   * 热门标签编码
   */
  hotTagCodes?: string[];
  /**
   * 是否启用(客户端无需考虑，已合并进 businessStatus )
   */
  isActive?: boolean;
  /**
   * 是否已收藏该餐厅
   */
  isCollected?: boolean;
  /**
   * 是否得来速餐厅
   */
  isDt?: boolean;
  /**
   * 纬度
   */
  latitude?: number;
  /**
   * 经度
   */
  longitude?: number;
  /**
   * 餐厅名称
   */
  name?: string;
  /**
   * 是否需要确认门店弹窗 true需要 1不是距离最近的门店（因为更近的门店当前不营业） 2 确实是距离最近的门店但是超过配置的距离
   */
  needPop?: boolean;
  /**
   * 是否新BE 0-否;1-是 (当 Store 与 BE 缝合时使用此字段)
   */
  newBe?: number;
  /**
   * 新BE到期日，格式 2021-05-19 23:50:00 (当 Store 与 BE 缝合时使用此字段)
   */
  newBeEndDate?: string;
  /**
   * 是否新店 0-否;1-是
   */
  newStore?: number;
  /**
   * 新店到期日，格式 2021-05-19 23:50:00
   */
  newStoreEndDate?: string;
  /**
   * 确认弹窗内容
   */
  popText?: string;
  /**
   * 餐厅简称
   */
  shortName?: string;
  /**
   * 当天开始营业时间
   */
  startTime?: string;
  /**
   * 门脸图片
   */
  storeFrontPic?: string;
  /**
   * 标签信息
   */
  tags?: StoreTag_3[];
  /**
   * 餐厅联系电话
   */
  telephone?: string;
  /**
   * 门店提示信息
   */
  tips?: string;
  /**
   * 欢迎语
   */
  welcomeMsg?: string;
}
export interface MpBusinessHour3 {
  dayPartList?: MpDaypart3[];
  endTime?: string;
  startTime?: string;
  weekday?: string;
}
export interface MpDaypart3 {
  businessHour?: TimePeriod3;
  dayPartCode?: number;
  dayPartNameCn?: string;
  dayPartNameEn?: string;
}
export interface TimePeriod3 {
  endTime?: string;
  startTime?: string;
}
export interface NoName3 {
  /**
   * daypart编码
   */
  daypartCode?: number;
  /**
   * 是否是默认的daypart
   */
  daypartFlag?: boolean;
  /**
   * daypart名称
   */
  daypartName?: string;
  /**
   * daypart结束时间
   */
  endTime?: string;
  /**
   * daypart开始时间
   */
  startTime?: string;
}
export interface StoreTag_3 {
  /**
   * 标签展示名称，如: 休息中、最近浏览、常去、去过、距离最近，未来可能支持英文
   */
  label?: string;
  /**
   * 标签值，用于程序处理，如: resting、recently_viewed、frequent、have_been、nearest
   */
  value?: string;
}

export interface CollectionStores {
  /**
   * 门店列表
   */
  stores?: StoreItem[];
}

export interface SearchStoreList {
  /**
   * 服务器时间，格式: yyyy-MM-dd HH:mm:ss
   */
  datetime?: string;
  /**
   * 当前页码
   */
  pageNo?: number;
  /**
   * 每页数量
   */
  pageSize?: number;
  /**
   * 门店数据
   */
  stores?: StoreItem[];
  /**
   * 总数量
   */
  totalSize?: number;
}

export interface StoreDetail {
  /**
   * 地址
   */
  address?: string;
  /**
   * 餐厅BE编码
   */
  beCode?: string;
  /**
   * 餐厅BE类型
   */
  beType?: number;
  /**
   * 营业时间列表
   */
  businessHours?: MpBusinessHour[];
  /**
   * 开店时间，格式: 2004-07-30
   */
  businessStartDay?: string;
  /**
   * 当前营业状态:0-未营业;1-营业中;
   */
  businessStatus?: number;
  /**
   * 所在城市编码
   */
  cityCode?: string;
  /**
   * 所在城市名称
   */
  cityName?: string;
  /**
   * 餐厅编码
   */
  code?: string;
  /**
   * 餐厅社区码
   */
  communityQrcode?: string;
  /**
   * 预约日期
   */
  date?: string;
  /**
   * 服务器时间，格式: yyyy-MM-dd HH:mm:ss
   */
  datetime?: string;
  /**
   * 餐段列表
   */
  dayparts?: NoName[];
  /**
   * 配送费编码
   */
  deliveryFeeCode?: string;
  /**
   * 预计送达时间
   */
  deliveryTime?: string;
  /**
   * 距离, 单位: 米
   */
  distance?: number;
  /**
   * 距离展示值, 包含单位
   */
  distanceText?: string;
  /**
   * 送达时间，单位分钟
   */
  duration?: number;
  /**
   * 当天结束营业时间
   */
  endTime?: string;
  /**
   * 预计送达时间描述文字
   */
  estimatedDeliveryTime?: string;
  /**
   * 是否有厨房监控视频
   */
  hasKitchenVideo?: boolean;
  /**
   * 热门标签编码
   */
  hotTagCodes?: string[];
  /**
   * 是否启用(客户端无需考虑，已合并进 businessStatus )
   */
  isActive?: boolean;
  /**
   * 是否已收藏该餐厅
   */
  isCollected?: boolean;
  /**
   * 是否得来速餐厅
   */
  isDt?: boolean;
  /**
   * 厨房监控视频title文案
   */
  kitchenVideoTitle?: string;
  /**
   * 纬度
   */
  latitude?: number;
  /**
   * 经度
   */
  longitude?: number;
  /**
   * 餐厅名称
   */
  name?: string;
  /**
   * 是否展示banner
   */
  needBanner?: boolean;
  /**
   * 是否需要确认门店弹窗 true需要 1不是距离最近的门店（因为更近的门店当前不营业） 2 确实是距离最近的门店但是超过配置的距离
   */
  needPop?: boolean;
  /**
   * 是否新BE 0-否;1-是 (当 Store 与 BE 缝合时使用此字段)
   */
  newBe?: number;
  /**
   * 新BE到期日，格式 2021-05-19 23:50:00 (当 Store 与 BE 缝合时使用此字段)
   */
  newBeEndDate?: string;
  /**
   * 是否新店 0-否;1-是
   */
  newStore?: number;
  /**
   * 新店到期日，格式 2021-05-19 23:50:00
   */
  newStoreEndDate?: string;
  // add by cy
  onlineBusinessStatus?: boolean;
  /**
   * 确认弹窗内容
   */
  popText?: string;
  /**
   * 餐厅社区右下角角标
   */
  qrCodeBadgeImg?: string;
  /**
   * 餐厅社区码旁边的描述
   */
  qrCodeDesc?: string;
  /**
   * 餐厅社区码旁边的描述的图片(由于后续需要展示富文本，为方便客户端，改用图片，替代 qrCodeDesc 字段)
   */
  qrCodeDescImg?: string;
  /**
   * 餐厅社区码旁边的标题
   */
  qrCodeTitle?: string;
  /**
   * 餐厅简称
   */
  shortName?: string;
  /**
   * 当天开始营业时间
   */
  startTime?: string;
  /**
   * 门脸图片
   */
  storeFrontPic?: string;
  /**
   * 标签信息
   */
  tags?: StoreTag_[];
  /**
   * 餐厅联系电话
   */
  telephone?: string;
  /**
   * 预约送达时间
   */
  time?: string;
  /**
   * 门店提示信息
   */
  tips?: string;
  /**
   * 欢迎语
   */
  welcomeMsg?: string;
}

/* 餐厅标签 */
export const fetchStoreTag = () =>
  httpInstance
    .get<CommonResponse<HotTagsItem[]>>(URL_CONST.STORESFILTERTAG, {
      params: {},
      headers: { biz_from: '1014', biz_scenario: BIZ_CODE_103 },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });

/* 附近餐厅 */
export const fetchNearByStores = ({
  showType = 2,
  latitude = 0,
  longitude = 0,
  distance = 1000,
  keyword = '',
  hotTagCode = '',
  beType = '',
}) =>
  httpInstance
    .get<CommonResponse<NearByStores>>(URL_CONST.NEARBYSTORE, {
      params: {
        latitude,
        longitude,
        distance,
        keyword,
        hotTagCode,
        showType,
        beType,
      },
      headers: { biz_from: '1006', biz_scenario: BIZ_CODE_103 },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });

export interface FavStoreParams {
  beType?: string;
  hotTagCode?: string;
}
/* 获取收藏餐厅 */
export const fetchMyFavStoreList = ({ beType = '', hotTagCode = '' }: FavStoreParams) =>
  httpInstance
    .get<CommonResponse<CollectionStores>>(URL_CONST.STOREFAVLIST, {
      params: { beType, hotTagCode },
      headers: { biz_from: '1014', biz_scenario: BIZ_CODE_103 },
    })
    .then((res) => {
      const { code, data } = res || {};
      if (code === 200) {
        return data;
      }
    });

export interface SearchStoreParams {
  pageNo?: number;
  pageSize?: number;
  cityCode: string;
  keyword?: string;
  hotTagCode?: string;
  beType?: string;
}

export const searchStore = ({
  pageNo = 1,
  pageSize = 10,
  cityCode = '',
  keyword = '',
  hotTagCode = '',
  beType = '',
}: SearchStoreParams) =>
  httpInstance
    .get<CommonResponse<SearchStoreList>>(URL_CONST.ALLSTORES, {
      params: { pageNo, pageSize, cityCode, keyword, hotTagCode, beType },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });

export interface UpdateStoreFavParams {
  storeCode: string;
  beCode?: string;
  beType?: string;
  operationType: number; //1 收藏  2为取消收藏
}
interface UpdateStoreFavRes {
  storeCode: string;
  beCode?: string;
  beType?: string;
  isCollected: boolean;
}
//更新收藏动作，添加或者取消
export const updateStoreFav = ({ storeCode, beCode = '', beType = '', operationType }: UpdateStoreFavParams) =>
  httpInstance
    .post<CommonResponse<UpdateStoreFavRes>>(URL_CONST.STOREUPDATEFAV, {
      params: { storeCode, beCode, beType, operationType },
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });

export const fetchStoreDetailInfo = (storeCode: string) =>
  httpInstance
    .get<CommonResponse<StoreDetail>>(URL_CONST.NEWSTOREDETAILINFO + storeCode, {
      params: {},
      headers: { biz_from: '1004', biz_scenario: BIZ_CODE_101 },
      pathVars: [storeCode],
    })
    .then((res) => {
      const { code, data, message } = res || {};
      if (code === 200) {
        return data;
      } else {
        throw new Error(message || '请求失败');
      }
    });
