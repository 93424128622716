import type { FC } from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useResource, useDeepMemo } from '@axios-use/react';
import { Image, Button, Toast } from 'antd-mobile';

import useQuery from '@/hooks/useQuery';
import { useCartAction } from '@/hooks/useCart';
import { useSetTitleStyle } from '@/hooks/useUpFuncs';
import { useSupportSafeArea } from '@/hooks/useSupportSafeArea';
import { getCDNAssets } from '@/utils';

import type { GetCouponProductListQuery } from '@/apis/product';
import { getCouponProductList } from '@/apis/product';
import ProductCardPrice from '../../Menu/List/Card/Price';
import { useStoreDateInfoWithLocation } from '../../external';

import styles from './page.module.less';

type UrlPathQuery = {
  name?: string;
  storeCode?: string;
  daypartCode?: string;
  orderType?: string;
  beCode?: string;
  couponCode?: string;
  couponId?: string;
  promotionId?: string;
};

const ProductCouponSelect: FC = () => {
  const query = useQuery<UrlPathQuery>();
  const navigate = useNavigate();

  useSetTitleStyle({
    title: '选择餐品',
    navBackgroundColor: 'FFFFFFFF',
    appletStyle: 'black',
    backBtnVisible: '1',
    appletTitleBarVisible: '1',
  });

  const [checkedCode, setCheckedCode] = useState<string>();
  const orderType = query?.orderType ? Number(query?.orderType) : 1;

  const { curDateInfo } = useStoreDateInfoWithLocation(orderType);
  const { updateCart } = useCartAction();

  const [productsRes] = useResource(
    getCouponProductList,
    [
      {
        storeCode: query?.storeCode,
        daypartCode: query?.daypartCode,
        orderType: query?.orderType,
        beCode: query?.beCode,
        couponCode: query?.couponCode,
        couponId: query?.couponId,
        promotionId: query?.promotionId,
        date: curDateInfo?.date,
        time: curDateInfo?.time,
      } as GetCouponProductListQuery,
    ],
    {
      filter: (p) => Boolean(p.daypartCode && p.storeCode && p.orderType && p.couponCode && p.couponId),
      cache: false,
    },
  );

  const onAddToCart = useCallback(async () => {
    if (checkedCode) {
      const _checkedProduct = productsRes?.data?.productList?.find((p) => p.productCode === checkedCode);

      if (_checkedProduct) {
        try {
          await updateCart({
            cartType: '1',
            orderType,
            daypartCode: query?.daypartCode || '',
            storeCode: query?.storeCode || '',
            beCode: query?.beCode,
            date: curDateInfo?.date,
            time: curDateInfo?.time,
            products: [
              {
                quantity: 1,
                code: _checkedProduct.productCode,
                name: _checkedProduct.productName,
                type: _checkedProduct.productType,
                couponCode: query?.couponCode,
                couponId: query?.couponId,
                promotionId: query?.promotionId,
                operationType: 1,
                pageSource: '7',
              },
            ],
          });

          navigate(-1);
        } catch (error) {
          const catchErrMsg = (error as Error)?.message;
          const errMsg = typeof catchErrMsg === 'string' && catchErrMsg ? catchErrMsg : '加入购物车失败';
          Toast.show({
            content: errMsg,
            position: 'top',
          });
        }
      }
    }
  }, [
    checkedCode,
    curDateInfo?.date,
    curDateInfo?.time,
    navigate,
    orderType,
    productsRes?.data?.productList,
    query?.beCode,
    query?.couponCode,
    query?.couponId,
    query?.daypartCode,
    query?.promotionId,
    query?.storeCode,
    updateCart,
  ]);

  useEffect(() => {
    if (productsRes?.data?.productList) {
      const _defaultCode = productsRes?.data?.productList?.[0]?.productCode;
      if (_defaultCode) {
        setCheckedCode(_defaultCode);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepMemo([productsRes?.data?.productList]));

  const supportSafeArea = useSupportSafeArea();

  return (
    <div className={styles.wrapper}>
      <div className={styles.scroll}>
        <div className={styles.coupon}>
          <div className={styles.couponName}>{query?.name || ''}</div>
          <div className={styles.couponSub}>适用于以下可售餐品，请任选其一使用</div>
          <Image className={styles.couponImgLeft} placeholder={null} src={getCDNAssets('title_banner_left.png')} />
          <Image className={styles.couponImgRight} placeholder={null} src={getCDNAssets('title_banner_right.png')} />
        </div>
        {productsRes.data?.productList?.map((p) => {
          const checked = checkedCode === p.productCode;
          const { eatInPrice, eatInPriceText, deliveryPrice, deliveryPriceText, discountPrice, discountPriceText } =
            p.priceInfo || {};

          const [_originPrice, _originPriceText] =
            orderType === 2 ? [deliveryPrice, deliveryPriceText] : [eatInPrice, eatInPriceText];

          const [priceText, separatePriceText] = (function () {
            if (_originPrice && p.count && p.count > 1) {
              const _originPriceTotal = _originPrice * p.count;
              if (discountPrice != null && _originPrice > discountPrice) {
                const _discountPriceTotal = discountPrice * p.count;
                return [String(_discountPriceTotal / 100), String(_originPriceTotal / 100)];
              }

              return [String(_originPriceTotal / 100), undefined];
            }

            let _separatePriceText = undefined;
            if (discountPriceText && _originPriceText && (_originPrice || 0) > (discountPrice || 0)) {
              _separatePriceText = _originPriceText;
            }
            return [discountPriceText || _originPriceText, _separatePriceText];
          })();

          return (
            <div key={p.productCode} className={styles.product}>
              <Image
                className={styles.productImg}
                src={p.productImage}
                fit="scale-down"
                fallback={<Image placeholder={null} src={getCDNAssets('menu_list_default_img.png')} />}
                placeholder={<Image placeholder={null} src={getCDNAssets('menu_list_default_img.png')} />}
              />
              <div className={styles.productContent}>
                <div className={styles.productName}>
                  {p.count && p.count > 1 && <span className={styles.productCount}>{p.count}份</span>}
                  {p.productName}
                </div>
                {p.defaultComboItemsText && <div className={styles.productCombo}>{p.defaultComboItemsText}</div>}
                <ProductCardPrice className={styles.productPrice} price={priceText} separatePrice={separatePriceText} />
              </div>
              <div className={styles.productSelect}>
                <Image
                  className={styles.productSelectIcon}
                  placeholder={null}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!checked) {
                      setCheckedCode(p.productCode);
                    }
                  }}
                  src={checked ? getCDNAssets('user_checked.png') : getCDNAssets('user_unchecked.png')}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.footer} data-supportsafearea={supportSafeArea}>
        <Button
          disabled={!checkedCode || productsRes.isLoading}
          color="primary"
          shape="rounded"
          block
          data-blod
          onClick={onAddToCart}>
          加入购物车
        </Button>
      </div>
    </div>
  );
};

export default ProductCouponSelect;
