import React, { useEffect, useMemo, useState } from 'react';
import cls from 'classnames';
import { Ellipsis } from 'antd-mobile';
import type { StoreItem } from '@/apis/product/types';
import type { ConfirmationPickupInfo_ } from '@/apis/orderConfirm.type';
import { order_shop_icon, check_icon } from '@/constants';
import { eatTypeList, imgSrcMap, filterEatTypeCodes } from '../../constant';
import { getCDNAssets } from '@/utils';
import './index.less';
interface IProps {
  // storeInfo: StoreItem;
  storePickUpInfo: ConfirmationPickupInfo_;
  onStoreChange?: () => void;
  eatTypeCode?: string;
  onEatTypeCodeChange?: (code: string) => void;
}

const StoreInfo: React.FC<IProps> = (props) => {
  const { storePickUpInfo, onStoreChange, eatTypeCode, onEatTypeCodeChange } = props;

  const { storeAddress, storeName, eatTypeOptions } = storePickUpInfo || {};

  const filterEatTypeOptions = useMemo(
    () => (eatTypeOptions || []).filter(({ code }) => filterEatTypeCodes.includes(code!)),
    [eatTypeOptions],
  );

  if (!storePickUpInfo) return null;

  return (
    <div className="store-info-container">
      <div className="base-info">
        <div className="left">
          <div className="name">{storeName}</div>
          <div className="address">{storeAddress}</div>
        </div>
        <div
          className="right"
          onClick={() => {
            onStoreChange && onStoreChange();
          }}>
          <img src={order_shop_icon} />
          <div className="btn-wrapper">
            <span className="btn">切换餐厅</span>
          </div>
        </div>
      </div>
      <div className="eat-type-wrapper">
        {filterEatTypeOptions.map((i) => {
          const { code, text, subText } = i || {};
          const imgSrc = imgSrcMap[code as keyof typeof imgSrcMap];
          const selected = code === eatTypeCode;
          return (
            <div
              key={code}
              className={cls('item', {
                selected,
                multiple: filterEatTypeOptions.length > 2,
                single: filterEatTypeOptions.length === 1,
              })}
              onClick={() => {
                onEatTypeCodeChange && onEatTypeCodeChange(code!);
              }}>
              <div className="item-content">
                <img className="icon" src={imgSrc} />
                <div className="eat-info">
                  <span className="title">{text}</span>
                  <Ellipsis className="subTitle" content={subText || ''} />
                  {/* <span className="subTitle">{subText}</span> */}
                </div>
                {selected && <img className="selected" src={check_icon} />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StoreInfo;
