/* eslint-disable @typescript-eslint/no-floating-promises */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCDNAssets, getPointUrl } from '@/utils';
import './index.less';
import { fetchUserInfo } from '@/apis/home';
import type { UserInfo } from '@/apis/home';
import { fetchRightCards } from '@/apis/my';
import type { MemberAssetsItem } from '@/apis/my';
import { MY_ADDRESS, USER_INFO, CARD_LIST, OUT_POINT, PROTOCOL, OPTIONS, INVOICE } from '@/constants/path';
import { useCookies } from 'react-cookie';
const rightArrow: string = getCDNAssets('right_arrow.png');
import useTitle from '@/hooks/useTitle';
import useLogin from '@/hooks/useLogin';
import { trackEvent } from '@/utils/tracker';

const itemList = [
  {
    title: '我的地址',
    link: MY_ADDRESS,
    needLogin: true,
  },
  {
    title: '发票管理',
    link: INVOICE,
  },
  // {
  //   title: '投诉与建议',
  //   link: '',
  // },
  {
    title: '条款与规则',
    link: PROTOCOL,
  },
  {
    title: '设置',
    link: OPTIONS,
    needLogin: true,
  },
];

const MyPage = () => {
  useTitle('我的');
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [useInfo, setUseInfo] = useState<UserInfo>();
  const [memberAssets, setMemberAssets] = useState<MemberAssetsItem[]>([]);
  const [availablePoints, setAvailablePoints] = useState('0');
  const [cookie] = useCookies<string, { [key: string]: string }>(['sid']);
  const getUserInfo = useCallback(() => {
    fetchUserInfo()
      .then((res) => {
        res && setUseInfo(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const getCards = useCallback(() => {
    fetchRightCards()
      .then((res) => {
        if (res) {
          const { memberAssets: mem = [] } = res;
          if (mem && mem.length) {
            setMemberAssets(mem);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getSubtitle = (item: MemberAssetsItem) => {
    const { subTitle = '', subTitleNum = '' } = item;
    const strs = subTitle.split('$');
    if (strs.length === 1) return <span className="member-item-subTitle">{strs[0]}</span>;
    return (
      <span className="member-item-subTitle">
        {strs[0]}
        {subTitleNum}
        {/* <span className="member-item-subNum">{subTitleNum}</span> */}
        {strs[1]}
      </span>
    );
  };
  const handletoPage = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate],
  );
  const handletoH5Page = useCallback(() => {
    const url = getPointUrl();
    if (cookie?.sid) {
      const realUrl = url + '&sid=' + cookie?.sid;
      trackEvent('iconClick', {
        belong_page: '我的',
        module_name: '个人信息',
        rank: 3,
        icon_name: '积分',
        url: realUrl,
      });
      window.location.href = realUrl;
    }
  }, [cookie?.sid]);

  useEffect(() => {
    setIsLogin(cookie?.sid ? true : false);
    if (isLogin) {
      getUserInfo();
      getCards();
    }
  }, [cookie, isLogin]);

  const handleLoginSuccess = useCallback(() => {
    setIsLogin(true);
  }, []);

  const { navLogin, btn: btnDom } = useLogin({ onSuccess: handleLoginSuccess, ignoreInit: true });

  useEffect(() => {
    if (!useInfo?.availablePoints) return;
    setAvailablePoints(useInfo.availablePoints);
  }, [useInfo]);

  return (
    <div className="usercenter">
      {isLogin ? (
        <div className="loginmess">
          <div className="user_login_avatar">
            <div className="left">
              <div
                className="avatarCon"
                onClick={() => {
                  trackEvent('iconClick', {
                    belong_page: '我的',
                    module_name: '个人信息',
                    rank: 1,
                    icon_name: '头像',
                    url: USER_INFO,
                  });
                  handletoPage(USER_INFO);
                }}>
                <img src={useInfo?.headImg || 'https://img.mcd.cn/gallery/0fc708ab0912a303.png'} className="avatar" />
              </div>
              <div>
                <div style={{ width: 345 - 204, fontWeight: 'bold' }}>{useInfo?.fullName || '麦麦'}</div>
                <div className="accountcon">
                  <img src="https://img.mcd.cn/gallery/d8701baa9a40fe1f.png" className="core-icon" />
                  <span
                    className="fen"
                    onClick={() => {
                      handletoH5Page();
                    }}>
                    <span className="account">{availablePoints}</span>
                  </span>
                  <img className="account_red_arrow" src={getCDNAssets('red_arrow.png')} />
                </div>
              </div>
            </div>
          </div>
          {memberAssets.length ? (
            <div className="member-panel">
              {memberAssets.map((item, index) => (
                <div
                  className="member-item"
                  key={index}
                  onClick={() => {
                    //测试券跳转
                    // handletoPage(`${CARD_LIST}?filterValue=pickup`);
                    trackEvent('iconClick', {
                      belong_page: '我的',
                      module_name: '会员资产',
                      rank: index + 1,
                      icon_name: item.title,
                      url: item.url,
                    });
                    handletoPage(item.url);
                  }}>
                  <div className="title-wrapper">
                    <span className="member-item-title">{item.title}</span>
                    {getSubtitle(item)}
                  </div>
                  <img src={item.image} />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <div className="pagebg">
            <img className="pagebg-img" src={getCDNAssets('pagebg.png')} />
            <div className="pagemess">
              <span>喜欢您来</span>
              <img className="star" src={getCDNAssets('stardeco.png')} />
            </div>
          </div>
          <div className="loginpanel">
            <span className="mes">周周领专属优惠，新会员首单即享免费圆筒</span>
            <button
              className="login"
              onClick={() => {
                trackEvent('buttonClick', {
                  belong_page: '我的',
                  module_name: '我的（未登录）',
                  button_name: '立即登录',
                });
                navLogin();
              }}>
              <div>
                <span>立即登录</span>
              </div>
            </button>
          </div>
        </div>
      )}

      <div className="entry" style={isLogin ? {} : { top: '-20px' }}>
        {itemList.map(
          ({ title, link, needLogin }) =>
            ((needLogin && isLogin) || !needLogin) && (
              <div
                key={title}
                className="entry-item"
                onClick={() => {
                  trackEvent('buttonClick', {
                    belong_page: '我的',
                    module_name: isLogin ? '我的（已登录）' : '我的（未登录）',
                    button_name: title,
                  });
                  navigate(link);
                }}>
                <span>{title}</span>
                <img src={rightArrow} />
              </div>
            ),
        )}
      </div>
      {btnDom}
    </div>
  );
};
export default MyPage;
